import React from 'react';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles((theme: any) => ({

}));

// const dropdownItems = [
//     { title: "About Us", content: "Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum." },
//     { title: "SB MaXX", content: "Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum." },
//     { title: "SB Maker", content: "Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum." },
//     { title: "SB Instafin", content: "Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum." }
// ];

const AboutUsDropdown: React.FC = () => {
    const classes = useStyles();

    return (
        // <div className={`${classes.container} py-10`}>
        //     <div className={`${classes.primaryContainer} flex gap-8`}>
        //         {dropdownItems.map((item, index) => (
        //             <div key={index} className={`${classes.dropdownContent}`}>
        //                 <h3 className='mb-4 font-bold'>{item.title}</h3>
        //                 <p className='text-lg'>{item.content}</p>
        //                 <div className={`${classes.aboutpreview} mt-8`}></div>
        //             </div>
        //         ))}
        //     </div>
        // </div>
        <div>
        </div>
    );
};

export default AboutUsDropdown;
