import React, { useEffect, useMemo, useState } from "react";
import { useLocation } from 'react-router-dom';
import bgImg from "../../../assets/images/authBackground.svg"
import { createUseStyles } from "react-jss";
import CompanyInformationTemplate from "./CompanyInformation.template";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import CloseIcon from '@mui/icons-material/Close';
import { AUTH_STATES } from "../../../utils/types";
import { useAuthenticatedUser } from "../../../hooks/useAuthenticatedUser";
import { useAuthService } from "../../../services/useAuthService";
import { Dialog, DialogContent } from '@mui/material';
import LoginWithMobileTemplate from "./LoginWithMobile.template";
import SignUpWithMobileTemplate from "./SignUpWithMobile.template";
import OtpVerificationTemplate from "./OtpVerification.template";
import LoginWithEmailTemplate from "./LoginWithEmail.template";
import PasswordTemplate from "./Password.template";
import RegisterEmailTemplate from "./RegisterEmail.template";
import EmailConfirmationTemplate from "./EmailConfirmation.template";
import steelBazaarLogo from "../../../assets/images/steelBazaarLogo.svg";
import closeIcon from "../../../assets/icons/closeRoundIcon.svg";
import Button from "../../atoms/Button/Button";

const useStyles = createUseStyles((theme: any) => ({
    dialogContainer: {
        "& .MuiDialog-paper": {
            width: "470px !important",
            maxWidth: "470px !important",
            borderRadius: "16px"
        },
    },
    header: {
        borderBottom: `1px solid ${theme.palette.border.neutral.neutral100}`,
    },
    backgroundImg: {
        backgroundImage: `url(${bgImg})`,
    },
    closeButton: {
        cursor: 'pointer',
    },
    sectionText: {
        color: "#1C3F9F"
    },
    errorMessage: {
        color: "#EF0000"
    },
    separator: {
        color: "#5D5D5D",
        '& > span:nth-child(odd)': {
            flex: 1,
            border: `1px solid #5D5D5D`,
            height: '1px',
        },
    }
}))

export interface IncognitoUser {
    email?: string,
    mobileNumber?: string,
    fullName?: string,
    gstin?: string,
    isGstinVerified?: boolean
}

export enum EMAIL_TYPE {
    EMIAL_VERIFICATION = "EMIAL_VERIFICATION",
    FORGET_PASSWORD = "FORGET_PASSWORD"
}

const AuthenticationTemplate: React.FC = () => {
    const classes = useStyles();
    const location = useLocation();
    const { user, isAuthDialogActive, syncAuthDialogActive } = useAuthenticatedUser();
    const [authState, setAuthState] = useState<AUTH_STATES>(() => {
        return new URLSearchParams(location.search).get('token')?.length ?
            AUTH_STATES.SET_PASSWORD : AUTH_STATES.LOGIN_WITH_MOBILE;
    });
    const authService = useAuthService();
    const [userDetails, setUserDetail] = useState<IncognitoUser>({ mobileNumber: user?.mobileNumber ?? "", email: user?.email, fullName: user?.fullName, gstin: user?.gstin, isGstinVerified: false });
    const [sentEmailType, setSentEmailType] = useState<AUTH_STATES>(AUTH_STATES.FORGOT_PASSWORD);
    const [otpVerificationProps, setOtpVerificationProps] = useState<({ otpVerifier: (verificationInfo: any) => Promise<any>, section: AUTH_STATES })>({
        otpVerifier: authService.login, section: AUTH_STATES.LOGIN_WITH_MOBILE
    });

    const updateUserDetails = (name: string, value: string | boolean) => {
        setUserDetail((prev: any) => ({ ...prev, [name]: value, }));
    }

    const authStateView = useMemo(() => {
        switch (authState) {
            case AUTH_STATES.LOGIN_WITH_MOBILE: return <LoginWithMobileTemplate setAuthState={setAuthState} mobileNumber={userDetails?.mobileNumber} updateUserDetails={updateUserDetails} styles={classes} />;
            case AUTH_STATES.USER_SIGNUP: return <SignUpWithMobileTemplate setAuthState={setAuthState} updateUserDetails={updateUserDetails} userDetails={userDetails} />;
            case AUTH_STATES.OTP_VERIFICATION: return <OtpVerificationTemplate setAuthState={setAuthState} userDetails={userDetails} {...otpVerificationProps} />;
            case AUTH_STATES.EMAIL_CONFIRMAITON: return <EmailConfirmationTemplate setAuthState={setAuthState} sentEmailType={sentEmailType} userDetails={userDetails} authState={authState} />;
            case AUTH_STATES.EMAIL_REGISTRATION: return <RegisterEmailTemplate setAuthState={setAuthState} updateUserDetails={updateUserDetails} setSentEmailType={setSentEmailType} authState={authState} />;
            case AUTH_STATES.FORGOT_PASSWORD: return <RegisterEmailTemplate setAuthState={setAuthState} updateUserDetails={updateUserDetails} setSentEmailType={setSentEmailType} authState={authState} />;
            case AUTH_STATES.SET_PASSWORD: return <PasswordTemplate setAuthState={setAuthState} />;
            default: return <LoginWithEmailTemplate setAuthState={setAuthState} styles={classes} />;
        }
    }, [authState, userDetails]);

    useEffect(() => {
        if (authState === AUTH_STATES.LOGIN_WITH_MOBILE || authState === AUTH_STATES.USER_SIGNUP) {
            setOtpVerificationProps(authState === AUTH_STATES.LOGIN_WITH_MOBILE ? {
                otpVerifier: authService.login,
                section: AUTH_STATES.LOGIN_WITH_MOBILE
            } : {
                otpVerifier: authService.signUp,
                section: AUTH_STATES.USER_SIGNUP
            });
        }
    }, [authState]);

    const handleDialogClose = (event: React.MouseEvent<HTMLSpanElement, MouseEvent>, reason: "backdropClick" | "escapeKeyDown" | null) => {
        if (reason === "backdropClick" || reason === "escapeKeyDown")
            return;
        syncAuthDialogActive(false);
        setAuthState(AUTH_STATES.LOGIN_WITH_MOBILE);
    };

    const handleBackState = () => {
        setAuthState((prev) => {
            switch (prev) {
                case AUTH_STATES.EMAIL_CONFIRMAITON: default: return sentEmailType;
                case AUTH_STATES.FORGOT_PASSWORD: return AUTH_STATES.LOGIN_WITH_EMAIL;
                case AUTH_STATES.OTP_VERIFICATION: return otpVerificationProps.section;
            }
        })
    }

    useEffect(() => {
        setUserDetail({ mobileNumber: user?.mobileNumber ?? "", email: user?.email, fullName: user?.fullName })
    }, [isAuthDialogActive, user])

    return (
        <Dialog fullWidth className={classes.dialogContainer} PaperProps={{ style: { height: 'auto' } }}
            open={isAuthDialogActive} onClose={handleDialogClose}>
            <div className="w-full h-full rounded-3xl p-6 grid gap-y-3">
                <div className="grid gap-y-8">
                    <div className={`${classes.header} flex justify-between pb-4`}>
                        <img className="w-[234px]" src={steelBazaarLogo} alt="AVIGHNA STEEL" />
                        <div className={`flex justify-end w-full`}>
                                <div className={`cursor-pointer`} onClick={(event) => handleDialogClose(event, null)} >
                                    <img src={closeIcon} alt="closeIcon" />
                                </div>
                        </div>
                    </div>
                    <div className="grid gap-y-6 items-center w-full">
                        <CompanyInformationTemplate section={authState} sentEmailType={sentEmailType} />
                        {authStateView}
                    </div>
                </div>
                {/* <div className="flex justify-center">
                    {([AUTH_STATES.FORGOT_PASSWORD, AUTH_STATES.OTP_VERIFICATION].includes(authState))
                        && <Button size="small" variant="secondaryText" label={"Back"} onClick={handleBackState} />}
                </div> */}
            </div>

        </Dialog>
    )
}

export default AuthenticationTemplate;