import React, { useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import Button from '../../../atoms/Button/Button';
import { News, useNewsService } from '../../../../services/useNewsService';

const useStyles = createUseStyles((theme: any) => ({
    container: {
        padding: "80px 0px"
    },
    heading: {
        color: theme.palette.text.neutral.neutral800,
        lineHeight: "58px",
        "& span": {
            color: theme.palette.text.primary.primary500,
        },
    },
    subheading: {
        fontSize: '32px',
        color: theme.palette.text.neutral.neutral700,
        lineHeight: "38px",
        fontStyle: 'italic',
    },
    mainContainer: {
        border: `1px solid ${theme.palette.border.neutral.neutral100}`,
    },
    largeImage: {
        borderRadius: "10px"
    },
    newsHeading: {
        color: theme.palette.text.primary.primary900,
        lineHeight: "33.6px"
    },
    newsDescription: {
        color: theme.palette.text.neutral.neutral700,
        lineHeight: "20.7px"
    },
    smallImage: {
        borderRadius: "10px",
        width: "160px",
        height: "148px",
    },
    samllNewsDescription: {
        color: theme.palette.text.neutral.neutral700,
        lineHeight: "16.1px"
    },
    chip: {
        color: theme.palette.text.complementary.complementary600,
        background: theme.palette.background.complementary.complementary50,
        borderRadius: '4px',
        border: `1px solid ${theme.palette.border.complementary.complementary100}`,
    },
    background: {
        background: "#d3d3d3",
    }
}));

const NewsTemplate: React.FC = () => {

    const classes = useStyles();
    const [loading, setLoading] = useState<boolean>(true);
    const newsService = useNewsService();
    const [news, setNewsTo] = useState<News[]>([]);

    const loadNews = async () => {
        setLoading(true);
        try {
            const params = {
                limit: 10,
                page: 1,
            };
            const newsResponse = await newsService.getNews(params);
            if (newsResponse?.data?.data?.length) {
                setNewsTo(newsResponse.data.data);
            } else {
                throw new Error("News fetch failed");
            }
        } catch (error) {
            setNewsTo([]);
        }
        setLoading(false);

    };

    useEffect(() => {
        loadNews();
    }, []);

    return (
        <div className={`${classes.container} grid gap-y-8`}>
            <div className='grid gap-y-2'>
                <div className={`${classes.heading} text-5xl font-bold text-center`}>Market <span>Insights and Updates</span></div>
                <div className={`${classes.subheading} text-center`}>Predict the Trends Stay Ahead</div>
            </div>

            {loading ? (
                <div className={`flex gap-4 w-full`}>
                    <div className={`w-[642px] h-[548px] animate-pulse rounded-lg ${classes.background}`}></div>
                    <div className='grid gap-y-3 w-[642px]'>
                        <div className={`w-full h-[172px] animate-pulse rounded-lg ${classes.background}`}></div>
                        <div className={`w-full h-[172px] animate-pulse rounded-lg ${classes.background}`}></div>
                        <div className={`w-full h-[172px] animate-pulse rounded-lg ${classes.background}`}></div>
                    </div>
                </div>
            ) : (
                <div className={`flex gap-4`}>
                    {news.splice(0, 1).map((item: any) => (
                        <div className={`${classes.mainContainer} flex flex-col gap-y-6 p-3 h-[551px] rounded-xl w-1/2`}>
                            <img src={item.banner} alt="" className={`${classes.largeImage} h-[290px] w-full`} />
                            <div className='grid gap-y-4'>
                                <div className={`${classes.chip} py-1 px-2 text-xs w-fit`}>Notification</div>
                                <div className='grid gap-y-2'>
                                    <div className={`${classes.newsHeading} text-2xl font-semibold`}>{item.title.substring(0, 30)}...</div>
                                    <div className={`${classes.newsDescription} text-lg`}>{item.content.replace(/<[^>]+>/g, '').substring(0, 280)}...</div>
                                </div>
                                <a href={`https://news.steelbazaar.com/news/${item.slug}`} target="_blank" rel="noopener noreferrer" className='w-fit'>
                                    <Button variant="secondaryText" label="Read More" size='small' />
                                </a>
                            </div>
                        </div>
                    ))}

                    <div className='w-1/2 flex flex-col gap-4'>
                        {news.splice(1, 3).map((item: any) => (
                            <div className={`${classes.mainContainer} flex gap-x-4 rounded-xl p-3`}>
                                <img className={`${classes.smallImage}`} src={item.banner} alt="" />
                                <div className={`grid gap-y-2`}>
                                    <div className='flex gap-x-2'>
                                        <div className={`${classes.newsHeading} text-2xl font-semibold`}>{item.title.substring(0, 45)}...</div>
                                        <div className={`${classes.chip} py-1 px-2 text-xs h-fit`}>Notification</div>
                                    </div>
                                    <div className={`${classes.samllNewsDescription} text-sm`}>{item.content.replace(/<[^>]+>/g, '').substring(0, 120)}...</div>
                                    <a href={`https://news.steelbazaar.com/news/${item.slug}`} target="_blank" rel="noopener noreferrer" className='w-fit'>
                                        <Button variant="secondaryText" label="Read More" size='small' />
                                    </a>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};

export default NewsTemplate;