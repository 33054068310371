import * as React from 'react';
import { createUseStyles } from 'react-jss';
import Button from '../../atoms/Button/Button';
import BussinessProfileAboutUsEditTemplate from './BussinessProfileAboutUsEditTemplate';
import noData from '../../../assets/images/noData.svg';
import { useState } from 'react';
import { getProfileDisplayData } from '../../../utils/helper';

const useStyles = createUseStyles((theme: any) => ({
    container: {
        border: `1px solid ${theme.palette.border.neutral.neutral100}`,
    },
    primaryContainer: {
        border: `1px solid ${theme.palette.border.neutral.neutral100}`,
    },
    secondaryContainer: {
        background: theme.palette.background.neutral.neutral50,
    },
    container_heading: {
        color: theme.palette.text.primary.primary900,
    },
    secondaryText: {
        color: theme.palette.text.neutral.neutral800,
    },
    borderBottom: {
        borderBottom: `1px solid ${theme.palette.border.neutral.neutral100}`,
    },
    card_content: {
        "& p": {
            color: theme.palette.text.neutral.neutral700,
        }
    },
}));

interface BussinessProfileAboutUsTemplateProps {
    businessProfile: any;
    handleSection: any;
    updateBusinessProfileSection: (section: string, values: any) => void;
    handleUpdateBusinessProfile: () => void;
}

const BussinessProfileAboutUsTemplate: React.FC<BussinessProfileAboutUsTemplateProps> = ({ businessProfile, handleSection, updateBusinessProfileSection, handleUpdateBusinessProfile }) => {
    const classes = useStyles();
    const [isEditing, setIsEditing] = useState(false);

    const handleEditClick = () => {
        setIsEditing(true);
    };

    const handleSuccess = () => {
        setIsEditing(false);
    };

    const handleBack = () => {
        setIsEditing(false);
        handleUpdateBusinessProfile();
    };

    return (
        <>
            {isEditing ? (
                <BussinessProfileAboutUsEditTemplate
                    businessProfile={businessProfile}
                    updateBusinessProfileSection={updateBusinessProfileSection}
                    onSuccess={handleSuccess}
                    onBack={handleBack}
                />
            ) : (
                <>
                    {businessProfile?.description ? (
                        <div className={`${classes.container} p-4 grid gap-y-6 rounded-xl w-full`}>
                            <div className={`flex justify-between items-center pb-6 ${classes.borderBottom}`}>
                                <h2 className={`${classes.container_heading} text-lg font-medium`}>About Us</h2>
                                <Button variant={'tertiaryContained'} label={'Edit'} onClick={handleEditClick} />
                            </div>
                            <div className={`${classes.card_content} grid gap-y-1`}>
                                <p className='font-medium text-base'>{getProfileDisplayData(businessProfile?.description)}</p>
                            </div>
                        </div>
                    ) : (
                        <div className={`${classes.secondaryContainer} py-8 flex justify-center w-full rounded-3xl`}>
                            <div className='flex flex-col items-center'>
                                <img src={noData} alt="" className='mb-8' />
                                <p className={`${classes.secondaryText} mb-4 text-base font-semibold`}>You haven't provided Description for your business.</p>
                                <Button variant={'primaryContained'} onClick={handleEditClick} label={"Add Description"} />
                            </div>
                        </div>
                    )}
                </>
            )}
        </>
    );
};

export default BussinessProfileAboutUsTemplate;
