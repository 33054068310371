import Tabs, { ITabsSchema } from '../../../molecules/Tabs/Tabs';
import RawMaterialListingTableTemplate from './RawMaterialListingTable.template';
import { useEffect, useState } from 'react';
import { HTTP_STATUS } from '../../../../utils/types';
import { useSnackbar } from '../../../../hooks/useSnackBar';
import { useAdminMasterService } from '../../../../services/useAdminMasterService';
import { createUseStyles } from 'react-jss';
import { ICategory } from '../../Rfq/ProductRequirementForm.template';

interface Category {
    id: number, label: string, path: string
}

const useStyles = createUseStyles((theme: any) => ({
}));

const RawMaterialListingTemplate = () => {
    const classes = useStyles();
    const [categories, setCategories] = useState<ICategory[]>([]);
    const [activeTab, setActiveTab] = useState(1);
    const adminMasterService = useAdminMasterService();
    const { showSnackbar, SnackBarComponent } = useSnackbar();
    const [tabSchema, setTabSchema] = useState<ITabsSchema[]>([]);

    const loadProductCategories = () => {
        adminMasterService.getAllCategories({ level: 2, page: 0, size: 10 })
            .then(res => {
                if (res?.status === HTTP_STATUS.OK)
                    setCategories(res?.data?.data?.content)
            }).catch((error) => {
                showSnackbar('error', "Error Fetching Category");
            })
    }

    useEffect(() => {
        loadProductCategories();
    }, [])

    useEffect(() => {
        setTabSchema(categories?.map(category => ({ label: category.name, component: <RawMaterialListingTableTemplate category={{ id: category.id, label: category.name }} /> })) ?? [])
    }, [categories])

    return (
        <div className='grid gap-y-6'>
            {SnackBarComponent}
            <div className='w-full'>
                <Tabs schema={tabSchema} value={(activeTab).toString()} setValue={setActiveTab} fullWidth={false} />
            </div>
        </div>
    )
}

export default RawMaterialListingTemplate