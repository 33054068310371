import React, { useEffect, useState, useContext, useRef } from 'react'
import { useCatalogueService } from '../../../services/useCatalogueService';
import { IWarehouse } from '../../template/CatalogueDetail/WarehouseLocation.template';
import { CATALOGUE_TYPE_STATES, CLASS_STATES, HTTP_STATUS, VENDOR_PRODUCT_DETAILS_TYPE } from '../../../utils/types';
import Select from '../../atoms/Select/Select';
import ProductUpcAttributeTemplate from '../../template/CatalogueDetail/ProductUpcAttribute.template';
import { createUseStyles } from 'react-jss';
import CatalogueImageTemplate, { IImage } from '../../template/CatalogueDetail/CatalogueImage.template';
import { makeRoute, snakeCaseToTitleCase } from '../../../utils/helper';
import { useLocation } from 'react-router-dom';
import { ICartRequestBody, useCartService } from '../../../services/useCartService';
import Breadcrumbs from '../../atoms/BreadCrumbs/BreadCrumbs';
import { CartContext } from '../../../contexts/CartContext';
import PriceDetailsTemplate from '../../template/CatalogueDetail/PriceDetails.template';
import { useAuthenticatedUser } from '../../../hooks/useAuthenticatedUser';
import CarouselRef from 'react-multi-carousel';
import { CART_LIFE_CYCLE, CUSTOMER_ROUTES, DELIVERY_INFORMATION, PACKAGING_TYPES, PAYMENT_METHODS, REGEX, SELECT_CUSTOMIZATION, UNIT } from '../../../utils/constant';
import TermsAndConditionsCarousel from '../../template/CatalogueDetail/TermsAndConditionsCarousel.template';
import SingleProductUpcAttributeTemplate from '../../template/CatalogueDetail/SingleUpcAttribute.template';
import Disclaimer from '../../template/CatalogueDetail/Disclaimer.template';
import FAQTemplate from '../../template/CatalogueDetail/FAQ.template';
import AddToFavouriteTemplate from '../../template/CatalogueDetail/AddToFavourite.template';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PaymentDetailsTemplate from '../../template/CatalogueDetail/PaymentDetails.template';
import DeliveryDetailsTemplate from '../../template/CatalogueDetail/DeliveryDetails.template';
import ContactUs from '../../molecules/ContactUs/ContactUs.page';
import SelectCustomizationTemplate, { IValueAddedService } from '../../template/Customization/SelectCustomization.template';
import TermsConditionsTemplate from '../../template/CatalogueDetail/TermsConditions.template';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import TextFieldV2 from '../../atoms/Input/TextFieldV2';
import SimilarBrands from '../../template/CatalogueDetail/SimilarBrands.template';
import Button from '../../atoms/Button/Button';
import { useBusinessProfileService } from '../../../services/useBusinessProfileService';
import { useSnackbar } from '../../../hooks/useSnackBar';
import contactIcon from "../../../assets/images/contactImage.svg"
import callBlueIcon from "../../../assets/images/callBlueIcon.svg"
import whatsappReviewIcon from "../../../assets/images/whatsappReviewIcon.svg"
import PriceDetailsStoreFrontTemplate from '../../template/CatalogueDetail/PriceDetailsStoreFront.template';
import PaymentDetailsReviewFormTemplate from '../../template/Catalogue/PaymentDetailsReviewForm.template';
import { usePostProductService } from '../../../services/usePostProductService';
import SingleUpcAttributeReviewForm from '../../template/Catalogue/SingleUpcAttributeReviewForm.Template';
import { useVendorInventoryService } from '../../../services/useVendorInventoryService';
import { WarehouseProductDetail } from '../../template/VendorCatalogue/AddCatalogueReviewForm.template';

export interface IAttribute {
    id: number;
    name: string;
    uom?: string;
    fieldType?: string;
    attributeOptions: number | string | string[];
}

export interface ICatalogue {
    id: number;
    name: string;
    description: string;
    classType: string;
    standard: string;
    grade: string;
    brand: string;
    manufacturer: string;
    minimumPrice: number;
    maximumPrice: number;
    catalogueAttributes: {
        SPECIFICATION: IAttribute[];
        CLASSIFICATION: IAttribute[];
        GENERALIZATION: IAttribute[];
    };
    catalogueImages: IImage[];
    packagingType: String[];
    customization: String[];
    isRepresentationImage: boolean;
}

export interface IUpcProperty {
    name: string;
    minimum?: number;
    maximum?: number;
    roomTemperature?: string;
    otherTemperature?: string;
    uom?: string;
}

export interface ICatalogueUpc {
    id: number;
    upcPrice: number;
    superCategory: string;
    mainCategory: string;
    productCategory: string;
    attributes: {
        SPECIFICATION: IAttribute[];
        CLASSIFICATION: IAttribute[];
        GENERALIZATION: IAttribute[];
    };
    hsnCodes: number[];
    tensileProperties: IUpcProperty[];
    chemicalComposition: IUpcProperty[];
    mechanicalProperties: IUpcProperty[];
    warehouseDetails: IWarehouse[];
    minimumOrderQuantity?: number;
}

export interface ICurrentCartItem {
    attributes: Record<string, any>;
    upc: ICatalogueUpc | null;
    warehouse: IWarehouse | null;
    packaging: string;
    quantity: string;
    secondarySkuId: number | null;
    paymentType: string | null;
}

const useStyles = createUseStyles((theme: any) => ({
    leftContainer: {
        width: "900px",
    },
    middleChild: {
        width: "529px",
    },
    rightContainer: {
        width: "379px",
    },
    catalogueName: {
        color: theme.palette.text.primary.primary900,
        lineHeight: "28px"
    },
    customerSupport:{
        borderRadius:"16px",
        boxShadow:"0px 7px 18px 0px rgba(29, 54, 121, 0.12)",
        backgroundColor:"white"
    },
    customerSupportName:{
        color:theme.palette.text.primary.primary900
    },
    customnerSupportDetails:{
        color:theme.palette.text.neutral.neutral600
    },
    mainContainer: {
        gridTemplateColumns: "repeat(4, minmax(0, 1fr))"
    },
    header: {
        color: theme.palette.text.neutral.neutral900
    },
    container: {
        padding: "12px",
        border: `1px solid ${theme.palette.border.neutral.neutral100}`
    },
    cardHeading: {
        color: theme.palette.text.primary.primary500,
        fontSize: "18px"
    },
    cardDescription: {
        color: theme.palette.text.neutral.neutral600,
        lineHeight: "19px",
        letterSpacing: "0.014px"
    },
    vendorDetails:{
        backgroundColor:theme.palette.background.primary.primary50
    },
    vendorInfo:{
        color:theme.palette.text.primary.primary500
    },
    manufacturerType:{
        color:theme.palette.text.neutral.neutral600
    },
    value: {
        color: theme.palette.text.neutral.neutral700,
        fontSize: "16px",
        lineHeight: "20px"
    },
    selectionWrapper: {
        borderRadius: "10px",
        border: `1px solid ${theme.palette.border.primary.primary800}`,
        color: theme.palette.text.primary.primary800,
    },
    select: {
        "& .MuiInputBase-input": {
            color: theme.palette.text.neutral.neutral400,
            fontSize: "16px",
            background: "none",
            height:"40px",
            display:"flex",
            alignItems:"center"
        },
        "& .MuiSvgIcon-root": {
            width: "16px",
            height: "16px",
            cursor: "pointer",
            margin: "4px 8px 0px 0px",
            color: `${theme.palette.text.neutral.neutral400}60`,
        },
        background: theme.palette.background.neutral.neutral200,
    },
    selectContainer: {
        marginTop: "-26px",
    },
    deliveryTerms: {
        width: "124px !important",
    },
    inStock: {
        background: theme.palette.background.success.success50,
        border: `1px solid ${theme.palette.border.success.success100}`,
        color: theme.palette.text.success.success600,
    },
    webContainer: {
        display: "grid",
        padding: "0px 70px 108px 70px"
    },
    mobileContainer: {
        display: "none",
    },
    termsAndConditionsWidth: {
        width: "90%"
    },
    inputContainer: {
        display: "flex",
        columnGap: "24px"
    },
    inputField: {
        // marginTop: "-10px",
    },
    location: {
        border: `1px solid ${theme.palette.border.neutral.neutral200}`,
        background: "none !important",
    },
    catalogueDetails: {
        rowGap: "24px"
    },
    carouselContainer: {
        "& .react-multiple-carousel__arrow--right": {
            right: "0 !important"
        },
        "& .react-multiple-carousel__arrow--left": {
            left: "0 !important"
        }
    },
    incrementDecrement: {
        background: "white",
        color: theme.palette.text.primary.primary700,
    },
    minimumQuantity: {
        color: theme.palette.text.neutral.neutral500,
    },
    count: {
        background: 'white',
        color: theme.palette.text.neutral.neutral800,
        border: `1px solid ${theme.palette.border.neutral.neutral200}`,
        fontSize: "16px",
        fontWeight: 500,
    },
    selectedUpc: {
        color: theme.palette.text.neutral.neutral800,
        border: `1px solid ${theme.palette.border.neutral.neutral200}`,
        background: "white",
    },
    input: {
        border: "1px solid",
        borderColor: theme.palette.border.neutral.neutral200,
        fontSize: "16px",
        fontWeight: 500,
        borderRadius: 8,
        color: theme.palette.text.primary.primary900,
        "&:hover": {
            borderColor: theme.palette.border.primary.primary500,
            borderWidth: 2,
            outline: "none",
        },
        "& .MuiOutlinedInput-notchedOutline": {
            outline: "none",
            borderWidth: 0,
            borderColor: theme.palette.border.neutral.neutral200,
        },
        "&:focus-within": {
            outline: "none",
            borderColor: `${theme.palette.border.primary.primary500} !important`,
            borderWidth: "2px !important",
        },
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            outline: "none",
            borderWidth: 0,
            borderColor: `${theme.palette.border.primary.primary500} !important`,
        },
        "& .MuiInputBase-input": {
            padding: "16px !important"
        },
    },
    label: {
        color: theme.palette.text.neutral.neutral600,
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "20px"
    },
    borderBottom: {
        borderBottom: `1px solid ${theme.palette.border.neutral.neutral100}`,
    },
    "@media (max-width: 480px)": {
        webContainer: {
            display: "none"
        },
        mobileContainer: {
            display: "grid"
        },
        catalogueName: {
            color: theme.palette.text.primary.primary900,
        },
        termsAndConditionsWidth: {
            width: "auto"
        },
        inputContainer: {
            display: "grid",
            rowGap: "12px"
        },
        catalogueDetails: {
            rowGap: "16px"
        },
        carouselContainer: {
            "& .react-multiple-carousel__arrow--right": {
                right: "0 !important"
            },
            "& .react-multiple-carousel__arrow--left": {
                left: "0 !important"
            },
            "& .react-multi-carousel-dot-list": {
                top: "225px"
            },
            maxWidth: "287px",
            boxShadow: `rgba(0, 0, 0, 0.35) 0px 5px 20px`,
        },
        value: {
            fontSize: "16px",
        },
        selectContainer: {
            marginTop: "-10px"
        },
        input: {
            border: "1px solid",
            borderColor: theme.palette.border.neutral.neutral200,
            fontSize: "14px",
            fontWeight: 500,
            borderRadius: 8,
            color: theme.palette.text.primary.primary900,
            "&:hover": {
                borderColor: theme.palette.border.primary.primary500,
                borderWidth: 2,
                outline: "none",
            },
            "& .MuiOutlinedInput-notchedOutline": {
                outline: "none",
                borderWidth: 0,
                borderColor: theme.palette.border.neutral.neutral200,
            },
            "&:focus-within": {
                outline: "none",
                borderColor: `${theme.palette.border.primary.primary500} !important`,
                borderWidth: "2px !important",
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                outline: "none",
                borderWidth: 0,
                borderColor: `${theme.palette.border.primary.primary500} !important`,
            },
            "& .MuiInputBase-input": {
                padding: "16px !important"
            },
        },
        label: {
            color: theme.palette.text.neutral.neutral600,
            fontSize: "14px",
            fontWeight: 400,
            lineHeight: "16.1px"
        },
        selectionWrapper: {
            width: "fit-content"
        },
    },
}));

const responsive = {
    desktop: {
        breakpoint: { max: 3000, min: 1280 },
        items: 4,
        partialVisibilityGutter: 10
    },
    tablet: {
        breakpoint: { max: 1280, min: 980 },
        items: 3,
        partialVisibilityGutter: 10
    },
    mobile: {
        breakpoint: { max: 980, min: 670 },
        items: 2,
        partialVisibilityGutter: 10
    },
    "sm-mobile": {
        breakpoint: { max: 670, min: 0 },
        items: 1
    }
};

const StoreFrontCatalogDetails: React.FC = () => {

    const classes = useStyles();

    const { user, syncAuthDialogActive } = useAuthenticatedUser();

    const cartContextData = useContext(CartContext);

    const catalogueService = useCatalogueService();
    const cartService = useCartService();

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    const [catalogueId, setCatalogueIdTo] = useState<number | null>(null);
    const [catalogue, setCatalogueTo] = useState<ICatalogue | null>(null);
    const [catalogueUpc, setCatalogueUpcTo] = useState<ICatalogueUpc[] | null>(null);
    const [packagingTypes, setPackagingTypesTo] = useState<Record<string, string>>({});
    const [currentCartItem, setCurrentCartItemTo] = useState<ICurrentCartItem>({
        attributes: {},
        upc: null,
        warehouse: null,
        packaging: "WITHOUT_PACKAGING",
        quantity: "",
        secondarySkuId: null,
        paymentType: PAYMENT_METHODS.CASH_PAYMENT
    });
    const [moq, setMOQ] = useState<string>('');
    const [businessProfile, setBusinessProfileTo] = useState<any>();
    const bussinessProfileService = useBusinessProfileService();
    const { showSnackbar, SnackBarComponent } = useSnackbar();

    const carouselRef = useRef<CarouselRef>(null);

    const gridTemplate = { xs: 6, sm: 6, md: 6, lg: 4, xl: 4 };

    const [valueAddedService, setValueAddedServiceTo] = useState<IValueAddedService>({
        shearing: [],
        slitting: [],
    })
    const [productCombination, setProductCombination] = useState<boolean | null>(null);
    const [paymentType, setPaymentType] = useState<string | null>(PAYMENT_METHODS.CASH_PAYMENT);
    const [productDetails,setProductDetails]=useState<any>({})
    const postProductService = usePostProductService();
    const [catalogueDetails, setCatalogueDetails]=useState<any>({})
    const vendorInventoryService = useVendorInventoryService();
    const [productIdForDetails, setProductIdForDetails]=useState<number>()
    const [warehouseDetails, setWarehouseDetails]=useState<any>([]);
    const [selectedWarehouse, setSelectedWarehouse]=useState<any>(warehouseDetails.length > 0 ? warehouseDetails[0] : {});
    const [specifications, setSpecifications]=useState<any>(null)
    const [catalogImageData, setCatalogImageData]=useState<any>({})
    const [catalogueImages, setCatalogueImages]=useState([]);

    const handleWarehouseChange = (e: any) => {
        const selected = warehouseDetails.filter(({warehouseId} : any) => warehouseId===e.target.value)
        setSelectedWarehouse({...selected[0]});
    };

    const getProductDetails = async () => {
        if(productIdForDetails)
            try{
                const response=await postProductService.getProductDetailsById(productIdForDetails)
                if(response.status===HTTP_STATUS.OK){
                    setProductDetails(response.data.data)
                    setSpecifications({
                        mainCategory:response.data.data.mainCategory,
                        superCategory:response.data.data.superCategory,
                        productCategory:response.data.data.productCategory,
                        attributes:response.data.data.attributesMap
                    })
                }
            }
            catch(error){
                setProductDetails({});
            }
    }

    const getBusinessProfile = async () => {
        try {
          const businessProfileResponse = await bussinessProfileService.getBusinessProfile();
          if (businessProfileResponse.status === HTTP_STATUS.OK) {
            const profileData = businessProfileResponse?.data?.data;
            setBusinessProfileTo(profileData);
          }
        } catch (error) {
          showSnackbar('error', 'Business Profile fetch failed');
        }
    };

    const scrollToLabel = () => {
        const label = document.getElementById('scrollToLabel');
        if (label) {
            label.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }

        const label2 = document.getElementById('scroll-mobile');
        if (label2) {
            label2.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    };

    const handleAddToCart = async () => {
        if (!user) {
            syncAuthDialogActive()
            return;
        }
        const requiredQuantity = parseFloat(currentCartItem.quantity);
        if (!REGEX.FLOAT.test(currentCartItem.quantity?.toString())) {
            return;
        }
        if (catalogue && currentCartItem && currentCartItem.upc && currentCartItem.warehouse && currentCartItem.paymentType) {
            let cartRequestBody: ICartRequestBody = {
                catalogueName: catalogue.name,
                upcId: currentCartItem.upc.id,
                warehouseId: currentCartItem.warehouse?.id,
                quantity: requiredQuantity,
                uom: currentCartItem.warehouse?.uom || "MT",
                packagingType: currentCartItem.packaging,
                secondarySkuId: null,
                valueAddedServices: valueAddedService,
                paymentType: currentCartItem.paymentType
            }
            await cartService.saveToCart(cartRequestBody);
            await cartContextData.syncCart(CART_LIFE_CYCLE.ADD_PRODUCT);
        }
    }

    const handleQuantityChange = (eventOrValue: any) => {
        const quantity = typeof eventOrValue === 'string' ? eventOrValue : eventOrValue.target.value;
        if (REGEX.QUANTITY.test(quantity) || quantity === '') {
            setCurrentCartItemTo({
                ...currentCartItem,
                quantity
            });
        }
    };

    const handlePaymentTypeChange = (paymentTypeMethod: string) => {
        setCurrentCartItemTo({
            ...currentCartItem,
            paymentType: paymentTypeMethod
        });
    };

    const convertWarehousesToOptions = (warehouses: any): any => {
        return warehouses.map((warehouse: any) => ({
            value: warehouse.warehouseId,
            label: warehouse.name.toLowerCase(),
        }));
    };

    const findMOQ = (attributes: IAttribute[]) => {
        return attributes?.find((attribute: IAttribute) => attribute.name === "MOQ")?.attributeOptions ?? 1;
    };

    const getProductImages = async () => {
        try{
            const catalogImages = await vendorInventoryService.getCatalogueImages(catalogImageData);
            setCatalogueImages(catalogImages.data.data)
        }
        catch(error){
            showSnackbar('error', "Images fetch failed")
        }
    }

    useEffect(() => {
        if(Object.values(catalogImageData).length > 1){
           getProductImages()
        }
    },[catalogImageData])

    useEffect(() => {
        const catalogueIdString = queryParams.get('catalogueId');
        if (catalogueIdString !== null) {
            const parsedCatalogueId = parseInt(catalogueIdString, 10);
            if (!isNaN(parsedCatalogueId)) {
                setCatalogueIdTo(parsedCatalogueId);
            }
        }
    }, [location.search])

    useEffect(() => {
        if (catalogueId) {
            catalogueService.getCatalogueById(catalogueId)
                .then((response) => {
                    if (response.status === HTTP_STATUS.OK) {
                        setCatalogueTo(response.data.data ? response.data.data : null);
                        setPackagingTypesTo(Object.fromEntries(response.data.data?.packagingTypes?.map((key: string) => [key, key])) ?? {});
                        setCurrentCartItemTo(prevCartItem => ({ ...prevCartItem, packaging: response.data.data?.packagingTypes[0] || null }));
                    }
                })
                .catch((error) => {
                    console.error('Error', error);
                    setCatalogueTo(null);
                });
        }
    }, [catalogueId]);

    useEffect(() => {
        if (catalogueId) {
            catalogueService.getAllUpcsByCatalogueId(catalogueId)
                .then((response) => {
                    setCatalogueUpcTo(response.status === HTTP_STATUS.OK && response.data.data ? response.data.data : null);
                    setMOQ(response?.data?.data[0].minimumOrderQuantity)
                    setCurrentCartItemTo(prevCartItem => ({
                        ...prevCartItem,
                        warehouse: response?.data?.data?.[0]?.warehouseDetails?.[0],
                        upc: response?.data?.data?.[0]
                    }));
                },)
                .catch((error) => {
                    console.error('Error', error);
                    setCatalogueUpcTo(null);
                })
        }
    }, [catalogueId]);

    useEffect(() => {
        let vendorId=businessProfile?.id;
        if(vendorId && catalogueId){
            catalogueService.getVendorCatalogueById(vendorId,catalogueId).then((response) => {
                setCatalogueDetails(response.data.data);
                setPaymentType(response.data.data.paymentTerms)
            })
            .catch((error) => {
                console.error("Error",error);
            })
        }
    },[businessProfile,catalogueId])

    const getVendorProductbyId = async () => {
        if (user?.businessId && catalogueDetails?.catalogueProductDetails?.length > 0) {
            try {
                const ProductsResponse = await vendorInventoryService.getDetailedProduct(user?.businessId, catalogueDetails?.catalogueProductDetails[0].vendorProductId, { vendorProductDetailsType: VENDOR_PRODUCT_DETAILS_TYPE.VENDOR_PRODUCT_USN_DETAILS });
                if (ProductsResponse.status === HTTP_STATUS.OK) {
                    const productData = ProductsResponse?.data?.data;
                    setProductIdForDetails(productData?.productId);
                    setCatalogImageData({
                        catalogueType:"CURRENT_INVENTORY",
                        brandName:productData.standardProductDetails.brand,
                        classType:productData.standardProductDetails.classType,
                        shape:productData.standardProductDetails.shape,
                        productCategoryId:productData.standardProductDetails.productCategoryId
                    })
                    setWarehouseDetails(productData?.warehouseWiseProductDetails.map(({deliveryTimeFrame,minimumOrderQuantity,warehouseId,warehouseName}:WarehouseProductDetail) => ({warehouseId,deliveryTimeFrame,name:warehouseName,minimumOrderQuantity})))
                }
            } catch (error) {
                showSnackbar('error', 'Detailed Product Price fetch failed');
            }
        }
    };

    useEffect(() => {
        if (warehouseDetails.length > 0) {
            setSelectedWarehouse({
                warehouseId: warehouseDetails[0].warehouseId,
                deliveryTimeFrame: warehouseDetails[0].deliveryTimeFrame,
                name: warehouseDetails[0].name,
                minimumOrderQuantity: warehouseDetails[0].minimumOrderQuantity,
            });
        }
    }, [warehouseDetails]); 

    useEffect(() => {
        setCurrentCartItemTo(prevCartItem => ({ ...prevCartItem, quantity: moq }));
    }, [moq]);

    const alterQuantity = (alterQuantityUnit: number) => () => {
        const currentQuantity = (parseFloat(currentCartItem.quantity) || 0).toString();
        handleQuantityChange(String(parseFloat(Math.max(parseFloat(currentQuantity) + alterQuantityUnit, parseFloat(moq)).toFixed(2))));
    };

    const increaseQuantity = () => {
        if (currentCartItem.warehouse) {
            alterQuantity(1)();
        }
    };

    const decreaseQuantity = () => {
        if (currentCartItem.warehouse) {
            alterQuantity(-1)();
        }
    };

    useEffect(() => {
        getProductDetails();
    },[productIdForDetails])

    useEffect(() => {
        getBusinessProfile();
    }, []);

    useEffect(() => {
        getVendorProductbyId()
    },[catalogueDetails])
    
    return (
        <div>
            <div className={`${classes.webContainer} max-container m-auto`}>
                <div className="flex justify-between">
                    <Breadcrumbs crumbs={[
                        {
                            link: makeRoute(CUSTOMER_ROUTES.CATALOGUE_LISTING, { query: { classes: catalogue?.classType ?? CLASS_STATES.STANDARD, catalogueTypes: CATALOGUE_TYPE_STATES.CURRENT_INVENTORY } }),
                            label: "Product Listing Page"

                        },
                        {
                            link: "",
                            label: "Product Description Page"
                        }
                    ]} />
                    <div className="flex justify-end items-end">
                        <ContactUs />
                    </div>
                </div>
                <div className='flex gap-x-6'>
                    <div className={`grid mt-6 ${classes.leftContainer}`}>
                        <div className={`${classes.borderBottom} w-full flex gap-x-6 pb-10`}>
                            <div className="grid gap-y-4 h-fit">
                                <CatalogueImageTemplate catalogueImages={catalogueImages} isRepresentationImage={catalogue?.isRepresentationImage} />
                                <TermsAndConditionsCarousel />
                                <AddToFavouriteTemplate id={catalogue?.id} catalogueType={CLASS_STATES.STANDARD} disableButton={true}/>
                            </div>
                            <div className={`${classes.middleChild} grid gap-y-6 h-fit mt-0 m-auto`}>
                                <div className={`${classes.catalogueName} text-2xl font-semibold`}>{catalogueDetails?.name}</div>
                                {/* TODO: Will uncomment after getting data from backend */}
                                {/* {DEALS_OF_THE_DAY_DATA.length && <DealsOfTheDayTemplate />} */}
                                <SingleUpcAttributeReviewForm attributes={productDetails?.attributesMap?.SPECIFICATION  || {}} />
                                    {catalogueDetails?.catalogueProductDetails?.length>0 && <div className="flex gap-3">
                                        <div className="grid gap-y-1" style={{minWidth:"165px"}}>
                                            <TextFieldV2
                                                label="Packaging"
                                                value={PACKAGING_TYPES.filter((item) => item.value === catalogueDetails?.catalogueProductDetails[0]?.packagingTypeEnum)[0]?.label || ''}
                                                // onChange={(event) => {
                                                //     setCurrentCartItemTo({
                                                //         ...currentCartItem,
                                                //         packaging: event.target.value,
                                                //     });
                                                // }}
                                                fullWidth
                                                InputProps={{ readOnly: true }}
                                                disabled
                                            />
                                        </div>
                                         <div className="flex flex-col gap-2">
                                            <div className={`${classes.value} text-base !leading-4 font-normal`}>Location</div>
                                            <div className={`-mt-1`}>
                                            {selectedWarehouse?.warehouseId &&
                                                <Select
                                                    label=""
                                                    variant='standard'
                                                    disableUnderline={true}
                                                    value={selectedWarehouse?.warehouseId}
                                                    fullWidth
                                                    onChange={(e: any) => handleWarehouseChange(e)}
                                                    className={`${classes.select} ${classes.location} !m-0 !pl-3 rounded-lg capitalize !h-full`}
                                                    name="warehouse"
                                                    id="warehouse"
                                                    IconComponent={(props: any) => <ExpandMoreIcon {...props} />}
                                                    options={convertWarehousesToOptions(warehouseDetails)}
                                                    />
                                                }
                                            </div>
                                        </div>
                                            <div className="grid gap-y-1" style={{minWidth:"130px"}}>
                                                <TextFieldV2
                                                    label="Delivery Terms"
                                                    value={DELIVERY_INFORMATION.filter(({value}) => value===selectedWarehouse?.deliveryTimeFrame)[0]?.label}
                                                    InputProps={{
                                                        disableUnderline: true,
                                                        readOnly: true,
                                                    }}
                                                    disabled
                                                />
                                            </div>
                                        <div className={`${classes.inStock} flex justify-end self-end py-1 px-2 text-xs font-medium h-fit whitespace-nowrap rounded`}>In Stock</div>
                                    </div>}
                                {currentCartItem?.upc && <div className="flex justify-between">
                                    <Button variant="tertiaryContained" label="Explore other Brands" onClick={scrollToLabel} />
                                </div>}
                            </div>
                        </div>

                        <div className={`py-10 ${classes.borderBottom}`}>
                            <div className="grid gap-y-3">
                                <span className={`${classes.header} text-base font-bold`}>{SELECT_CUSTOMIZATION.SELECT_CUSTOMIZATION}</span>
                                <div className={`${classes.mainContainer} grid gap-3`}>
                                    {catalogueDetails?.catalogueProductDetails?.length > 0 && catalogueDetails?.catalogueProductDetails[0]?.productServicesDetailsRequestBodyDTOs?.map((item: any) => (
                                        <div className={`${classes.container} flex flex-col gap-y-4 rounded-xl w-full`}>
                                            <div>
                                                <span className={`${classes.cardHeading} font-semibold`}>{item.serviceName}</span>
                                                <p className={`${classes.cardDescription} text-sm font-normal`}>{item.serviceDescription}</p>
                                            </div>
                                            <div className="mt-auto">
                                                <Button
                                                    fullWidth
                                                    variant="tertiaryContained"
                                                    label="Select"
                                                    size="medium"
                                                    // onClick={handleSelectCustomization(item.value)}
                                                    disabled
                                                    />
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>

                        <div className={`py-10 ${classes.borderBottom}`}><PaymentDetailsReviewFormTemplate paymentType={paymentType} handlePaymentTypeChange={handlePaymentTypeChange} /></div>
                        <div className={`py-10 ${classes.borderBottom}`}><DeliveryDetailsTemplate /></div>
                        <div className={`py-10 ${classes.borderBottom}`}>{specifications && <ProductUpcAttributeTemplate upc={specifications} />}</div>
                        {currentCartItem.upc !== null && <div className={`py-10 ${classes.borderBottom}`}><SimilarBrands currentCartItem={currentCartItem} catalogue={catalogue} catalogueType={CATALOGUE_TYPE_STATES.CURRENT_INVENTORY} /></div>}
                        <div className={`py-10 ${classes.borderBottom}`}><FAQTemplate /></div>
                        <div className={`py-10 ${classes.borderBottom}`}><TermsConditionsTemplate content={catalogueDetails?.termsAndConditions} /></div>
                        <div className={`pt-10`} id="scrollToDisclaimer"><Disclaimer /></div>
                    </div>
                    <div className={classes.rightContainer}>
                        <div className={`pt-6 sticky top-[150px] flex flex-col gap-4`}>
                            {<PriceDetailsStoreFrontTemplate handleAddToCart={handleAddToCart} 
                            paymentType={currentCartItem.paymentType} 
                            moq={String(selectedWarehouse?.minimumOrderQuantity) || ""} 
                            handleQuantityChange={handleQuantityChange} 
                            minimumPrice={catalogueDetails?.catalogueProductDetails?.filter((item:any) => item.warehouseId===selectedWarehouse?.warehouseId)[0]?.offerSalePrice} 
                            maximumPrice={catalogueDetails?.catalogueProductDetails?.filter((item:any) => item.warehouseId===selectedWarehouse?.warehouseId)[0]?.marketRetailPrice} 
                            quantity={currentCartItem.quantity} 
                            gst={18} 
                            // selectedUpc={currentCartItem?.upc} 
                            warehouse={currentCartItem.warehouse} 
                            uom={(currentCartItem?.warehouse?.uom)?.toString() || ""} 
                            catalogueType={CATALOGUE_TYPE_STATES.CURRENT_INVENTORY} 
                            // catalogue={catalogue} 
                            productCombination={productCombination} />}
                            <div className={`${classes.customerSupport} flex flex-col gap-3 p-4`}>
                                <div className='flex justify-between'>
                                    <div className='flex gap-3.5'>
                                        <img src={contactIcon} width={"38px"} height="38px" alt="contact icon" />
                                        <div>
                                            <div className={`${classes.customerSupportName} text-sm font-normal`}>{businessProfile?.pocs[0]?.name}</div>
                                            <div className={`${classes.customnerSupportDetails} text-xs font-normal`}>{`${businessProfile?.pocs[0]?.designation ? businessProfile?.pocs[0]?.designation : ""}${`${businessProfile?.pocs[0]?.designation ? " | " : ""}`}${businessProfile?.pocs[0]?.countryCode ? businessProfile?.pocs[0]?.countryCode : ""} ${businessProfile?.pocs[0]?.mobile ? businessProfile?.pocs[0]?.mobile : ""}`}</div>
                                        </div>
                                    </div>
                                    <div className='flex gap-2'>
                                        <img className='cursor-pointer' src={whatsappReviewIcon} alt="whatsapp" />
                                        <img className='cursor-pointer' src={callBlueIcon} alt="call" />
                                    </div>
                                </div>
                                <div className={`${classes.vendorDetails} flex flex-col gap-0.5 p-2 rounded-lg`}>
                                    <div className='flex justify-between'>
                                        <div className={`text-sm font-medium underline ${classes.vendorInfo} w-[250px]`}>{businessProfile?.name}</div>
                                        <div className={`text-sm font-normal ${classes.vendorInfo}`}>Vendor ID: {businessProfile?.id}</div>
                                    </div>
                                    <div className={`text-xs font-light ${classes.manufacturerType}`}>Manufacturer/Factory, Trading Company, Group Corporation</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className={`gap-y-6 ${classes.mobileContainer}`}>
                <div className={`grid gap-y-6 px-4`}>
                    <div className="">
                        <Breadcrumbs crumbs={[
                            {
                                link: makeRoute(CUSTOMER_ROUTES.CATALOGUE_LISTING, { query: { classes: catalogue?.classType ?? CLASS_STATES.STANDARD, catalogueTypes: CATALOGUE_TYPE_STATES.CURRENT_INVENTORY } }),
                                label: catalogue?.classType === CLASS_STATES.STANDARD ? snakeCaseToTitleCase(CLASS_STATES.STANDARD) : snakeCaseToTitleCase(CLASS_STATES.NONSTANDARD)
                            },
                            {
                                link: "",
                                label: "View"
                            }
                        ]} />
                    </div>
                    <div className="w-full grid gap-y-2 mb-2">
                        <div className={`${classes.catalogueName} text-lg font-semibold`}>{catalogue?.name}</div>
                        <CatalogueImageTemplate catalogueImages={catalogue?.catalogueImages} isRepresentationImage={catalogue?.isRepresentationImage} />
                    </div>
                    <div><SingleProductUpcAttributeTemplate attributes={catalogue?.catalogueAttributes?.SPECIFICATION || {}} /> </div>
                    <div className='border-t'> </div>
                    {currentCartItem?.upc && Object.keys(currentCartItem?.upc).length > 0 && (
                        <div className={`${classes.inputContainer}`}>
                            <div style={{minWidth:"170px"}}>   
                                <TextFieldV2
                                    label="Packaging"
                                    value={snakeCaseToTitleCase(currentCartItem.packaging)}
                                    onChange={(event) => {
                                        setCurrentCartItemTo({
                                            ...currentCartItem,
                                            packaging: event.target.value,
                                        });
                                    }}
                                    fullWidth
                                    disabled={true}
                                    />
                            </div>

                            {currentCartItem?.upc?.warehouseDetails?.length > 0 && (<div className="grid gap-y-2">
                                <div className={classes.label}>
                                    Location
                                </div>
                                <Select
                                    label=""
                                    value={currentCartItem.warehouse}
                                    fullWidth
                                    onChange={(e: any) => handleWarehouseChange(e)}
                                    name="warehouse"
                                    id="warehouse"
                                    options={convertWarehousesToOptions(currentCartItem?.upc?.warehouseDetails || [])}
                                    className={classes.input}
                                />
                            </div>)}

                            {currentCartItem?.upc?.warehouseDetails?.length > 0 && (
                                <TextFieldV2
                                    fullWidth
                                    label="Delivery Terms"
                                    value={"Within 3 Days"}
                                    disabled={true}
                                />
                            )}

                            {currentCartItem?.upc?.warehouseDetails?.length > 0 && <div className={`${classes.inStock} py-1 px-2 text-xs font-medium`}>
                                In Stock
                            </div>}

                            {currentCartItem?.upc && <div className="flex justify-between">
                                <div className={`${classes.selectionWrapper} text-sm py-2.5 px-4 font-medium cursor-pointer`} onClick={scrollToLabel}>
                                    Explore other Brands
                                </div>
                            </div>}
                        </div>
                    )}

                    <div className='border-t'> </div>
                    <div className="grid gap-y-3">
                        <div className={`${classes.value} font-medium`}>Enter Quantity (MT)</div>
                        <div className='flex gap-x-4'>
                            <div className={`w-8 h-8 my-auto flex rounded justify-center items-center  ${classes.incrementDecrement}`} onClick={decreaseQuantity} style={{ cursor: (currentCartItem.warehouse && (parseFloat(currentCartItem.quantity) > parseFloat(moq))) ? 'pointer' : 'not-allowed' }}><RemoveIcon /></div>
                            <input type="text" className={`w-24 h-9 rounded-lg flex text-center justify-center items-center gap-2  ${moq === "" ? classes.selectedUpc : classes.count}`} value={moq === "" ? "" : currentCartItem.quantity} disabled={moq === "" || !currentCartItem.warehouse} onChange={event => handleQuantityChange(event.target.value)} />
                            <div className={`w-8 h-8 my-auto flex rounded justify-center items-center  ${classes.incrementDecrement}`} onClick={increaseQuantity} style={{ cursor: currentCartItem.warehouse ? 'pointer' : 'not-allowed' }}><AddIcon /></div>
                        </div>
                        {!(moq === "") && <div className={`text-xs font-semibold ${classes.minimumQuantity}`}>Minimum Order Quantity : {moq}{UNIT.UOM} </div>}
                    </div>
                    <div className='border-t'> </div>
                    <div className={``}>
                        <SelectCustomizationTemplate
                            catalogue={catalogue}
                            secondaryCatalogue={null}
                            quantity={parseFloat(currentCartItem.quantity)}
                            selectedUpc={currentCartItem?.upc}
                            valueAddedService={valueAddedService}
                            setValueAddedServiceTo={setValueAddedServiceTo}
                        />
                    </div>
                    <div className='border-t'> </div>
                    <div className={``}><PaymentDetailsTemplate paymentType={paymentType} handlePaymentTypeChange={handlePaymentTypeChange} /></div>
                    <div className='border-t'> </div>
                    <div className={``}><DeliveryDetailsTemplate /></div>
                    <div className='border-t'> </div>
                    <div className={``}>{currentCartItem?.upc && currentCartItem && Object.keys(currentCartItem?.upc).length > 0 && <ProductUpcAttributeTemplate upc={currentCartItem?.upc} />}</div>
                    <div className='border-t'> </div>
                    {/* <div className={``} id='scroll-mobile'><SimilarBrands  currentCartItem={currentCartItem}/></div> */}
                    <div className='border-t'> </div>
                    <div className={``}><FAQTemplate /></div>
                    <div className='border-t'> </div>
                    <div className={``}><TermsConditionsTemplate /></div>
                    <div className='border-t'> </div>
                    <div className={``} id="scrollToDisclaimer"><Disclaimer /></div>
                </div>

                <div className="w-full sticky bottom-24 z-50">
                    <div className={`flex flex-col gap-4`}>
                        <div>
                            {<PriceDetailsStoreFrontTemplate handleAddToCart={handleAddToCart} 
                            paymentType={currentCartItem.paymentType} 
                            moq={String(selectedWarehouse?.minimumOrderQuantity) || ""} 
                            handleQuantityChange={handleQuantityChange} 
                            minimumPrice={catalogueDetails?.catalogueProductDetails?.filter((item:any) => item.warehouseId===selectedWarehouse?.warehouseId)[0]?.offerSalePrice} 
                            maximumPrice={catalogueDetails?.catalogueProductDetails?.filter((item:any) => item.warehouseId===selectedWarehouse?.warehouseId)[0]?.marketRetailPrice} 
                            quantity={currentCartItem.quantity} 
                            gst={18} 
                            // selectedUpc={currentCartItem?.upc} 
                            warehouse={currentCartItem.warehouse} 
                            uom={(currentCartItem?.warehouse?.uom)?.toString() || ""} 
                            catalogueType={CATALOGUE_TYPE_STATES.CURRENT_INVENTORY} 
                            // catalogue={catalogue} 
                            productCombination={productCombination} />}
                        </div>
                        <div className={`${classes.customerSupport} flex flex-col gap-3 p-4`}>
                            <div className='flex justify-between'>
                                <div className='flex gap-3.5'>
                                    <img src={contactIcon} width={"38px"} height="38px" alt="contact icon" />
                                    <div>
                                        <div className={`${classes.customerSupportName} text-sm font-normal`}>{businessProfile?.pocs[0]?.name}</div>
                                        <div className={`${classes.customnerSupportDetails} text-xs font-normal`}>{`${businessProfile?.pocs[0]?.designation ? businessProfile?.pocs[0]?.designation : ""}${`${businessProfile?.pocs[0]?.designation ? " | " : ""}`}${businessProfile?.pocs[0]?.countryCode ? businessProfile?.pocs[0]?.countryCode : ""} ${businessProfile?.pocs[0]?.mobile ? businessProfile?.pocs[0]?.mobile : ""}`}</div>
                                    </div>
                                </div>
                                <div className='flex gap-2'>
                                    <img className='cursor-pointer' src={callBlueIcon} alt="call" />
                                    <img className='cursor-pointer' src={whatsappReviewIcon} alt="whatsapp" />
                                </div>
                            </div>
                            <div className={`${classes.vendorDetails} flex flex-col gap-0.5 p-2 rounded-lg`}>
                                <div className='flex justify-between'>
                                    <div className='text-sm font-medium underline'>{businessProfile?.name}</div>
                                    <div className='text-sm font-normal'>Vendor ID: {businessProfile?.id}</div>
                                </div>
                                <div className='text-sm font-light'>Manufacturer/Factory, Trading Company, Group Corporation</div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
}

export default StoreFrontCatalogDetails;
