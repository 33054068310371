import React from 'react'
import { CONNECT_WITH_US_POPUP } from '../../../utils/constant';
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((theme: any) => ({
    contactUsWrapper: {
        display: "flex",
        width: "321px",
        flexDirection: "column",
        border: `1px solid ${theme.palette.border.neutral.neutral100}`,
        background: "white",
        boxShadow: `2px 2px 12px 0px rgba(0, 0, 0, 0.12)`,
        position: "absolute",
        zIndex: 4,
        marginBottom:'10px',
        marginLeft:"65px",
        cursor: "default",
        bottom:'70px'
    },
    detailsWrapper: {
        display: "flex",
        flexDirection: "column",        
        color: theme.palette.text.neutral.neutral800,
    },
    subText: {
        color: theme.palette.text.neutral.neutral600,
     
    },
}))

const CartContactUsPop: React.FC = () => {

    const classes = useStyles();

   return (
        <div className={`${classes.contactUsWrapper} cart-contact-us-popup p-4 gap-4 rounded-2xl items-start`}>
            {CONNECT_WITH_US_POPUP.map(({ icon, text, country, mobile, email, description }) => (
                <div className="flex gap-x-6">
                    <img src={icon} alt="icon" className="w-14 h-14" />
                    <div className={`${classes.detailsWrapper} items-start my-auto`}>
                        <span className="text-sm font-medium">{text}</span>
                        <span className={`text-sm font-medium leading-4 ${classes.subText}`}>{`${mobile ? country + " " + mobile : email}`}</span>
                        <p className="text-xs font-normal">{description}</p>
                    </div>
                </div>
            ))}
        </div>
    )
}

export default CartContactUsPop