import { createUseStyles } from "react-jss";
import { IProductSpecification } from "../../pages/CatalogueDetail/SecondaryCatalogueDetails.page";
import { formatUOM, sortSecondaryAttributes } from "../../../utils/helper";
import useMetaDataService from "../../../hooks/useMetaDataService";
import { ATTRIBUTE_LABEL, COLOR_DATA } from "../../../utils/constant";
import { IColorPalette } from "./AttributeSelection.template";

export interface ISecondaryProductAttributesTemplateProps {
    attributes: IProductSpecification[];
    netWeight: number;
    grossWeight: number;
}

const useStyles = createUseStyles((theme: any) => ({
    webContainer: {
        display: "grid"
    },
    container: {
        border: `1px solid ${theme.palette.border.neutral.neutral100}`,
    },
    attributes: {
        border: `1px solid ${theme.palette.border.secondary.secondary200}`,
        padding: "8px 12px",
        fontSize: "16px",
    },
    attributeNames: {
        color: theme.palette.text.neutral.neutral900,
    },
    attributeContainer: {
        fontSize: "14px"
    },
    attributeName: {
        color: theme.palette.text.neutral.neutral900,
        lineHeight: "16px"
    },
    uom: {
        fontSize: "12px"
    },
    attributOption: {
        color: theme.palette.text.neutral.neutral600,
    },
    attribute: {
        minWidth: "33%",
        margin: 0,
        '&:nth-child(n+4)': {
            borderTop: `1px solid ${theme.palette.border.neutral.neutral100}`,
        },
    },
    heading: {
        color: theme.palette.text.neutral.neutral900,
        lineHeight: "20px"
    },
    attributeValue: {
        color: theme.palette.text.primary.primary800,
    },
    mobileContainer: {
        display: "none"
    },
    "@media (max-width: 480px)": {
        webContainer: {
            display: "none"
        },
        container: {
            color: theme.palette.text.secondaryDark,
            border: `none`,
            padding: "0px",
        },
        mobileContainer: {
            display: "grid"
        },
        attributes: {
            fontSize: "14px",
            padding: "8px 12px",
        },
        attribute: {
            minWidth: "50%",
            '&:nth-child(n+4)': {
                borderTop: `none`,
            }
        },
        attributeContainer: {
            fontSize: "12px"
        },
        uom: {
            fontSize: "10px"
        },
        attributeNames: {
            color: theme.palette.text.neutral.neutral900,
        },
        attributeName: {
            color: theme.palette.text.neutral.neutral900,
        },
        attributOption: {
            color: theme.palette.text.neutral.neutral600,
        },
    }
}));

const SecondaryProductAttributesTemplate: React.FC<ISecondaryProductAttributesTemplateProps> = ({ attributes, netWeight, grossWeight }) => {
    const { metaData: filterData } = useMetaDataService();
    const classes = useStyles();

    const getAttributeValue = (attributeName: string, attributeValue: string) => {
        try {
            if(attributeName.trim().toLowerCase() !== ATTRIBUTE_LABEL.COLOR.toLowerCase()) {
                return attributeValue;
            }
              const colorPalette = COLOR_DATA[attributeValue as keyof IColorPalette];
            return (
                <div className="flex items-center">
                    <div className="w-6 h-4 mr-2 rounded-sm" style={{ backgroundColor: colorPalette.bgColor }}></div>
                    <span>{attributeValue}</span>
                </div>
            )
        } catch (error) {
            return attributeValue;
        }
	}

    return (
        <>
            <div className={`${classes.webContainer} gap-y-3 text-base font-semibold`}>
            <div className={`${classes.heading} text-base font-semibold`}>Specifications</div>
                <div className={`flex flex-wrap px-6 py-3 rounded-2xl ${classes.container} font-medium`}>
                    {sortSecondaryAttributes(attributes, filterData?.attributes)?.map((attribute: any, index: number) => {
                        let attributeOption = ''
                        try {
                            attributeOption = formatUOM("", attribute.uom || '')
                        } catch (error) {
                            console.error(error)
                            attributeOption = ""
                        }
                        if(attribute.name.trim().toLowerCase() === "hardness"){
                            return null;
                        }
                        return (
                            <div className={`flex flex-col py-3  m-auto flex-1 ${classes.attribute}`} key={index}>
                                <div className={`${classes.attributeNames} pb-2.5 justify-center flex text-sm`}>
                                    <div className={`text-center ${classes.attributeContainer}`}>
                                        <span className={`text-sm font-medium mr-1 ${classes.attributeName}`}>{attribute.name}</span>
                                        {attribute.uom && (
                                            <span className={`text-xs font-medium ${classes.attributOption}`}>({attribute.uom.toLowerCase()}) </span>
                                        )}
                                    </div>
                                </div>
                                <div className={`${classes.attributes} rounded-lg m-auto`}>{attribute.minValue === attribute.maxValue || attribute.maxValue === '' ? (
                                    <div className={`${classes.attributeValue} flex justify-center items-center text-center !py-0 rounded-lg text-base font-medium`}>{getAttributeValue(attribute.name, attribute.minValue)}</div>
                                ) : (
                                    <div className="flex gap-1">
                                        <div className={`${classes.attributeValue} flex justify-center items-center text-base font-medium`}>{getAttributeValue(attribute.name, attribute.minValue)}</div>
                                        <div>-</div>
                                        <div className={`${classes.attributeValue} flex justify-center items-center text-base font-medium`}>{getAttributeValue(attribute.name, attribute.maxValue)}</div>
                                    </div>
                                )}
                                </div>
                            </div>
                        )
                    })}
                    <div className="flex border-t w-full">
                        <div className={`flex flex-col !px-4 !py-4 m-auto flex-1 ${classes.attribute}`}>
                            <div className={`${classes.attributeNames} pb-2.5 justify-center flex text-base font-medium`}>
                                <div className={`text-center ${classes.attributeName}`}>Net Weight (MT)</div>
                            </div>
                            <div className={`${classes.attributes} rounded-lg m-auto`}>
                                <div className={`${classes.attributeValue} flex justify-center items-center !py-0 !px-3 rounded-lg text-base font-medium`}>{netWeight}</div>
                            </div>
                        </div>
                        <div className={`flex flex-col !px-4 !py-4 m-auto flex-1 ${classes.attribute}`}>
                            <div className={`${classes.attributeNames} pb-2.5 justify-center flex text-sm font-medium`}>
                                <div className={`text-center ${classes.attributeName}`}>Gross Weight (MT)</div>
                            </div>
                            <div className={`${classes.attributes} rounded-lg m-auto`}>
                                <div className={`${classes.attributeValue} flex justify-center items-center !py-0 !px-3 rounded-lg text-base font-medium`}>{grossWeight}</div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>


            <div className={`${classes.mobileContainer} gap-y-3`}>
                <div className={`${classes.heading} text-base font-semibold`}>Specifications</div>
                <div className={`flex flex-wrap rounded-2xl ${classes.container} font-medium`}>
                    {sortSecondaryAttributes(attributes, filterData?.attributes)?.map((attribute: any, index: number) => {
                        let attributeOption = ''
                        try {
                            attributeOption = formatUOM("", attribute.uom || '')
                        } catch (error) {
                            console.error(error)
                            attributeOption = ""
                        }
                        if(attribute.name.trim().toLowerCase() === "hardness"){
                            return null;
                        }
                        return (
                            <div className={`flex flex-col !px-4 !pb-4 m-auto flex-1 ${classes.attribute}`} key={index}>
                                <div className={`${classes.attributeNames} pb-2 justify-center flex`}>
                                    <div className={`text-center ${classes.attributeContainer}`}>
                                        <span className={`text-xs mr-1 ${classes.attributeName}`}>{attribute.name}</span>
                                        {attribute.uom && (
                                            <span className={`text-xs ${classes.attributOption}`}>({attribute.uom.toLowerCase()}) </span>
                                        )}
                                    </div>
                                </div>
                                {attribute.minValue === attribute.maxValue || attribute.maxValue === '' ? (
                                    <div className={`${classes.attributes} rounded-lg m-auto`}>
                                        <div className={`${classes.attributeValue} flex justify-center items-center text-center !py-0 rounded-lg text-base font-medium`}>{getAttributeValue(attribute.name, attribute.minValue)}</div>
                                    </div>
                                ) : (
                                    <div className="flex">
                                        <div className={`${classes.attributes} rounded-lg m-auto`}>
                                            <div className={`${classes.attributeValue} flex justify-center items-center !py-0 rounded-lg text-base font-medium`}>{getAttributeValue(attribute.name, attribute.minValue)}</div>
                                        </div>
                                        <div className="flex items-center mx-1">-</div>
                                        <div className={`${classes.attributes} rounded-lg m-auto`}>
                                            <div className={`${classes.attributeValue} flex justify-center items-center !py-0 rounded-lg text-base font-medium`}>{getAttributeValue(attribute.name, attribute.maxValue)}</div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        )
                    })}
                    <div className="flex border-t w-full">
                        <div className={`flex flex-col !px-4 !pt-4 m-auto flex-1 ${classes.attribute}`}>
                            <div className={`${classes.attributeNames} pb-2 justify-center flex text-xs`}>
                                <div className={`text-center ${classes.attributeName}`}>Net Weight (MT)</div>
                            </div>
                            <div className={`${classes.attributes} rounded-lg m-auto`}>
                                <div className={`${classes.attributeValue} flex justify-center items-center !py-0 rounded-lg text-base font-medium`}>{netWeight}</div>
                            </div>
                        </div>
                        <div className={`flex flex-col !px-4 !pt-4 m-auto flex-1 ${classes.attribute}`}>
                            <div className={`${classes.attributeNames} pb-2 justify-center flex text-xs`}>
                                <div className={`text-center ${classes.attributeName}`}>Gross Weight (MT)</div>
                            </div>
                            <div className={`${classes.attributes} rounded-lg m-auto`}>
                                <div className={`${classes.attributeValue} flex justify-center items-center !py-0 rounded-lg text-base font-medium`}>{grossWeight}</div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>



        </>

    );
}

export default SecondaryProductAttributesTemplate;
