import React from 'react'
import { createUseStyles } from 'react-jss';
import { MODE } from '../../../../../utils/constant';
import TextFieldV2 from '../../../../atoms/Input/TextFieldV2';
import { titleModification } from '../../../../../utils/helper';
import AddIcon from '@mui/icons-material/Add';
import DropDown from './DropDown';

interface EquipmentFormTemplateProps {
    mode: string,
    formik: any;
}

const useStyles = createUseStyles((theme: any) => ({
    border: {
        border: `1px solid ${theme.palette.border.neutral.neutral100}`
    },
    heading: {
        color: theme.palette.text.primary.primary900,
    },
    errorMessage: {
        color: theme.palette.text.secondary.secondary500,
    },
    icon: {
        backgroundColor: theme.palette.background.primary.primary500,
        color: "white",
        width: "48px",
        height: "48px",
        borderRadius: "10px",
    },
}));

const EquipmentFormTemplate: React.FC<EquipmentFormTemplateProps> = ({ mode, formik }) => {
    const classes = useStyles();

    const handleAddEquipment = () => {
        if (formik.values?.equipmentName && !formik.values?.equipmentNames.includes(formik.values?.equipmentName)) {
            formik.setFieldValue("equipmentNames", [...formik.values?.equipmentNames, formik.values?.equipmentName]);
        }
        formik.setFieldValue("equipmentName", "");
    }

    const handleDeleteEquipment = (index: number) => {
        const updatedEquipmentNames = [...formik.values?.equipmentNames];
        updatedEquipmentNames.splice(index, 1);
        formik.setFieldValue("equipmentNames", updatedEquipmentNames);
    };

    return (
        <div className={`${classes.border} grid gap-y-4 p-4 rounded-xl`}>
            <div className={`${classes.heading} text-base font-medium`}>Equipment</div>
            <div className='grid gap-y-2'>
                <div className='flex gap-4 items-end'>
                    <div className={`grid  gap-y-1 w-1/2 `}>
                        <TextFieldV2
                            label="Equipment Name"
                            placeholder='Enter Equipment Name'
                            {...formik.getFieldProps("equipmentName")}
                            error={formik.touched.equipmentName && Boolean(formik.errors.equipmentName)}
                            inputProps={{ readOnly: mode === MODE.VIEW, maxLength: 30 }}
                            onBlur={(event) => {
                                const newValue = titleModification(event.target.value);
                                formik.setFieldValue('equipmentName', newValue);
                            }}
                        />
                    </div>
                    <AddIcon className={`${classes.icon} w-full cursor-pointer`} onClick={handleAddEquipment} />
                </div>
                {formik.errors.equipmentNames && formik.touched.equipmentNames && (
                    <div className={`${classes.errorMessage} text-xs -mt-2`}>{formik.errors.equipmentNames}</div>
                )}

                {
                    formik.values?.equipmentNames?.length > 0 && <DropDown heading='Selected Equipments' selectedValue={formik.values?.equipmentNames} handleDelete={handleDeleteEquipment} />
                }
            </div>

            <div className="grid gap-y-1">
                <TextFieldV2
                    variant="outlined"
                    label="Add Description"
                    placeholder='Enter Here'
                    multiline
                    maxRows={4}
                    {...formik.getFieldProps("equipmentDescription")}
                    error={formik.touched.description && Boolean(formik.errors.equipmentDescription)}
                    inputProps={{ readOnly: mode === MODE.VIEW, maxLength: 500 }}
                    onBlur={(event) => {
                        const newValue = titleModification(event.target.value);
                        formik.setFieldValue('equipmentDescription', newValue);
                    }}
                />
                {formik.errors.equipmentDescription && formik.touched.equipmentDescription && (
                    <div className={`${classes.errorMessage} text-xs`}>{formik.errors.equipmentDescription}</div>
                )}
            </div>
        </div>
    )
}

export default EquipmentFormTemplate;