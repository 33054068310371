import { Tab, Box } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import React, { ReactNode } from 'react'
import { createUseStyles } from 'react-jss';
import { Height } from '@mui/icons-material';

export interface ITabsSchema {
    label: string, 
    component: ReactNode
}

interface TabsProps {
    schema: ITabsSchema[],
    value: string,
    setValue: (value: any) => void;
    fullWidth?:boolean;
    selectedTabStyle?: string
}

const useStyles = createUseStyles((theme: any) => ({
  selectedTab: {
    "&.Mui-selected": {
      color: `${theme.palette.background.primary.primary400} !important`,
      borderBottom: `2px solid ${theme.palette.background.primary.primary400} !important`,
      fontWeight: `500 !important`,
      borderRadius: '8px 8px 0 0',
      zIndex: '2 !important',
      fontSize: `16px`
    },
    fontSize: `16px`,
    
  },
  defaultTab: {
    color: `#E6E6E6 !important`,
    backgroundColor: `#E6E6E6 !important`,
    height: '1px !important',
    left: '0px !important',
    width: '100% !important',
    
  }
}));

const Tabs:React.FC<TabsProps> = ({schema, value, setValue, fullWidth = true, selectedTabStyle}) => {
  const classes = useStyles();
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(parseInt(newValue));
  };

  return (
    <div className='w-full' >
      <TabContext value={value}>
        <div className={`${fullWidth ? 'w-full' : 'w-max'}`}>
          <TabList onChange={handleChange} aria-label="lab API tabs example" TabIndicatorProps={{className:`${classes.defaultTab} `}} className={`relative`}>
            {schema.map((tab, index) => (
                <Tab label={tab['label']} value={(index+1).toString()} key={index} className={`${classes.selectedTab} ${selectedTabStyle} !font-normal `} />
            ))}
          </TabList>
        </div>
        {schema.map((tab, index) => (
                <TabPanel className={"!p-0"} value={(index+1).toString()} key={index}>{tab['component']}</TabPanel>
            ))}
      </TabContext>
    </div>
  );
}

export default Tabs