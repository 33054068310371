import React, { useEffect, useState } from 'react'
import { createUseStyles } from 'react-jss';
import { useUserService } from '../../../services/useUserService';
import { useSnackbar } from '../../../hooks/useSnackBar';
import { HTTP_STATUS } from '../../../utils/types';
import CancelIcon from '@mui/icons-material/Cancel';
import * as Yup from "yup";
import Visiblity from "../../../assets/icons/eyeOutlinedneutral400.svg"
import VisiblityCrossed from "../../../assets/icons/viewEyeOutlinedIconPrimary500.svg"
import { Checkbox, FormHelperText, IconButton, InputAdornment } from '@mui/material';
import Button from '../../atoms/Button/Button';
import { useFormik } from 'formik';
import TextFieldV2 from '../../atoms/Input/TextFieldV2';
import { useAuthenticatedUser } from '../../../hooks/useAuthenticatedUser';
import { AuthenticatedUserType } from '../../../contexts/AuthenticatedUserContext';
import { PASSWORD_CHECKLIST, REGEX } from '../../../utils/constant';
import SuccessDialogTemplate from '../../template/Home/SuccessDialog.template';
import CheckedIcon from '../../../assets/icons/signUpCheckedIcon.svg';
import UnCheckedIcon from '../../../assets/icons/signUpUncheckedIcon.svg';
import SuccessFailurePopup from '../../molecules/SuccessFailurePopup/SuccessFailurePopup';

const useStyles = createUseStyles((theme: any) => ({
    titleText: {
        color: theme.palette.text.primary.primary900
    },
    successGreenColor: {
        color: theme.palette.background.primary.primary600
    },
    checkContainer: {
        background: theme.palette.background.primary.primary50
    },
    defaultLabel: {
        color: theme.palette.background.primary.primary600
    },
    errorRed: {
        color: theme.palette.text.secondary.secondary600
    }
}));

export interface VerifyTokenParams {
    token: string | null
}

export interface IPasswordPayload {
    oldPassword: string | null,
    newPassword: string,
    confirmPassword: string
}

interface ShowPassword {
    old: boolean,
    new: boolean,
    confirm: boolean
}

interface PASSWORD_CHECKS {
    uppercase: boolean,
    minLength: boolean,
    lowercase: boolean,
    symbol: boolean,
    number: boolean,
}

const showPasswordInitialValue = {
    old: false, new: false, confirm: false
}

const validationSchema1 = Yup.object({
    oldPassword: Yup.string().required('Old Password is required')
        .min(8, 'Old Password must be at least 8 characters'),
    newPassword: Yup.string()
        .required('New Password is required')
        .notOneOf([Yup.ref('oldPassword')], 'New Password must be different from Old Password'),
    confirmPassword: Yup.string().oneOf([Yup.ref('newPassword')], 'Passwords must match').required('Confirm Password is required')
})

const validationSchema2 = Yup.object({
    newPassword: Yup.string()
        .required('New Password is required')
        .notOneOf([Yup.ref('oldPassword')], 'New Password must be different from Old Password'),
    confirmPassword: Yup.string().oneOf([Yup.ref('newPassword')], 'Passwords must match').required('Confirm Password is required')
})

const ChangePasswordPage = () => {
    const classes = useStyles();
    const userService = useUserService();
    const { user, setAuthenticatedUser } = useAuthenticatedUser();
    const { showSnackbar, SnackBarComponent } = useSnackbar();
    const [showPassword, setShowPassword] = useState<ShowPassword>(showPasswordInitialValue);
    const [isSaveActive, setIsSaveActive] = useState<boolean>(false);
    const [isSuccessDialogActive, setIsDialogActive] = useState(false);
    const [passwordChecks, setPasswordChecks] = useState<PASSWORD_CHECKS>({
        uppercase: false,
        minLength: false,
        lowercase: false,
        symbol: false,
        number: false,
    });

    const toggleShowPassword = (key: string) => () =>
        setShowPassword({ ...showPassword, [key]: !showPassword[key as keyof ShowPassword] })

    const toggleIsSaveDisable = () => {
        setIsSaveActive(
            Object.values(passwordChecks).every(check => check) &&
            !!!Object.keys(formik.errors).find(key => ['newPassword', 'oldPassword', 'confirmPassword'].includes(key))
        )
    }

    const handlePasswordChange = (value: string) => {
        formik.setFieldValue('newPassword', value)
        setPasswordChecks({
            uppercase: REGEX.UPPERCASE.test(value),
            minLength: value.length >= 8,
            lowercase: REGEX.LOWERCASE.test(value),
            symbol: REGEX.SYMBOL.test(value),
            number: REGEX.NUMBER.test(value),
        });
    };

    const handlePasswordSuccessResponse = () => {
        setIsDialogActive(false);
    }

    const formik = useFormik<IPasswordPayload>({
        initialValues: {
            oldPassword: !!user?.password ? "" : null,
            newPassword: "",
            confirmPassword: ""
        },
        validationSchema: !!user?.password ? validationSchema1 : validationSchema2,
        validateOnMount: true,
        onSubmit: async (values, { setSubmitting, resetForm }) => {
            setSubmitting(true)
            userService.updatePassword({ oldPassword: values.oldPassword, newPassword: values.newPassword })
                .then(res => {
                    if (res?.status === HTTP_STATUS.OK) {
                        const editedUser = { ...user } as AuthenticatedUserType;
                        editedUser.password = true;
                        setAuthenticatedUser(editedUser as AuthenticatedUserType)
                        resetForm();
                        setIsDialogActive(true);
                    } else if (res?.status === HTTP_STATUS.BAD_REQUEST) {
                        showSnackbar('error', res?.data?.statusMessage)
                    } else {
                        showSnackbar('error', "Error while updating password")
                    }
                }).catch(error => {
                    showSnackbar('error', "Something went wrong")
                    console.error("Update Password", error)
                }).finally(() => setSubmitting(false))
        }
    });

    useEffect(() => {
        toggleIsSaveDisable();
        handlePasswordChange(formik.values.newPassword)
    }, [formik.errors])

    return (
        <div className='grid gap-y-5'>
            <div className={`${classes.titleText} font-medium text-lg`}>Change Password</div>
            {isSuccessDialogActive && (
                <SuccessFailurePopup
                    variant={"Success"}
                    heading='Password Changed!'
                    description={<div className={`grid text-center`}>
                        <div>Your password has been changed</div>
                        <div>successfully. You can now use your new</div>
                        <div>password for future activities.</div>
                    </div>}
                    setShowPopup={setIsDialogActive}
                    button1={{
                        text: 'Continue',
                        variant: "tertiaryContained",
                        size: "large",
                        onClick: handlePasswordSuccessResponse
                    }}
                />
            )}
            <div className="flex flex-col w-full" >
                {SnackBarComponent}
                <form className="flex flex-col gap-y-5 items-start w-1/2" onSubmit={formik.handleSubmit}>
                    <div className='w-full flex flex-col gap-y-5'>
                        {!!user?.password && <div>
                            <TextFieldV2
                                label="Please Enter Your Old Password"
                                type={showPassword.old ? "text" : "password"}
                                {...formik.getFieldProps("oldPassword")}
                                fullWidth
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={toggleShowPassword('old')}
                                            edge="end"
                                            className='!mr-1'
                                        >
                                            {showPassword.old ? <img src={VisiblityCrossed} /> : <img src={Visiblity} width='200%' />}
                                        </IconButton>
                                    </InputAdornment>
                                }}
                                error={formik.touched?.oldPassword && Boolean(formik.errors?.oldPassword)}
                            />
                            {formik.touched?.oldPassword && Boolean(formik.errors?.oldPassword) &&
                                <div className='flex w-full'>
                                    <FormHelperText error >{formik.errors?.oldPassword}</FormHelperText>
                                </div>
                            }
                        </div>}
                        <div>
                            <TextFieldV2
                                label="Please Enter Your New Password"
                                {...formik.getFieldProps("newPassword")}
                                type={showPassword.new ? "text" : "password"}
                                onChange={(e) => handlePasswordChange(e.target.value)}
                                fullWidth
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={toggleShowPassword('new')}
                                            edge="end"
                                            className='!mr-1'
                                        >
                                            {showPassword.new ? <img src={VisiblityCrossed} /> : <img src={Visiblity} />}
                                        </IconButton>
                                    </InputAdornment>,
                                }}
                                error={formik.touched?.newPassword && Boolean(formik.errors?.newPassword)}
                            />
                            {formik.touched?.newPassword && Boolean(formik.errors?.newPassword) &&
                                <div className='flex w-full'>
                                    <FormHelperText error className=''>{formik.errors?.newPassword}</FormHelperText>
                                </div>
                            }
                        </div>
                        <div className={`grid w-full grid-cols-2 gap-y-3 p-3 rounded select-none pointer-none text-sm ${classes.checkContainer}`}>
                            {Object.entries(PASSWORD_CHECKLIST).map(([key, value]) => (
                                <div key={key} className=''>
                                    <Checkbox
                                        sx={{ padding: "1px", width: "18px", height: "18px", marginRight: "4px" }}
                                        checked={passwordChecks[key as keyof PASSWORD_CHECKS]}
                                        icon={formik.touched.newPassword && !passwordChecks[key as keyof PASSWORD_CHECKS] ? <CancelIcon className={`${classes.errorRed}`} /> : <img src={UnCheckedIcon} alt="unCheckedIcon" />}
                                        checkedIcon={<img src={CheckedIcon} alt="checkedIcon" />}
                                        disabled
                                    />
                                    <span className={` ${formik.touched.newPassword && !passwordChecks[key as keyof PASSWORD_CHECKS] ? classes.errorRed : passwordChecks[key as keyof PASSWORD_CHECKS] ? classes.successGreenColor : classes.defaultLabel}`}>
                                        {value}
                                    </span>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className='w-full'>
                        <TextFieldV2
                            label="Please Confirm Your New Password"
                            {...formik.getFieldProps("confirmPassword")}
                            type={showPassword.confirm ? "text" : "password"}
                            fullWidth
                            InputProps={{
                                endAdornment: <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={toggleShowPassword('confirm')}
                                        edge="end"
                                        className='!mr-1'
                                    >
                                        {showPassword.confirm ? <img src={VisiblityCrossed} /> : <img src={Visiblity} />}
                                    </IconButton>
                                </InputAdornment>,
                            }}
                            error={formik.touched?.confirmPassword && Boolean(formik.errors?.confirmPassword)}
                        />
                        {formik.touched?.confirmPassword && Boolean(formik.errors?.confirmPassword) &&
                            <div className='flex w-full'>
                                <FormHelperText error className=''>{formik.errors?.confirmPassword}</FormHelperText>
                            </div>
                        }
                    </div>
                    <div className='mt-1'>
                        <Button variant="primaryContained" label="Save" type="submit" isLoading={formik.isSubmitting} disabled={!isSaveActive || formik.isSubmitting} />
                    </div>
                </form>
            </div>
        </div>
    )
}

export default ChangePasswordPage