import React from 'react'
import { createUseStyles } from "react-jss";
import { PocSectionProps } from './KeyPersonDetails.template';
import AddNewKeyPersonDetilsTemplate from './AddNewKeyPersonDetils.template';
import TextFieldV2 from '../../../atoms/Input/TextFieldV2';
import AddIcon from '@mui/icons-material/Add';
import { KEY_PERSON_DETAIL_STATES } from '../../../../utils/types';
import Button from '../../../atoms/Button/Button';
import { useNavigate } from 'react-router-dom';

const useStyles = createUseStyles((theme: any) => ({

    container: {
        borderRadius: "8px",
        border: `1px solid ${theme.palette.border.neutral.neutral100}`,
    },
    icon: {
        backgroundColor: theme.palette.background.primary.primary800,
        color: "white",
        width: "48px",
        height: "48px",
        borderRadius: "10px",
    }
}));

const GetNumberOfKeyPersonDetails: React.FC<PocSectionProps> = ({ formik, setCurrentSectionTo }) => {

    const classes = useStyles();

    const handleNext = (event: React.FormEvent) => {
        event.preventDefault();
        const requiredFields = ["noOfRows"];
        let fieldError = false;
        for (const key of requiredFields) {
            if (key in formik.errors) {
                formik.setFieldTouched(key, true);
                fieldError = true;
            }
        }
        if (fieldError === true) return;
        const numberOfRows = formik.values.noOfRows ?? 0;
        const emptyPoc = { name: '', email: '', mobile: '', department: '', designation: '', profilePic: '', countryCode: '+91', socialLinks: {}, isAuthorized: false, alternateEmail: '', alternateMobile: '', prefix: '', modeOfCommunication: '', whatsAppLink: '' };
        const newPocValues = Array.from({ length: numberOfRows }, () => ({ ...emptyPoc }));
        formik.setFieldValue('newPocs', newPocValues)
    };

    const keyDetailProps: PocSectionProps = {
        setCurrentSectionTo: setCurrentSectionTo,
        formik: formik
    };

    const handleBack = () => {
        setCurrentSectionTo(KEY_PERSON_DETAIL_STATES.VIEW_KEY_PERSON_DETAILS)
        formik.setTouched({});
    };

    return (
        <>
            <div className={` rounded-2xl p-4 ${classes.container}`}>
                <div className='flex  gap-4 items-end  '>
                    <div className={`grid  gap-y-2 w-1/2 `}>
                        <TextFieldV2
                            {...formik.getFieldProps("noOfRows")}
                            placeholder="Enter number"
                            label=" Enter no. of Key Person"
                            variant="outlined"
                            inputProps={{ min: 1 }}
                            type="number"
                            error={
                                formik.touched.noOfRows &&
                                Boolean(formik.errors.noOfRows)
                            }
                            helperText={formik.touched.noOfRows && formik.errors.noOfRows}
                        />
                    </div>
                    <AddIcon className={`${classes.icon} w-full cursor-pointer`} onClick={handleNext} />
                </div>
            </div>
            {
                formik?.values?.newPocs.length > 0 && <AddNewKeyPersonDetilsTemplate {...keyDetailProps} />
            }
            {formik.values?.newPocs?.length == 0 &&
                <div className="flex justify-end">
                        <Button
                            variant="tertiaryContained"
                            label="Back"
                            onClick={handleBack}
                        />
                </div>
            }
        </>
    )
}
export default GetNumberOfKeyPersonDetails