import { useContext, useEffect, useRef, useState } from "react";
import { createUseStyles } from "react-jss";
import { useCatalogueService } from "../../../services/useCatalogueService";
import { ICartRequestBody, useCartService } from "../../../services/useCartService";
import { useLocation } from "react-router-dom";
import { CartContext } from "../../../contexts/CartContext";
import { CATALOGUE_TYPE_STATES, CLASS_STATES, HTTP_STATUS } from "../../../utils/types";
import CatalogueImageTemplate, { IImage } from "../../template/CatalogueDetail/CatalogueImage.template";
import SecondaryProductAttributesTemplate from "../../template/CatalogueDetail/SecondaryProductAttributes.template";
import Breadcrumbs from "../../atoms/BreadCrumbs/BreadCrumbs";
import { makeRoute, snakeCaseToTitleCase } from "../../../utils/helper";
import SecondaryProducProperties from "../../template/CatalogueDetail/SecondaryProducProperties.template";
import { useAuthenticatedUser } from "../../../hooks/useAuthenticatedUser";
import CarouselRef from "react-multi-carousel";
// import SecondaryPriceDetailsTemplate from "../../template/CatalogueDetail/SecondaryPriceDetails.template";
import { CART_LIFE_CYCLE, CUSTOMER_ROUTES, PAYMENT_METHODS, REGEX } from "../../../utils/constant";
import TermsAndConditionsCarousel from "../../template/CatalogueDetail/TermsAndConditionsCarousel.template";
import Disclaimer from '../../template/CatalogueDetail/Disclaimer.template';
import FAQTemplate from '../../template/CatalogueDetail/FAQ.template';
import AddToFavouriteTemplate from "../../template/CatalogueDetail/AddToFavourite.template";
import SelectCustomizationTemplate, { IValueAddedService } from "../../template/Customization/SelectCustomization.template";
import TermsConditionsTemplate from "../../template/CatalogueDetail/TermsConditions.template";
import DeliveryDetailsTemplate from "../../template/CatalogueDetail/DeliveryDetails.template";
import PaymentDetailsTemplate from "../../template/CatalogueDetail/PaymentDetails.template";
import TextFieldV2 from "../../atoms/Input/TextFieldV2";
import ContactUs from "../../molecules/ContactUs/ContactUs.page";
import SecondaryPriceDetailsTemplate from "../../template/CatalogueDetail/SecondaryPriceDetails.template";
import { ICurrentCartItem } from "./CatalogueDetails.page";

export interface IProductSpecification {
    name: string;
    maxValue: string;
    minValue: string;
    uom: string;
}

export interface IProductClassification {
    name: string;
    value: string;
}

export interface ISecondaryAttributes {
    productSpecification: IProductSpecification[];
    productClassification: IProductClassification[];
    skuCode?: string;
}

export interface ISecondaryCatalogue {
    id: number;
    secondarySkuId: number;
    catalogueName: string;
    minimumQuantity: number;
    availableQuantity: number;
    application: string;
    defectDetails: string;
    additionalProductDetails: string;
    keywords: string;
    price: any;
    packagingType: String[];
    termsConditions: string;
    status: string;
    attributes: any;
    warehouseName: string;
    grade: string;
    catalogueImages: IImage[];
    name: string;
    warehouseId: number;
    uom: string;
    netWeight: number;
    grossWeight: number;
    isRepresentationImage: boolean;
    customization: String[];
    skuCode: string;
}

const useStyles = createUseStyles((theme: any) => ({
    leftContainer: {
    },
    middleChild: {
        width: "61.18%"
    },
    rightChild: {
        width: "29.15%",
    },
    catalogueName: {
        color: theme.palette.text.primary.primary900,
        lineHeight: "28px"
    },
    value: {
        color: theme.palette.text.neutral.neutral700,
        fontSize: "16px",
        lineHeight: "20px"
    },
    quantityTerms: {
        color: theme.palette.text.secondary.secondary500
    },
    select: {
        "& .MuiInputBase-input": {
            color: theme.palette.text.neutral.neutral400,
            fontSize: "14px",
            background: "none",
        },
        "& .MuiSvgIcon-root": {
            width: "16px",
            height: "16px",
            cursor: "pointer",
            margin: "4px 8px 0px 0px",
            color: `${theme.palette.text.neutral.neutral400}60`,
        },
        background: theme.palette.background.neutral.neutral200,
    },
    selectContainer: {
        marginTop: "-31px"
    },
    webContainer: {
        display: "grid",
        padding: "0px 70px 108px 70px"
    },
    mobileContainer: {
        display: "none",
    },
    termsAndConditionsWidth: {
        width: "90%"
    },
    inputContainer: {
        display: "flex",
        columnGap: "24px"
    },
    activeCategory: {
        color: theme.palette.text.neutral.neutral900,
    },
    location: {
        border: `1px solid ${theme.palette.border.neutral.neutral200}`,
        background: "none !important",
    },
    inStock: {
        background: theme.palette.background.success.success50,
        border: `1px solid ${theme.palette.border.success.success100}`,
        color: theme.palette.text.success.success600,
    },
    borderBottom: {
        borderBottom: `1px solid ${theme.palette.border.neutral.neutral100}`,
    },
    "@media (max-width: 476px)": {
        webContainer: {
            display: "none"
        },
        mobileContainer: {
            display: "grid"
        },
        catalogueName: {
            color: theme.palette.text.primary.primary900,
        },
        termsAndConditionsWidth: {
            width: "auto"
        },
        inputContainer: {
            display: "grid",
            rowGap: "16px"
        },
        value: {
            fontSize: "14px",
        },
        selectContainer: {
            marginTop: "-16px"
        },
    }
}));

const responsive = {
    desktop: {
        breakpoint: { max: 3000, min: 1280 },
        items: 4,
        partialVisibilityGutter: 10
    },
    tablet: {
        breakpoint: { max: 1280, min: 980 },
        items: 3,
        partialVisibilityGutter: 10
    },
    mobile: {
        breakpoint: { max: 980, min: 670 },
        items: 2,
        partialVisibilityGutter: 10
    },
    "sm-mobile": {
        breakpoint: { max: 670, min: 0 },
        items: 1
    }
};

const SecondaryCatalogueDetails: React.FC = () => {

    const classes = useStyles();

    const { user, syncAuthDialogActive } = useAuthenticatedUser();

    const cartContextData = useContext(CartContext);

    const catalogueService = useCatalogueService();
    const cartService = useCartService();

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    const [secondaryCatalogueId, setSecondaryCatalogueIdTo] = useState<number | null>(null);
    const [secondaryCatalogue, setsecondaryCatalogueTo] = useState<ISecondaryCatalogue | null>(null);
    const [packagingTypes, setPackagingTypesTo] = useState<Record<string, string>>({});
    const [currentCartItem, setCurrentCartItemTo] = useState<ICurrentCartItem>({
        attributes: {},
        upc: null,
        warehouse: null,
        packaging: "WITHOUT_PACKAGING",
        quantity: "",
        secondarySkuId: null,
        paymentType: PAYMENT_METHODS.CASH_PAYMENT
    });

    const carouselRef = useRef<CarouselRef>(null);

    const gridTemplate = { xs: 12, sm: 6, md: 4, lg: 4, xl: 4 };

    const [valueAddedService, setValueAddedServiceTo] = useState<IValueAddedService>({
        shearing: [],
        slitting: [],
    })
    const [paymentType, setPaymentType] = useState<string | null>(PAYMENT_METHODS.CASH_PAYMENT);
    const [productCombination, setProductCombination] = useState<boolean | null>(null);

    const handlePaymentTypeChange = (paymentTypeMethod: string) => {
        setCurrentCartItemTo({
            ...currentCartItem,
            paymentType: paymentTypeMethod
        });
        setPaymentType(paymentTypeMethod);
    };

    const handleQuantityChange = (quantity: string) => {
        if (REGEX.QUANTITY.test(quantity) || quantity === '') {
            setCurrentCartItemTo({
                ...currentCartItem,
                quantity
            });
        }
    };

    const handleWarehouseChange = (e: any) => {
        setCurrentCartItemTo({
            ...currentCartItem,
            warehouse: e.target.value,
        });
    };

    const convertWarehousesToOptions = (warehouses: any): any => {
        return warehouses.map((warehouse: any) => ({
            value: warehouse,
            label: warehouse.name,
        }));
    };

    const handleAddToCart = async () => {
        if (!user) {
            syncAuthDialogActive()
            return;
        }
        const requiredQuantity = parseFloat(currentCartItem.quantity);
        if (isNaN(requiredQuantity)) {
            return;
        }
        if (secondaryCatalogue && currentCartItem && currentCartItem.warehouse && currentCartItem.paymentType) {
            let cartRequestBody: ICartRequestBody = {
                catalogueName: secondaryCatalogue.catalogueName,
                upcId: null,
                warehouseId: secondaryCatalogue.warehouseId,
                quantity: requiredQuantity,
                uom: secondaryCatalogue.uom || "MT",
                packagingType: currentCartItem.packaging,
                secondarySkuId: secondaryCatalogue.secondarySkuId,
                valueAddedServices: valueAddedService,
                paymentType: currentCartItem.paymentType
            }
            await cartService.saveToCart(cartRequestBody);
            await cartContextData.syncCart(CART_LIFE_CYCLE.ADD_PRODUCT);
        }
    }

    useEffect(() => {
        const catalogueIdString = queryParams.get('secondaryCatalogueId');
        if (catalogueIdString !== null) {
            const parsedCatalogueId = parseInt(catalogueIdString, 10);
            if (!isNaN(parsedCatalogueId)) {
                setSecondaryCatalogueIdTo(parsedCatalogueId);
            }
        }
    }, [location.search])

    useEffect(() => {
        if (secondaryCatalogueId) {
            catalogueService.getSecondaryCatalogueById(secondaryCatalogueId)
                .then((response) => {
                    if (response.status === HTTP_STATUS.OK) {
                        setsecondaryCatalogueTo(response.data.data ? response.data.data : null)
                        setsecondaryCatalogueTo((prevSecondaryCatalogue: ISecondaryCatalogue | null) => ({
                            ...(prevSecondaryCatalogue as ISecondaryCatalogue),
                            name: prevSecondaryCatalogue?.catalogueName || "",
                        }))
                        setPackagingTypesTo(Object.fromEntries(response.data.data?.packagingType?.map((key: string) => [key, key])) ?? {});
                        setCurrentCartItemTo(prevCartItem => ({ ...prevCartItem, packaging: response.data.data?.packagingType[0] || null, secondarySkuId: response.data.data.secondarySkuId, warehouse: response.data.data.warehouseName, quantity: String(response.data.data?.minimumQuantity || "") }));
                    }
                })
                .catch((error) => {
                    console.error('Error', error);
                    setsecondaryCatalogueTo(null);
                });
        }
    }, [secondaryCatalogueId])

    return (
        <div>
            <div className={`${classes.webContainer} max-container m-auto`}>
                <div className="flex justify-between">
                    <Breadcrumbs crumbs={[
                        {
                            link: makeRoute(CUSTOMER_ROUTES.CATALOGUE_LISTING, { query: { classes: CLASS_STATES.SECONDARY, catalogueTypes: CATALOGUE_TYPE_STATES.CURRENT_INVENTORY } }),
                            label: "secondary"

                        },
                        {
                            link: "",
                            label: "View"
                        }
                    ]} />
                    <div className="flex justify-end items-end">
                        <ContactUs />
                    </div>
                </div>
                <div className="flex gap-x-6">
                    <div className={`grid mt-6 flex-1 ${classes.leftContainer}`}>
                        <div className="w-full flex gap-x-6 ">
                            <div className="grid gap-y-5 h-fit">
                                <CatalogueImageTemplate isSharable={false} catalogueImages={secondaryCatalogue?.catalogueImages} isRepresentationImage={secondaryCatalogue?.isRepresentationImage} />
                                <TermsAndConditionsCarousel />
                                <AddToFavouriteTemplate id={secondaryCatalogue?.id} catalogueType={CLASS_STATES.SECONDARY} />
                            </div>
                            <div className={`grid gap-y-6 h-fit mt-0 m-auto ${classes.middleChild}`}>
                                <div className={`${classes.catalogueName} text-2xl font-semibold`}>{secondaryCatalogue?.name}</div>
                                {/* TODO: Will uncomment after getting data from backend */}
                                {/* {DEALS_OF_THE_DAY_DATA.length && <DealsOfTheDayTemplate />} */}

                                <SecondaryProductAttributesTemplate
                                    attributes={secondaryCatalogue?.attributes?.productSpecification}
                                    netWeight={secondaryCatalogue?.netWeight || 0}
                                    grossWeight={secondaryCatalogue?.grossWeight || 0}
                                />
                                <div className="flex gap-x-3">
                                    <div className="grid gap-y-1" style={{minWidth:"170px"}}>
                                        <TextFieldV2
                                            label="Packaging"
                                            value={snakeCaseToTitleCase(currentCartItem.packaging)}
                                            onChange={(event) => {
                                                setCurrentCartItemTo({
                                                    ...currentCartItem,
                                                    packaging: event.target.value,
                                                });
                                            }}
                                            fullWidth
                                            InputProps={{
                                                disableUnderline: true,
                                                readOnly: true,
                                            }}
                                            disabled
                                        />
                                    </div>
                                    <div className="grid">
                                        <TextFieldV2
                                            label="Location"
                                            type="text"
                                            InputProps={{
                                                disableUnderline: true,
                                                readOnly: true,
                                            }}

                                            value={secondaryCatalogue?.warehouseName}
                                            fullWidth
                                            required
                                            disabled
                                        />
                                    </div>
                                    <div className="grid">

                                        <TextFieldV2
                                            label="Delivery Terms"
                                            value={"Within 3 Days"}
                                            InputProps={{
                                                disableUnderline: true,
                                                readOnly: true,
                                            }}
                                            disabled
                                        />
                                    </div>
                                    {secondaryCatalogue?.netWeight && secondaryCatalogue.netWeight > 0 ? (
                                        <div className={`${classes.inStock} flex justify-end self-end py-1 px-2 text-xs font-medium h-fit whitespace-nowrap rounded`}>In Stock</div>
                                    ) : null}
                                </div>

                            </div>
                        </div>
                        {/* <div className={`py-10 ${classes.borderBottom}`}>
                            <SelectCustomizationTemplate
                                catalogue={null}
                                secondaryCatalogue={secondaryCatalogue}
                                quantity={parseFloat(currentCartItem.quantity)}
                                selectedUpc={currentCartItem?.upc}
                                valueAddedService={valueAddedService}
                                setValueAddedServiceTo={setValueAddedServiceTo}
                            />
                        </div> */}
                        <div className={`py-10 ${classes.borderBottom}`}><PaymentDetailsTemplate paymentType={paymentType} handlePaymentTypeChange={handlePaymentTypeChange} /></div>
                        <div className={`py-10 ${classes.borderBottom}`}><DeliveryDetailsTemplate /></div>
                        <div className={`py-10 ${classes.borderBottom}`}>
                            <SecondaryProducProperties
                                productSpecification={secondaryCatalogue?.attributes?.productSpecification}
                                productClassification={secondaryCatalogue?.attributes?.productClassification}
                                skuCode={secondaryCatalogue?.skuCode}
                            />
                        </div>
                        <div className={`py-10 ${classes.borderBottom}`}><FAQTemplate /></div>
                        <div className={`py-10 ${classes.borderBottom}`}><TermsConditionsTemplate /></div>
                        <div className="pt-10" id="scrollToDisclaimer"><Disclaimer /></div>
                    </div>
                    <div className={classes.rightChild}>
                        <div className={`pt-6 sticky top-[150px] grid`}>
                            {secondaryCatalogue && (
                                <SecondaryPriceDetailsTemplate
                                    handleAddToCart={handleAddToCart}
                                    paymentType={currentCartItem.paymentType}
                                    minimumPrice={parseFloat(secondaryCatalogue?.price?.default)}
                                    maximumPrice={parseFloat(secondaryCatalogue?.price?.default)}
                                    quantity={secondaryCatalogue?.netWeight?.toString() ?? ''}
                                    warehousename={secondaryCatalogue?.warehouseName}
                                    gst={18}
                                    uom={currentCartItem?.warehouse?.uom?.toString() || ""}
                                    moq={secondaryCatalogue?.minimumQuantity}
                                    handleQuantityChange={handleQuantityChange}
                                    showAlteredQuantity={false}
                                    secondaryCatalogue={secondaryCatalogue}
                                    productCombination={productCombination}

                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>

            <div className={`gap-y-6 ${classes.mobileContainer}`}>
                <div className={`grid gap-y-6 px-4`}>
                    <div className="">
                        <Breadcrumbs crumbs={[
                            {
                                link: makeRoute(CUSTOMER_ROUTES.CATALOGUE_LISTING, { query: { classes: CLASS_STATES.SECONDARY, catalogueTypes: CATALOGUE_TYPE_STATES.CURRENT_INVENTORY } }),
                                label: "secondary"

                            },
                            {
                                link: "",
                                label: "View"
                            }
                        ]} />
                    </div>
                    <div className="w-full grid gap-y-2 mb-2">
                        <div className={`${classes.catalogueName} text-lg font-semibold`}>{secondaryCatalogue?.name}</div>
                        <CatalogueImageTemplate isSharable={false} catalogueImages={secondaryCatalogue?.catalogueImages} isRepresentationImage={secondaryCatalogue?.isRepresentationImage} />
                    </div>

                    <SecondaryProductAttributesTemplate
                        attributes={
                            secondaryCatalogue?.attributes?.productSpecification
                        }
                        netWeight={secondaryCatalogue?.netWeight || 0}
                        grossWeight={secondaryCatalogue?.grossWeight || 0}
                    />

                    <div className='border-t'> </div>
                    <div className={`${classes.inputContainer}`}>
                        <TextFieldV2
                            label="Packaging"
                            value={snakeCaseToTitleCase(currentCartItem.packaging)}
                            onChange={(event) => {
                                setCurrentCartItemTo({
                                    ...currentCartItem,
                                    packaging: event.target.value,
                                });
                            }}
                            fullWidth
                            disabled={true}
                        />
                        <TextFieldV2
                            label="Location"
                            disabled={true}
                            value={secondaryCatalogue?.warehouseName}
                            fullWidth
                            required
                        />
                        <TextFieldV2
                            fullWidth
                            label="Delivery Terms"
                            value={"Within 3 Days"}
                            disabled={true}
                        />
                        {secondaryCatalogue?.netWeight && secondaryCatalogue.netWeight > 0 ? (
                            <div className={`${classes.inStock} flex justify-end self-end py-1 px-2 text-xs font-medium h-fit whitespace-nowrap rounded`}>In Stock</div>
                        ) : null}

                    </div>
                    <div className='border-t'> </div>
                    <div className={``}><PaymentDetailsTemplate paymentType={paymentType} handlePaymentTypeChange={handlePaymentTypeChange} /></div>
                    <div className='border-t'> </div>
                    <div className={``}><DeliveryDetailsTemplate /></div>
                    <div className='border-t'> </div>
                    <div className="grid gap-y-3">
                        <SecondaryProducProperties
                            productSpecification={secondaryCatalogue?.attributes?.productSpecification}
                            productClassification={
                                secondaryCatalogue?.attributes?.productClassification
                            }
                            skuCode={secondaryCatalogue?.skuCode}
                        />
                    </div>
                    <div className='border-t'> </div>
                    <div className={``}><FAQTemplate /></div>
                    <div className='border-t'> </div>
                    <div className={``}><TermsConditionsTemplate /></div>
                    <div className='border-t'> </div>
                    <div className={``} id="scrollToDisclaimer"><Disclaimer /></div>
                </div>

                <div className="w-full sticky bottom-24 z-50">
                    {secondaryCatalogue && (
                        <SecondaryPriceDetailsTemplate
                            handleAddToCart={handleAddToCart}
                            paymentType={currentCartItem.paymentType}
                            minimumPrice={parseFloat(secondaryCatalogue?.price?.default)}
                            maximumPrice={parseFloat(secondaryCatalogue?.price?.default)}
                            quantity={secondaryCatalogue?.netWeight?.toString() ?? ''}
                            warehousename={secondaryCatalogue?.warehouseName}
                            gst={18}
                            uom={currentCartItem?.warehouse?.uom?.toString() || ""}
                            moq={secondaryCatalogue?.minimumQuantity}
                            handleQuantityChange={handleQuantityChange}
                            showAlteredQuantity={false}
                            secondaryCatalogue={secondaryCatalogue}
                            productCombination={productCombination}
                        />
                    )}
                </div>

            </div>
        </div>
    );
}

export default SecondaryCatalogueDetails;
