import * as React from 'react';
import { createUseStyles } from 'react-jss';
import * as yup from "yup";
import Button from '../../atoms/Button/Button';
import { useFormik } from 'formik';
import { useSnackbar } from '../../../hooks/useSnackBar';
import { useBusinessProfileService } from '../../../services/useBusinessProfileService';
import TextFieldV2 from '../../atoms/Input/TextFieldV2';

const useStyles = createUseStyles((theme: any) => ({
    container: {
        border: `1px solid ${theme.palette.border.neutral.neutral100}`,
        width: "100%",
    },
    container_heading: {
        color: theme.palette.text.primary.primary900,
    },
    borderBottom: {
        borderBottom: `1px solid ${theme.palette.border.neutral.neutral100}`,
    }
}));

export interface description {
    description: string;
}

interface BussinessProfileAboutUsEditTemplateProps {
    updateBusinessProfileSection: any;
    businessProfile: any;
    onSuccess: (updatedProfile: any) => void;
    onBack: () => void;
}

const BussinessProfileAboutUsEditTemplate: React.FC<BussinessProfileAboutUsEditTemplateProps> = ({ updateBusinessProfileSection, businessProfile, onSuccess, onBack }) => {
    const classes = useStyles();
    const { showSnackbar } = useSnackbar();
    const businessProfileService = useBusinessProfileService();

    const aboutusSchema = yup.object().shape({
        description: yup.string().required('Description is required').max(150, 'Description must be at most 150 characters'),
    });

    const aboutusformik = useFormik<description>({
        initialValues: {
            description: businessProfile?.description || '',
        },
        validationSchema: aboutusSchema,
        enableReinitialize: true,
        onSubmit: async (values: any, { setSubmitting }) => {
            try {
                setSubmitting(true);
                const requestBody = {
                    description: values.description,
                };
                updateBusinessProfileSection("OTHER_INFORMATION", requestBody);
                showSnackbar("success", "Business profile updated successfully");
                updateBusinessProfileSection("description", values);
                onSuccess({ ...businessProfile, description: values.description });
            } catch (error) {
                showSnackbar("error", `Business profile update failed: ${error}`);
            } finally {
                setSubmitting(false);
            }
        },
    });

    return (
        <form onSubmit={aboutusformik.handleSubmit}>
            <div className={`${classes.container} p-4 grid gap-y-6 rounded-xl w-full`}>
                <div className={`flex justify-between items-center pb-6 ${classes.borderBottom}`}>
                    <h2 className={`${classes.container_heading} text-2xl font-medium`}>About Us</h2>
                    <div className='flex gap-4'>
                        <Button variant={'tertiaryContained'} label={'Back'} type="button" onClick={onBack} />
                        <Button variant={'primaryContained'} label={'Save'} type="submit" />
                    </div>
                </div>
                <TextFieldV2
                    label="About Us"
                    className="w-44"
                    fullWidth
                    {...aboutusformik.getFieldProps("description")}
                    error={aboutusformik.touched?.description && Boolean(aboutusformik.errors?.description)}
                    helperText={aboutusformik.touched?.description && aboutusformik.errors?.description}
                    inputProps={{ readOnly: false }}
                />
            </div>
        </form>
    );
};

export default BussinessProfileAboutUsEditTemplate;