import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { createUseStyles } from 'react-jss';
import { IconButton, Badge } from "@mui/material";
import profileIcon from '../../../assets/icons/profileIcon.svg';
import cartIcon from '../../../assets/icons/cartIcon.svg';
import wishlistIcon from '../../../assets/icons/wishlistIcon.svg';
import footerLogo from '../../../assets/images/footerLogo.svg';
import NavbarOptions from './NavbarOptions';
import HeaderSearch from './HeaderSearch.template';
import Button from '../../atoms/Button/Button';
import RequestForQuotationModalTemplate from '../Rfq/RequestForQuotationModal.template';
import { CART_LIFE_CYCLE, CUSTOMER_ROUTES } from '../../../utils/constant';
import { CartContext } from '../../../contexts/CartContext';
import { WishlistContext } from '../../../contexts/WishlistContext';
import { useAuthenticatedUser } from '../../../hooks/useAuthenticatedUser';
import { addToQueue } from '../../../utils/helper';
import AuthenticationTemplate from '../Authentication/Authentication.template';

const useStyles = createUseStyles((theme: any) => ({
    container: {
        background: '#fff',
        zIndex: '99',
        width: '100%',
        boxShadow: "0px 4px 24px 0px rgba(29, 54, 121, 0.16)",
        position: 'sticky',
        top: '0',
    },
    searchContainer: {
        borderRadius: '0px 12px 12px 0px',
        background: theme.palette.background.primary.primary500,
    },
    searchContainerMain: {
        border: `1px solid ${theme.palette.border.primary.primary200}`,
        borderRadius: ' 12px',
        overflow: 'hidden',

        "& .MuiInputBase-root": {
            background: 'white',
            height: '48px',
            border: 'none',
            width: '371px',
        }
    },
    badge: {
        backgroundColor: theme.palette.background.primary.primary400,
        color: "white",
    },
    arrow: {
        borderBottom: `10px solid ${theme.palette.background.neutral.neutral100}`,
        borderRight: "10px solid transparent",
        borderLeft: "10px solid transparent",
    },
    toolTipMessage: {
        backgroundColor: theme.palette.background.primary.primary400,
    },
}));

const NavbarTemplate: React.FC = () => {
    const classes = useStyles();
    const navigate = useNavigate();
    const cartContextData = useContext(CartContext);
    const wishlistContextData = useContext(WishlistContext);
    const { user, syncAuthDialogActive } = useAuthenticatedUser();
    const location = useLocation();
    const [localIsStorefront, setLocalIsStorefront] = useState(false);

    const navigateToHomePage = () => {
        navigate("/");
    };

    useEffect(() => {
        if(location.pathname===CUSTOMER_ROUTES.STORE_FRONT){
            setLocalIsStorefront(true);
        }
        else{
            setLocalIsStorefront(false);
        }
    }, [location.pathname]);

    useEffect(() => {
        if (new URLSearchParams(location.search).get('token')?.length) {
            syncAuthDialogActive(true)
        }
        else
            user && !user.businessId && navigate(CUSTOMER_ROUTES.ONBOARDING)
    }, [])

    useEffect(() => {
        (async () => {
            await cartContextData.syncCart(CART_LIFE_CYCLE.LOGIN);
        })();
    }, [user]);

    useEffect(() => {
        (async () => {
            await wishlistContextData.syncWishlist(CART_LIFE_CYCLE.LOGIN);
        })();
    }, [user]);

    const WishlistTooltip = useMemo(() => {
        if (wishlistContextData.tooltip.visible) {
            return (
                <div className="cursor-pointer grid relative">
                    <IconButton onClick={() => { navigate(CUSTOMER_ROUTES.WISHLIST) }} >
                        <Badge badgeContent={wishlistContextData.wishlistItemsCount} classes={{ badge: classes.badge }}>
                            <img src={wishlistIcon} alt="wishlistIcon" className="" />
                        </Badge>
                    </IconButton>
                    <div className="absolute top-full z-20 left-1/2 transform -translate-x-1/2">
                        <div className={`h-px w-0.5 m-auto ${classes.arrow}`}></div>
                        <div className={`p-2 rounded w-max text-white ${classes.toolTipMessage}`}>{wishlistContextData.tooltip.message}</div>
                    </div>
                </div>
            );
        } else {
            return (
                <IconButton onClick={() => { navigate(CUSTOMER_ROUTES.WISHLIST) }}>
                    <Badge badgeContent={wishlistContextData.wishlistItemsCount} classes={{ badge: classes.badge }}>
                        <img src={wishlistIcon} alt="cartIcon" className="" />
                    </Badge>
                </IconButton>
            );
        }
    }, [wishlistContextData.tooltip.visible, wishlistContextData.tooltip.message, wishlistContextData.wishlistItemsCount]);

    const Tooltip = useMemo(() => {
        if (cartContextData.tooltip.visible) {
            return (
                <div className="cursor-pointer grid relative">
                    <IconButton onClick={() => { navigate(CUSTOMER_ROUTES.CART) }}>
                        <Badge badgeContent={cartContextData.cartItemsCount} classes={{ badge: classes.badge }}>
                            <img src={cartIcon} alt="cartIcon" className="" />
                        </Badge>
                    </IconButton>
                    <div className="absolute top-full z-20 left-1/2 transform -translate-x-1/2">
                        <div className={`h-px w-0.5 m-auto ${classes.arrow}`}></div>
                        <div className={`p-2 rounded w-max text-white ${classes.toolTipMessage}`}>{cartContextData.tooltip.message}</div>
                    </div>
                </div>
            );
        } else {
            return (
                <IconButton onClick={() => { navigate(CUSTOMER_ROUTES.CART) }}>
                    <Badge badgeContent={cartContextData.cartItemsCount} classes={{ badge: classes.badge }} >
                        <img src={cartIcon} alt="cartIcon" className="" />
                    </Badge>
                </IconButton>
            );
        }
    }, [cartContextData.tooltip.visible, cartContextData.tooltip.message, cartContextData.cartItemsCount,]);

    const handleDashboardActive = () => {
        if (user) {
            addToQueue(JSON.stringify({ "key": "dashboard", "expandedMenu": { "Profile Overview": true, "My Profile": true } }));
            navigate(`${CUSTOMER_ROUTES.DASHBOARD}/my-profile`)
        }
        else syncAuthDialogActive();
    }

    const [dialogOpen, setDialogOpen] = useState<boolean>(false);
    const handleRfqForm = () => {
        setDialogOpen(true);
    }

    return (
        <>
            <AuthenticationTemplate />
            <div className={`${classes.container} mt-auto`}>
                <div className='flex items-center gap-x-8 justify-between py-4 w-[1300px] mx-auto'>
                    <img className='cursor-pointer' src={footerLogo} alt="" onClick={navigateToHomePage} />
                    <div className='max-w-[660px] w-full'>
                        <HeaderSearch />
                    </div>
                    <div className='flex items-center gap-x-3'>
                        <div className='p-1'>
                            {WishlistTooltip}
                        </div>
                        <div className='p-1'>
                            {Tooltip}
                        </div>
                        <div className='p-1'>
                            <IconButton onClick={() => { handleDashboardActive() }} >
                                <img className="cursor-pointer" src={profileIcon} alt="cartIcon" />
                            </IconButton>
                        </div>
                        <Button variant={'primaryContained'} label='Get Quote' iconPosition="10px" onClick={handleRfqForm} />
                        <RequestForQuotationModalTemplate dialogOpen={dialogOpen} setDialogOpen={setDialogOpen} />
                    </div>
                </div>
                {!localIsStorefront ? <NavbarOptions /> : null}
            </div>
        </>
    );
};

export default NavbarTemplate;