import React from "react";
import { createUseStyles } from "react-jss";
import useMetaDataService from "../../../hooks/useMetaDataService";
import { ATTRIBUTE_LABEL } from "../../../utils/constant";
import AttributeView from "../../organisms/AttributeView/AttributeView";

const useStyles = createUseStyles((theme: any) => ({
    container: {
        border: `1px solid ${theme.palette.border.neutral.neutral100}`,
    },
    heading: {
        color: theme.palette.text.neutral.neutral900,
    }
}));

interface SecondaryLotProductItemTemplate {
    secondaryProducts: any;
}

export interface IRangeSpecificaiton {
    name: string;
    minValue: string;
    maxValue: string;
    uom: string;
}

interface SecondaryProductSpecification {
    value: {
        attributes: {
            productSpecification: IRangeSpecificaiton[];
        };
    };
}

const SecondaryLotProductAttributesTemplate: React.FC<SecondaryLotProductItemTemplate> = ({ secondaryProducts }) => {

    const classes = useStyles();
    const { metaData: filterData } = useMetaDataService();

    const sortRangedSpecificaiton = (rangedSpecification: Map<string, { minValue: string; maxValue: string; uom: string }>) => {
        const sortedResult: Map<string, { minValue: string; maxValue: string; uom: string }> = new Map();
        const rankedSortedAttributes = filterData.attributes?.sort((a: any, b: any) => a?.id - b?.id).map(attribute => attribute?.name) ?? []
        rankedSortedAttributes.forEach((attributeName) => {
            if (rangedSpecification.has(attributeName)) {
                const attributeValue = rangedSpecification.get(attributeName);
                if (attributeValue !== undefined)
                    sortedResult.set(attributeName, attributeValue);
            }
        });
        return sortedResult;
    }

    function getSpecificationRange(secondaryProductList: SecondaryProductSpecification[]): Map<string, { minValue: string; maxValue: string; uom: string }> {
        const result: Map<string, { minValue: string; maxValue: string; uom: string }> = new Map();
        secondaryProductList?.forEach((secondaryProduct) => {
            secondaryProduct.value.attributes.productSpecification.forEach((attribute: IRangeSpecificaiton) => {
                if (!result.has(attribute.name)) {
                    result.set(attribute.name, { minValue: attribute.minValue, maxValue: attribute.maxValue, uom: attribute.uom });
                } else {
                    const currentValue = result.get(attribute.name)!;
                    if (isNaN(parseInt(attribute.minValue))) {
                        currentValue.minValue = attribute.minValue;
                        currentValue.maxValue = attribute.maxValue;
                    } else {
                        if (attribute.name === ATTRIBUTE_LABEL.COLOR || attribute.name === ATTRIBUTE_LABEL.TEMPER || attribute.name === ATTRIBUTE_LABEL.COATING_GRADE || attribute.name === ATTRIBUTE_LABEL.HARDNESS) {
                            currentValue.minValue = attribute.minValue < currentValue.minValue ? attribute.minValue : currentValue.minValue;
                            currentValue.maxValue = attribute.maxValue > currentValue.maxValue ? attribute.maxValue : currentValue.maxValue;
                        } else {
                            currentValue.minValue = Math.min(parseFloat(attribute.minValue), parseFloat(currentValue.minValue)).toString();
                            currentValue.maxValue = Math.max(parseFloat(attribute.maxValue), parseFloat(currentValue.maxValue)).toString();
                        }
                    }
                    result.set(attribute.name, currentValue);
                }
            });
        });
        return result;
    }

    return (
        <div className="grid gap-y-3 text-base font-semibold">
            <div className={`${classes.heading} text-base font-semibold`}>Specifications</div>
            <div className={`flex flex-wrap rounded-2xl px-6 py-3 ${classes.container} `}>
                {secondaryProducts && filterData && Array.from(sortRangedSpecificaiton(getSpecificationRange(secondaryProducts)).entries()).map(([key, value]) => (
                    <AttributeView key={key} label={key.trim()} specification={value} />
                ))}
            </div>
        </div>
    );
};


export default SecondaryLotProductAttributesTemplate;