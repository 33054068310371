interface YourProfileAndYourInformationProps {
    contentStyle?: string;
    boxStyle?: string;
    subHeading?: string;
    header?: string;
}

const YourProfileAndYourInformation: React.FC<YourProfileAndYourInformationProps> = ({ contentStyle, header, boxStyle, subHeading }) => {
    return (
        <>
            <div className={boxStyle}>
                <span className={subHeading}>{header}</span>
            </div>
            <div className={contentStyle}>
                <p>
                    3.1 Users must register on the Platform by submitting required documents and information, including KYC Documents, GST details, etc., and creating a Profile. Upon successful registration, Buyers receive a non-transferable username and password linked to their Profile. This information should be kept confidential, as Buyers are responsible for all actions on the Platform using their username or password and for all information/content posted under their Profile. SteelBazaar representatives may assist Buyers in creating accounts and registering on the Platform.
                </p>
                <br />
                <p>
                    3.2 By creating a Profile, You confirm that the information in Your Profile is accurate, genuine, and will be regularly updated.
                </p>
                <br />
                <p>
                    3.3 You are responsible for maintaining the confidentiality and security of Your Profile and fully responsible for all activities carried out through it. You agree to (a) immediately notify SteelBazaar of any unauthorized use or security breach; and (b) ensure that You exit from Your Profile at the end of each session. SteelBazaar will not be liable for any loss or damage arising from Your failure to comply with these requirements.
                </p>
                <br />
                <p>
                    3.4 SteelBazaar reserves the right to, at its discretion and in accordance with applicable law, monitor any content or information posted by You and remove any User Content from the Platform if SteelBazaar determines, in its sole discretion, that such User Content violates these Terms of Use or any applicable law or for any other reason. These actions do not negate or dilute SteelBazaar's position as an intermediary or impose any liability on SteelBazaar with respect to User Content.
                </p>
                <br />
                <p>
                    3.5 Furthermore, You authorize Us to:
                </p>
                <br />
                <p>
                    (a) collect, use, share, process, and store all information provided by You and Your access information, including the IP address, IMEI number, and MAC address of the computer/device from where the Platform was accessed;
                </p>
                <br />
                <p>
                    (b) either directly or through third parties, verify and confirm information in Your Profile;
                </p>
                <br />
                <p>
                    (c) contact You using the information in Your Profile and provide promotional materials that may be of interest to You;
                </p>
                <br />
                <p>
                    (d) use any and all information provided by You to create Your Profile; and
                </p>
                <br />
                <p>
                    (e) modify, limit, or delete Your Profile as may be required.
                </p>
            </div>
        </>
    )
}

export default YourProfileAndYourInformation;