import React, { Dispatch, SetStateAction } from 'react';
import { createUseStyles } from 'react-jss';
import EmptyCertificate from '../../../assets/images/emptyCertificate.svg';
import Button from '../../atoms/Button/Button';

interface IEmptyBussinessDocumentTemplate {
    setEdit: Dispatch<SetStateAction<boolean>>
}
const useStyles = createUseStyles((theme: any) => ({
    heading: {
        color: theme.palette.text.neutral.neutral800,
    },
    container: {
        backgroundColor: theme.palette.background.neutral.neutral50
    },

}));

const EmptyBussinessDocumentTemplate: React.FC<IEmptyBussinessDocumentTemplate> = ({ setEdit }) => {
    const classes = useStyles();
    return (
        <div className={`${classes.container} py-8 flex justify-center w-full rounded-3xl`}>
            <div className='flex flex-col items-center'>
                <img src={EmptyCertificate} alt="" className='mb-8' />

                <p className={`${classes.heading} mb-4 text-base font-semibold`}>You haven't provided certificate/documents for your business.</p>
                <Button variant={'primaryContained'} onClick={() => { setEdit(true) }} label="Upload certificate documents" />
            </div>
        </div>
    )
};
export default EmptyBussinessDocumentTemplate;
