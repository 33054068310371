import { FormikProps } from 'formik';
import React from 'react'
import { createUseStyles } from 'react-jss';
import { IStatutoryDetailsForm } from '../../pages/DashBoard/StatutoryDetail.page';
import UploadCertificatesDocumentTemplate from './UploadCertificatesDocument.template';
import UploadBankDetailsTemplate from './UploadBankDetails.template';

export interface IStatutoryDetailProps {
    formik: FormikProps<IStatutoryDetailsForm>;
    refreshBusinessProfile: any;
    showEditForm: any;
    showEditFormBank: any;
}
const useStyles = createUseStyles((theme: any) => ({
    titleText: {
        color: theme.palette.text.primary.primary900
    }
}));

const StatutoryDetailTemplate: React.FC<IStatutoryDetailProps> = ({ formik, refreshBusinessProfile, showEditForm, showEditFormBank }) => {
    const classes = useStyles();
    return (
        <div className='grid gap-y-5 justify-items-stretch'>
            <div className={`${classes.titleText} font-medium text-lg`}>Statutory Documents</div>
            <UploadCertificatesDocumentTemplate formik={formik} refreshBusinessProfile={refreshBusinessProfile} showEditForm={showEditForm} showEditFormBank={showEditFormBank}/>
            <UploadBankDetailsTemplate formik={formik} refreshBusinessProfile={refreshBusinessProfile} showEditForm={showEditForm} showEditFormBank={showEditFormBank}/>
        </div>
    )
}
export default StatutoryDetailTemplate