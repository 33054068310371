interface LogisticAndFulfillmentServicesProps {
    contentStyle: string;
    boxStyle: string;
    subHeading: string;
    header: string;
}

const LogisticAndFulfillmentServicesTemplate: React.FC<LogisticAndFulfillmentServicesProps> = ({ contentStyle, boxStyle, subHeading, header }) => {
    return (  
        <div className={contentStyle}>
        <div className={boxStyle}>
            <span className={subHeading}>{header}</span>
        </div>
        <p> 6.1 You are obligated to collect (in case You choose the self-pick-up option) / take delivery of the Products purchased by You or the Services availed, that are said to be in a deliverable state. In the event of non-acceptance or refusal to collect / accept the delivery of the Products ordered by You within the time as stipulated/agreed upon, the Products shall stand forfeited, You may be liable to SteelBazaar for such non-acceptance and shall further be liable to SteelBazaar for any loss of any fee or charges that SteelBazaar shall have earned if such delivery or Transaction should have been completed. Such charges may also be subject to GST, which will be charged over and above the fees / penalty. You acknowledge that such damages or loss to SteelBazaar and the sellers listed on the Platform are not consequential or indirect. You shall countersign a lorry receipt and/or eway bill/invoice copy or such other document collection / delivery form required by SteelBazaar, in which You confirm that the Product was collected / delivered in the correct condition (without obvious defects or damage).
        </p><br />
        <p>6.2 Delivery charges are not hidden charges and are charged (if at all) extra depending on the logistics facilities provided by SteelBazaar. Delivery times are influenced by Product availability, geographic location, Your shipping destination and the seller’s logistics partner's time-to-deliver in Your location. Products are generally delivered on business days. Apart from Sundays, there are certain holidays throughout the year that are not considered as business days.
        </p><br />
        <p>Intellectual Property</p>
        <p>
            7.1 The intellectual property in the Platform, and in the material, content and information made available on the Platform including graphics, images, photographs, logos, trademarks, the appearance, organization, and layout of this Platform and the underlying software code belongs to SteelBazaar or SteelBazaar's licensors. You must not copy, modify, alter, publish, broadcast, distribute, sell or transfer (whether in whole or in part) any of the material referred to above. The information provided on the Platform and the Products are for Your personal use only.
        </p><br />
        <p>7.2 You may not frame or use framing techniques to enclose any trademark, logo, or other proprietary information (including images, text, page layout, or form) of SteelBazaar without our prior written consent. You may not use any meta tags or any other “hidden text” utilizing SteelBazaar’s or its affiliates’ names or trademarks without the prior written consent of SteelBazaar and / or its Affiliates, as applicable. Any unauthorized use terminates the permission or license granted by SteelBazaar and / or its Affiliates, as applicable.
        </p><br />
        <p>7.3 All material on the Platform including images, illustrations, audio clips, and video clips, are protected by copyrights, trademarks and other intellectual property rights. You must not copy, reproduce, republish, upload, post, transmit, or distribute any material in any way, including by email or other electronic means and whether, directly or indirectly, You must not assist any other person to do so. Without the prior written consent of the owner, modification or use of the material on any other website / networked computer environment or for any purpose other than personal, non-commercial use is a violation of the copyrights, trademarks, and other proprietary rights is prohibited. Any use for which You receive any remuneration, whether money or otherwise, is a commercial use for the purposes of this clause.
        </p><br />
        <p>8. User Content</p>
        <p>
            8.1 You grant SteelBazaar a license to use and modify all User Content in which You may hold any intellectual property rights. SteelBazaar can, consistent with Our Privacy Policy, use the User Content, including for creating databases, creating derivative works, analyzing data, analyzing trends and usage, and in any other manner.
        </p><br />
        <p>Contents Posted on the Platform</p>
        <p>
            9.1 The Platform and the processes, and their selection and arrangement, including but not limited to the text, graphics, user interfaces, visual interfaces, sounds and music (if any), artwork and computer code on the Platform is owned and controlled by SteelBazaar or its licensors and the design, structure, selection, coordination, expression, look and feel and arrangement of such content is protected by copyright, patent and trademark laws, and various other intellectual property rights. Through Your use of the Platform, by no means are any rights impliedly or expressly granted to You in respect of such content. SteelBazaar reserves the right to change or modify such content from time to time at its sole discretion.
        </p><br />
        <p>9.2 You may use information on the Products and Services purposely made available on the Platform for downloading, provided that You (a) do not remove any proprietary notice language in all copies of such documents; (b) use such information only for Your personal, non-commercial informational purpose and do not copy or post such information on any networked computer or broadcast it in any media; (c) make no modifications to any such information, and (d) do not make any additional representations or warranties relating to such documents.
        </p><br />
        <p>10. Privacy Policy</p>
        <p>
            10.1 Any personal information You supply to SteelBazaar when You use this Platform will be used in accordance with SteelBazaar's privacy policy, a copy of which is at SteelBazaar Privacy Policy (“Privacy Policy”). The personal information / data provided to SteelBazaar by You during the course of Your usage of the Platform will be treated as strictly confidential and in accordance with the Privacy Policy and applicable laws and regulations.
        </p><br />
        <p>Infringement and Take Down Policy</p>

        <p>11.1 We are committed to removing listing or content on the Platform which infringes any intellectual property rights of others. To facilitate this, We have a verification process so that the Users who are intellectual property owners could easily report listings or content that infringe their rights. In the event that You believe that Your intellectual property right has been infringed by any of our Users, please email SteelBazaar immediately at compliance@steelbazaar.in along with the information set out below:
        </p><br />
        <p>(a) Description of the alleged intellectual property right that has been infringed along with adequate information to identify the intellectual property, including URL or screen shots of the listing / content on our Platform;
        </p><br />
        <p>(b) Details establishing that the complainant is the owner or the exclusive licensee of the infringed intellectual property;
        </p><br />
        <p>(c) Details of the person, if known who is responsible for uploading the listing / content, infringing Your intellectual property;
        </p><br />
        <p>(d) Undertaking that You will file an infringement suit in a competent court against the User responsible for uploading the infringing intellectual property, and undertaking that You shall produce the orders of the court to SteelBazaar within 21 days from the date of receipt of the infringement notice; and
        </p><br />
        <p>(e) Your address, telephone number, and email address.
        </p><br /><br />
        <p>
            11.2 SteelBazaar will respond to all infringement notices and will comply with all applicable law in relation to the take down of such listings or content on the Platform. SteelBazaar also reserves the right to terminate a User's account if the User is guilty of such infringement.
        </p><br />
        <p>11.3 When You send SteelBazaar an infringement notice, You: (a) declare that all statements made by You are true; (b) acknowledge and agree that SteelBazaar may share Your infringement notice with third parties including the parties involved in the allegedly objectionable activities / infringing activities, our group companies, our legal advisors and other third parties; (c) You agree to indemnify SteelBazaar in the event Your notice is issued with any mala fide intentions and we suffer any losses for taking action based on Your notice.
        </p><br />
        <p>11.4 On receipt of Your notice, SteelBazaar will verify the allegations and if SteelBazaar finds that the alleged listing / content is infringing Your intellectual property rights, SteelBazaar shall remove the listing / content promptly upon such determination.
        </p><br />
        <p>11.5 SteelBazaar has the right to restore the listing of the Product in the event that You are unable to produce the orders of the competent court having jurisdiction as set out above. SteelBazaar is further not obligated to respond to any further takedown notices in relation to the same alleged infringement
        </p><br />
        <p>12. Breach of these Terms of Use</p>
        <p>
            If You have, or if SteelBazaar has reasonable grounds to believe that You have, violated these Terms of Use or any other additional terms that apply to You, SteelBazaar reserves the right to suspend or terminate Your access to the Platform at any time, forfeit any amounts paid by You, take all such remedies available to SteelBazaar, and report such action to relevant authorities.
        </p><br />
        <p>13. Third Party Sites</p>
        <p>
            All third party advertisements, hyperlinks, or other redirection tools on the Platform that take You to content operated by third parties are not controlled by SteelBazaar and do not form part of the Platform. SteelBazaar is not liable for any loss or harm that may occur to You as a result of such sites.
        </p><br />
        <p>Disclaimer and Limitation of Liability</p>
        <p>
            14.1 The Platform is provided on an “as is” and “as available” basis without any representation or warranty, express or implied. SteelBazaar does not warrant that:
        </p><br />
        <p>(a) the Platform will be constantly available or available at all. SteelBazaar shall have no liability to You for any interruption or delay in access to the Platform availed through it, irrespective of the cause;
        </p><br />
        <p>(b) the information on the Platform is complete, true, reliable, current, accurate, or non-misleading;
        </p><br />
        <p>(c) that the Platform is secure or free of viruses, trojans, or other malware; or
        </p><br />
        <p>(d) the contents of the Platform do not infringe any intellectual property rights.
        </p><br />
        <p>14.2 SteelBazaar shall not be liable for any direct, special, punitive, incidental, indirect, or consequential damages, including but not limited to any loss of profits, revenue, contracts, anticipated savings, goodwill, expenditure, business, or data of any kind in connection with these Terms of Use or any Transactions that occur on the Platform. SteelBazaar's combined liability (if any) for any losses or claims arising out of any Transaction shall not exceed the total fees paid to SteelBazaar for such Transaction.
        </p><br />
        <p>14.3 SteelBazaar neither recommends You to buy any Products on the Platform nor does SteelBazaar endorse, control, or accept any responsibility for any such Products, and nor does SteelBazaar (unless expressly stated otherwise) provide any guarantee, warranties, or assurance with respect to the advertisement, exhibition, making available, merchantability, fitness of the Product or Service, offer to sell or transactions of sale or purchase of Products on the Platform or non-infringement of third party intellectual property rights.
        </p><br />
        <p>14.4 SteelBazaar does not accept any liability for any errors or omissions, whether on behalf of itself or third parties.
        </p><br />
        <p>15. Indemnity</p>
        <p>
            You agree to indemnify SteelBazaar, its Affiliates and its licensors, subsidiaries, group companies (as applicable), and their respective officers, directors, agents, and employees, on demand, against any claim, action, damage, loss, liability, cost, charge, expense, or payment which SteelBazaar may pay, suffer, incur, or are liable for, in relation to any act You do or cause to be done, in breach of the Terms of Use or any additional terms applicable to You or Your violation of any law, rules, or regulations (including infringement of intellectual property rights) of a third party.
        </p><br />
        <p>16. Amendments to the Terms of Use</p>
        <p>
            SteelBazaar reserves the right to amend or replace these Terms of Use and other Agreements/policies on the Platform periodically by updating this document. Your continued use of the Platform following the posting of changes will signify your acceptance of such changes.
        </p><br />
        <p>17. Severability and Waiver</p>
        <p>
            If any provision of these Terms of Use is found to be invalid by any court having competent jurisdiction, the invalidity of such provision shall not affect the validity of the remaining provisions of these Terms of Use, which shall remain in full force and effect. No waiver of any term of these Terms of Use shall be deemed a further or continuing waiver of such term or any other term.
        </p><br />
        <p>18. Relationship between Us and Users</p>
        <p>
            Neither usage nor registration on the Platform creates an association, partnership, venture, or relationship of principal and agent, master and servant, or employer and employee between the User and SteelBazaar.
        </p><br />
        <p>Applicable Law, Jurisdiction, and Dispute Resolution</p>
        <p>
            19.1 If the parties are unable to resolve any dispute or difference within a period of thirty (30) days, then any such dispute or difference arising out of, under, or in connection with this Term of Use, including any question regarding its formation, existence, validity, enforceability, performance, interpretation, or the breach, termination, or validity thereof, shall be referred to and finally resolved by arbitration in accordance with the provisions of the Arbitration and Conciliation Act, 1996, as amended from time to time.
        </p><br />
        <p>19.2 The arbitral tribunal shall consist of one arbitrator, to be mutually appointed by the Parties . The venue and seat of the arbitration shall be New Delhi, and the courts in New Delhi shall have exclusive jurisdiction. The language of the arbitration shall be English.
        </p><br />
        <p>19.3 The arbitral award shall be final and binding on the parties. Unless costs are awarded in the arbitration proceeding, all expenses and costs incurred by the parties in connection with such arbitration will be borne by the party jointly except for the legal fees of the Parties.
        </p><br />
        <p>19.4 These Terms of Use and applicable additional terms/policies shall be governed by and interpreted and construed in accordance with the laws of India. Subject to the above paragraph of this clause, any disputes pertaining to the Platform shall be subject to the exclusive jurisdiction of the appropriate courts in Delhi.
        </p><br />
        <p>20. Grievance Officer</p>
        <p>
            Users can access, modify, correct, and eliminate the data about him/her/it which has been collected pursuant to his/her/its decision to become a User. If you believe that your rights are being infringed or you have any grievances, complaints, or concerns, including in relation to the information shared by the User with SteelBazaar on the Platform, you may reach out to our grievance officer through telephonic or electronic mode in the following manner:
        </p><br />
        <p>Name - Abhinav Singla<br/>
            Designation - Director<br/>
            E-mail - abhinav@steelbazaar.in<br/>
            Address - A-88, MIDC Taloja, Taloja Industrial Area, Distt. Raigad,
            Navi Mumbai - 410208
        </p>


    </div>);
}
export default LogisticAndFulfillmentServicesTemplate;