import React from 'react';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles((theme: any) => ({
    container: {
        background: "white",
        position: 'absolute',
        width: '100%',
        top: '146px',
        left: '0',
        zIndex: '99',
    },
    primaryContainer: {
        width: '1297px',
        margin: '0 auto',
    },
    heading: {
        color: theme.palette.text.primary.primary700,
        fontSize: '32px',
    },
    subText: {
        color: theme.palette.text.neutral.neutral600,
    },
    memberOptions: {
        width: '320px',
        // background: theme.palette.background.primary.primary300,
        background: 'rgba(231, 246, 254, 0.50)',
        "& span": {
            color: theme.palette.text.primary.primary600,
        }
    },
}));



const MembershipDropdown: React.FC = () => {
    const classes = useStyles();

    return (
        // <div className={`${classes.container} py-10`}>
        //     <div className={`${classes.primaryContainer} flex justify-between`}>
        //         <div className='w-1/2'>
        //             <h3 className={`${classes.heading} font-bold`}>Membership</h3>
        //             <p className={ `${classes.subText} text-2xl font-normal`}>Enjoy extra perks and benefits.</p>
        //         </div>
        //         <div className='flex flex-col  w-1/2'>
        //             <div className='flex items-center mb-8 gap-8'>
        //                 <div className={`${classes.memberOptions} px-6 py-8 w-full`}>
        //                     <span className='text-4xl font-semibold'>SB Silver</span>
        //                 </div>
        //                 <div className={`${classes.memberOptions} w-full px-6 py-8`}>
        //                     <span className='text-4xl font-semibold'>SB Gold</span>
        //                 </div>
        //             </div>
        //             <div className='flex items-center gap-8'>
        //                 <div className={`${classes.memberOptions} w-full px-6 py-8 `}>
        //                     <span className='text-4xl font-semibold'>SB Platinum</span>
        //                 </div>
        //                 <div className={`${classes.memberOptions} w-full px-6 py-8`}>
        //                     <span className='text-4xl font-semibold'>SB Enterprises</span>
        //                 </div>
        //             </div>

        //         </div>
        //     </div>
        // </div>
        <div></div>
    );
};

export default MembershipDropdown;
