import React from 'react';

const Animation: React.FC = () => {
    return (
        <div>
            <style>
                {`
       .text{
        position: absolute;
        font-size: 56px;
        font-weight: 700;
      }

      .text:nth-of-type(1){
        animation-name: fade;
        animation-fill-mode: both;
        animation-iteration-count: infinite;
        animation-duration: 20s;
        animation-delay: 0s;
        animation-timing-function: ease-in-out;
        color: #6EC8F8;
      }
      
      .text:nth-of-type(2){
        animation-name: fade;
        animation-fill-mode: both;
        animation-iteration-count: infinite;
        animation-duration: 20s;
        animation-delay: 4s;
        animation-timing-function: ease-in-out;
        color: #F7E8A4;
      }
      
      .text:nth-of-type(3){
        animation-name: fade;
        animation-fill-mode: both;
        animation-iteration-count: infinite;
        animation-duration: 20s;
        animation-delay: 8s;
        animation-timing-function: ease-in-out;
        color: #7DBC76;
      }

      .text:nth-of-type(4){
        animation-name: fade;
        animation-fill-mode: both;
        animation-iteration-count: infinite;
        animation-duration: 20s;
        animation-delay: 12s;
        animation-timing-function: ease-in-out;
        color: #849AE1;
      }

      .text:nth-of-type(5){
        animation-name: fade;
        animation-fill-mode: both;
        animation-iteration-count: infinite;
        animation-duration: 20s;
        animation-delay: 16s;
        animation-timing-function: ease-in-out;
        color: #F2A6AB;
      }
      
      @keyframes fade{
        0%, 25% {
            opacity: 0;
          }
          30%, 45% {
            opacity: 1;
          }
          50%, 100% {
            opacity: 0;
          }
        }

        `}
            </style>
                <h2 className='text'>Marketplaces</h2>
                <h2 className='text'>Procurement Solutions</h2>
                <h2 className='text'>Tailored Finance Solutions</h2>
                <h2 className='text'>Customised Manufacturing</h2>
                <h2 className='text'>AI Based Analytics</h2>
        </div>
    );
};

export default Animation;
