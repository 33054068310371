import React from "react";
import { FormControl, InputLabel, Select as MuiSelect, MenuItem, SelectProps as MuiSelectProps } from "@mui/material";
import { createUseStyles } from "react-jss";

interface Option {
    value: string | number;
    label: string;
}

interface SelectProps extends Omit<MuiSelectProps, "label"> {
    options: Option[];
    label: string;
}

const useStyles = createUseStyles((theme: any) => ({
    input: {
        "& .MuiInputBase-root": {
            borderRadius: "12px !important",
            height:"100%"
        },
    },
}));

const Select: React.FC<SelectProps> = ({ options, label, ...props }) => {
    const classes = useStyles()
    return (
        <FormControl fullWidth className={classes.input} >
            <InputLabel id={`dropdown-label-${label}`}>{label}</InputLabel>
            <MuiSelect id={`select-${label}`} label={label} {...props}>
                {options.map(option => (
                    <MenuItem className="capitalize" key={option.value} value={option.value}>
                        {option.label}
                    </MenuItem>
                ))}
            </MuiSelect>
        </FormControl>
    );
};

export default Select;