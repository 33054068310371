import { TableBody, TableContainer, TableCell, TableHead, TableRow, Table } from '@mui/material';
import { createUseStyles } from 'react-jss';
import editIcon from "../../../assets/images/editIcon.svg"
import { IShearing } from './ShearingCustomizationPopup';

interface ShearingCustomizationTableTemplateProps {
    valueAddedService: IShearing[];
    setDialogOpen: (open: boolean) => void;
}

const useStyles = createUseStyles((theme: any) => ({
    heading:{
        color: theme.palette.text.neutral.neutral900,
    },
    tableHead: {
        padding: "12px 16px",
        background: theme.palette.background.neutral.neutral50,
    },
    tableHeadCell: {
        fontSize: "14px",
        fontWeight: 600,
        lineHeight: "16px",
        padding: "12px 0px",
        color: theme.palette.text.neutral.neutral900,
        textAlign: "center",
        '&:first-child': {
            borderTopLeftRadius: '10px',
            borderBottomLeftRadius: '10px',
          },
          '&:last-child': {
            borderTopRightRadius: '10px',
            borderBottomRightRadius: '10px',
          },
          "&.MuiTableCell-root":{
            borderBottom: "none"
          }
    },
    tableCell: {
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "16px",
        padding: "12px",
        color: theme.palette.text.neutral.neutral700,
        textAlign: "center",
    },
    tableContaier: {

    },
    "@media (max-width: 480px)": {
        tableContaier: {
            width: "840px"
        },

    },
}))

const SHEARING_HEADING = [
    "Sno.",
    "Length (MM)",
    "No. of Pieces",
    "Weight/Pc (MT)",
    "Weight (MT)",
    "CTL Charges/MT",
    "Total Charges",
]

const ShearingCustomizationTableTemplate: React.FC<ShearingCustomizationTableTemplateProps> = ({ valueAddedService, setDialogOpen }) => {
    const classes = useStyles();

    return (
        <>
            <TableContainer className="w-full">
                <div className='grid gap-y-3'>
                    <div className='flex justify-between'>
                        <div className={`${classes.heading} text-base font-medium my-auto`}>Shearing (CTL)</div>
                        <div className='m-2'>
                            <img src={editIcon} alt="" onClick={(e) => setDialogOpen(true)} />
                        </div>
                    </div>
                    <div className={`overflow-x-auto overflow-hidden w-full`}>
                        <div className={`${classes.tableContaier}`}>
                            <Table className="w-full" size="small">
                                <TableHead className={classes.tableHead}>
                                    <TableRow>
                                        {SHEARING_HEADING.map((item, index) => (
                                            <TableCell key={index} className={`${classes.tableHeadCell} first:pl-3 first:text-left last:pr-3 last:text-right`}>{item}</TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {valueAddedService.map((data: any, index: number) => (
                                        <TableRow key={index} sx={{ 'border-b &:last-child td, &:last-child th': { border: 0 } }}>
                                            <TableCell className={classes.tableCell}>{index + 1}</TableCell>
                                            <TableCell className={classes.tableCell}>{data.length}</TableCell>
                                            <TableCell className={classes.tableCell}>{data.pieces}</TableCell>
                                            <TableCell className={classes.tableCell}>{data.weightPerPiece}</TableCell>
                                            <TableCell className={classes.tableCell}>{data.weight}</TableCell>
                                            <TableCell className={classes.tableCell}>{data.chargesPerMT}</TableCell>
                                            <TableCell className={classes.tableCell}>{data.totalCharges}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </div>
                    </div>
                </div>
            </TableContainer>
        </>
    )
}

export default ShearingCustomizationTableTemplate