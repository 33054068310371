import { createUseStyles } from 'react-jss';
import { IRegisteredAddress } from './ProfileOverview.template';
import { getProfileDisplayData } from '../../../utils/helper';

const useStyles = createUseStyles((theme: any) => ({
    container: {
        border: `1px solid ${theme.palette.border.neutral.neutral100}`,
        width: "100%"
    },
    title: {
        color: theme.palette.text.primary.primary400,
    },
    value: {
        color: theme.palette.text.neutral.neutral700,
    },
    property: {
        color: theme.palette.text.neutral.neutral700,
    },
}));

export interface IBusinessAddessTemplateProps {
    address: IRegisteredAddress;
    title: string;
}

const BusinessAddressTemplate: React.FC<IBusinessAddessTemplateProps> = ({ address, title }) => {

    const classes = useStyles();

    return (
        <div className={`p-4 flex flex-col gap-y-3 rounded-xl ${classes.container} h-full`}>
            <div className={`${classes.title} text-lg font-semibold`}>{getProfileDisplayData(title)}</div>
            <div className='flex flex-col gap-y-3 flex-1'>
                <div className="grid gap-1">
                    <div className={`${classes.value} font-normal text-lg`}>Address Line 1</div>
                    <div className={`${classes.property} font-medium text-lg break-all`} style={{ height: "72px" }}>{getProfileDisplayData(address?.addressLine1)}
                    </div>
                </div>
                <div className="grid gap-1">
                    <div className={`${classes.value} font-normal text-lg`}>Address Line 2</div>
                    <div className={`${classes.property} font-medium text-lg`} style={{ height: "48px" }}>{getProfileDisplayData(address?.addressLine2)}</div>
                </div>
                <div className="flex justify-between w-full">
                    <div className='grid gap-y-1'>
                        <div className={`${classes.value} font-normal text-lg`}>Pincode</div>
                        <div className={`${classes.property} font-medium text-lg`}>{getProfileDisplayData(address?.pincode)}</div>
                    </div>
                    <div className='grid gap-y-1'>
                        <div className={`${classes.value} font-normal text-lg`}>City</div>
                        <div className={`${classes.property} font-medium text-lg`}>{getProfileDisplayData(address?.city)}</div>
                    </div>
                    <div className='grid gap-y-1'>
                        <div className={`${classes.value} font-normal text-lg`}>State</div>
                        <div className={`${classes.property} font-medium text-lg`}>{getProfileDisplayData(address?.state)}</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BusinessAddressTemplate