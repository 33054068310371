import React from 'react'
import { createUseStyles } from 'react-jss';
import { FormikProps } from 'formik';
import { useNavigate, useParams } from 'react-router-dom';
import { makeRoute } from '../../../../../utils/helper';
import { SERVICE_STATUS_OPTIONS, VALUE_ADDED_SERVICE_ROUTES } from '../../../../../utils/constant';
import { USCPayload } from '../../../../../services/useVASMasterCategoryService';
import Button from '../../../../atoms/Button/Button';
import { Attribute, SubAttribute } from '../../../../../services/useAttributeServiceV2';
import StatusV2 from '../../../../atoms/Status/StatusV2';

interface ProductServiceViewTemplateProps {
    mode: string,
    formik: FormikProps<USCPayload>,
    onBack: () => void
}

const useStyles = createUseStyles((theme: any) => ({
    mainHeading: {
        color: theme.palette.text.primary.primary900,
    },
    border: {
        border: `1px solid ${theme.palette.border.neutral.neutral100}`
    },
    heading: {
        color: theme.palette.text.primary.primary900,
    },
    errorMessage: {
        color: theme.palette.text.secondary.secondary500,
    },
    property: {
        color: theme.palette.text.neutral.neutral600,
    },
    mainValue: {
        color: theme.palette.text.primary.primary400,
    }
}));

const USCViewTemplate: React.FC<ProductServiceViewTemplateProps> = ({ mode, onBack, formik }) => {
    const classes = useStyles();
    const navigate = useNavigate();

    const params = useParams();

    const handleActionRoute = (id: number) => () => {
        navigate(makeRoute(VALUE_ADDED_SERVICE_ROUTES.USCS_UPDATE, { params: { id: id } }));
    };

    return (
        <form className="grid gap-y-8" >
            <div className='flex gap-1'>
                <div className={`${classes.mainHeading} text-lg font-medium my-auto`}>View USC:</div>
                <div className={`${classes.mainValue} text-lg font-medium my-auto`}>{formik.values?.uscCode}</div>
            </div>

            <div className='grid gap-y-4'>
                <div className={`${classes.border} grid gap-y-4 p-4 rounded-xl`}>
                    <div className={`${classes.heading} text-base font-medium`}>USC Details</div>
                    <div className='grid grid-cols-5 gap-x-4'>
                        <div className="grid gap-y-1">
                            <div className={`${classes.property} text-sm`}>USC</div>
                            <div className={`${classes.heading} text-base font-medium`}>{formik.values?.name}</div>
                        </div>
                        <div className="grid gap-y-1">
                            <div className={`${classes.property} text-sm`}>USC Name</div>
                            <div className={`${classes.heading} text-base font-medium`}>{formik.values?.name}</div>
                        </div>
                        <div className="grid gap-y-1">
                            <div className={`${classes.property} text-sm`}>Linked Super Service</div>
                            <div className={`${classes.heading} text-base font-medium`}>{formik.values?.superService?.label}</div>
                        </div>
                        <div className="grid gap-y-1">
                            <div className={`${classes.property} text-sm`}>Linked Main Service</div>
                            <div className={`${classes.heading} text-base font-medium`}>{formik.values?.mainService?.label}</div>
                        </div>
                        <div className="grid gap-y-1">
                            <div className={`${classes.property} text-sm`}>Linked Service Category</div>
                            <div className={`${classes.heading} text-base font-medium`}>{formik.values?.productService?.label}</div>
                        </div>
                    </div>
                    <div className="grid gap-y-1">
                        <div className="grid gap-y-1">
                            <div className={`${classes.property} text-sm`}>Description</div>
                            <div className={`${classes.heading} text-base font-medium`}>{formik.values?.description}</div>
                        </div>
                    </div>
                </div>

                <div className={`${classes.border} grid gap-y-4 p-4 rounded-xl`}>
                    <div className={`${classes.heading} text-base font-medium`}>Attributes</div>
                    {
                        formik.values?.attributes.map((attribute: Attribute) => (
                            <div className={`${classes.border} grid gap-y-4 p-4 rounded-xl`} key={attribute.id}>
                                <div className={`${classes.heading} text-base font-medium`}>{attribute.name}(Min-Max)</div>
                                <div className='grid grid-cols-6 gap-4'>
                                    {
                                        attribute.subAttributes.filter((subAttribute: any) => formik?.values?.subAttributeIds?.hasOwnProperty(subAttribute.id)).filter((subAttribute, index, self) => 
                                            index === self.findIndex(sa => 
                                                sa.minValue === subAttribute.minValue && sa.maxValue === subAttribute.maxValue
                                            )
                                        ).map((subAttribute: SubAttribute, index: number) => {
                                           return <div className="grid gap-y-1" key={subAttribute.id}>
                                                <div className={`${classes.property}`}>Selection {index + 1}</div>
                                                <div className={`${classes.heading} text-base font-medium`}>
                                                    {(+subAttribute.minValue).toFixed(2)}-{(+subAttribute.maxValue).toFixed(2)} {attribute.uom}
                                                </div>
                                            </div> })
                                    }
                                </div>
                            </div>

                        ))}
                </div>
                <StatusV2 heading="Service Status" options={SERVICE_STATUS_OPTIONS} formik={formik} name="status" mode={mode} />
            </div>

            <div className="flex justify-end gap-4 mt-2">
                <Button variant="tertiaryContained" label='Back' onClick={onBack} />
                <Button onClick={handleActionRoute(Number(params.id))} variant='primaryContained' label={"Update"} />
            </div>
        </form>
    )
}

export default USCViewTemplate