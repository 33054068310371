import Radio from '@mui/material/Radio';
import RadioGroup, { RadioGroupProps } from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import RadioActive from '../../../assets/images/radioActive.svg';
import RadioInActive from '../../../assets/images/radioInactive.svg'

interface Option {
    value: string;
    label: string;
    disabled?: boolean;
}

interface CustomRadioGroupProps extends Omit<RadioGroupProps, 'value' | 'onChange'> {
    options: Option[];
    value: string;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const CustomRadioGroup: React.FC<CustomRadioGroupProps> = ({
    options,
    value,
    onChange,
    ...radioGroupProps
}) => {
    
    return (
        <RadioGroup {...radioGroupProps} value={value} onChange={onChange} row className='flex gap-3'>
            {options.map((option) => (
                <FormControlLabel
                    key={option.value}
                    value={option.value}
                    control={
                        <Radio icon={<img src={RadioInActive} />} checkedIcon={<img src={RadioActive} />} />}
                    label={option.label}
                    disabled={option.disabled}
                    className={`px-3 py-2 border rounder rounded-lg  !ml-0 !mr-0 ${radioGroupProps.className}`}
                />
            ))}
        </RadioGroup>
    );
};

export default CustomRadioGroup