import React from 'react'
import { useAuthenticatedUser } from '../../../hooks/useAuthenticatedUser';
import { AUTH_STATES } from '../../../utils/types';
import Button from '../../atoms/Button/Button';
import { useNavigate } from 'react-router-dom';
import { CUSTOMER_ROUTES } from '../../../utils/constant';
import { IncognitoUser } from './Authentication.template';
import Emial from "../../../assets/images/Email.svg"
import line from "../../../assets/images/Line 2.svg"
import { createUseStyles } from 'react-jss';
import AuthDialogFooter from '../../molecules/AuthDialogFooter/AuthDialogFooter';

interface EmailConfirmationTemplateProps {
    setAuthState: (state: AUTH_STATES) => void;
    sentEmailType: AUTH_STATES;
    userDetails: IncognitoUser;
    authState: AUTH_STATES;
}

const useStyles = createUseStyles((theme: any) => ({
    content: {
        color: "#5F5F5F",
        lineHeight: "20px"
    },
    orange: {
        color: theme.palette.text.secondary.secondary400,
        lineHeight: "22px"
    },
    confirm: {
        color: theme.palette.text.primary.primary800,
        lineHeight: "22px"
    }
}));

const EmailConfirmationTemplate: React.FC<EmailConfirmationTemplateProps> = ({ setAuthState, userDetails, sentEmailType }) => {
    const classes = useStyles();
    const navigate = useNavigate();
    const { user, syncAuthDialogActive } = useAuthenticatedUser();
    const handleDialogClose = () => {
        syncAuthDialogActive(false);
        setAuthState(AUTH_STATES.LOGIN_WITH_MOBILE)
        user && !user.businessId && navigate(CUSTOMER_ROUTES.ONBOARDING)
    };

    const setAuthStateTo = (authState: AUTH_STATES) => () => {
        setAuthState(authState);
    }

    const handleOnboaring = () => {
        syncAuthDialogActive(false);
       navigate(CUSTOMER_ROUTES.ONBOARDING)
    };

    return (
        <div className='flex flex-col text-center items-center gap-4 w-full'>
            <div className='grid gap-y-3'>
                <img className='w-[100px] mx-auto' src={Emial} alt="EMAIL ENVELOP" />
                <div className={`text-lg font-bold ${classes.confirm}`}>Please check your Email</div>
               <img className="w-[73px] mx-auto" src={line} alt="__" />
            </div>
            <div className='flex flex-col gap-y-2'>
                <span className={`font-base ${classes.content}`}>We have sent a confirmation email to</span>
                <span className={`text-lg font-medium ${classes.orange}`}>{userDetails.email || "your email id"}</span>
                <div className={`w-4/6 text-center mx-auto ${classes.content}`}>Check your email and click on the link to {sentEmailType === AUTH_STATES.FORGOT_PASSWORD ? "change your password" : "confirm"}.</div>
            </div>
            {
                sentEmailType === AUTH_STATES.EMAIL_REGISTRATION && (
                    <div className='grid grid-cols-2 gap-x-3 w-full'>
                        <Button variant="tertiaryContained" size={"medium"} label="Continue Browsing" type="submit" onClick={handleDialogClose} />
                        <Button variant="primaryContained" size={"medium"} label="Start Onboarding" type="submit" onClick={handleOnboaring} />
                    </div>
                )
            }
            {/* {sentEmailType !== AUTH_STATES.FORGOT_PASSWORD && <AuthDialogFooter setAuthStateTo={setAuthStateTo} section={AUTH_STATES.LOGIN_WITH_EMAIL} loginType={AUTH_STATES.LOGIN_WITH_EMAIL} isLogin={false} />} */}
        </div>
    )
}

export default EmailConfirmationTemplate