import * as React from 'react';
import { useState } from 'react';
import { createUseStyles } from 'react-jss';
import Button from '../../atoms/Button/Button';
import noData from '../../../assets/images/noData.svg';
import BussinessProfileGSTEditTemplate from './BussinessProfileGSTEdit.template';
import { getProfileDisplayData } from '../../../utils/helper';

const useStyles = createUseStyles((theme: any) => ({
    container: {
        border: `1px solid ${theme.palette.border.neutral.neutral100}`,
    },
    primaryContainer: {
        border: `1px solid ${theme.palette.border.neutral.neutral100}`,
    },
    secondaryContainer: {
        background: theme.palette.background.neutral.neutral50,
    },
    container_heading: {
        color: theme.palette.text.primary.primary900,
    },
    title: {
        color: theme.palette.text.primary.primary900,
    },
    card_content: {
        "& span": {
            color: theme.palette.text.neutral.neutral600,
        },
        "& p": {
            color: theme.palette.text.neutral.neutral700,
        }
    },
    secondaryText: {
        color: theme.palette.text.neutral.neutral800,
    },
    borderBottom: {
        borderBottom: `1px solid ${theme.palette.border.neutral.neutral100}`,
    }
}));

interface BussinessGSTTemplateProps {
    businessProfile: any,
    handleSection: any,
    updateBusinessProfileSection: (section: string, values: any) => void,
    handleUpdateBusinessProfile: () => void;
}

const BussinessProfileGSTTemplate: React.FC<BussinessGSTTemplateProps> = ({ businessProfile, handleSection, updateBusinessProfileSection, handleUpdateBusinessProfile }) => {
    const classes = useStyles();
    const [isEditing, setIsEditing] = useState(false);

    const handleEditClick = () => {
        setIsEditing(true);
    };

    const handleSuccess = () => {
        setIsEditing(false);
    };

    const handleBack = () => {
        setIsEditing(false);
        handleUpdateBusinessProfile();
    };

    return (
        <>
            {isEditing ? (
                <BussinessProfileGSTEditTemplate
                    businessProfile={businessProfile}
                    updateBusinessProfileSection={updateBusinessProfileSection}
                    onSuccess={handleSuccess} onBack={handleBack} gstformik={undefined} />
            ) : (
                <>
                    {businessProfile?.gstin ? (
                        <div className={`${classes.container} p-4 grid gap-y-6 rounded-xl w-full`}>
                            <div className={`${classes.title} pb-6 ${classes.borderBottom} text-lg font-medium`}>GST Details</div>
                            {businessProfile?.address && (
                                <div className='grid gap-y-6'>
                                    <div className='flex gap-16 w-full'>
                                        <div className={`${classes.card_content} flex gap-2 text-sm`}>
                                            <span >GST Registered Number:</span>
                                            <p className='font-medium'>{getProfileDisplayData(businessProfile?.gstin)}</p>
                                        </div>
                                        <div className={`${classes.card_content} flex gap-2 text-sm`}>
                                            <span>PAN Number:</span>
                                            <p className='font-medium'>{getProfileDisplayData(businessProfile?.pan)}</p>
                                        </div>
                                    </div>

                                    <div className={`${classes.primaryContainer} p-4 grid gap-y-3 rounded-xl w-full`}>
                                        <div className={`${classes.container_heading} font-semibold text-base`}>Principle Office Address (as per GST)</div>
                                        <div className={`${classes.card_content} max-w-lg break-all text-sm grid gap-y-1`}>
                                            <span>Address Line 1</span>
                                            <p className='font-medium'>{getProfileDisplayData(businessProfile?.address?.line1)}</p>
                                        </div>
                                        <div className={`${classes.card_content} max-w-lg break-all text-sm grid gap-y-1`}>
                                            <span>Address Line 2</span>
                                            <p className='font-medium'>{getProfileDisplayData(businessProfile?.address?.line2)}</p>
                                        </div>
                                        <div className='grid grid-cols-3 gap-3 text-sm'>
                                            <div className={`${classes.card_content} grid gap-y-1`}>
                                                <span>Pincode</span>
                                                <p className='font-medium'>{getProfileDisplayData(businessProfile?.address?.pincode)}</p>
                                            </div>
                                            <div className={`${classes.card_content} grid gap-y-1`}>
                                                <span>City</span>
                                                <p className='font-medium'>{getProfileDisplayData(businessProfile?.address?.city)}</p>
                                            </div>
                                            <div className={`${classes.card_content} grid gap-y-1`}>
                                                <span>State</span>
                                                <p className='font-medium'>{getProfileDisplayData(businessProfile?.address?.state)}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    ) : (
                        <div className={`${classes.secondaryContainer} py-8 flex justify-center w-full rounded-3xl`}>
                            <div className='flex flex-col items-center'>
                                <img src={noData} alt="" className='mb-8' />
                                <p className={`${classes.secondaryText} mb-4 text-base font-semibold`}>You haven't provided GST details for your business.</p>
                                <Button variant={'primaryContained'} onClick={handleEditClick} label={"Add GST Details"} />
                            </div>
                        </div>
                    )}
                </>
            )}
        </>
    );
};

export default BussinessProfileGSTTemplate;
