import * as React from 'react';
import { createUseStyles } from 'react-jss';
import Button from '../../atoms/Button/Button';
import { useState, useEffect } from 'react';
import TurnOverEditTemplate from './TurnOverEdit.template';
import noData from '../../../assets/images/noData.svg';
import { BusinessProfile } from './BussinessProfileMain';

const useStyles = createUseStyles((theme: any) => ({
    container: {
        border: `1px solid ${theme.palette.border.neutral.neutral100}`,
    },
    primaryContainer: {
        border: `1px solid ${theme.palette.border.neutral.neutral100}`,
    },
    secondaryContainer: {
        background: theme.palette.background.neutral.neutral50,
    },
    container_heading: {
        color: theme.palette.text.primary.primary900,
    },
    card_content: {
        "& span": {
            color: theme.palette.text.neutral.neutral600,
        },
        "& p": {
            color: theme.palette.text.neutral.neutral700,
        }
    },
    secondaryText: {
        color: theme.palette.text.neutral.neutral800,
    },
    borderBottom: {
        borderBottom: `1px solid ${theme.palette.border.neutral.neutral100}`,
    }
}));

interface TurnOverTemplateProps {
    businessProfile: BusinessProfile | undefined;
    handleSection: any;
    updateBusinessProfileSection: (section: string, values: any) => void;
    handleUpdateBusinessProfile: () => void;
}

const TurnOverTemplate: React.FC<TurnOverTemplateProps> = ({
    businessProfile,
    updateBusinessProfileSection,
    handleUpdateBusinessProfile
}) => {
    const classes = useStyles();
    const [isEditing, setIsEditing] = useState(false);
    const [turnOverMap, setTurnOverMap] = useState<{ [key: string]: number }>({});

    const handleEditClick = () => {
        setIsEditing(true);
    };

    const handleSuccess = (updatedProfile: any) => {
        setIsEditing(false);
    };

    const handleBack = () => {
        setIsEditing(false);
        handleUpdateBusinessProfile();
    };

    useEffect(() => {
        if (businessProfile?.finance?.turnOvers) {
            const turnOverData = businessProfile.finance.turnOvers.reduce((acc, curr) => {
                acc[curr.year] = curr.amount;
                return acc;
            }, {} as { [key: string]: number });
            setTurnOverMap(turnOverData);
        }
    }, [businessProfile?.finance?.turnOvers]);

    const getLastThreeYears = () => {
        const currentYear = new Date().getFullYear();
        return [
            `${currentYear - 1}-${currentYear}`,
            `${currentYear - 2}-${currentYear - 1}`,
            `${currentYear - 3}-${currentYear - 2}`
        ];
    };

    const fixedYears = getLastThreeYears();

    return (
        <div>
            {isEditing ? (
                <TurnOverEditTemplate
                    businessProfile={businessProfile}
                    updateBusinessProfileSection={updateBusinessProfileSection}
                    onSuccess={handleSuccess}
                    onBack={handleBack}
                />
            ) : (
                <>
                    {Object.keys(turnOverMap).length === 0 ? (
                        <div className={`${classes.secondaryContainer} py-8 flex justify-center w-full rounded-3xl`}>
                            <div className='flex flex-col items-center'>
                                <img src={noData} alt="" className='mb-8' />
                                <p className={`${classes.secondaryText} mb-4 text-base font-semibold`}>You haven't provided Turn over Details for your business.</p>
                                <Button className='rounded-lg' variant={'primaryContained'} onClick={handleEditClick} label={"Add Turnover Details"} />
                            </div>
                        </div>
                    ) : (
                        <>
                            <div className={`${classes.container} p-4 grid gap-y-6 rounded-xl w-full`}>
                                <div className={`flex justify-between items-center pb-6 ${classes.borderBottom}`}>
                                    <h2 className={`${classes.container_heading} text-lg font-medium`}>Turn over Details</h2>
                                    <Button variant={'tertiaryContained'} label={'Edit'} onClick={handleEditClick} />
                                </div>
                                <div className='grid grid-cols-3 gap-x-9 text-sm'>
                                    {fixedYears.map((year, index) => (
                                        <div key={index} className={`${classes.card_content}  grid gap-y-1`}>
                                            <span>FY {year}</span>
                                            <p className='font-medium'>₹ {turnOverMap[year] ? `${(turnOverMap[year]).toLocaleString()}` : ''}</p>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </>
                    )}
                </>
            )}
        </div>
    );
};

export default TurnOverTemplate;