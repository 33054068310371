import React from "react";
import { createUseStyles } from "react-jss";
import Grid from "@mui/material/Grid";
import { Attribute } from "../../../utils/types";
import { formatUOM, sortSpecificationAttributes } from "../../../utils/helper";
import useMetaDataService from "../../../hooks/useMetaDataService";
import { ATTRIBUTE_LABEL, COLOR_DATA } from "../../../utils/constant";
import { IGridTemplate } from "../../organisms/GridView/GridView.organism";
import { IColorPalette } from "../CatalogueDetail/AttributeSelection.template";

const useStyles = createUseStyles((theme: any) => ({
  title: {
    color: theme.palette.text.neutral.neutral600,
    fontSize: "14px"
  },
  subText: {
    color: theme.palette.text.primary.primary900,
  },
  container:{

  },
  "@media (max-width: 476px)": {
    container:{
      display: "grid",
      padding: "12px",
      background: theme.palette.background.primary.primary100,
      borderRadius: "6px"
    },
    title: {
      color: theme.palette.text.neutral.neutral500,
      textAlign: "center",
      fontSize: "12px"
    },
    subText: {
      color: theme.palette.text.neutral.neutral600,
      textAlign: "center"
    },
  },
}));

interface ProductUpcItemTemplateProps {
  data: any;
  gridTemplate: IGridTemplate;
}

const ProductUpcItemTemplate: React.FC<ProductUpcItemTemplateProps> = ({ data, gridTemplate }) => {
  const classes = useStyles();
  const { metaData: filterData } = useMetaDataService();
  const sortedSpecificationAttributes = sortSpecificationAttributes(data, filterData.attributes);

  const getAttributeValue = (attributeName?: string, attributeValue?: string) => {
    try {
      if (attributeValue === undefined || attributeValue === '') {
        return '';
      }
      if (attributeName && attributeName.trim().toLowerCase() !== ATTRIBUTE_LABEL.COLOR.toLowerCase()) {
        return attributeValue;
      }
        const colorPalette = COLOR_DATA[attributeValue as keyof IColorPalette];
        return (
            <div className="flex items-center">
                <div className="w-4 h-3 mr-1 rounded-sm" style={{ backgroundColor: colorPalette.bgColor }}></div>
                <span>{attributeValue}</span>
            </div>
        )
    } catch (error) {
        return attributeValue;
    }
  }

  return (
    <div className={classes.container}>
      <Grid container spacing={2}>
        {sortedSpecificationAttributes?.map((attribute: Attribute, index: number) => (
            <Grid item xs={gridTemplate.xs} sm={gridTemplate.sm} md={gridTemplate.md} lg={gridTemplate.lg} xl={gridTemplate.xl} key={attribute.name}>
            <div className="grid gap-y-1">
              <div className={`${classes.title} `}>{attribute?.name}</div>
              <div className={`${classes.subText} text-xs font-semibold`}>{getAttributeValue(attribute?.name, attribute?.attributeOptions)} {attribute.attributeOptions && attribute.uom && formatUOM(attribute.attributeOptions, attribute.uom)}
              </div>
                </div>
            </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default ProductUpcItemTemplate;
