import React, { useState } from 'react'
import { createUseStyles } from 'react-jss';
import Radio from '@mui/material/Radio';
import RadioActive from '../../..//assets/images/radioActive.svg';
import RadioInActive from '../../../assets/images/radioInactive.svg'
import productDemo from '../../../assets/images/productDemo.svg';
import Button from '../../atoms/Button/Button';
import { IProductSpecifications } from './ProductCatalogueView.template';
import { IProductData } from '../DashBoard/Inventory Management/SelectProduct.template';
import ViewProductModalTemplate from '../DashBoard/Inventory Management/ViewProductModal.template';

interface IProductViewProps {
    product: IProductData | null;
    handleChange?: (event: any) => void;
    showButton?: boolean;
    showAttributes?: boolean;
    selectedValue?: string | number;
    formik?: any;
}

const useStyles = createUseStyles((theme: any) => ({
    
    selectBox: {
        border: `1px solid ${theme.palette.border.neutral.neutral200}`,
        transition: "box-shadow 0.3s ease-in-out",
        "&:hover": {
            boxShadow: `0px 0px 24px 0px ${theme.palette.border.neutral.neutral400}`,
        },
    },
    skuCode: {
        color: theme.palette.text.neutral.neutral800,
    },
    lineColor: {
        border: `1px solid ${theme.palette.border.neutral.neutral400}`,
    },
    attributeText: {
        color: theme.palette.text.neutral.neutral600,
    },
    attributeValue: {
        color: theme.palette.text.neutral.neutral700,
    },
    img: {
        maxWidth: "146px",
        height: "146px"
    }
}));

const SKUViewTemplate: React.FC<any> = ({ product, handleChange, showButton, formik, showAttributes }) => {
    const classes = useStyles();
    const [dialogOpen, setDialogOpen]=useState<boolean>(false)
    const onViewHandle = () => {
        setDialogOpen(true)
    }
    return (
        <div className={`${classes.selectBox} rounded-xl p-6 flex gap-6`}>
            <div className='flex items-center'>
                {showButton &&
                    <Radio
                        checked={formik?.values?.productId == product?.productId}
                        onChange={handleChange}
                        value={product?.productId}
                        icon={<img src={RadioInActive} sizes='large' />}
                        checkedIcon={<img src={RadioActive} sizes='large' />}
                        className='w-max'
                    />
                }
            </div>
            <div className='flex items-center'>
                <img src={productDemo} alt="productDemo" className={classes.img} />
            </div>
            <div className=' grid gap-4  w-full'>
                <div className='flex   gap-40 justify-between w-full'>
                    <div className='grid w-[427px]'>
                        <text className={`${classes.attributeValue} font-bold text-lg`}> {product?.title} </text>
                        <div className='grid gap-1.5 mt-3'>
                            <text className={`${classes.skuCode} text-xs font-normal`}>SKU Code</text>
                            <text className={`${classes.attributeValue} font-semibold text-base`}>{product?.productCode}</text>
                        </div>
                    </div>

                    <Button
                        variant="secondaryContained"
                        label="View Product"
                        onClick={onViewHandle}
                        className='w-max'
                    />
                    <div className='hidden'>
                        {dialogOpen && <ViewProductModalTemplate product={product} dialogOpen={dialogOpen} setDialogOpen={setDialogOpen}/>}
                    </div>
                </div>
                <hr className={`${classes.lineColor} border-t `} />
                <div className='flex gap-5 w-full justify-between'>
                    {product?.attributes?.productSpecification?.map(
                        (spec: IProductSpecifications) => (
                            <div className="grid gap-1.5" key={spec.name}>
                                <span className={`${classes.attributeText} text-sm font-normal`}>
                                    {spec.name}
                                </span>
                                <span className={`${classes.attributeValue} font-semibold text-base `}>
                                    {spec.minValue === spec.maxValue
                                        ? `${spec.minValue} ${spec.uom?.toLowerCase()}`
                                        : `${spec.minValue} - ${spec.maxValue} ${spec.uom?.toLowerCase()}`.trim()}
                                </span>

                            </div>
                        )
                    )}
                    {showAttributes &&
                        <div className="flex gap-x-36">
                            <div>
                                <span className={`${classes.attributeText} text-sm font-normal`}>
                                    Thickness
                                </span>

                                <span className={`${classes.attributeValue} block font-semibold text-base `}>
                                    {product?.thickness?.toLowerCase() ?? "-"}
                                </span>
                            </div>
                            <div>
                                <span className={`${classes.attributeText} text-sm font-normal`}>
                                    Width
                                </span>

                                <span className={`${classes.attributeValue} block font-semibold text-base `}>
                                    {product?.width?.toLowerCase() ?? "-"}
                                </span>
                            </div>
                            <div>
                                <span className={`${classes.attributeText} text-sm font-normal`}>
                                    Length
                                </span>
                                <span className={`${classes.attributeValue} block font-semibold text-base `}>
                                    {product?.length?.toLowerCase() ?? "-"}
                                </span>
                            </div>
                            <div>
                                <span className={`${classes.attributeText} text-sm font-normal`}>
                                    Temper
                                </span>
                                <span className={`${classes.attributeValue} block font-semibold text-base `}>
                                    {product?.temper?.toLowerCase() ?? "-"}
                                </span>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default SKUViewTemplate;