import { createUseStyles } from "react-jss";
import uploadImageIcon from "../../../../assets/images/noImage.svg";
import { DOCUMENT_RESOURCE_TYPE, FINISHED_PRODUCTS, FINISHED_PRODUCTS_APPLICATION_TYPE } from "../../../../utils/constant";
import { useEffect, useState } from "react";
import { FormikProps } from "formik";
import Button from "../../../atoms/Button/Button";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useSnackbar } from "../../../../hooks/useSnackBar";
import SelectV2 from "../../../atoms/Select/SelectV2";
import TextFieldV2 from "../../../atoms/Input/TextFieldV2";
import MultiSelectInput2 from "../../../atoms/MultiSelectInput/MultiSelectInput2";
import { IMultiSelectOption } from '../../../atoms/MultiSelectInput/MultiSelectInput2';
import ImageUploadModalTemplate from "../../OnBoarding/ImageUploadModal.template";
import { useFileService } from "../../../../services/useFileService";
import { HTTP_STATUS } from "../../../../utils/types";
import { divide, isNumber } from "lodash";
import filledPlusIcon from '../../../../assets/icons/addIconOutlinedPrimary800.svg'
import filledEditIcon from '../../../../assets/icons/editPenOutlinedIconPrimary500.svg'
import { capitalizeFirstLetter, convertToCamelCase } from "../../../../utils/helper";
import DisplayImage from "../../../molecules/DisplayImage/DisplayImage";
import { UploadedImageData } from "../../CatalogueDetail/SimilarBrands.template";
import { FinishedProductsFormik } from "../../../pages/DashBoard/FinishedProductCreation.page";
interface AddFinishedProductTemplateProps {
    handleOnBack: () => void;
    formik: FormikProps<FinishedProductsFormik>;
    setSelectedFile: (file: (string | null)[]) => void | undefined;
    cardId: number;
    isLoading: boolean
}

const useStyles = createUseStyles((theme: any) => ({
    container: {
        border: `1px solid ${theme.palette.border.neutral.neutral100}`,
    },
    finishedProduct: {
        color: theme.palette.text.primary.primary800,
    },
    uploadImageWrapper: {
        color: theme.palette.text.neutral.neutral700,
    },
    imagePreview: {
        width: "140px",
        height: "160px",
    },

    addButton: {
        "&.MuiButton-root": {
            border: "none",
        },
    },
    imageContainer: {
        width: '141px',
        height: '159px',
        borderRadius: '8px'
    },
    alphaBackground: {
        background: 'rgba(0,0,0,0.1)',
        border: `1px solid ${theme.palette.background.primary.primary500}`
    },
    border: {
        border: `1px solid ${theme.palette.border.neutral.neutral100}`,
    }
}))

const AddFinishedProductTemplate: React.FC<AddFinishedProductTemplateProps> = ({ handleOnBack, formik, isLoading }) => {

    const classes = useStyles();
    const { showSnackbar, SnackBarComponent } = useSnackbar();
    const [isImageModalOpen, setIsImageModalOpen] = useState<boolean>(false);
    const [selectedFile, setSelectedFileTo] = useState<File | null>(null);
    const [hoverId, setHoverIdTo] = useState<string | null>(null);
    const [selectedPreviewImagePath, setSelectedPreviewImagePath] = useState<string>("");
    const [imageDialogOpen, setImageDialogOpenTo] = useState(false);
    const [selectedIndex, setSelectedImageIndex] = useState<number | null>(null)

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (isNumber(selectedIndex) && formik.values.images[selectedIndex] !== null && !(formik.values.images[selectedIndex] instanceof File)) {
            formik.setFieldValue('imageListToDelete', [...formik.values.imageListToDelete as number[], (formik.values.images[selectedIndex] as UploadedImageData)?.id])
        }
        formik.setFieldValue(`images[${selectedIndex}]`, selectedFile as File)
        setSelectedFileTo(null);
        setIsImageModalOpen(false);
    };

    const handleUpload = (index: number) => (e: any) => {
        e.stopPropagation();
        setSelectedFileTo(null);
        setSelectedImageIndex(index);
        setIsImageModalOpen(true);
    }

    const handleSelectChange = (options: IMultiSelectOption[]) => {
        formik.setFieldValue('applicationType', options);
    }

    const handleImageModalClose = () => {
        setIsImageModalOpen(false);
    };

    const handleImageView = (path: string) => () => {
        !imageDialogOpen && setSelectedPreviewImagePath(path);
        setImageDialogOpenTo(!imageDialogOpen);
    }

    return (
        <div className={`flex flex-col gap-y-3`}>
            {SnackBarComponent}
            <span className={`text-2xl font-medium  ${classes.finishedProduct}`}>{`Add ${FINISHED_PRODUCTS.FINISHED_PRODUCT}`}</span>
            <form onSubmit={formik.handleSubmit} className="grid gap-y-6">
                <div className={`${classes.border} grid gap-y-6 p-6 rounded-xl`}>
                    <div className={`${classes.uploadImageWrapper} `}>
                        <span className="text-base font-medium">{FINISHED_PRODUCTS.UPLOAD_IMAGE}</span>
                        <div className="flex gap-5 mt-3">
                            {Array(formik.values?.images?.length < 5 ? formik.values?.images?.length + 1 : 5).fill(null).map((_, index) => (
                                <div key={index}>
                                    {index !== formik.values?.images?.length ?
                                        <div className={`overflow-hidden relative ${classes.imageContainer}`} onMouseEnter={() => setHoverIdTo(`EDIT-${index}`)} onMouseLeave={() => setHoverIdTo(null)}>
                                            <img className={`rounded-lg cursor-pointer ${classes.imagePreview}`}
                                                src={formik.values?.images?.[index] instanceof File ?
                                                    URL?.createObjectURL(formik.values?.images?.[index] as File)
                                                    :
                                                    (formik.values?.images?.[index] as UploadedImageData)?.path} alt="icon" />
                                            {hoverId === `EDIT-${index}` && <span className={`${classes.alphaBackground} rounded-lg absolute top-0 right-0 w-full h-full z-0`}
                                                onClick={handleImageView(formik.values?.images?.[index] instanceof File ?
                                                    URL?.createObjectURL(formik.values?.images?.[index] as File)
                                                    :
                                                    (formik.values?.images?.[index] as UploadedImageData)?.path)}>
                                                <img src={filledEditIcon} alt="" onClick={handleUpload(index)} className="absolute top-0 right-0 mt-2 mr-2 z-10 cursor-pointer" />
                                            </span>}
                                        </div>
                                        : (
                                            <div className={`relative `} onMouseEnter={() => setHoverIdTo(`ADD-${index}`)} onMouseLeave={() => setHoverIdTo(null)}>
                                                <img src={uploadImageIcon} alt="" className="z-0 h-full w-full" />
                                                {hoverId === `ADD-${index}` && <span className={`${classes.alphaBackground} rounded-lg absolute top-0 right-0 w-full h-full`}>
                                                    <img src={filledPlusIcon} alt="" onClick={handleUpload(index)} className="absolute top-0 right-0 mt-2 mr-2 z-10 cursor-pointer" />
                                                </span>}
                                            </div>
                                        )}
                                    {
                                        <ImageUploadModalTemplate
                                            dialogOpen={isImageModalOpen}
                                            setDialogOpen={handleImageModalClose}
                                            onSubmit={handleFileChange}
                                            selectedFile={selectedFile}
                                            setSelectedFile={setSelectedFileTo}
                                            isLocalUpload
                                        />}
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="grid">
                        <div className="grid grid-cols-2 gap-x-4">
                            <TextFieldV2
                                label="Product Name"
                                {...formik.getFieldProps("productName")}
                                placeholder="Enter Product Name"
                                value={convertToCamelCase(formik.values.productName)}
                                fullWidth
                                error={formik.touched.productName && Boolean(formik.errors.productName)}
                                helperText={formik.errors?.productName}
                            />

                            <MultiSelectInput2
                                {...formik.getFieldProps('applicationType')}
                                options={FINISHED_PRODUCTS_APPLICATION_TYPE?.map(option => ({ label: option.label, value: option.value }))}
                                label="Application Type"
                                value={formik.values.applicationType}
                                onchange={handleSelectChange}
                                fullWidth
                                placeholder='Select Application Type'
                                error={formik.touched.applicationType && Boolean(formik.errors.applicationType)}
                                helperText={formik.errors.applicationType as string}
                            />

                        </div>
                        <div className="mt-3">
                            <TextFieldV2
                                label="Product Description"
                                {...formik.getFieldProps("productDescription")}
                                placeholder="Enter Description"
                                fullWidth
                                error={formik.touched.productDescription && Boolean(formik.errors.productDescription)}
                                helperText={formik.errors?.productDescription}
                            />
                        </div>
                    </div>
                </div>
                <div className="flex justify-end gap-x-4">
                    <Button label="Back" variant="tertiaryContained" onClick={handleOnBack} />
                    <Button type="submit" label="Add" variant="primaryContained" className={classes.addButton} isLoading={isLoading} />
                </div>
            </form>

            <DisplayImage path={selectedPreviewImagePath} imageDialogOpen={imageDialogOpen} setImageDialogOpenTo={setImageDialogOpenTo} />
        </div>
    )
}

export default AddFinishedProductTemplate;