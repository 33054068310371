import { request } from "."
// import { VerifyTokenParams } from "../components/template/Authentication/Password.template";
// import { RequestTokenParams } from "../components/template/Authentication/RegisterEmail.template";
import { useAuthenticatedUser } from "../hooks/useAuthenticatedUser";
import { API_METHOD } from "../utils/constant";

export interface VerifyTokenParams{
    token: string | null
}

export interface RequestTokenParams {
    email: string
}

export const USER_URLS = {
    UPDATE_USER: "/user",
    GET_USER: "/user",
    FORGOT_PASSWORD: "/email/request-token",
    VERIFY_TOKEN: "/email/verify-token",
    SET_PASSWORD: "/user/set-password",
    UPDATE_PASSWORD: "/user/password",
    CREATE_USER: "/user/admin",
}

export interface IUserRequestBody {
    mobileNumber: string;
    fullName: string;
    email: string | null;
    gstin: string | null;
    companyProfile: {
        address: {
            city: string;
            email: string | null;
            state: string;
            pincode: string;
            line1: string;
            line2: string;
            mobileNumber: string;
            resourceType: string;
        };
        business: {
            name: string;
            gstin: string | null;
            statutory: {};
        };
    };
}

export const useUserService = () => {
    const { user: authenticatedUser } = useAuthenticatedUser();

    const updateUser = (data: { fullName?: string; email?: string; phoneNumber?: string; gstin?: string; }) => {
        return request(API_METHOD.PUT, USER_URLS.UPDATE_USER, authenticatedUser, data);
    }
    const forgetPassword = (params: RequestTokenParams) => {
        return request(API_METHOD.GET, USER_URLS.FORGOT_PASSWORD, null, null, { params });
    }
    const verifyToken = (params: VerifyTokenParams) => {
        return request(API_METHOD.GET, USER_URLS.VERIFY_TOKEN, null, null, { params });
    }
    const updatePassword = (data: { oldPassword: string | null, newPassword: string }) => {
        return request(API_METHOD.PUT, USER_URLS.UPDATE_PASSWORD, authenticatedUser, data);
    } 
    const setPassword = (params: VerifyTokenParams, data: { newPassword: string }) => {
        return request(API_METHOD.PUT, USER_URLS.SET_PASSWORD, null, data, { params });
    } 
    const createUser = (data: IUserRequestBody) => {
        return request(API_METHOD.POST, USER_URLS.CREATE_USER, authenticatedUser, data);
    };
    const getUser = () => {
        return request(API_METHOD.GET, USER_URLS.GET_USER, authenticatedUser);
    };

    return {
        updateUser, forgetPassword, verifyToken, setPassword, createUser, updatePassword, getUser
    }
};