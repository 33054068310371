import * as React from "react";
import { useEffect, useState } from "react";
import { useLeadService } from "../../../services/useLeadService";
import { createUseStyles } from "react-jss";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { useNavigate, useParams } from "react-router-dom";
import { CUSTOMER_ROUTES, STATUS } from "../../../utils/constant";
import { getFormattedDate, REQUEST_ORDER_STATUS } from "../../../utils/helper";

export interface Quotation {
  id: number;
  status: string;
  createdDate: Date;
  updatedDate: Date;
}

const useStyles = createUseStyles((theme: any) => ({
  quotationTableHead: {
    background: theme.palette.background.neutral.neutral50,
  },
  quotationTableBody: {
    background: theme.palette.background.neutral.neutral50,
    color: theme.palette.text.primary.primary900,
    "&:nth-of-type(even)": {
      background: theme.palette.background.neutral.neutral50,
    },
  },
  completed:{
    color: theme.palette.text.success.success500,
  },
  ongoing:{
    color: theme.palette.text.neutral.neutral500,
  },
  cancelled: {
    color: theme.palette.text.secondary.secondary500,
  },
  container: {
    display: "grid",
    rowGap: "24px"
  },
  headingContainer: {
      paddingBottom: "24px",
      borderBottom: `1px solid ${theme.palette.background.primary.primary500}`,
      fontSize: "24px"
  },
  tableContainer: {

  },
  tableSubContainer: {
      
  },
  "@media (max-width: 476px)": {
    container:{
      marginTop: "56px",
      rowGap: "16px"
    },
    headingContainer: {
      paddingBottom: "0px",
      borderBottom: "none",
      fontSize: "16px"
    },
    tableContainer: {
      overflowX: "auto",

    },
    tableSubContainer: {
      width: "800px"
    }
  },
}));

const QuotationListTemplate: React.FC = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const leadService = useLeadService();
  const params = useParams();
  const [quotations, setQuotationsTo] = useState<Quotation[]>([]);

  const loadQuotations = async () => {
    try {
      const quotationResponse = await leadService.getQuotationByLeadId(Number(params.leadId));
      if (quotationResponse?.data?.data?.content?.length) {
        setQuotationsTo(quotationResponse.data.data.content);
      } else {
        throw new Error("Quotations fetch failed");
      }
    } catch (error) {
      setQuotationsTo([]);
    }
  };

  useEffect(() => {
    loadQuotations();
  }, [params.leadId]);

  const handleViewQuotation = (id: number) => () => {
    if(window.location.href.includes(`${CUSTOMER_ROUTES.LEAD_SECONDARY_LOT}`)) {
      navigate(`${CUSTOMER_ROUTES.DASHBOARD}${CUSTOMER_ROUTES.LEAD_SECONDARY_LOT}/${params.leadId}${CUSTOMER_ROUTES.QUOTATION}/${id}`);
    } else if(window.location.href.includes(`${CUSTOMER_ROUTES.LEAD_SECONDARY}`)) {
      navigate(`${CUSTOMER_ROUTES.DASHBOARD}${CUSTOMER_ROUTES.LEAD_SECONDARY}/${params.leadId}${CUSTOMER_ROUTES.QUOTATION}/${id}`);
    } else {
      navigate(`${CUSTOMER_ROUTES.DASHBOARD}${CUSTOMER_ROUTES.LEAD}/${params.leadId}${CUSTOMER_ROUTES.QUOTATION}/${id}`);
    } 

  };

  return (
    <div className={`${classes.container} w-full`}>
      <div className={`${classes.headingContainer} flex items-center justify-between `}>
        <h2 className="">Quotations</h2>
      </div>

      <div className={`${classes.tableContainer}`}>
        <div className={`${classes.tableSubContainer}`}>
          <div className={`${classes.quotationTableHead} flex justify-between py-3 text-sm font-semibold rounded-lg`}>
            <div className="w-14 ml-3">
              Sr No.
            </div>
            <div className="w-24">
              Quotation ID
            </div>
            <div className="w-36">
              Created Date
            </div>
            <div className="w-36">
              Updated Date
            </div>
            <div className="w-28">
              Status
            </div>
            <div className="w-16 mr-3 text-right">
              Action
            </div>
          </div>
          {quotations &&
            quotations.map((item, index) => {
              return (
                <div className={`${classes.quotationTableBody} flex justify-between text-sm font-normal py-3`} key={item.id}>
                  <div className="w-14 ml-3">
                    {index + 1}
                  </div>
                  <div className="w-24">
                    # {item.id}
                  </div>
                  <div className="w-36">
                      {getFormattedDate(item?.createdDate)}
                  </div>
                  <div className="w-36">
                      {getFormattedDate(item?.updatedDate)}
                  </div>
                  <div className={`w-28 ${ item.status === STATUS.ACCEPTED
                  ? classes.completed
                  : item.status === STATUS.ONGOING
                  ? classes.ongoing
                  : item.status === STATUS.REJECTED
                  ? classes.cancelled
                  : ""}`}>
                    {REQUEST_ORDER_STATUS(item.status)}
                  </div>
                  <div className="w-16 mr-3 flex gap-1 text-right">
                    <RemoveRedEyeIcon className="text-secondary cursor-pointer m-auto" onClick={handleViewQuotation(item.id)}/>
                  </div>
                </div>
              );
            })}
            {quotations.length===0 && <div className="mt-4 text-lg font-semibold mx-auto">No Quotation is generated.</div>}
        </div>
      </div>
    </div>
  );
};

export default QuotationListTemplate;
