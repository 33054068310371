import React, { ChangeEvent } from 'react';
import { createUseStyles } from 'react-jss';
import { SelectChangeEvent } from '@mui/material/Select';
import { CREDIT_PERIOD, OTHER_PREFERENCE, PAYMENT_PREFERENCE } from '../../../utils/constant';
import { Checkbox } from '@mui/material';
import { Field, FieldProps } from 'formik';
import SelectV2 from '../../atoms/Select/SelectV2';

const useStyles = createUseStyles((theme: any) => ({
    otherPref: {
        color: theme.palette.text.primary.primary800
    },
    checkBox: {
        "&.MuiCheckbox-root.Mui-checked": {
            color: theme.palette.background.primary.primary400,
        },
        "&.MuiButtonBase-root.MuiCheckbox-root": {
            padding: "0px"
        }
    },
    error: {
        color: 'red',
        fontSize: '13px',
        marginTop: '4px'
    },
    placeholder: {
        color: theme.palette.text.neutral.neutral400,
        fontSize: '16px'
    }
}));

interface PaymentPreferenceProps {
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
    values: {
        payments: string;
        creditDays: string;
        itemCheckbox: number[];
    };
    formik: any;
}

const PaymentPreference: React.FC<PaymentPreferenceProps> = ({ setFieldValue, values, formik }) => {
    const classes = useStyles();

    const handlePaymentChange = (event: SelectChangeEvent<any>) => {
        const value = event.target.value as string;
        setFieldValue("payments", value);
    };

    const handleCreditDayChange = (event: SelectChangeEvent<any>) => {
        const value = event.target.value as string;
        setFieldValue("creditDays", value);
    };

    const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { id, checked } = event.target;
        const idNumber = parseInt(id, 10);
        setFieldValue("itemCheckbox", checked ? [...values.itemCheckbox, idNumber] : values.itemCheckbox.filter(item => item !== idNumber));
    };

    return (
        <div className='grid gap-y-6'>
            <div className="flex gap-x-6 w-8/12">
                <div className="w-full">
                    <Field name="payments">
                        {({ field }: FieldProps) => (
                            <>
                                <SelectV2
                                    {...field}
                                    label="Payment Preference"
                                    disableUnderline={true}
                                    fullWidth
                                    placeholder="Select Payment Preference"
                                    onChange={handlePaymentChange}
                                    displayEmpty
                                    renderValue={field.value ? undefined : () => <span className={classes.placeholder}>Select Payment Preference</span>}
                                    options={PAYMENT_PREFERENCE.map((option) => ({
                                        label: option.label,
                                        value: option.value
                                    }))}

                                />
                                {formik.errors.payments && formik.touched.payments && (
                                    <div className={classes.error}>{formik.errors.payments}</div>
                                )}
                            </>
                        )}
                    </Field>
                </div>
                <div className="w-full">
                    <Field name="creditDays">
                        {({ field }: FieldProps) => (
                            <>
                                <SelectV2
                                    {...field}
                                    label="Credit Period Days"
                                    placeholder="Select Credit Period Days"
                                    disableUnderline={true}
                                    fullWidth
                                    onChange={handleCreditDayChange}
                                    displayEmpty
                                    renderValue={field.value ? undefined : () => <span className={classes.placeholder}>Select Credit days</span>}
                                    options={CREDIT_PERIOD.map((option) => ({
                                        label: option.label,
                                        value: option.value
                                    }))}
                                />
                                {formik.errors.creditDays && formik.touched.creditDays && (
                                    <div className={classes.error}>{formik.errors.creditDays}</div>
                                )}
                            </>
                        )}
                    </Field>
                </div>
            </div>
            <div className='grid gap-y-3'>

                    {OTHER_PREFERENCE.map((item) => (
                        <label key={item.id} className={`${classes.otherPref} flex text-base font-normal items-center`}>
                            <Field type="checkbox" name="itemCheckbox" value={item.id.toString()}>
                                {({ field }: FieldProps) => (
                                    <Checkbox
                                        {...field}
                                        id={item.id.toString()}
                                        checked={values.itemCheckbox.includes(item.id)}
                                        onChange={handleCheckboxChange}
                                        className={`${classes.checkBox}`}
                                    />
                                )}
                            </Field>
                            <div className='ml-2'>{item.value}</div>
                        </label>
                    ))}
            </div>
        </div>
    );
};

export default PaymentPreference;
