import React from 'react';
import { createUseStyles } from 'react-jss';
import visionBanner from '../../../../src/assets/images/visionBanner.svg';
import { ClassNames } from '@emotion/react';

const useStyles = createUseStyles((theme: any) => ({
    container: {
        width: '1297px',
        margin: '0 auto',
    },
    bannerContainer: {
        width: '100%',
        height: '350px',
        backgroundImage: `url(${visionBanner})`,
        backgroundSize: 'cover',
        position: 'relative', color: '#ffffff',
        marginTop: '0px', marginBottom: '50px',
        '& .StaticHeader': {
            position: 'relative',
            height: '350px',
            width: '100%',
            '& h1': {
                position: 'absolute',
                top: '50%',
                fontSize: '40px',
                transform: 'translateY(-50%)',
                '& span': { fontSize: '16px', width: '60%', display: 'block' }
            },
        },
    },
    containerStatic: {
    },
    Card: {
        borderRadius: '14px',
        padding: '20px',
        boxShadow: '0px 0px 5px #00000020',
        textAlign: 'left', display: 'block', marginBottom: '40px',
        '& h2': {
            fontSize: '24px', fontWeight: '500'
        },
        '& p': {
            fontSize: '16px', marginBottom: '10px'
        },
        '& ul': {
            paddingLeft: '40px', marginBottom: '10px',
            '& li': {
                listStyle: 'disc',
            },
        },
        '& a': {
            background: '#2743A0', color: '#ffffff', borderRadius: '10px;', padding: '10px 15px',
            display: 'inline-block', marginTop: '10px', textDecoration: 'none'
        },
    },
    BlueBox: {
        color: '#ffffff',
        padding: '15px 0px',
        '& a': {
            marginTop: '15px',
            fontWeight: '400',
            border: 'solid 1px #ffffff50', padding: '5px 10px',
            display: 'inline-block'
        }
    },
}));

const SteelBazaarServices: React.FC = () => {
    const classes = useStyles();
    return (
        <>
            <div className={`${classes.bannerContainer}`}>
                <div className={`${classes.container} relative`}>
                    <div className="lg:container container lg:flex lg:justify-between mx-auto ">
                        <div className='StaticHeader'>
                            <h1>SteelBazaar Services
                                <span>At SteelBazaar, we're dedicated to more than just supplying high-quality steel. <br /> Our comprehensive services are tailored to the unique needs of your projects, ensuring that very details is managed with expertise and care.</span>
                            </h1>
                        </div>
                    </div>
                </div>
            </div>
            <div className='lg:container container mx-auto lg:px-0 px-5'>
                <div className="col-span-12 text-center">
                    <div className='grid lg:grid-cols-2 pt-5 lg:gap-y-4 gap-y-16 grid-cols-1 lg:gap-x-14 gap-x-0'>
                        <div className={`${classes.Card} cardBx`}>
                            <h2>Project Management</h2>
                            <p>Our dedicated project management team ensures that every aspect of your steel-related projects is handled with precision and expertise. From initial planning to final delivery, we oversee all details to guarantee timely execution, quality control, and budget adherence. </p>
                            <ul>
                                <li>End-to-end project coordination</li>
                                <li>Seamless communication and reporting</li>
                                <li>Budget optimization and timeline management</li>
                            </ul>
                            <p>Let us manage your project, so you can focus on what matters most-growing your business. </p>
                            <div> <a href=''>Contact Us Now</a></div>
                        </div>
                        <div className={`${classes.Card} cardBx`}>
                            <h2>Customization</h2>
                            <p>Every project is unique, and we understand that. Our fully customizable steel solutions are tailored to meet your exact specifications. Whether you need specific dimensions, finishes, or unique materials, we ensure you receive the precise product required for your project's success. </p>
                            <ul>
                                <li>Flexible manufacturing processes</li>
                                <li>Tailored steel products for specific applications</li>
                                <li>Fast and reliable delivery</li>
                            </ul>
                            <p>Get the perfect steel solution made just for you. </p>
                            <div><a href=''>Request a Quote Today</a></div>
                        </div>
                        <div className={`${classes.Card} cardBx`}>
                            <h2>Fabrication</h2>
                            <p>Our fabrication services allow you to transform raw steel into the components your project demands. Using
                                cutting-edge technology and skilled craftsmanship, we shape, cut, weld, and assemble steel according to your
                                precise requirements.</p>
                            <ul>
                                <li>Cutting, welding, and shaping steel</li>
                                <li>Customized assembly of complex structures</li>
                                <li>Stringent quality control measures</li>
                            </ul>
                            <p>Let us handle the fabrication for precision-built steel components.</p>
                            <div><a href=''>Learn More</a></div>
                        </div>
                        <div className={`${classes.Card} cardBx`}>
                            <h2>Dismantling</h2>
                            <p>SteelBazaar offers professional dismantling services for existing structures and steel installations. Our team
                                carefully disassembles structures with minimal disruption and maximum efficiency.</p>
                            <ul>
                                <li>Safe and efficient dismantling</li>
                                <li>Responsible material disposal</li>
                                <li>Minimal downtime and disruption to operations</li>
                            </ul>
                            <p>Looking for safe and reliable dismantling? We're here to help.</p>
                            <div><a href=''>Get Started</a></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='full-w BgPrimary500'>
                <div className='lg:container container mx-auto lg:px-0 px-5'>
                    <div className={`${classes.BlueBox} col-span-12 text-center`}>
                        <p>
                            At SteelBazaar, we're more than just a supplier; we're your partner in success. Explore how our services can help streamline your projects and improve efficiency
                        </p>
                        <div><a href=''>Reach Out to Our Experts</a></div>
                    </div></div>
            </div>
        </>
    );
};
export default SteelBazaarServices;