import React from 'react';
import { createUseStyles } from 'react-jss';
import SaleSolutionsTemplate from '../../template/Home/SalesSolutions/SaleSolutions.template';
import CustomisedMaterialTemplate from '../../template/CustomisedMaterial/CustomisedMaterial.template';
import AipoweredTemplate from '../../template/Home/AiPowered/Aipowered.template';
import FinanceCardTemplate from '../../template/Home/FinanceCards/FinanceCard.template';
import RequestDemoTemplate from '../../template/Home/RequestDemo/RequestDemo.template';
import HomeBanner from '../../template/Home/HomeBanner/HomeBanner';
import NewsTemplate from '../../template/Home/NewsSection/NewsSection.template';
import ProductSliderTemplate from '../../template/Home/ProductRange/ProductRange.template';
import NewArrivalsTemplate from '../../template/Home/NewArrivals/NewArrivals.template';
import IndustriesSlider from '../../template/Home/IndustriesSlider/IndustriesSlider.template';
import ProcurementTemplate from '../../template/Home/Procurement/Procurement.template';
import BrandSlider from '../../template/Home/BrandSlider/BrandSlider.template';
import AuctionAndCustomisedTemplate from '../../template/Home/Auction&Customised/AuctionAndCustomised.template';
import ServicesTemplate from '../../template/Home/Services/Services.template';
import RawSemiFinishedTemplate from '../../template/Home/RawSemiFinished/RawSemiFinished.template';
import FinishedTemplate from '../../template/Home/Finished/Finished.template';

const useStyles = createUseStyles((theme: any) => ({
    container: {
        display: "grid",
        padding: "0px 70px",
    },
}));
const HomePage: React.FC = () => {
    const classes = useStyles();
    return (
        <div className='grid item-center'>
             <HomeBanner />
             <RawSemiFinishedTemplate />
             <FinishedTemplate />
             {/* <div className={`${classes.container} max-container mx-auto flex flex-col`}>
                 <ProductSliderTemplate />
                <NewArrivalsTemplate />
                <ProcurementTemplate />
                <CustomisedMaterialTemplate />
                <SaleSolutionsTemplate /> 
            </div> */}
            <AuctionAndCustomisedTemplate />
            <div className={`${classes.container} max-container mx-auto flex flex-col`}>
                <AipoweredTemplate />
                <FinanceCardTemplate />
                <ServicesTemplate />
            </div>
            <BrandSlider />
            <div className={`${classes.container} max-container mx-auto flex flex-col`}>
                <NewsTemplate />
                <IndustriesSlider />
            </div>
            <RequestDemoTemplate />
        </div>
    );
};

export default HomePage;