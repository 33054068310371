import { FormikProps } from 'formik'
import React from 'react'
import { IMaterialOtherPreference } from './MaterialOtherPreferences.template';
import { createUseStyles } from 'react-jss';
import Button from '../../../atoms/Button/Button';
import { MODE } from '../../../../utils/types';
import { capitalizeFirstLetter } from '../../../../utils/helper';

interface MaterialOtherPreferencesViewTemplate {
    formik: FormikProps<IMaterialOtherPreference>
    setModeTo: (value: MODE) => void;
    mode: MODE;
    setIsFormModeTo: (value: boolean) => void;
}

const useStyles = createUseStyles((theme: any) => ({
    primary500: {
        color: theme.palette.text.primary.primary500,
    },
    heading: {
        color: theme.palette.text.primary.primary800
    },
    sectionBackground: {
        background: 'white',
        border: `1px solid ${theme.palette.border.neutral.neutral100}`
    },
    text: {
        color: theme.palette.text.neutral.neutral600
    },
    value: {
        color: theme.palette.text.neutral.neutral700
    },
    borderTop: {
        borderTop: `1px solid ${theme.palette.border.neutral.neutral100}`
    },
    selectedCustomization: {
        color: theme.palette.text.neutral.neutral900
    }
}));

const MaterialOtherPreferencesViewTemplate: React.FC<MaterialOtherPreferencesViewTemplate> = ({ formik, mode, setIsFormModeTo }) => {
    const classes = useStyles();

    const handleEdit = () => {
        setIsFormModeTo(true);
    }

    return (
        <div className={`p-6 rounded-xl w-full ${classes.sectionBackground}`}>
            <div className={`grid gap-3 w-full`}>
                <div className={`w-full flex justify-between items-center`}>
                    <span className={`${classes.heading} text-lg font-medium`}>Other Preference</span>
                    <Button variant='tertiaryContained' label={`${mode !== MODE.ADD ? 'Edit' : '+Add'}`} className='!font-normal' onClick={handleEdit} />
                </div>
                <div className={`grid gap-6 w-full pt-3 ${classes.borderTop}`}>
                    {mode !== MODE.ADD && <div className={`flex gap-3`}>
                        <div className={`${classes.selectedCustomization} font-normal text-base`}>Selected Customizations:</div>
                        <div className={`flex`}>
                            {formik.values.customization.map((item, index) => (
                                <div className='flex '>
                                    <span className={`${classes.primary500}`}>{item}</span>
                                    {!(formik.values?.customization?.length - 1 === index) && <span className='mx-4'> | </span>}
                                </div>
                            ))}
                        </div>
                    </div>}
                    {mode !== MODE.ADD &&
                        <div className={`grid gap-6 grid-cols-3 text-sm`}>
                            <div className='grid gap-y-1'>
                                <div className={`${classes.text} font-normal text-sm`}>Delivery time after order</div>
                                <div className={`${classes.value} font-medium`}>{formik.values.preferredDeliveryTime}</div>
                            </div>
                            <div className='grid gap-y-1'>
                                <div className={`${classes.text} font-normal text-sm`}>Preferred delivery location</div>
                                <div className={`${classes.value} font-medium`}>{(formik.values.preferredDeliveryLocation).map(location => capitalizeFirstLetter(location.label)).join(', ')}</div>
                            </div>
                            <div className='grid gap-y-1'>
                                <div className={`${classes.text} font-normal text-sm`}>Import/Export</div>
                                <div className={`${classes.value} font-medium`}>{capitalizeFirstLetter(formik.values.tradeType)}</div>
                            </div>
                        </div>}
                </div>
            </div>
        </div>
    )
}

export default MaterialOtherPreferencesViewTemplate