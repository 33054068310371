import React, { useEffect, useState } from 'react'
import TabsV2, { ITabsSchema } from '../../molecules/TabsV2/TabsV2';
import { CATALOGUES_TYPE, CATALOGUES_TYPES, TAB_MAPPING_CATALOGUES } from '../../../utils/constant';
import ComingSoon from '../../atoms/ComingSoon/ComingSoon';
import { IVendorStats, initialStatsValue } from '../../pages/PostProduct/PostProductList.page';
import IVendorCatalogueTableViewTemplate from './VendorCatalogueTableView.template';
import { usePostProductService } from '../../../services/usePostProductService';
import { useAuthenticatedUser } from '../../../hooks/useAuthenticatedUser';
import { HTTP_STATUS } from '../../../utils/types';

export interface IVendorCatalogueTemplateProps {
  handleAddNewProduct: () => void;
}
const VendorCatalogueTemplate: React.FC<IVendorCatalogueTemplateProps> = ({ handleAddNewProduct }) => {

  const initialTab = TAB_MAPPING_CATALOGUES[CATALOGUES_TYPES.STANDARD_CATALOG as TabType];
  const [activeTab, setActiveTabTo] = useState(initialTab);
  const [catalogStats, setCatalogStats] = useState<IVendorStats>(initialStatsValue);
  const productService = usePostProductService();
  const { user } = useAuthenticatedUser();

  const loadVendorCatalogStats = () => {
    if (user?.businessId) {
      productService.getVendorProductStats(user?.businessId, "CATALOGUE")
        .then(res => {
          if (res?.status === HTTP_STATUS.OK) {
            setCatalogStats(res?.data?.data);
          }
        }).catch((error) => {
          console.error("Error Fetching Stats: ", error);
        });
    }
  }

  const schema: ITabsSchema[] = [
    { label: `Standard  (${catalogStats.STANDARD ?? 0})`, component: <IVendorCatalogueTableViewTemplate catalogueType={"STANDARD"} stats={catalogStats.STANDARD ?? 0} handleAddNewProduct={handleAddNewProduct}/> },
    { label: `Non Standard `, component: <ComingSoon /> },
    { label: 'Commercial Scrap', component: <ComingSoon /> },
    { label: 'Melting Scrap', component: <ComingSoon /> },
  ];

  type TabType = keyof typeof TAB_MAPPING_CATALOGUES;

  const handleTabChange = (tab: string | number) => {
    setActiveTabTo(tab.toString());
  };

  useEffect(() => {
    const currentTab = initialTab;
    setActiveTabTo(currentTab);
  }, [initialTab]);

  useEffect(() => {
    loadVendorCatalogStats();
  }, [])

  return (
    <div className='mt-6'>
      <TabsV2 schema={schema} value={activeTab} setValue={handleTabChange} />
    </div>)
}

export default VendorCatalogueTemplate