import React from 'react'
import { createUseStyles } from 'react-jss';
import Button from '../../../atoms/Button/Button';
import RawMaterialListingTemplate from './RawMaterialListing.template';
import { useNavigate } from 'react-router-dom';
import MaterialOtherPreferencesTemplate from './MaterialOtherPreferences.template';
import MaterialBrandManufacturerTemplate from './MaterialBrandManufacturer.template';
import ProdcutCategoryTemplate from './ProductCategoy.template';

const useStyles = createUseStyles((theme: any) => ({
    heading: {
        color: theme.palette.text.primary.priary800
    },
    sectionBackground: {
        background: "rgba(242, 242, 243, 0.40)"
    },

}));

const MainRawMaterialsTemplate = () => {
    const classes = useStyles();
    const navigate = useNavigate();

    const handleAddProduct = () => {
        navigate(`/dashboard/my-material/add`)
    }

    return (
        <div className='grid gap-6 mt-6'>
            <div className={`w-full flex justify-between items-center `}>
                <span className={`${classes.heading} text-lg my-auto`}>Raw Material Listing</span>
                <Button variant='primaryContained' label="+Add New Product" onClick={handleAddProduct} />
            </div>
            <div className={`${classes.sectionBackground} p-3 rounded-xl grid gap-y-6`}>
                <div className=''>
                    < RawMaterialListingTemplate />
                </div>
                <div>
                    < ProdcutCategoryTemplate />
                </div>
                <div>
                    < MaterialBrandManufacturerTemplate />
                </div>
                <div>
                    < MaterialOtherPreferencesTemplate />
                </div>
            </div>
        </div>
    )
}

export default MainRawMaterialsTemplate