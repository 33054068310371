import React from 'react'
import { IMaterialRequirement } from '../../../pages/DashBoard/MyMaterialProduct.page'
import { createUseStyles } from 'react-jss';
import { capitalizeFirstLetter } from '../../../../utils/helper';

interface MaterialViewTemplateProps {
    materialRequirement: IMaterialRequirement
}

const useStyles = createUseStyles((theme: any) => ({
    darkHeading: {
        color: theme.palette.text.primary.primary800,
        lineHeight: "22px"
    },
    heading: {
        color: theme.palette.text.neutral.neutral600,
        lineHeight: "16px"
    },
    text: {
        color: theme.palette.text.neutral.neutral700,
        lineHeight: "20px"
    }
}));

const MaterialViewTemplate: React.FC<MaterialViewTemplateProps> = ({ materialRequirement }) => {
    const classes = useStyles();
    return (
        <div className='flex flex-col gap-6'>
            <div>
                <div className={`pb-3 text-lg font-medium ${classes.darkHeading}`}>Standard</div>
                <div className='flex justify-between gap-4'>
                    <div className='grid gap-y-1'>
                        <div className={`${classes.heading} font-normal`}>Standard</div>
                        <div className={`${classes.text} font-medium`}>{materialRequirement?.standardName}</div>
                    </div>
                    <div className='grid gap-y-1'>
                        <div className={`${classes.heading} font-normal`}>Grade</div>
                        <div className={`${classes.text} font-medium`}>{materialRequirement?.gradeName}</div>
                    </div>
                    <div className='grid gap-y-1'>
                        <div className={`${classes.heading} font-normal`}>Shape</div>
                        <div className={`${classes.text} font-medium`}>{materialRequirement?.shape?.split("_").map(el => capitalizeFirstLetter(el)).join(" ")}</div>
                    </div>
                </div>
            </div>
            <div>
                <div className={`pb-3 text-lg font-medium ${classes.darkHeading}`}>Product Specification</div>
                <div className='grid grid-cols-3 gap-6'>
                    {
                        materialRequirement?.attributes?.map((attribute) => {
                            return (
                                <div className='grid gap-y-1'>
                                    <div className={`${classes.heading} font-normal`}>{`${attribute.name} ${attribute.uom ? `(${attribute.uom.toLowerCase()})` : ""}`}</div>
                                    <div className={`${classes.text} font-medium`}>{attribute.attributeOptions} </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
            <div>
                <div className={`pb-3 text-lg font-medium ${classes.darkHeading}`}>Other Information</div>
                <div className='flex gap-x-14 pb-3'>
                    <div className='grid gap-y-1'>
                        <div className={`${classes.heading} font-normal`}>Application Type</div>
                        <div className={`${classes.text} font-medium`}>{materialRequirement?.applicationType?.split("_").map(el => capitalizeFirstLetter(el)).join(" ")}</div>
                    </div>
                    <div className='grid gap-y-1'>
                        <div className={`${classes.heading} font-normal`}>Primary Sourcing Purpose</div>
                        <div className={`${classes.text} font-medium`}>{materialRequirement?.primarySourcingPurpose}</div>
                    </div>
                </div>
                <div className='grid gap-y-1'>
                        <div className={`${classes.heading} font-normal`}>Any Other Specific Requirement (Optional)</div>
                        <div className={`${classes.text} font-medium`}>{materialRequirement?.otherSpecificRequirement}</div>
                </div>
            </div>
            <div>
                <div className={`pb-3 text-lg font-medium ${classes.darkHeading}`}>Estimated Product Requirement</div>
                <div className='grid grid-cols-2 gap-6'>
                    <div className='grid gap-y-1'>
                        <div className={`${classes.heading} font-normal`}>Frequency</div>
                        <div className={`${classes.text} font-medium`}>{materialRequirement?.frequency}</div>
                    </div>
                    <div className='grid gap-y-1'>
                        <div className={`${classes.heading} font-normal`}>Quantity</div>
                        <div className={`${classes.text} font-medium`}>{materialRequirement?.quantity} (MT)</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MaterialViewTemplate