import React from 'react';
import { createUseStyles } from "react-jss";
import Dialog from '@mui/material/Dialog';
import Button from "../../atoms/Button/Button";
import CloseIcon from '@mui/icons-material/Close';

interface IPopUpModalTemplateProps {
    dialogOpen: boolean;
    heading: string;
    text: string;
    handleDialogClose: (value:boolean) => void;
}

const useStyles = createUseStyles((theme: any) => ({
    container: {
        "& .MuiDialog-paper": {
            width: "458px !important",
            maxWidth: "458px !important",
            borderRadius: "16px"
        },
    },
    closeIcon: {
        color: theme.palette.text.primary.primary900
    },
    heading: {
        color: theme.palette.text.primary.primary800
    },
    textColor: {
        color: theme.palette.text.neutral.neutral700
    },
    button: {
        paddingLeft: "89px !important",
        paddingRight: "89px !important",
        color: theme.palette.button.secondaryDark,
    }
}));

const AlertPopupModelTemplate: React.FC<IPopUpModalTemplateProps> = ({ dialogOpen, heading, text, handleDialogClose }) => {
    const classes = useStyles();
    
    return (
        <Dialog 
            fullWidth 
            className={classes.container} 
            PaperProps={{ style: { height: 'auto' } }}
            open={dialogOpen} 
            onClose={handleDialogClose}
        >
            <div className="absolute top-0 right-0 p-4 cursor-pointer" onClick={()=> handleDialogClose(false)}>
                <CloseIcon className={classes.closeIcon} />
            </div>
            <div className="border-2 px-8 pt-12 pb-12 grid justify-items-center gap-8">
                <div className="grid justify-items-center gap-3">
                    <div className={`${classes.heading} font-semibold text-2xl`}>{heading}</div>
                    <div className={`font-normal text-sm ${classes.textColor}`}>{text}</div>
                </div>
                <div className="flex justify-center gap-8">
                    <Button
                        variant="tertiaryContained"
                        label="No"
                        onClick={()=> handleDialogClose(false)}
                    />
                    <Button
                        variant="primaryContained"
                        label="Yes"
                        onClick={()=> handleDialogClose(true)}
                    />
                </div>
            </div>
        </Dialog>
    );
}

export default AlertPopupModelTemplate;