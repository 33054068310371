import { FormHelperText } from '@mui/material';
import React, { useState } from 'react'
import { createUseStyles } from 'react-jss'
import Button from '../../atoms/Button/Button';
import { HTTP_STATUS, ONBOARDING_STATES } from '../../../utils/types';
import { IOnBoardingPayload } from '../../pages/OnBoarding/OnBoarding.page';
import { useBusinessService } from '../../../services/useBusinessService';
import { useAuthenticatedUser } from '../../../hooks/useAuthenticatedUser';
import { useSnackbar } from '../../../hooks/useSnackBar';
import { useNavigate } from 'react-router-dom';
import { FormikProps } from 'formik';

interface ServiceRequirementsTemplateProps {
    formik: FormikProps<IOnBoardingPayload>;
    setFormState: (value: ONBOARDING_STATES) => void;
};

export interface IServiceRequirements {
    business: {
        serviceRequirements: string
    }
}

const useStyles = createUseStyles((theme: any) => ({
    heading: {
        color: theme.palette.text.neutral.neutral800,
        fontSize: "28px",
        lineHeight: "34px"
    },
    description: {
        color: theme.palette.text.neutral.neutral700,
        lineHeight: "20px"
    },
    selected: {
        border: `1px solid ${theme.palette.border.primary.primary200}`,
        background: theme.palette.background.primary.primary50,
    },
    label: {
        color: theme.palette.text.neutral.neutral700,
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "16px"
    },
    unSelected: {
        color: theme.palette.text.primary.primary800,
        border: `1px solid ${theme.palette.border.primary.primary200}`,
        "&:hover": {
            background: theme.palette.background.primary.primary50,
        },
    },
}))

const requirements = [
    'Slitting',
    'Metal Offset Printing',
    'Shearing',
    'Blanking',
    'Warehousing and Handling',
    'Logistics'
]

const ServiceRequirementsTemplate: React.FC<ServiceRequirementsTemplateProps> = ({ formik, setFormState }) => {
    const classes = useStyles();
    const businessService = useBusinessService();
    const { user } = useAuthenticatedUser();
    const { showSnackbar, SnackBarComponent } = useSnackbar();
    const navigate = useNavigate();
    const [error, setError] = useState(false);

    const handleSelect = (option: any) => {
        const selection = new Set(formik.getFieldProps("serviceRequirements").value);
        if (selection.has(option))
            selection.delete(option)
        else {
            selection.add(option)
            setError(false)
        }
        formik.setFieldValue('serviceRequirements', Array.from(selection));
    }

    const handleSubmit = () => {
        const selectedRequirements = formik.getFieldProps("serviceRequirements").value;
        if (!selectedRequirements.length) {
            setError(true)
            return
        }
        formik.setSubmitting(true);
        user && businessService.updateBusiness({ business: { serviceRequirements: selectedRequirements.join(",") } }, user.businessId)
            .then(res => {
                if (res?.status === HTTP_STATUS.OK)
                    navigate('/');
                else showSnackbar('error', 'Something went wrong');
            }).catch(error => {
                console.error(error)
                showSnackbar('error', 'Something went wrong')
            }).finally(() => { formik.setSubmitting(false); });
    }

    const onBack = () => {
        setFormState(ONBOARDING_STATES.PRODUCT)
    }

    const handleSkip = () => {
        navigate('/');
    }

    return (
        <div className='grid gap-y-10'>
            {SnackBarComponent}
            <div className={`grid gap-y-1`}>
                <div className={`${classes.heading} font-medium`} >Business Profile</div>
                <div className={`${classes.description} text-base font-medium`}>Please verify your business details</div>
            </div>

            <div className='grid gap-y-2'>
                <div className={classes.label}>Service Requirements</div>
                <div className=''>
                    <div className='flex gap-2 flex-wrap'>
                        {
                            requirements.map((option) => (
                                <div className={`py-2.5 px-4 text-base font-medium rounded-md w-fit cursor-pointer ${formik.getFieldProps("serviceRequirements").value.includes(option) ? classes.selected : classes.unSelected}`}
                                    onClick={() => handleSelect(option)}>{option}</div>
                            ))
                        }
                    </div>
                    {error &&
                        <FormHelperText error className='top-half '>Service requirement is required</FormHelperText>
                    }
                </div>
            </div>

            <div className='flex justify-between'>
                <Button size='medium' variant='tertiaryContained' label={"Back"} className='' onClick={onBack} />
                <div className='flex justify-end gap-x-3'>
                    <Button size='medium' variant='tertiaryContained' label={"Skip for now"} onClick={handleSkip} />
                    <Button size='medium' variant="primaryContained" label="Submit" className='w-[179px]' onClick={handleSubmit} isLoading={formik.isSubmitting} />
                </div>
            </div>
        </div>)
}

export default ServiceRequirementsTemplate