import { API_METHOD } from '../utils/constant';
import { request } from '.';
import { replaceUrlParams } from '../utils/helper';
import { useAuthenticatedUser } from '../hooks/useAuthenticatedUser';

const SIMILAR_BRAND = {
    GET: "/products/:brandUpcId/similar-brands"
}

export const useProductUpcBrandMappingService = () => {
    const { user: authenticatedUser } = useAuthenticatedUser();


    const fetchSimilarCataloguesByBrandUpcId = (brandUpcId: number) => {
        return request(API_METHOD.GET, replaceUrlParams(SIMILAR_BRAND.GET, { brandUpcId }), authenticatedUser, null);
    }

    return {
        fetchSimilarCataloguesByBrandUpcId,
    }
}