import React, { useEffect } from 'react'
import { createUseStyles } from 'react-jss';
import { useSnackbar } from '../../../../../hooks/useSnackBar';
import { useVASMasterCategoryService } from '../../../../../services/useVASMasterCategoryService';
import { HTTP_STATUS } from '../../../../../utils/types';
import { MODE } from '../../../../../utils/constant';
import TextFieldV2 from '../../../../atoms/Input/TextFieldV2';
import { Attribute, SubAttribute } from '../../../../../services/useAttributeServiceV2';

interface AttributeLinkedFormTemplateProps {
    mode: string,
    formik: any;
}

const useStyles = createUseStyles((theme: any) => ({
    border: {
        border: `1px solid ${theme.palette.border.neutral.neutral100}`
    },
    heading: {
        color: theme.palette.text.primary.primary900,
        lineHeight: "20px"
    },
    errorMessage: {
        color: theme.palette.text.secondary.secondary500,
    },
    checkBox: {
        color: theme.palette.text.neutral.neutral300,
        '&.Mui-checked': {
            color: theme.palette.text.primary.primary400,
        },
    },
    number: {
        backgroundColor: theme.palette.background.primary.primary500,
        color: theme.palette.text.primary.primary50,
        width: "55px",
        height: "46px"
    }
}));

const AttributeLinkedFormTemplate: React.FC<AttributeLinkedFormTemplateProps> = ({ mode, formik }) => {
    const classes = useStyles();
    const { showSnackbar, SnackBarComponent } = useSnackbar();
    const vasMasterCategoryService = useVASMasterCategoryService();

    const loadUsc = async () => {
        vasMasterCategoryService.getUSCById(formik.values?.uscId)
            .then(res => {
                if (res.status === HTTP_STATUS.OK) {
                    formik.setFieldValue("attributes", res.data.data.attributes)
                }
            }).catch((error) => {
                formik.setFieldValue("attributes", [])
                console.error("Error Fetching USC: ", error);
                showSnackbar('error', "Error while fetching USC data");
            })
    }

    useEffect(() => {
        if (formik.values?.uscId)
            loadUsc()
    }, [formik.values?.uscId])

    useEffect(() => {
        if (mode === MODE.ADD) {
            const subAttributeIds: Record<string, { minTolerance: number | null; maxTolerance: number | null }> = {};
            formik.values?.attributes.forEach((attribute: any) => {
                attribute.subAttributes.forEach((subAttribute: any) => {
                    subAttributeIds[subAttribute.id] = {
                        minTolerance: 0,
                        maxTolerance: 0
                    };
                });
            });
            formik.setFieldValue("subAttributeIds", subAttributeIds);
        }
    }, [formik.values?.attributes]);

    const handleToleranceChange = (subAttributeId: number, field: 'minTolerance' | 'maxTolerance') => (event: React.ChangeEvent<HTMLInputElement>) => {
        let value = event.target.value;
        if (value == '' || value == null) {
            value = '0'
        } else
            value = parseInt(value, 10).toString();

        formik.setFieldValue(`subAttributeIds.${subAttributeId}.${field}`, !value ? null : value);
    };

    return (
        <>
            {
                formik?.values?.attributes?.length > 0 &&
                <div className={`${classes.border} grid gap-y-4 p-6 rounded-xl`}>
                    <div className={`${classes.heading} text-base font-medium`}>Attributes Linked with Selected USC</div>
                    {
                        formik.values?.attributes &&
                        <div className={`${classes.border} grid gap-y-4 p-4 rounded-xl`} >
                            {
                                formik.values?.attributes?.map((attribute: Attribute, key: any) => (
                                    <div className={`grid gap-y-4 rounded-xl`} key={attribute.id}>
                                        <div className={`${classes.heading} text-base font-medium`}>{attribute.name}</div>
                                        <div className='grid gap-y-4'>
                                            {
                                                attribute.subAttributes.map((subAttribute: SubAttribute, index: any) => (
                                                    <div className='flex gap-x-4'>
                                                        <div className={`${classes.number} rounded-xl text-lg font-medium my-auto flex justify-center items-center`}>{index + 1}</div>
                                                        <div className={`${classes.border} flex gap-x-4 p-3 flex-1 rounded-xl`}>
                                                            <div className="grid w-full">
                                                                <TextFieldV2
                                                                    label="Minimum Range"
                                                                    placeholder='Enter Here'
                                                                    disabled
                                                                    value={subAttribute.minValue}
                                                                />
                                                            </div>
                                                            <div className="grid w-full">
                                                                <TextFieldV2
                                                                    label="Maximum Range"
                                                                    placeholder='Enter Here'
                                                                    disabled
                                                                    value={subAttribute.maxValue}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className={`${classes.border} flex gap-x-4 p-3 flex-1 rounded-xl`}>
                                                            <div className="grid gap-y-1 w-full">
                                                                <TextFieldV2
                                                                    label="-Tolerance"
                                                                    placeholder='Enter Here'
                                                                    value={formik.values?.subAttributeIds?.[subAttribute.id]?.minTolerance}
                                                                    onChange={handleToleranceChange(subAttribute.id, 'minTolerance')}
                                                                    error={formik.touched.subAttributeIds?.[subAttribute.id]?.minTolerance && Boolean(formik.errors.subAttributeIds?.[subAttribute.id]?.minTolerance)}
                                                                    inputProps={{
                                                                        readOnly: mode === MODE.VIEW,
                                                                    }}
                                                                    type='number'
                                                                />
                                                                {formik.errors.subAttributeIds?.[key]?.minTolerance && formik.touched.subAttributeIds?.[key]?.minTolerance && (
                                                                    <div>{`Error for ID ${key}: ${formik.errors.subAttributeIds[key].minTolerance}`}</div>
                                                                )}
                                                            </div>
                                                            <div className="grid gap-y-1 w-full">
                                                                <TextFieldV2
                                                                    label="+Tolerance"
                                                                    placeholder='Enter Here'
                                                                    value={formik.values?.subAttributeIds?.[subAttribute.id]?.maxTolerance}
                                                                    onChange={handleToleranceChange(subAttribute.id, 'maxTolerance')}
                                                                    error={formik.touched.subAttributeIds?.[subAttribute.id]?.maxTolerance && Boolean(formik.errors.subAttributeIds?.[subAttribute.id]?.maxTolerance)}
                                                                    inputProps={{
                                                                        readOnly: mode === MODE.VIEW,
                                                                        min: 0,
                                                                    }}
                                                                    type='number'
                                                                />
                                                                {formik.touched.subAttributeIds?.[subAttribute.id]?.maxTolerance && formik.errors.subAttributeIds?.[subAttribute.id]?.maxTolerance && (
                                                                    <div className={`${classes.errorMessage} text-xs`}>{formik.errors.subAttributeIds[subAttribute.id].maxTolerance}</div>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                ))}
                            {formik.errors.subAttributeIds && formik.touched.subAttributeIds && (
                                <div className={`${classes.errorMessage} text-xs`}>{formik.errors.subAttributeIds}</div>
                            )}
                        </div>
                    }
                </div>
            }
        </>
    )
}

export default AttributeLinkedFormTemplate;