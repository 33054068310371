import React from 'react'
import { createUseStyles } from 'react-jss'
import Dialog from '@mui/material/Dialog';
import Button from "../../atoms/Button/Button";
import CloseIcon from '@mui/icons-material/Close';
import Radio from '@mui/joy/Radio';
import RadioActive from '../../../assets/images/radioActive.svg';
import RadioInActive from '../../../assets/images/radioInactive.svg'
import { Checkbox } from '@mui/joy';
import { Link } from 'react-router-dom';
import { CUSTOMER_ROUTES, STATIC_PAGES_ROUTES, VENDOR_TYPE } from '../../../utils/constant';
import SelectV2 from '../../atoms/Select/SelectV2';

interface ConfirmDialogProps {
    dialogOpen: boolean;
    setDialogOpen: (open: boolean) => void;
    message: string;
    question: string;
    onSubmit: (response: boolean) => void;
    sellerType: string, 
    setSellerType: (response: string) => void;
}

const useStyles = createUseStyles((theme: any) => ({
    container: {
        "& .MuiDialog-paper": {
            width: "694px !important",
            maxWidth: "694px !important",
            borderRadius: "16px",
            padding: "24px"
        },
    },
    headingColor: {
        color: "#331400",
    },
    "@media (max-width: 480px)": {
        container: {
            "& .MuiDialog-paper": {
                width: "448px !important",
                maxWidth: "448px !important",
                height: "826px",
                margin: "16px",
            }
        }
    },
    dullText: {
        color: "#989898"
    },
    sectionText: {
        color: theme.palette.text.primary.primary500,
        marginLeft: '5px',  
        marginRight: '5px',
        textDecoration: 'underline',
        '&:hover': {
            color: 'black', 
        }
    },
    error: {
        fontSize: '13px',
        marginTop: '4px'
    },
}))

const ConfirmDialog: React.FC<ConfirmDialogProps> = ({ dialogOpen, setDialogOpen, message, question, onSubmit, sellerType, setSellerType }) => {
    const classes = useStyles();
    const [value, setValue] = React.useState(true);
    const [isAgreed, setIsAgreed] = React.useState<boolean | null>(null);
    const [isSellerTypeSelected, setSellerTypeSelected] = React.useState<boolean | null>(null);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue(event.target.value == "true");
    };

    const handleDialogClose = (event: React.MouseEvent | {}, reason: "backdropClick" | "escapeKeyDown") => {
        if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
            closeDialog();
        }
    }

    const closeDialog = () => {
        setDialogOpen(false)
        onSubmit(false);
    }

    const handleSubmit = () => {

        if(!value) setDialogOpen(false);
        let errorFlag = false;
        if(isAgreed == null || !isAgreed) { 
            setIsAgreed(false);
            errorFlag  = true;
        }
        if(isSellerTypeSelected == null || !isSellerTypeSelected) {
            setSellerTypeSelected(false);
            errorFlag = true;
        }
        if(errorFlag) {
            onSubmit(false);
            return;
        }
        onSubmit(value);
        setDialogOpen(false);
    }

    const handleSellerTypeChange = (selectedSellerType: string) => {
        setSellerType(selectedSellerType);
        setSellerTypeSelected(true);
    }

    const handleAgreementChange = () => {
        setIsAgreed(isAgreed ===  null ? true : !isAgreed);
    }

    return (
        <Dialog fullWidth className={classes.container} PaperProps={{ style: { height: 'auto' } }}
            open={dialogOpen} onClose={handleDialogClose} >
            <div className={`flex justify-between items-center`} onClick={closeDialog}>
                <span className={`text-2xl font-medium ${classes.headingColor}`}>{message}</span>
                <CloseIcon className={`${classes.headingColor}`} />
            </div>
            <div className={`my-4 border-t`}></div>
            <div className='p-6 rounded-xl border'>
                <div className={`text-lg font-medium ${classes.headingColor}`}>{question}</div>
                <div className='flex gap-9 mt-4'>
                    <Radio value={true} label="Yes" checked={value === true} onChange={handleChange} checkedIcon={<img src={RadioActive} />} uncheckedIcon={<img src={RadioInActive} />} />
                    <Radio value={false} label="No" checked={value === false} onChange={handleChange} checkedIcon={<img src={RadioActive}/>} uncheckedIcon={<img src={RadioInActive} />} />
                </div>
            </div>
            {value && (
                <>
                    <div className={`my-2`}></div>
                    <div className='p-6 rounded-xl border'>
                        <div className={`text-lg font-medium ${classes.headingColor}`}>{"Please select your seller type"}</div>
                        <div className='flex gap-9 mt-4'>
                        
                            <SelectV2
                                value={sellerType}
                                label="Seller Type"
                                disableUnderline={true}
                                displayEmpty
                                placeholder="Select here"
                                fullWidth
                                onChange={(event: any) => handleSellerTypeChange( event.target.value)}
                                options={VENDOR_TYPE.map((option) => ({
                                    label: option.label,
                                    value: option.value
                                }))}
                            />
                        </div>
                            {isSellerTypeSelected === false && (
                                <div className={classes.error}>{`Please select the seller type to proceed`}</div>
                            )}
                        <div className="flex flex-col justify-center pb-4 my-2">
                            <div className="flex items-center gap-2">
                                <Checkbox checked={isAgreed === true} onChange={handleAgreementChange}/> 
                                <span className={`${classes.dullText}`}>I have read and agree to the
                                    <Link to={STATIC_PAGES_ROUTES.TERMS_AND_CONDITIONS} target="_blank" className={`${classes.sectionText}`}>
                                        Terms and Conditions 
                                    </Link>
                                   </span>
                            </div>
                            {isAgreed === false  &&
                                <div className={classes.error}>{`Please read & agree to the terms and conditions to proceed`}</div>
                            }
                        </div>
                    </div>
                </>
            )}
            <div className='flex justify-end mt-6'>
                <Button variant='primaryContained' label="Submit Response" onClick={handleSubmit} />
            </div>
        </Dialog >
    )
}

export default ConfirmDialog