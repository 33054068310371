import { useAuthenticatedUser } from '../hooks/useAuthenticatedUser';
import { API_METHOD } from '../utils/constant';
import { request } from '.';
import { replaceUrlParams } from '../utils/helper';

const PRODUCT_URLS = {
        GET_PRODUCT_PRICE_DETAIL:"/vendor/price-management/product/:productId/warehouse/:warehouseId/prices",
        GET_ALL_PRODUCT_PRICES:"/vendor/price-management/product/:vendorProductId/prices",
        CREATE_PRODUCT_PRICE: "/vendor/price-management"
    }

 export interface IProductPrice {
    page:number;
    size:number;
 }

 interface IProductPriceRequestBody {
    validityTo: string;
    validityFrom: string;
    status?: string;
    warehouseLocation?: string;
    reserveSalesPrice: number;
    marketPrice: number;
    vendorProductId: number;
 }
 
export const useVendorPriceService = () => {
    const { user: authenticatedUser } = useAuthenticatedUser();

    const getVendorProductPriceByVendorProductIdAndWarehouseId = (productId: number , warehouseId: number ) => {
        return request(API_METHOD.GET, replaceUrlParams(PRODUCT_URLS.GET_PRODUCT_PRICE_DETAIL, {productId, warehouseId}), authenticatedUser);
    }

    const getAllPriceProducts = (vendorProductId: number , params: IProductPrice) => {
        return request(API_METHOD.GET, replaceUrlParams(PRODUCT_URLS.GET_ALL_PRODUCT_PRICES, {vendorProductId}), authenticatedUser, null, { params });
    }

    const createProductPrices = (data: IProductPriceRequestBody[]) => {
        return request(API_METHOD.POST, PRODUCT_URLS.CREATE_PRODUCT_PRICE, authenticatedUser, data);
    };

    return {
        getVendorProductPriceByVendorProductIdAndWarehouseId,
        getAllPriceProducts,
        createProductPrices
    };
};
