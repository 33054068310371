import React, { useEffect, useMemo, useState } from 'react';
import { createUseStyles } from 'react-jss';
import MenuIcon from "@mui/icons-material/Menu";
import { useNavigate } from 'react-router-dom';
import StoreFrontAboutUsPage from '../../pages/DashBoard/StoreFrrontAboutUs.page';
import { makeRoute } from '../../../utils/helper';
import { CUSTOMER_ROUTES } from '../../../utils/constant';
import TabsV3, { ITabsSchema } from '../../molecules/TabsV2/TabsV3';
import StoreFrontProduct from '../../pages/StoreFront/StoreFrontProduct.page';
import StoreFrontContactUs from '../../pages/StoreFront/StoreFrontContactUs.page';

interface StoreFrontNavProps {
    localIsStorefront?: boolean
}

const useStyles = createUseStyles((theme: any) => ({
    container: {
        borderTop: `1px solid ${theme.palette.border.neutral.neutral200}`,
    },
    tabContainer: {
        backgroundColor: theme.palette.background.primary.primary50,
        color: "#331400",
    },
    homeSection: {
        borderRight: `1px solid ${theme.palette.border.neutral.neutral400}`,
        cursor: 'pointer',
    },
    categories: {
        color: theme.palette.text.neutral.neutral600,
    },
    homemenu: {
        position: 'relative',
        color: theme.palette.text.neutral.neutral600,
        cursor: 'pointer',
        '&:hover $dropDownContent': {
            display: 'block',
        },
    },
    dropDownContent: {
        display: 'none',
        position: 'absolute',
        left: 0,
        backgroundColor: theme.palette.background.paper,
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        zIndex: 1000,
        width: '200px',
        top: '0px',
    },
}));

const StoreFrontNavBarOptions: React.FC<StoreFrontNavProps> = ({ localIsStorefront }) => {

    const classes = useStyles();
    const [activeTab, setActiveTab] = useState<string>('1');
    const navigate = useNavigate()

    const schema: ITabsSchema[] = [
        { label: 'About Us', component: <StoreFrontAboutUsPage /> },
        { label: 'Products', component: <StoreFrontProduct/> , hasIcon: <MenuIcon />},
        { label: 'Services', component: <div></div>,  disabled: true },
        { label: 'Auctions', component: <div></div>, disabled: true },
        { label: 'Sales Offer', component: <div></div>, disabled: true },
        { label: 'Contact Us', component: <StoreFrontContactUs/> }
    ]

    const handleTabChange = (tab: string | number) => {
        setActiveTab(tab.toString());
        const route = makeRoute(CUSTOMER_ROUTES.STORE_FRONT, { query: { tab: tab.toString() } });
        navigate(`${route}`);
    };
    
    return (
        <div className={`${classes.container} w-full`}>
            {localIsStorefront && 
            <div className={`flex items-center w-[1300px] gap-x-[20px] mx-auto`}>
                <TabsV3 schema={schema} value={activeTab.toString()} setValue={handleTabChange} />
            </div>}
        </div>
    );
};

export default StoreFrontNavBarOptions;