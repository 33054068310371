import { request } from "."
import { useAuthenticatedUser } from "../hooks/useAuthenticatedUser";
import { API_METHOD } from "../utils/constant";

export const USER_PROFILE_TYPE_URLS = {
    CREATE_VENDOR_PROFILE: "/user-profile-types"
}

export const useUserProfileTypeService = () => {
    const { user: authenticatedUser } = useAuthenticatedUser();

    const createVendorProfile = (params: { checkProfile: boolean }) => {
        return request(API_METHOD.POST, USER_PROFILE_TYPE_URLS.CREATE_VENDOR_PROFILE, authenticatedUser, null, { params });
    };

    return { createVendorProfile };
}