import React, { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import { IAttribute, ICatalogueUpc, IUpcProperty } from "../../pages/CatalogueDetail/CatalogueDetails.page";
import { keyToLabel } from "../../../utils/helper";
import { ATTRIBUTES_TO_HIDE, ATTRIBUTE_LABEL, COLOR_DATA, MAX_DISPLAY_ITEMS, SEE_MORE_LESS } from "../../../utils/constant";
import { IColorPalette } from "../../../utils/types";
import Button from "../../atoms/Button/Button";

const useStyles = createUseStyles((theme: any) => ({
    mainContainer: {
        rowGap: "12px",
    },
    heading: {
        color: theme.palette.text.neutral.neutral900,
    },
    container: {
        rowGap: "12px",
    },
    tabContainer: {
        width: "100%"
    },
    tabCard: {
        flex: 1,
    },
    tabTitle: {
        color: theme.palette.text.neutral.neutral400,
    },
    activeTabTitle: {
        color: theme.palette.text.primary.primary400,
        borderBottom: `2px solid ${theme.palette.border.primary.primary400}`,
    },
    specifications: {
        borderBottom: "none",
    },
    label: {
        '&:first-child': {
            borderTop: 'none',
        },
        color: theme.palette.text.neutral.neutral900,
        fontSize: "16px"
    },
    lastLabel: {
        '&:nth-child(10)': {
            opacity: "40%",
        },
    },
    value: {
        '&:nth-child(2)': {
            borderTop: 'none',
        },
        color: theme.palette.text.neutral.neutral700,
        fontSize: "16px",
    },
    lastValue: {
        '&:nth-child(10)': {
            opacity: "40%",
        },
    },
    borderBottom: {
        borderBottom: `1px solid ${theme.palette.border.primary.primary100}`,
    },
    border: {
        border: `1px solid ${theme.palette.border.neutral.neutral100}`,
    },
    "@media (max-width: 480px)": {
        mainContainer: {
            rowGap: "12px",
        },
        container: {
            rowGap: "12px",
        },
        tabContainer: {
            width: "max-content"
        },
        value: {
            fontSize: "14px",
        },
        label: {
            fontSize: "14px"
        },
    },
}));

type Attribute = {
    name: string;
    attributeOptions: string | number | string[];
    uom?: string;
};
interface IProductUpcAttributes {
    label: string;
    data: IAttribute[] | IUpcProperty[];
}
interface IProductUpcAttributeTemplateProps {
    upc: ICatalogueUpc;
}

const ProductUpcAttributeTemplate: React.FC<IProductUpcAttributeTemplateProps> = ({ upc }) => {

    const classes = useStyles();

    const [showAllSpecifications, setShowAllSpecifications] = useState(false);
    const [activeAttributeIndex, setActiveAttributeIndexTo] = useState<string>(keyToLabel("mechanicalProperties"));

    const createAttributeskeyValuePair = (inputArray: Attribute[]): { label: string, key: string }[] => {
        const attributesKeyValuePairs = inputArray?.map((item: Attribute) => ({
            label: item?.name,
            key: item?.attributeOptions.toString() as string,
            uom: item?.uom,
        }));
        return attributesKeyValuePairs;
    };

    const createMechanicalPropertiesKeyValuePair = (inputArray: IUpcProperty[]): { label: string, key: string }[] => {
        const propertiesKeyValuePairs = inputArray?.map((item: IUpcProperty) => ([
            {
                label: item?.name,
                key: item?.roomTemperature || "",
                uom: item?.uom || ""
            }
        ]));
        return propertiesKeyValuePairs?.flat();
    };

    const convertPropertiesKeyValuePair = (inputArray: IUpcProperty[]): any => {
        const propertiesKeyValuePairs = inputArray?.map((item: IUpcProperty) => ({
            name: item?.name,
            min: item?.minimum,
            max: item?.maximum
        }));
        return propertiesKeyValuePairs;
    };

    const combineArrays = (...arrays: any): any[] => {
        if (arrays[1] && Array.isArray(arrays[1])) {
            arrays[1] = arrays[1].filter(item => !ATTRIBUTES_TO_HIDE.includes(item.label.trim().toUpperCase()));
        }
        return [].concat(...arrays);
    };

    const productCategories = [{ "label": "Super Category", "key": upc?.superCategory }, { "label": "Main Category", "key": upc?.mainCategory }, { "label": "Product Category", "key": upc?.productCategory }];

    const categories: IProductUpcAttributes[] = [
        {
            label: "Mechanical Properties",
            data: createMechanicalPropertiesKeyValuePair(upc?.mechanicalProperties)
        },
        {
            label: "Tensile Properties",
            data: convertPropertiesKeyValuePair(upc?.tensileProperties)
        },
        {
            label: "Chemical Composition",
            data: convertPropertiesKeyValuePair(upc?.chemicalComposition)
        },
        {
            label: "Main Specifications",
            data: combineArrays(productCategories, createAttributeskeyValuePair(upc?.attributes?.SPECIFICATION), createAttributeskeyValuePair(upc?.attributes?.CLASSIFICATION), createAttributeskeyValuePair(upc?.attributes?.GENERALIZATION))
        },
    ];

    const handleToggleClick = (category: string) => {
        setActiveAttributeIndexTo(category);
    };

    const activeCategoryData = categories.find((category) => keyToLabel(category?.label) === activeAttributeIndex)?.data;

    const [value, setValue] = useState(categories?.findIndex(category => keyToLabel(category?.label) === activeAttributeIndex));

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        const categoryName = keyToLabel(categories[newValue].label);
        handleToggleClick(categoryName);
        setValue(newValue);
    };

    const handleTabChange = (currentTabIndex: string) => () => {
        setActiveAttributeIndexTo(currentTabIndex);
    };

    const toggleShowAllSpecifications = () => {
        setShowAllSpecifications(!showAllSpecifications);
    };

    const getSpecificationsToDisplay = () => {
            if (showAllSpecifications) {
            return activeCategoryData;
        } else {
            return activeCategoryData && activeCategoryData.slice(0, MAX_DISPLAY_ITEMS.SPECIFICATIONS);
        }
        };
    

    useEffect(() => {
        const categoriesWithData = categories.filter(
            (category) => category.data && category.data.length > 0
        );
        if (categoriesWithData.length > 0) {
            const initialActiveIndex = keyToLabel(categoriesWithData[0].label);
            setActiveAttributeIndexTo(initialActiveIndex);
        }
    }, []);
    
    const getAttributeValue = (attributeName: string, attributeValue: string, uom: string) => {
        try {
            if(attributeName.trim().toLowerCase() !== ATTRIBUTE_LABEL.COLOR.toLowerCase()) {
                return <span>{attributeValue} {uom}</span>;
            }
            const colorPalette = COLOR_DATA[attributeValue as keyof IColorPalette];
            return (
                <div className="ml-1 flex items-center">
                    <div className="w-6 h-4 mr-2 rounded-sm" style={{ backgroundColor: colorPalette.bgColor }}></div>
                    <span>{attributeValue} {uom}</span>
                </div>
            )
        } catch (error) {
            return <span>{attributeValue} {uom}</span>;
        }
	}

    return (
        <div className={`${classes.mainContainer} grid`}>
            <div className={`${classes.heading} text-base font-bold`}>Detailed Specifications (As per above selection)</div>

            <div className={`${classes.container} grid w-full`}>
                <div className={`overflow-x-auto overflow-hidden w-full`}>
                    <div className={`${classes.tabContainer} flex`} >
                        {categories
                            .filter(category => category.data && category.data.length > 0)
                            .map((category, index) => (
                                <span
                                    key={category.label}
                                    onClick={handleTabChange(category.label)}
                                    style={{ padding: '8px 12px' }}
                                    className={` ${classes.tabCard} cursor-pointer text-center ${activeAttributeIndex === category.label ? `${classes.activeTabTitle} text-base font-semibold` : `${classes.tabTitle} text-base font-medium ${classes.borderBottom}`}`}
                                >
                                    {category.label}
                                </span>
                            ))}
                    </div>
                </div>
                {activeCategoryData && <div className={`${!showAllSpecifications && activeCategoryData.length > MAX_DISPLAY_ITEMS.SPECIFICATIONS ? `${classes.specifications} w-full ${classes.border} rounded-2xl` : `${classes.border} w-full rounded-2xl`}`}>
                    <div className="grid grid-cols-2">
                        { (getSpecificationsToDisplay()  as any[])?.map((item: any, index: number) => (
                            <>
                                {item &&
                                    <>
                                        <div className={`${classes.label} font-medium border-t p-2.5 text-center ${!showAllSpecifications && activeCategoryData.length > MAX_DISPLAY_ITEMS.SPECIFICATIONS && index === MAX_DISPLAY_ITEMS.SPECIFICATIONS - 1 ? classes.lastLabel : ""}`}>
                                            {activeAttributeIndex === keyToLabel("chemicalComposition") ? `${item.name}` : `${item.label}`}
                                        </div>
                                        <div className={`${classes.value} border-l border-t p-2.5 text-center flex justify-center items-center ${!showAllSpecifications && activeCategoryData.length > MAX_DISPLAY_ITEMS.SPECIFICATIONS && index === MAX_DISPLAY_ITEMS.SPECIFICATIONS - 1 ? classes.lastValue : ""}`}>
                                            {activeAttributeIndex === keyToLabel("chemicalComposition") ? `${item.min} - ${item.max} ` : item.uom ? getAttributeValue(item.name ?? item.label, item.key, item.uom) : getAttributeValue(item.name ?? item.label, item.key, item.uom)}
                                        </div>
                                    </>
                                }
                            </>
                        ))}
                    </div>
                </div>}
            </div>

            {activeCategoryData && activeCategoryData.length > MAX_DISPLAY_ITEMS.SPECIFICATIONS && (
                <div className='flex justify-center'>
                    <Button size='medium' variant='secondaryText'  onClick={toggleShowAllSpecifications} label={showAllSpecifications ? SEE_MORE_LESS.SEE_LESS : SEE_MORE_LESS.SEE_MORE}/>
                </div>
            )}
        </div>
    );
};

export default ProductUpcAttributeTemplate;
