import React, { useEffect, useState } from 'react'
import { createUseStyles } from 'react-jss';
import { useNavigate } from 'react-router-dom';
import { FormHelperText } from '@mui/material';
import { VendorCatalogueSectionProp } from './VendorCatalogueForm.template';
import { VENDOR_CATALOGUE_FORM_STATES, HTTP_STATUS, STATUS, VENDOR_PRODUCT_DETAILS_TYPE } from '../../../utils/types';
import Button from '../../atoms/Button/Button';
import { useAuthenticatedUser } from '../../../hooks/useAuthenticatedUser';
import { useVendorInventoryService } from '../../../services/useVendorInventoryService';
import { useSnackbar } from '../../../hooks/useSnackBar';
import ProductViewTemplate from '../DashBoard/Inventory Management/ProductView.template';
import TabsV2, { ITabsSchema } from '../../molecules/Tabs/TabsV2';
import { capitalizeFirstLetter } from '../../../utils/helper';
import Tabs from '../../molecules/Tabs/Tabs';
import PricingDetailFormTemplate from './PricingDetailForm.template';
import { IQuantityDiscount, IVendorCatalogueAddForm, IcatalogueProductDetails } from '../../pages/VendorCatalogue/VendorCatalogueAdd.page';
import { FormikErrors, useFormik } from 'formik';
import { IProductData } from '../DashBoard/Inventory Management/SelectProduct.template';
import { IVendorCatalogueAddServiceForm, useVendorCatalogueService } from '../../../services/useVendorCatalogueService';
import { MODE, VENDOR_CATALOGUE_ROUTES } from '../../../utils/constant';

export interface IVendorCatalogueUsnDetails {
    locationId: number;
    locationName: string;
    remainingStock: number;
    totalStock: number;
    deliveryInformation: string;
    minimumOrderQuantity: number;
    reserveSalesPrice: number;
    priceValidityFrom: string;
    priceValidityTo: string;
}

const useStyles = createUseStyles((theme: any) => ({
    webContainer: {
        display: "grid"
    },
    activatedProgressBar: {
        backgroundColor: theme.palette.background.primary.primary500
    },
    activeProgressBar: {
        backgroundColor: theme.palette.background.primary.primary400
    },
    UnTouchProgressBar: {
        backgroundColor: theme.palette.background.neutral.neutral200
    },
    barHeading: {
        color: theme.palette.text.neutral.neutral700
    },
    textHeading: {
        color: theme.palette.text.primary.primary900
    },
    selectBox: {
        border: `1px solid ${theme.palette.border.neutral.neutral100}`,
    },
    radioColor: {
        color: theme.palette.background.primary.primary400,
        width: '31%'
    },
    PricingText: {
        color: theme.palette.text.primary.primary900
    }
}));
const AddPricingDetailFormTemplate: React.FC<VendorCatalogueSectionProp> = ({ formik, setCurrentSectionTo, mode }) => {
    const classes = useStyles();
    const navigate = useNavigate();
    const { user } = useAuthenticatedUser();
    const [productsData, setProductsData] = useState<IProductData | null>(null);
    const vendorInventoryService = useVendorInventoryService();
    const [warehouseData, setWarehouseData] = useState<{ id: number, name: string }[]>([{ id: 0, name: '' }]);
    const [activeTab, setActiveTab] = useState('1');
    const { showSnackbar, SnackBarComponent } = useSnackbar();
    const [vendorUsnDetails, setVendorUsnDetails] = useState<IVendorCatalogueUsnDetails[]>([]);
    const vendorCatalogueService = useVendorCatalogueService();


    const fetchVendorUsnDetails = async () => {
        if (user?.businessId && formik.values.productId) {
            try {
                const usnDetailsResponse = await vendorCatalogueService.getVendorUsnDetails(user.businessId, formik.values.productId);
                if (usnDetailsResponse.status === HTTP_STATUS.OK) {
                    const details = usnDetailsResponse?.data?.data;
                    setVendorUsnDetails(details);
                }
            } catch (error) {
                showSnackbar('error', 'Vendor USN Details fetched failed');
            }
        }
    }

    const handleCancel = () => {
        navigate(VENDOR_CATALOGUE_ROUTES.MAKE_CATALOGUE);
    };

    const handleOnSaveDraft = async () => {
        const body: IVendorCatalogueAddServiceForm = {
            name: formik.values.name,
            description: formik.values.description,
            catalogueType: formik.values.catalogueType,
            productType: formik.values.productType,
            paymentTerms: formik.values.paymentTerms,
            status: "DRAFT",
            termsAndConditions: formik.values.termsConditions,
            catalogueProductDetails: formik.values.catalogueProductDetails.map((productDetails) => ({
                vendorProductId: Number(formik.values.productId) ?? 0,
                warehouseId: productDetails.warehouseId ?? 0,
                marketRetailPrice: productDetails.maximumRetailPrice ?? 0,
                offerSalePrice: productDetails.offerSalePrice ?? 0,
                paymentType: formik.values.paymentTerms ?? "",
                deliveryTerms: productDetails.deliveryTerms,
                packagingTypeEnum: productDetails.packagingType,
                productChargesDetailsRequestBodyDTOs: [
                    { locationId: productDetails.warehouseId ?? 0, chargeType: "TCS", rate: productDetails.tcs ?? 0, rateType: "PERCENTAGE" },
                    { locationId: productDetails.warehouseId ?? 0, chargeType: "CGST", rate: productDetails.cGst ?? 0, rateType: "PERCENTAGE" },
                    { locationId: productDetails.warehouseId ?? 0, chargeType: "SGST", rate: productDetails.sGst ?? 0, rateType: "PERCENTAGE" },
                    { locationId: productDetails.warehouseId ?? 0, chargeType: "IGST", rate: productDetails.iGst ?? 0, rateType: "PERCENTAGE" },
                    { locationId: productDetails.warehouseId ?? 0, chargeType: "TAX_3", rate: productDetails.tax3 ?? 0, rateType: "PERCENTAGE" },
                ],
                productDiscountDetailsRequestBodyDTOs: productDetails.productDiscountDetails.map((discount) => ({
                    locationId: productDetails.warehouseId ?? 0,
                    minQuantity: discount.minQuantity ?? 0,
                    maxQuantity: discount.maxQuantity ?? 0,
                    discountType: "PERCENTAGE",
                    minDiscount: discount.minDiscount ?? 0,
                    maxDiscount: discount.minDiscount ?? 0,
                })),
                productServicesDetailsRequestBodyDTOs: productDetails.productServicesDetails.map((machine) => ({
                    locationId: productDetails.warehouseId ?? 0,
                    machineId: machine.machineId ?? 0,
                }))
            }))
        };
    
        if (user?.businessId) {
            try {
                const vendorCatalogueCreateResponse = await vendorCatalogueService.createVendorCatalogue(user.businessId, body);
                if (vendorCatalogueCreateResponse.status === HTTP_STATUS.OK) {
                    navigate(VENDOR_CATALOGUE_ROUTES.MAKE_CATALOGUE);
                }
            } catch (error) {
                showSnackbar("error", `Failed to Draft Catalogue. Please try again.`);
            }
        }
    };

    const getVedorProductbyId = async () => {
        if (user?.businessId && formik?.values?.productId) {
            try {
                const productId = formik?.values?.productId;
                const ProductsResponse = await vendorInventoryService.getDetailedProduct(user?.businessId, productId, { vendorProductDetailsType: VENDOR_PRODUCT_DETAILS_TYPE.VENDOR_PRODUCT_USN_DETAILS });
                if (ProductsResponse.status === HTTP_STATUS.OK) {
                    const productData = ProductsResponse?.data?.data;
                    setProductsData({
                        id: productData?.id,
                        productId: productData?.productId,
                        skuCode: productData?.standardProductDetails.upcCode,
                        productName: productData?.productUpcTitle,
                        standardProductDetails: productData?.standardProductDetails,
                        vendorProductStockDetails: productData?.vendorProductStockDetails
                    });
                }
            } catch (error) {
                showSnackbar('error', 'Failed to fetch Product price details, Please try again');
            }
        }
    };
    useEffect(() => {
        getVedorProductbyId();
    }, [user?.businessId]);

    useEffect(() => {
        fetchVendorUsnDetails();
    }, [user?.businessId]);

    const schema: ITabsSchema[] = vendorUsnDetails?.map((details: IVendorCatalogueUsnDetails, index: number) => ({
        label: capitalizeFirstLetter(details.locationName),
        component: <PricingDetailFormTemplate vendorUsnDetails={details} warehouseId={details.locationId} productId={2} formik={formik} index={index} />
    })) ?? [];

    const handleBack = () => {
        setCurrentSectionTo(VENDOR_CATALOGUE_FORM_STATES.PRODUCT);
    }
    
    const onNext = (event: React.FormEvent) => {
        event.preventDefault();
        let fieldError = false;
        formik?.errors?.catalogueProductDetails && (formik.errors.catalogueProductDetails as FormikErrors<IcatalogueProductDetails>[]).forEach((catalogueProductErrors: FormikErrors<IcatalogueProductDetails>, index: number) => {
            if (!catalogueProductErrors) return; 
            const errorFields = ['maximumRetailPrice', 'offerSalePrice', 'cGst', 'sGst', 'iGst', 'tcs', 'tax'];
            for (const key of errorFields) {
                if (key in catalogueProductErrors) {
                    formik.setFieldTouched(`catalogueProductDetails[${index}].${key}`, true);
                    fieldError = true;
                }
            }

            catalogueProductErrors?.productDiscountDetails && (catalogueProductErrors?.productDiscountDetails as FormikErrors<IQuantityDiscount>[]).forEach((productDiscountDetail: FormikErrors<IQuantityDiscount>, localIndex: number) => {
                const requiredFields = ['minQuantity', 'minDiscount', 'maxQuantity'];
                for (const key of requiredFields) {
                    if (key in productDiscountDetail) {
                        formik.setFieldTouched(`catalogueProductDetails[${index}].productDiscountDetails.[${localIndex}].${key}`, true);
                        fieldError = true;
                    }
                }
            })
        });
        formik.setValues((prevValues: IVendorCatalogueAddForm) => ({
            ...prevValues,
        }));
        if (fieldError) return;
        const isPricesFilled = formik.values.catalogueProductDetails.every(
            (priceDetails: IcatalogueProductDetails) => 
                priceDetails.maximumRetailPrice !== null &&
                priceDetails.maximumRetailPrice === 0 &&
                priceDetails.offerSalePrice !== null &&
                priceDetails.offerSalePrice === 0
        );

        if(isPricesFilled){
            showSnackbar('error', "Atleast one warehouse must have both Maximum Retail Price and Offer Sales Price .")
            return;
        }
        setCurrentSectionTo(VENDOR_CATALOGUE_FORM_STATES.CUSTOMIZATION);
    }
    useEffect(() => {
        document.getElementById("main")?.scroll(0, 0); 
    }, []);
    return (
        <div className={`${classes.webContainer} grid gap-y-8 `}>
            <div className={`w-full m-auto flex justify-between gap-x-2 text-var(--black, #4D4D4D) text-center text-sm `}>
                <div className="grid gap-y-3 w-3/5 ">
                    <div className='flex gap-x-2 mx-auto '>
                        <div className={`${classes.activatedProgressBar} w-2 h-2 my-auto rounded-full`}></div>
                        <div className={`${classes.barHeading} my-auto`}>Select Catalog Type </div>
                    </div>
                    <div className={`${classes.activatedProgressBar} h-2.5 rounded `}></div>
                </div>
                <div className="grid gap-y-3 w-3/5 ">
                    <div className='flex gap-x-2 mx-auto'>
                        <div className={`${classes.activatedProgressBar} w-2 h-2 my-auto rounded-full`}></div>
                        <div className={`${classes.barHeading} my-auto`}>Select Product Type </div>
                    </div>
                    <div className={`${classes.activatedProgressBar} h-2.5 rounded `}></div>
                </div>
                <div className="grid gap-y-3 w-1/2">
                    <div className='flex gap-x-2 mx-auto'>
                        <div className={`${classes.activatedProgressBar} w-2 h-2 my-auto rounded-full`}></div>
                        <div className={`${classes.barHeading} my-auto`}>Select Product </div>
                    </div>
                    <div className={`${classes.activatedProgressBar} h-2.5 rounded `}></div>
                </div>
                <div className="grid gap-y-3 w-3/5">
                    <div className='flex gap-x-2 mx-auto'>
                        <div className={`${classes.activeProgressBar} w-3 h-3 my-auto rounded-full`}></div>
                        <div className={`${classes.barHeading} my-auto  font-semibold`}>Enter Pricing Details</div>
                    </div>
                    <div className={`${classes.activeProgressBar} h-2.5 rounded `}></div>
                </div>
                <div className="grid gap-y-3 w-3/5">
                    <div className='flex gap-x-2 mx-auto'>
                        <div className={`${classes.UnTouchProgressBar} w-2 h-2 my-auto rounded-full`}></div>
                        <div className={`${classes.barHeading} my-auto`}>Select Customisation </div>
                    </div>
                    <div className={`${classes.UnTouchProgressBar} h-2.5 rounded `}></div>
                </div>
                <div className="grid gap-y-3 w-1/5">
                    <div className='flex gap-x-2 mx-auto'>
                        <div className={`${classes.UnTouchProgressBar} w-2 h-2 my-auto rounded-full`}></div>
                        <div className={`${classes.barHeading} my-auto`}>Review</div>
                    </div>
                    <div className={`${classes.UnTouchProgressBar} h-2.5 rounded `}></div>
                </div>
            </div>

            <div className=' grid gap-4 '>
                <div className='flex  justify-between'>
                    <div className=' grid gap-1/2'>
                        <text className={`${classes.textHeading} text-base font-medium`}> Add USN Details</text>
                        <text className={`${classes.barHeading} text-xs font-normal`}> Please add USN details for the locations the product is available </text>
                    </div>
                </div>
                <ProductViewTemplate
                    product={productsData}
                    showButton={false}
                    showUsnDetails={true}
                />
                <div className=' grid gap-4 '>
                    <div className={`${classes.selectBox} rounded-xl p-3 grid gap-4`}>
                        <text className={`${classes.PricingText} font-medium text-2xl`}>Pricing Details </text>
                        <div className='w-full relative'>
                            <Tabs schema={schema} value={(activeTab).toString()} setValue={setActiveTab} />
                        </div>
                    </div>
                </div>
            </div>

            <div className=' flex justify-between'>
                <Button
                    variant="tertiaryContained"
                    label="Cancel"
                    onClick={handleCancel}
                />
                <div className='flex gap-6 '>
                    <Button
                        variant="tertiaryContained"
                        label="Back"
                        onClick={handleBack}
                    />
                   {/* {mode === MODE.ADD && (
                        <Button
                            variant="secondaryContained"
                            label="Save as Draft"
                            onClick={handleOnSaveDraft}
                        />
                    )} */}
                     <Button
                        variant="primaryContained"
                        label="Next"
                        onClick={onNext}
                    />
                </div>
            </div>
        </div>
    )
}

export default AddPricingDetailFormTemplate