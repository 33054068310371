import { createUseStyles } from "react-jss";
import { FINISHED_PRODUCTS, FINISHED_PRODUCT_ROUTES } from "../../../../utils/constant";
import Button from "../../../atoms/Button/Button";
import { useNavigate } from "react-router-dom";
import FinishedProductListingTemplate from "./FinishedProductListing.template";
import { useEffect, useState } from "react";
import { HTTP_STATUS, IPagination } from "../../../../utils/types";
import { useSnackbar } from "../../../../hooks/useSnackBar";
import NoDataFrameTemplate from "../NoDataFrame.template";
import { useFinishedProductService } from "../../../../services/useFinishedProductService";
import { UploadedImageData } from "../../CatalogueDetail/SimilarBrands.template";

const useStyles = createUseStyles((theme: any) => ({
    finishedProduct: {
        color: theme.palette.text.primary.primary800,
    }
}))

export interface FinishedProductsProps {
    id: number,
    name: string,
    productDescription: string,
    applicationType: string[],
    images: UploadedImageData[],
    imageListToDelete?: number[]
}

const FinishedProductTemplate = () => {

    const classes = useStyles();
    const navigate = useNavigate();
    const [finishedProducts, setFinishedProducts] = useState<FinishedProductsProps[]>([]);
    const finishedProductService = useFinishedProductService();
    const { showSnackbar, SnackBarComponent } = useSnackbar();
    const [pagination, setPaginationTo] = useState<IPagination>({
        size: 10,
        totalRecords: 0,
        page: 0,
    });
    const handleAddNewProduct = () => {
        navigate(FINISHED_PRODUCT_ROUTES.ADD_FINISHED_PRODUCT);
    }

    const loadFinishedProduct = async () => {
        try {
            const finishedProduct = await finishedProductService.getAllFinishedProducts({ size: pagination.size, page: pagination.page });
            if (finishedProduct.status === HTTP_STATUS.OK) {
                setFinishedProducts(finishedProduct.data.data.content);
                setPaginationTo({
                    ...pagination,
                    totalPages: finishedProduct.data.data.totalPages,
                    totalRecords: finishedProduct.data.data.totalElements
                });
            }
        }
        catch (error) {
            showSnackbar("error", "Error Fetching Data");
        }
    }

    useEffect(() => {
        loadFinishedProduct();
    }, [pagination.page, pagination.size])

    return (
        <div className="grid gap-y-6 mt-8">
            {SnackBarComponent}
            <div className="flex justify-between">
                <span className={`text-lg font-normal my-auto ${classes.finishedProduct}`}>{FINISHED_PRODUCTS.FINISHED_PRODUCTS_LISTING}</span>
                <Button variant="primaryContained" label="+Add New Product" onClick={handleAddNewProduct} />
            </div>
            <div>
                {!!finishedProducts.length ?
                    <FinishedProductListingTemplate finishedProducts={finishedProducts} loadFinishedProduct={loadFinishedProduct} setPaginationTo={setPaginationTo} pagination={pagination} />
                    : <div className="">
                        <NoDataFrameTemplate title="No Result Found" message="You haven't provided Finished Product for your business. " />
                    </div>}
            </div>
        </div>
    )
}

export default FinishedProductTemplate;