import React, { useState } from "react";
import { AUTH_STATES, HTTP_STATUS } from "../../../utils/types";
import { IconButton, InputAdornment } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useAuthenticatedUser } from "../../../hooks/useAuthenticatedUser";
import { useAuthService } from "../../../services/useAuthService";
import { useSnackbar } from "../../../hooks/useSnackBar";
import * as Yup from "yup";
import { useFormik } from "formik";
import { CUSTOMER_ROUTES, REGEX } from "../../../utils/constant";
import { Classes } from 'jss';
import Button from "../../atoms/Button/Button";
import AuthDialogFooter from "../../molecules/AuthDialogFooter/AuthDialogFooter";
import { useNavigate } from "react-router-dom";
import TextFieldV2 from "../../atoms/Input/TextFieldV2";

interface LoginWithEmailTemplateProps {
    setAuthState: (state: AUTH_STATES) => void;
    styles: Classes<any>;
}

const initialValues = {
    email: '',
    password: ''
}

const LoginWithEmailTemplate: React.FC<LoginWithEmailTemplateProps> = ({ setAuthState, styles }) => {
    const { showSnackbar, SnackBarComponent } = useSnackbar();
    const { syncAuthDialogActive, setAuthenticatedUser } = useAuthenticatedUser();
    const authService = useAuthService();
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const navigate = useNavigate();

    const validationSchema = Yup.object({
        email: Yup.string()
            .email("Invalid email address")
            .required("Email is required")
            .matches(REGEX.EMAIL, "Invalid email address"),
        password: Yup.string().required("Password is required"),
    });

    const handleDialogClose = () => {
        syncAuthDialogActive(false);
        setAuthState(AUTH_STATES.LOGIN_WITH_MOBILE)
    };

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (values, { setSubmitting }) => {
            setSubmitting(true);
            authService.login(values)
                .then((res) => {
                    if (res?.status === HTTP_STATUS.OK) {
                        let { user, token, businessId , isVendor} = res?.data?.data ?? {};
                        setAuthenticatedUser({ ...user, isVendor, token, businessId });
                        handleDialogClose();
                        !businessId && navigate(CUSTOMER_ROUTES.ONBOARDING)
                    } else if (res?.status === HTTP_STATUS.BAD_REQUEST) {
                        showSnackbar('error', res?.data?.statusMessage);
                    }
                }).catch(error => {
                    console.error("Login with Email:", error)
                }).finally(() => {
                    setSubmitting(false);
                })
        },
    });

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const setAuthStateTo = (authState: AUTH_STATES) => () => {
        setAuthState(authState);
    }

    return (
        <div className="grid w-full">
            {SnackBarComponent}
            <form className="grid w-full" onSubmit={formik.handleSubmit}>
                <div className="grid gap-y-3">
                    <div className="flex flex-col">
                        <TextFieldV2
                            label="Email Address"
                            type="text"
                            error={
                                formik.touched.email && Boolean(formik.errors.email)
                            }
                            {...formik.getFieldProps("email")}
                            autoComplete='false'
                        />
                        {formik.touched.email && formik.errors.email && (
                            <div className={` ${styles.errorMessage}`}>
                                <small>{formik.errors.email}</small>
                            </div>
                        )}
                    </div>
                    <div className="flex flex-col">
                        <TextFieldV2
                            label="Password"
                            type={showPassword ? "text" : "password"}
                            autoComplete='false'
                            error={
                                formik.touched.password && Boolean(formik.errors.password)
                            }
                            {...formik.getFieldProps("password")}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        edge="end"
                                    >
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>,
                            }}
                        />
                        {formik.touched.password && formik.errors.password && (
                            <div className={` ${styles.errorMessage}`}>
                                <small>{formik.errors.password}</small>
                            </div>
                        )}
                    </div>
                </div>

                <div className="grid gap-y-6">
                    <div className='flex justify-end items-center'>
                        <Button className="!pr-0" label="Forgot Password?" variant="secondaryText" size="small" onClick={setAuthStateTo(AUTH_STATES.FORGOT_PASSWORD)} />
                    </div>
                    <Button variant="primaryContained" label="Login" type="submit" isLoading={formik.isSubmitting} />
                </div>
            </form>
            <AuthDialogFooter setAuthStateTo={setAuthStateTo} section={AUTH_STATES.USER_SIGNUP} loginType={AUTH_STATES.LOGIN_WITH_MOBILE} isLogin={true} />
        </div>
    );
}

export default LoginWithEmailTemplate; 