import React, { useEffect, useRef } from "react";
import brandLogo1 from '../../../../assets/images/brandLogo1.svg';
import brandLogo2 from '../../../../assets/images/brandLogo2.svg';
import brandLogo3 from '../../../../assets/images/brandLogo3.svg';
import brandLogo4 from '../../../../assets/images/brandLogo4.svg';
import brandLogo5 from '../../../../assets/images/brandLogo5.svg';
import brandLogo6 from '../../../../assets/images/brandLogo6.svg';
import { createUseStyles } from "react-jss";
import Carousel from "react-multi-carousel";
import CarouselRef from 'react-multi-carousel';

const useStyles = createUseStyles((theme: any) => ({
    mainContainer:{
        backgroundColor: "#FAFAFA",
        borderTop:`1px dashed #AEB0B6`,
        borderBottom:`1px dashed #AEB0B6`,
        padding: "40px 0px"
    },
    container: {
        margin: "0px auto",
        width: '1300px',
    },
    heading: {
        fontSize: "32px",
        color: theme.palette.text.neutral.neutral900,
        lineHeight: '38px'
    },
    carouselContainer: {
        "& .react-multiple-carousel__arrow": {
            display: "none",
        },
        "& .react-multi-carousel-list": {
            width: "1300px",
        },
        "& .react-multi-carousel-list ul": {
            display: "flex",
            alignItems: "center",
            columnGap: "100px"
        },
        "& .react-multi-carousel-list li": {
            width: "fit-content !important",
        },
    },
    "@media (max-width: 480px)": {

    },
}));

const BrandSlider: React.FC = () => {
    const classes = useStyles();
    const carouselRef = useRef<CarouselRef>(null);

    useEffect(() => {
        if (carouselRef.current) {
            carouselRef.current.goToSlide(0, true);
        }
    }, []);

    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1280 },
            items: 6,
            partialVisibilityGutter: 15
        },
        tablet: {
            breakpoint: { max: 1280, min: 980 },
            items: 3,
            partialVisibilityGutter: 10
        },
        mobile: {
            breakpoint: { max: 480, min: 320 },
            items: 2,
            partialVisibilityGutter: 0
        },
        "sm-mobile": {
            breakpoint: { max: 464, min: 0 },
            items: 2,
            partialVisibilityGutter: 10
        }
    };

    return (
        <div className={`${classes.mainContainer}`}>
            <div className={` grid gap-y-8 ${classes.container}`}>
            <div className={`font-bold text-center ${classes.heading}`}>Brand/Suppliers</div>
            <div className={`${classes.carouselContainer} rounded-3xl overflow-hidden`}>
                <Carousel
                    className=""
                    responsive={responsive}
                    showDots={false}
                    infinite={true}
                    partialVisible={true}
                    ref={carouselRef}
                    autoPlay={true}
                    autoPlaySpeed={2000}
                    removeArrowOnDeviceType={["sm-mobile"]} 
                >
                    <div className=""><img src={brandLogo1} alt="" /></div>
                    <div className="w-max"><img src={brandLogo1} alt="" /></div>
                    <div className="w-max"><img src={brandLogo2} alt="" /></div>
                    <div className="w-max"><img src={brandLogo3} alt="" /></div>
                    <div className="w-max"><img src={brandLogo4} alt="" /></div>
                    <div className="w-max"><img src={brandLogo5} alt="" /></div>
                    <div className="w-max"><img src={brandLogo6} alt="" /></div>
                    <div className="w-max"><img src={brandLogo1} alt="" /></div>
                    <div className="w-max"><img src={brandLogo2} alt="" /></div>
                    <div className="w-max"><img src={brandLogo3} alt="" /></div>
                </Carousel>
            </div>
        </div>
        </div>
    );
};

export default BrandSlider;

