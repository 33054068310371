import React, { useContext, useState } from "react";
import { createUseStyles } from "react-jss";
import { useNavigate } from "react-router-dom";
import { CART_LIFE_CYCLE, CLASS_TYPES, CUSTOMER_ROUTES } from "../../../utils/constant";
import noImage from "../../../assets/images/noImage.jpg"
import { formatUOM, makeRoute, sortAttributes } from "../../../utils/helper";
import { CATALOGUE_TYPE_STATES, CLASS_STATES, HTTP_STATUS } from "../../../utils/types";
import useMetaDataService from "../../../hooks/useMetaDataService";
import { useAuthenticatedUser } from "../../../hooks/useAuthenticatedUser";
import { WishlistContext } from "../../../contexts/WishlistContext";
import { useWishlistService } from "../../../services/useWishlistService";
import rightArrow from "../../../assets/icons/rightArrow.svg";

interface GridItemProps {
    data: any;
    isImageVisible?: boolean;
    styles?: string;
    label?: string
}
export interface IStyle {
    [key: string]: string
}
const useStyles = createUseStyles((theme: any) => ({
    container: {
        transition: "box-shadow 0.3s ease-in-out",
        "&:hover": {
            boxShadow: "0px 5px 16px 0px rgba(8, 15, 52, 0.08)"
        },
        borderRadius: "5px",
    },
    imageContainer: {
        height: "200px",
    },
    image: {
        borderTopLeftRadius: "5px",
        borderTopRightRadius: "5px"
    },
    iconContainer: {
        top: "12px",
        right: "12px",
        backgroundColor: theme.palette.background.secondary.secondary50,
        borderRadius: "4px",
        color: theme.palette.text.secondary.secondary700,
        lineHeight: "14px",
        border: `1px solid ${theme.palette.border.secondary.secondary100}`,
    },
    catalogueName: {
        color: theme.palette.text.primary.primary900,
        height: "32px",
        lineHeight: "16px",
    },
    attributeTitle: {
        color: theme.palette.text.neutral.neutral600,
        lineHeight: "14px",
    },
    price: {
        color: theme.palette.text.neutral.neutral700,
        lineHeight: "13.8px",
        letterSpacing: "0.12px"
    },
    priceValue: {
        color: theme.palette.text.primary.primary900,
        lineHeight: "16px",
    },
    mt: {
        color: theme.palette.text.neutral.neutral600,
        lineHeight: "14px",
    },
    moq: {
        color: theme.palette.text.neutral.neutral600,
        lineHeight: "14px",
    },
    borderTop: {
        borderTop: `1px solid ${theme.palette.border.neutral.neutral200}`,
    },
    shadow: {
        background: "linear-gradient(0deg, rgba(10, 18, 41, 0.00) 0%, rgba(10, 18, 41, 0.40) 100%)",
    },
    customArrow: {
        borderRadius: "50%",
        width: "24px",
        height: "24px",
        cursor: "pointer",
        border: `1px solid ${theme.palette.border.neutral.neutral200}`,
    },
}));

const CatalogueGridItemV2: React.FC<GridItemProps> = ({ data, isImageVisible = true, styles, label }) => {
    const classes = useStyles();
    const navigate = useNavigate();
    const { metaData: filterData } = useMetaDataService();

    const onViewCatalogue = (event: any) => {
        if (event.target.closest("#child")) return;
        if (data.classType === CLASS_STATES.STANDARD || data.classType === CLASS_STATES.NONSTANDARD) {
            if (data.catalogueType === CATALOGUE_TYPE_STATES.CURRENT_INVENTORY) {
                navigate(makeRoute(CUSTOMER_ROUTES.CATALOGUE_DESCRIPTION, { query: { "catalogueId": data.id } }))
            }
            else if (data.catalogueType === CATALOGUE_TYPE_STATES.MAKE_TO_ORDER) {
                navigate(makeRoute(CUSTOMER_ROUTES.CATALOGUE_MTO_VIEW, { query: { "catalogueId": data.id } }));
            }
        }
        else if (data.classType === CLASS_STATES.SECONDARY) {
            if (data.catalogueType === CATALOGUE_TYPE_STATES.MAKE_TO_ORDER) {
                navigate(makeRoute(CUSTOMER_ROUTES.SECONDARY_CATALOGUE_LOT_VIEW, { query: { "secondaryCatalogueId": data.id } }));
            }
            else {
                navigate(makeRoute(CUSTOMER_ROUTES.SECONDARY_CATALOGUE_DESCRIPTION, { query: { "secondaryCatalogueId": data.id } }));
            }
        }
    }

    const formatPrice = (minPrice: number, maxPrice: number, inStock: boolean): string => {
        if (!inStock) {
            return "-";
        }
        if (minPrice === maxPrice) {
            return `₹${minPrice.toLocaleString()}`;
        }
        return `₹${minPrice.toLocaleString()}-₹${maxPrice.toLocaleString()}`;
    };
    
    const sortedAttributes = sortAttributes(data.attributes, filterData.attributes);
    const { user } = useAuthenticatedUser();
    const wishlistContextData = useContext(WishlistContext);
    const wishlistService = useWishlistService();
    const handleFavoriteClick = () => (event: any) => {
        if (event.target.closest("#child")) {
            updateWishlist();
        }
    };

    const updateWishlist = async () => {
        try {
            const payload: any = {
                userId: user?.id,
                favourites: {
                    catalogueIds: [...wishlistContextData.favourites.catalogueIds],
                    secondaryCatalogueIds: [...wishlistContextData.favourites.secondaryCatalogueIds]
                }
            };
            if (data.classType === CLASS_STATES.STANDARD || data.classType === CLASS_STATES.NONSTANDARD) {
                payload.favourites.catalogueIds = payload.favourites.catalogueIds.filter((cId: number) => cId !== data.id);
            } else if (data.classType === CLASS_STATES.SECONDARY) {
                payload.favourites.secondaryCatalogueIds = payload.favourites.secondaryCatalogueIds.filter((cId: number) => cId !== data.id);
            }
            const wishlistResponse = await wishlistService.updateWishlist(payload);
            if (wishlistResponse?.status === HTTP_STATUS.OK) {
                await wishlistContextData.syncWishlist(CART_LIFE_CYCLE.REMOVE_PRODUCT);
                return true;
            } else {
                throw new Error("Wishlist Details fetch failed");
            }
        } catch (error) {
            return false;
        }
    };

    const [hovered, setHovered] = useState<boolean>(false)

    return (
        <div className={`grid ${classes.container} ${styles} relative cursor-pointer`} onClick={onViewCatalogue} onMouseEnter={() => setHovered(true)} onMouseLeave={() => setHovered(false)}>
            {isImageVisible && (
                <div className={`${classes.imageContainer} relative mt-0 my-auto z-0 `}>
                    <img className={`w-full h-full ${classes.image} ${label === "wishlist" && hovered ? classes.shadow : ''}`} src={data.catalogueImagePath[0] || noImage} alt="CatalogueImage" />
                    {label === "wishlist" && hovered &&
                        <div className={`${classes.iconContainer} flex px-2 py-1 absolute z-20 cursor-pointer text-xs font-medium`} id="child" onClick={handleFavoriteClick()} >Remove</div>
                    }
                </div>
            )}
            <div className={`grid`}>
                <div className="grid gap-y-1 p-2">
                    <div className={`text-sm font-medium text-wrap truncate ${classes.catalogueName}`}>{data.name.length > 55 ? data.name.substring(0, 55) + "..." : data.name}</div>
                    <div className="flex">
                        {sortedAttributes.splice(0, 1).map(({ key, value }) => {
                            let [min, max, uom] = value as string[];
                            min = min.replace(/\.0*$/, '');
                            max = max.replace(/\.0*$/, '');
                            const isRangeZero = min === max || max === "";
                            return (
                                <div className={`${classes.attributeTitle} text-xs font-normal`}>{key}: {formatUOM(min, uom, isRangeZero ? (min) : `${min}-${max}`)}</div>
                            );
                        })}
                    </div>
                </div>

                <div className={`${classes.borderTop} flex justify-between  p-2`}>
                    <div className={` grid gap-x-1`}>
                        <div className="flex gap-x-1">
                        <div className={`${classes.priceValue} text-sm font-medium`}>
                                {formatPrice(data.minimumPrice, data.maximumPrice, data.stockAvaliable)}
                        </div>
                            <div className={`${classes.mt} text-xs font-light flex justify-end items-end`}>({data.uom})</div>
                        </div>
                        <div className={`${classes.moq} text-xs font-normal`}>MOQ: {data.stockAvaliable ? data.moq : "0"} {data.uom}</div>
                    </div>

                    <div className={`${classes.customArrow} bg-white flex justify-center items-center my-auto`}>
                        <img className="w-1.5 h-2.5" src={rightArrow} alt="" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CatalogueGridItemV2;