import React, { useState } from 'react';
import { IContentComponent } from './ContentPreviewer';
import { createUseStyles } from 'react-jss';

interface SidePanelItemProps {
    policy: IContentComponent;
    active: boolean;
    onClick: () => void;
    isClickable: boolean
}

const useStyles = createUseStyles((theme: any) => ({
    container: {
        display: 'flex',
        gap: '8px',
    },
    headingAnchor: {
        color: "#5B5B5B",
        cursor: 'pointer',
        transition: 'background-color 0.2s ease',
        '&:hover': {
            backgroundColor: '#EFF3FF',
            color: "#1D3679",
        },
    },
    categoryAnchor: {
        borderRadius: '0 8px 8px 0px',
        columnGap: '0',
        '&:hover': {
            columnGap: "8px",   
        },
        transition: 'column-gap 0.5s ease'
    },
    categoryAnchorBorder: {
        backgroundColor: `transparent`,
        transition: 'border-left-color 0.2s ease',
        borderRadius: '8px 8px',
    },
    active: {
        backgroundColor: '#EFF3FF',
    },
    activeBorder: {
        backgroundColor: '#1D3679',
    },
    hovered: {
        '& $categoryAnchorBorder': {
            backgroundColor: '#1D3679',
        },
    }, 
    heading: {
        color: '#4D4D4D',
    },
}));

const ContentPreviewerHeading: React.FC<SidePanelItemProps> = ({ policy, active, onClick, isClickable }) => {
    const classes = useStyles();
    const [hovered, setHovered] = useState(false);

    const handleMouseEnter = () => {
        setHovered(true);
    };

    const handleMouseLeave = () => {
        setHovered(false);
    };

    return (
        <div className={`${classes.container} ${active ? '' : ''} rounded`}>
            <div
                className={`${isClickable && classes.headingAnchor} ${ isClickable && hovered ? classes.hovered : '' } ${active ? "": "scale-y-105"} flex gap-x-2 w-full rounded-lg`}
                onClick={isClickable ? onClick : () => {}}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
            >
                {isClickable ? (
                    <div className={`  ${active ? `${classes.headingAnchor} ${classes.active} rounded-lg gap-x-2` : `${classes.categoryAnchor}`} py-2.5 px-2.5 flex w-full`}>
                        <div className={`w-1.5 ${classes.categoryAnchorBorder}  ${active ? ` ${classes.activeBorder} ` : ''} rounded`}></div>
                        <div className={`text-sm p-0.5 `}> {policy.Key}</div>
                    </div>) : (<div className={`text-base font-semibold ${classes.heading}`}>{policy.Key}</div>)
                }
                
            </div>
        </div>
    );
};

export default ContentPreviewerHeading;
