import React, { useRef } from 'react';
import { createUseStyles } from 'react-jss';
import Carousel from "react-multi-carousel";
import CarouselRef from "react-multi-carousel";
import TransparentProcess from '../../../assets/images/transparentProcess.svg';
import OriginalProduct from '../../../assets/images/originalProduct.svg';
import BestPrices from '../../../assets/images/bestPrices.svg';
import SecurePayments from '../../../assets/images/securePayments.svg';

const useStyles = createUseStyles((theme: any) => ({
    carouselContainer: {
        "& .react-multiple-carousel__arrow--right": {
            right: "0 !important"
        },
        "& .react-multiple-carousel__arrow--left": {
            left: "0 !important"
        },
        "& .react-multi-carousel-list": {
            borderRadius: "8px",
        },
        maxWidth: "344px",
    },
}));

const carouselItems = [
    {
        title: 'Secure Payments',
        image: SecurePayments
    },
    {
        title: 'Transparent Process',
        image: TransparentProcess
    },
    {
        title: '100% Original Products',
        image: OriginalProduct
    },
    {
        title: 'Best Pricing in the Market',
        image: BestPrices
    },
];

const TermsAndConditionsCarousel: React.FC = () => {
    const classes = useStyles()
    const carouselRef = useRef<CarouselRef>(null);
    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1280 },
            items: 1,
            partialVisibilityGutter: 10,
        },
        tablet: {
            breakpoint: { max: 1280, min: 980 },
            items: 1,
            partialVisibilityGutter: 10,
        },
        mobile: {
            breakpoint: { max: 980, min: 670 },
            items: 1,
            partialVisibilityGutter: 10,
        },
        "sm-mobile": {
            breakpoint: { max: 670, min: 0 },
            items: 1,
        },
    };

    return (
        <div className={`${classes.carouselContainer} rounded-lg`}>
            <Carousel
                responsive={responsive}
                infinite={true}
                partialVisible={false}
                ref={carouselRef}
                removeArrowOnDeviceType={["desktop", "tablet"]}
                autoPlay={true}
                autoPlaySpeed={2000}
                slidesToSlide={1}
            >
                {carouselItems.map((item) => (
                    <div style={{ height: "131px", backgroundImage: `url(${item.image})` }}>
                    </div>
                ))}
            </Carousel>
        </div>
    );
};

export default TermsAndConditionsCarousel;