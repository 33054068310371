import React from "react";
import { createUseStyles } from "react-jss";
import { formatUOM } from "../../../utils/helper";
import { ATTRIBUTE_LABEL, COLOR_DATA } from "../../../utils/constant";
import { IColorPalette } from "../../template/CatalogueDetail/AttributeSelection.template";

const useStyles = createUseStyles((theme: any) => ({
    attributeName: {
        color: theme.palette.text.neutral.neutral900,
        lineHeight: "16px"
    },
    attributeNames: {
        color: theme.palette.text.neutral.neutral900,
    },
    uom: {
        color: theme.palette.text.neutral.neutral600,
    },
    attributes: {
        color: theme.palette.text.primary.primary800,
    },
    attributeValue: {
        lineHeight: "36px",
        color: theme.palette.text.primary.primary800,
        border: `1px solid ${theme.palette.border.secondary.secondary200}`,
    },
    attribute: {
        // '&:nth-child(1), &:nth-child(2), &:nth-child(3)': {
        //     marginTop: '-4px',
        // },
        // '&:nth-child(n+4)': {
        //     borderTop: `1px solid ${theme.palette.border.neutral.neutral100}`,
        //     marginBottom: "-12px",
        // },
        minWidth: "33%",
        margin: 0,
        '&:nth-child(n+4)': {
            borderTop: `1px solid ${theme.palette.border.neutral.neutral100}`,
        }
    },
    "@media (max-width: 476px)": {
        attributes: {
            fontSize: "14px"
        },
        attributeName: {
            fontSize: "12px"
        },
        uom: {
            fontSize: "10px"
        },
    }
}));

interface AttributeViewProps {
    label: string;
    specification: { minValue: string; maxValue: string, uom: string };
}

const AttributeView: React.FC<AttributeViewProps> = ({ specification, label }) => {
    const classes = useStyles();
    const getAttributeValue = (attributeName: string, attributeValue: string) => {
        try {
            if(attributeName.trim().toLowerCase() !== ATTRIBUTE_LABEL.COLOR.toLowerCase()) {
                return attributeValue;
            }
              const colorPalette = COLOR_DATA[attributeValue as keyof IColorPalette];
            return (
                <div className="flex items-center">
                    <div className="w-6 h-4 mr-2 rounded-sm" style={{ backgroundColor: colorPalette.bgColor }}></div>
                    <span>{attributeValue}</span>
                </div>
            )
        } catch (error) {
            return attributeValue;
        }
	}
    return (
        <>
            {specification && label.trim().toLowerCase()!== "hardness" && (
                <div className={`flex flex-col py-3 m-auto flex-1 ${classes.attribute}`} key={label}>
                        <div className={`${classes.attributeNames} pb-2.5 justify-center flex text-sm`}>
                            <div className={`text-center font-medium mr-1 ${classes.attributeName}`}>{label.trim()}</div>
                            {specification?.uom && (
                                <div className={`text-center text-xs font-medium ${classes.uom}`}>{`(${formatUOM("", specification.uom).trim()})`}</div>
                            )}
                        </div>
                        <div className={`${classes.attributes} font-medium m-auto`}>
                            {specification.minValue === specification.maxValue ? (
                                <div className={`${classes.attributeValue} flex justify-center items-center !px-3 !py-0 rounded-lg text-base`}>{getAttributeValue(label, specification.minValue)}</div>
                            ) : (
                                <div className="flex">
                                    <div className={`${classes.attributeValue} flex justify-center items-center !px-3 !py-0 rounded-lg text-base`}>{getAttributeValue(label, specification.minValue)}</div>
                                    <div className="flex items-center mx-1">-</div>
                                    <div className={`${classes.attributeValue} flex justify-center items-center !px-3 !py-0 rounded-lg text-base`}>{getAttributeValue(label, specification.maxValue)}</div>
                                </div>
                            )}
                        </div>
                </div>
            )}
        </>
    );
};

export default AttributeView;