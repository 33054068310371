import React from 'react';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles((theme: any) => ({
    container: {
        background: "white",
        position: 'absolute',
        width: '100%',
        top: '146px',
        left: '0',
        zIndex: '99',
    },
    primaryContainer: {
        width: '1297px',
        margin: '0 auto',
    },
    dropdownContent: {
        "& h3": {
            color: theme.palette.text.primary.primary600,
            fontSize: '28px',
        },
        "& span": {
            color: theme.palette.text.neutral.neutral600,
        },
    },
    aboutpreview: {
        width: '234px',
        height: '251px',
        background: theme.palette.background.primary.primary100,
    },
}));

const dropdownItems = [
    { title: "Trade" },
    { title: "eAuction" },
    { title: "eListing" },
    { title: "Procurement" },
    { title: "Finance" }
];

const ServiceDropDown: React.FC = () => {
    const classes = useStyles();

    return (
        // <div className={`${classes.container} py-10`}>
        //     <div className={`${classes.primaryContainer} flex gap-8`}>
        //         {dropdownItems.map((item, index) => (
        //             <div key={index} className={`${classes.dropdownContent}`}>
        //                 <h3 className='font-bold'>{item.title}</h3>
        //                 <div className={`${classes.aboutpreview} mt-8`}></div>
        //             </div>
        //         ))}
        //     </div>
        // </div>
        <div></div>
    );
};

export default ServiceDropDown;
