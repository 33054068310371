import React from 'react'
import { createUseStyles } from 'react-jss';
import { MODE } from '../../../../../utils/constant';
import TextFieldV2 from '../../../../atoms/Input/TextFieldV2';
import { titleModification } from '../../../../../utils/helper';
import InputAdornment from "@mui/material/InputAdornment";

interface CapacityDetailsFormTemplateProps {
    mode: string,
    formik: any;
}

const useStyles = createUseStyles((theme: any) => ({
    border: {
        border: `1px solid ${theme.palette.border.neutral.neutral100}`
    },
    heading: {
        color: theme.palette.text.primary.primary900,
    },
    errorMessage: {
        color: theme.palette.text.secondary.secondary500,
    },
    textFieldText:{
        color:theme.palette.text.neutral.neutral400,
    }
}));

const CapacityDetailsFormTemplate: React.FC<CapacityDetailsFormTemplateProps> = ({ mode, formik }) => {
    const classes = useStyles();

    return (
        <div className={`${classes.border} grid gap-y-4 p-4 rounded-xl`}>
        <div className={`${classes.heading} text-base font-medium`}>Capacity Details</div>
        <div className='grid grid-cols-4 gap-x-4'>
            <div className="grid gap-y-1">
                <TextFieldV2
                    label="Material Type"
                    placeholder='Select Material Type'
                    {...formik.getFieldProps("materialHandle")}
                    error={formik.touched.materialHandle && Boolean(formik.errors.materialHandle)}
                    inputProps={{ readOnly: mode === MODE.VIEW, maxLength: 30 }}
                    onBlur={(event) => {
                        const newValue = titleModification(event.target.value);
                        formik.setFieldValue('materialHandle', newValue);
                    }}
                />
                {formik.errors.materialHandle && formik.touched.materialHandle && (
                    <div className={`${classes.errorMessage} text-xs`}>{formik.errors.materialHandle}</div>
                )}
            </div>

            <div className="grid gap-y-1">
                <TextFieldV2
                    label="Quality Standard"
                    placeholder='Select Quality Standard'
                    {...formik.getFieldProps("qualityStandard")}
                    error={formik.touched.qualityStandard && Boolean(formik.errors.qualityStandard)}
                    inputProps={{ readOnly: mode === MODE.VIEW, maxLength: 30 }}
                    onBlur={(event) => {
                        const newValue = titleModification(event.target.value);
                        formik.setFieldValue('qualityStandard', newValue);
                    }}
                />
                {formik.errors.qualityStandard && formik.touched.qualityStandard && (
                    <div className={`${classes.errorMessage} text-xs`}>{formik.errors.qualityStandard}</div>
                )}
            </div>

            <div className="grid gap-y-1">
                <TextFieldV2
                    label="Material Grade"
                    placeholder='Select Material Grade'
                    {...formik.getFieldProps("materialGrade")}
                    error={formik.touched.materialGrade && Boolean(formik.errors.materialGrade)}
                    inputProps={{ readOnly: mode === MODE.VIEW, maxLength: 30 }}
                    onBlur={(event) => {
                        const newValue = titleModification(event.target.value);
                        formik.setFieldValue('materialGrade', newValue);
                    }}
                />
                {formik.errors.materialGrade && formik.touched.materialGrade && (
                    <div className={`${classes.errorMessage} text-xs`}>{formik.errors.materialGrade}</div>
                )}
            </div>
            <div className="grid gap-y-1">
                <TextFieldV2
                    label="Serviceability"
                    placeholder='Select Serviceability'
                    {...formik.getFieldProps("serviceability")}
                    error={formik.touched.serviceability && Boolean(formik.errors.serviceability)}
                    inputProps={{ readOnly: mode === MODE.VIEW, maxLength: 30 }}
                    onBlur={(event) => {
                        const newValue = titleModification(event.target.value);
                        formik.setFieldValue('serviceability', newValue);
                    }}
                />
                {formik.errors.serviceability && formik.touched.serviceability && (
                    <div className={`${classes.errorMessage} text-xs`}>{formik.errors.serviceability}</div>
                )}
            </div>
        </div>


        <div className={`${classes.border} grid gap-y-3 p-6 rounded-xl`}>
            <div className={`${classes.heading} text-base font-medium`}>Order Quantity</div>
            <div className='grid grid-cols-2 gap-x-4'>
                <div className="grid gap-y-1">
                    <TextFieldV2
                        label="Minimum Order Quantity"
                        placeholder='Enter Minimum Order Quantity'
                        {...formik.getFieldProps("minOrderQuantity")}
                        error={formik.touched.description && Boolean(formik.errors.minOrderQuantity)}
                        inputProps={{ readOnly: mode === MODE.VIEW }}
                        type='number'
                        InputProps={{ 
                            endAdornment: (
                            <InputAdornment position="end">
                                <div className={`${classes.textFieldText} font-light`}>MT</div>
                            </InputAdornment>
                        )}}
                    />
                    {formik.errors.minOrderQuantity && formik.touched.minOrderQuantity && (
                        <div className={`${classes.errorMessage} text-xs`}>{formik.errors.minOrderQuantity}</div>
                    )}
                </div>
                <div className="grid gap-y-1">
                    <TextFieldV2
                        label="Maximum Order Quantity"
                        placeholder='Enter Maximum Order Quantity'
                        {...formik.getFieldProps("maxOrderQuantity")}
                        error={formik.touched.description && Boolean(formik.errors.maxOrderQuantity)}
                        inputProps={{ readOnly: mode === MODE.VIEW }}
                        type='number'
                        InputProps={{ 
                            endAdornment: (
                            <InputAdornment position="end">
                                <div className={`${classes.textFieldText} font-light`}>MT</div>
                            </InputAdornment>
                        )}}
                    />
                    {formik.errors.maxOrderQuantity && formik.touched.maxOrderQuantity && (
                        <div className={`${classes.errorMessage} text-xs`}>{formik.errors.maxOrderQuantity}</div>
                    )}
                </div>
            </div>
        </div>
    </div>
    )
}

export default CapacityDetailsFormTemplate;