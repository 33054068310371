import React, { useEffect } from 'react'
import { createUseStyles } from 'react-jss';
import { FormikProps } from 'formik';
import { useNavigate, useParams } from 'react-router-dom';
import { MachinePayload } from '../../../../../services/useVASMasterCategoryService';
import { capitalizeFirstLetter, makeRoute } from '../../../../../utils/helper';
import { SERVICE_STATUS_OPTIONS, VALUE_ADDED_SERVICE_ROUTES } from '../../../../../utils/constant';
import Button from '../../../../atoms/Button/Button';
import { Attribute, SubAttribute } from '../../../../../services/useAttributeServiceV2';
import StatusV2 from '../../../../atoms/Status/StatusV2';
import { MACHINE_FORM_STATE } from '../../../../pages/DashBoard/ValueAddedService/Machine/MachineCreate.page';
import { UploadedImageData } from '../../../../pages/DashBoard/FinishedProductCreation.page';

interface MachineReviewTemplateProps {
    mode: string,
    formik: FormikProps<MachinePayload>,
    onBack: () => void,
    setFormStateTo: (state: MACHINE_FORM_STATE) => () => void;
}

const useStyles = createUseStyles((theme: any) => ({
    mainHeading: {
        color: theme.palette.text.primary.primary900,
    },
    activeProgressBar: {
        backgroundColor: theme.palette.background.primary.primary500,
    },
    barHeading: {
        color: theme.palette.text.primary.primary500,
    },
    border: {
        border: `1px solid ${theme.palette.border.neutral.neutral100}`
    },
    heading: {
        color: theme.palette.text.primary.primary900,
    },
    errorMessage: {
        color: theme.palette.text.secondary.secondary500,
    },
    property: {
        color: theme.palette.text.neutral.neutral600,
    },
    image: {
        width: "141px",
        height: "159px"
    },
    value: {
        color: theme.palette.text.primary.primary900
    },
    number: {
        backgroundColor: theme.palette.background.primary.primary500,
        color: theme.palette.text.primary.primary50,
        width: "54px",
        height: "54px"
    },
}));

const MachineReviewTemplate: React.FC<MachineReviewTemplateProps> = ({ mode, onBack, formik, setFormStateTo }) => {
    const classes = useStyles();
    const navigate = useNavigate();

    const params = useParams();

    const handleSubmit = () => {
        formik.handleSubmit()
    };

    useEffect(() => {
        document.getElementById('main')?.scroll(0,0)
    },[])

    return (
        <>
            <div className={`${classes.mainHeading} text-lg font-semibold`}>Add Machine</div>

            <div className={`w-full m-auto flex justify-between gap-x-2 text-center text-sm mb-4`}>
                <div className="grid gap-y-3 w-1/2">
                    <div className='flex gap-x-2 mx-auto'>
                        <div className={`${classes.activeProgressBar} w-3 h-3 my-auto rounded-full`}></div>
                        <div className={`${classes.barHeading} my-auto text-sm font-semibold`}>Machine Details</div>
                    </div>
                    <div className={`${classes.activeProgressBar} h-2.5 rounded `}></div>
                </div>
                <div className="grid gap-y-3 w-1/2">
                    <div className='flex gap-x-2 mx-auto'>
                        <div className={`${classes.activeProgressBar} w-2 h-2 my-auto rounded-full`}></div>
                        <div className={`${classes.barHeading} text-sm my-auto`}>Review</div>
                    </div>
                    <div className={`${classes.activeProgressBar} h-2.5 rounded `}></div>
                </div>
            </div>
            <form className="grid gap-y-6" >
                <div className=''>
                    <div className={`${classes.mainHeading} text-lg font-medium my-auto`}>Review</div>
                    <div className={`${classes.property}`}>Please review the data you have entered</div>

                </div>

                <div className='grid gap-y-4'>
                    <div className={`${classes.border} grid gap-y-4 p-4 rounded-xl`}>
                        <div className={`${classes.heading} text-base font-medium`}>Machine Overview</div>
                        <div className='flex flex-wrap gap-x-6 gap-y-4'>
                            <div className="grid gap-y-1">
                                <div className={`${classes.property}`}>Machine Name</div>
                                <div className={`${classes.heading} text-base font-medium`}>{formik.values?.machineName}</div>
                            </div>
                            <div className="grid gap-y-1">
                                <div className={`${classes.property}`}>Brand/Manufacturer of Machine</div>
                                <div className={`${classes.heading} text-base font-medium`}>{formik.values?.machineVendor}</div>
                            </div>
                            <div className="grid gap-y-1">
                                <div className={`${classes.property}`}>Model Number</div>
                                <div className={`${classes.heading} text-base font-medium`}>{formik.values?.modelNumber}</div>
                            </div>
                            <div className="grid gap-y-1">
                                <div className={`${classes.property}`}>Warehouse Location</div>
                                <div className={`${classes.heading} text-base font-medium`}>{formik.values?.warehouse?.label}</div>
                            </div>
                            <div className="grid gap-y-1">
                                <div className={`${classes.property}`}>Machine Type</div>
                                <div className={`${classes.heading} text-base font-medium`}>{formik.values?.machineType}</div>
                            </div>
                            <div className="grid gap-y-1">
                                <div className={`${classes.property}`}>Year of Manufacture</div>
                                <div className={`${classes.heading} text-base font-medium`}>{formik.values?.manufactureYear}</div>
                            </div>
                            <div className="grid gap-y-1">
                                <div className={`${classes.property}`}>Operational Speed</div>
                                <div className={`${classes.heading} text-base font-medium`}>{formik.values?.operationalSpeed}</div>
                            </div>
                        </div>
                    </div>

                    <div className={`${classes.border} grid gap-y-4 p-4 rounded-xl`}>
                        <div className={`${classes.heading} text-base font-medium`}>Attributes Linked with Selected USC</div>
                        {
                            formik.values?.attributes &&
                            <div className={`${classes.border} grid gap-y-4 p-4 rounded-xl`} >
                                {
                                    formik.values?.attributes?.map((attribute: Attribute, key: any) => (
                                        <div className={`grid gap-y-4 p-4 rounded-xl ${classes.border}`} key={attribute.id}>
                                            <div className={`${classes.heading} text-base font-medium`}>{attribute.name}</div>
                                            <div className='grid grid-cols-2 gap-y-4'>
                                                {
                                                    attribute.subAttributes.map((subAttribute: SubAttribute, index: any) => (
                                                        <div className='flex gap-x-6'>
                                                            <div className={`${classes.number} rounded-md text-lg font-semibold my-auto flex justify-center items-center`}>{index + 1}</div>
                                                            <div className={`grid gap-y-1`}>
                                                                <div className={`${classes.property}`}>Thickness Range</div>
                                                                <div className={`${classes.heading} text-base font-medium`}>{subAttribute.minValue}-{subAttribute.maxValue} {attribute?.uom}</div>
                                                            </div>
                                                            <div className={`grid gap-y-1`}>
                                                                <div className={`${classes.property}`}>- Tolerance</div>
                                                                <div className={`${classes.heading} text-base font-medium`}> {formik.values?.subAttributeIds?.[subAttribute.id]?.minTolerance} {attribute?.uom}</div>
                                                            </div>

                                                            <div className={`grid gap-y-1`}>
                                                                <div className={`${classes.property}`}>+ Tolerance</div>
                                                                <div className={`${classes.heading} text-base font-medium`}> {formik.values?.subAttributeIds?.[subAttribute.id]?.maxTolerance} {attribute?.uom}</div>
                                                            </div>
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        </div>
                                    ))}
                            </div>
                        }
                    </div>

                    <div className={`${classes.border} grid gap-y-4 p-4 rounded-xl`}>
                        <div className={`${classes.heading} text-base font-medium`}>USC and Service Category</div>
                        <div className='grid grid-cols-5'>
                            <div className="grid gap-y-1">
                                <div className={`${classes.property}`}>Service Category</div>
                                <div className={`${classes.heading} text-base font-medium`}>{formik.values?.serviceCategory?.label}</div>
                            </div>
                            <div className="grid gap-y-1">
                                <div className={`${classes.property}`}>Capability</div>
                                <div className={`${classes.heading} text-base font-medium`}>{formik.values?.usc?.label}</div>
                            </div>
                        </div>
                    </div>

                    <div className={`${classes.border} grid gap-y-4 p-4 rounded-xl`}>
                        <div className={`${classes.heading} text-base font-medium`}>Equipment</div>
                        <div className='grid grid-cols-5'>
                            {formik.values?.equipmentNames?.map((name: string, index: number) => (
                                <div key={index} className="grid gap-y-1">
                                    <div className={`${classes.property}`}>{index + 1}. Equipment Name</div>
                                    <div className={`${classes.heading} text-base font-medium`}>{name}</div>
                                </div>
                            ))}
                        </div>
                        <div className="grid gap-y-1">
                            <div className="grid gap-y-1">
                                <div className={`${classes.property}`}>Description</div>
                                <div className={`${classes.heading} text-base font-medium`}>{formik.values?.equipmentDescription}</div>
                            </div>
                        </div>
                    </div>

                    <div className={`${classes.border} grid gap-y-4 p-4 rounded-xl`}>
                        <div className={`${classes.heading} text-base font-medium`}>Other Details</div>
                        <div className='grid grid-cols-5'>
                            <div className="grid gap-y-1">
                                <div className={`${classes.property}`}>Material Handle</div>
                                <div className={`${classes.heading} text-base font-medium`}>{formik.values?.materialHandle}</div>
                            </div>
                            <div className="grid gap-y-1">
                                <div className={`${classes.property}`}>Quality Standard</div>
                                <div className={`${classes.heading} text-base font-medium`}>{formik.values?.qualityStandard}</div>
                            </div>
                            <div className="grid gap-y-1">
                                <div className={`${classes.property}`}>Material Grade</div>
                                <div className={`${classes.heading} text-base font-medium`}>{formik.values?.materialGrade}</div>
                            </div>
                            <div className="grid gap-y-1">
                                <div className={`${classes.property}`}>Serviceability</div>
                                <div className={`${classes.heading} text-base font-medium`}>{formik.values?.serviceability}</div>
                            </div>
                            <div className="grid gap-y-1">
                                <div className={`${classes.property}`}>Order Quantity (Min-Max)</div>
                                <div className={`${classes.heading} text-base font-medium`}>{formik.values?.minOrderQuantity} - {formik.values?.maxOrderQuantity} MT</div>
                            </div>
                        </div>
                    </div>

                    <div className={`${classes.border} grid gap-y-4 p-4 rounded-xl`}>
                        <div className={`${classes.heading} text-base font-medium`}>Service Price</div>
                        <div className='grid grid-cols-5'>
                            <div className="grid gap-y-1">
                                <div className={`${classes.property}`}>Normal Service Charges/MT</div>
                                <div className={`${classes.heading} text-base font-medium`}>{formik.values?.serviceCharge?.normalServiceCharge}</div>
                            </div>
                            <div className="grid gap-y-1">
                                <div className={`${classes.property}`}>Minimum Service Charges/MT</div>
                                <div className={`${classes.heading} text-base font-medium`}>{formik.values?.serviceCharge?.minimumServiceCharge}</div>
                            </div>
                            <div className="grid gap-y-1">
                                <div className={`${classes.property}`}>Maximum Service Charges/MT</div>
                                <div className={`${classes.heading} text-base font-medium`}>{formik.values?.serviceCharge?.maximumServiceCharge}</div>
                            </div>

                        </div>
                    </div>

                    <div className={`${classes.border} grid gap-y-4 p-4 rounded-xl`}>
                        <div className={`${classes.heading} text-base font-medium`}>Machine Description</div>
                        <div className="grid gap-y-1">
                            <div className="grid gap-y-1">
                                <div className={`${classes.property}`}>Description</div>
                                <div className={`${classes.heading} text-base font-medium`}>{formik.values?.machineDescription}</div>
                            </div>
                        </div>
                    </div>

                    <div className={`${classes.border} grid gap-y-4 p-4 rounded-xl`}>
                        <div className={`${classes.heading} text-base font-medium`}>Machine Images and Videos</div>
                        <div className="flex gap-x-4">
                            {formik?.values?.images?.map((image: any, index: number) => (
                                <img className={`${classes.image} rounded-lg`} key={index} src={image instanceof File ?
                                    URL?.createObjectURL(image as File)
                                    :
                                    (image as UploadedImageData)?.path} alt="machineImage" />
                            ))}
                        </div>
                    </div>

                    <StatusV2 heading="Service Status" options={SERVICE_STATUS_OPTIONS} formik={formik} name="status" mode={mode} />
                </div>

                <div className="flex justify-between gap-4 mt-2">
                    <Button variant="tertiaryContained" label='Cancel' onClick={onBack} />
                    <div className="flex justify-end gap-4">
                        <Button variant="tertiaryContained" label='Back' onClick={setFormStateTo(MACHINE_FORM_STATE.MACHINE_DETAILS)} />
                        <Button onClick={handleSubmit} variant='primaryContained' label={`+${capitalizeFirstLetter(mode)}`} />
                    </div>
                </div>

            </form>
        </>

    )
}

export default MachineReviewTemplate