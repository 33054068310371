import React from 'react';
import { createUseStyles } from 'react-jss';
import sellOnSteel from '../../../assets/images/sellOnSteel.jpg';
import vendorCardIcon from '../../../assets/images/vendorCardIcon.svg';
import signUp from '../../../assets/images/signUp.svg';
import sellPageImg from '../../../assets/images/sellPageImg.svg';
import venderProfile from '../../../assets/images/venderProfile.svg';
import digitalStore from '../../../assets/images/digitalStore.svg';
import inventory from '../../../assets/images/inventory.svg';
import sales from '../../../assets/images/sales.svg';
import levarage from '../../../assets/images/levarage.svg';
import financeOptions from '../../../assets/images/financeOptions.svg';
import leads from '../../../assets/images/leads.svg';
import supportAssistance from '../../../assets/images/supportAssistance.svg';
import marketing from '../../../assets/images/marketing.svg';
import stats from '../../../assets/images/stats.svg';
import feedback from '../../../assets/images/feedback.svg';
import RequestDemoTemplate from '../../template/Home/RequestDemo/RequestDemo.template';
import cardIcon01 from '../../../assets/icons/cardIcon01.svg';
import cardIcon1 from '../../../assets/icons/cardIcon1.svg';
import cardIcon2 from '../../../assets/icons/cardIcon2.svg';
import cardIcon02 from '../../../assets/icons/cardIcon02.svg';
import cardIcon3 from '../../../assets/icons/cardIcon3.svg';
import cardIcon4 from '../../../assets/icons/cardIcon4.svg';
import cardIcon5 from '../../../assets/icons/cardIcon5.svg';
import cardIcon6 from '../../../assets/icons/cardIcon6.svg';
import Button from '../../atoms/Button/Button';

const useStyles = createUseStyles((theme: any) => ({
    bannerContainer: {
        width: '100%',
        height: '650px',
        backgroundImage: `url(${sellOnSteel})`,
        backgroundSize: 'cover',
        position: 'relative',
    },
    container: {
        width: '1297px',
        margin: '0 auto',
    },
    bannerSellContainer: {
        padding: '10% 0px',
    },
    heading: {
        fontSize: '56px',
        lineHeight: '66px',
        color: theme.palette.text.primary.primary50,
    },
    bannerDescription: {
        color: theme.palette.text.primary.primary50,
        lineHeight: '28px',
    },
    text: {
        fontSize: '32px',
        color: theme.palette.text.primary.primary700,
    },
    primaryText: {
        fontSize: '40px',
        color: theme.palette.text.neutral.neutral400,
    },
    btn: {
        background: '#fff',
    },
    intoContainer: {
        backgroundColor: theme.palette.background.neutral.neutral50,
    },
    venderSection: {

    },
    venderHeading: {
        fontSize: '48px',
        color: theme.palette.text.primary.primary700,
    },
    vendorCard: {
        border: `1px solid ${theme.palette.border.complementary.complementary300}`,
        width: '277px',
    },
    cardText: {
        color: theme.palette.text.primary.primary900,
        lineHeight: '22px',
    },
    cardSecondaryText: {
        color: theme.palette.text.neutral.neutral600,
    },
    sectionSecondaryText: {
        color: theme.palette.text.neutral.neutral600,
    },
    sellContainer: {
        background: theme.palette.background.neutral.neutral50,
        position: 'relative',
    },
    registerContainer: {
        borderTop: `2px solid ${theme.palette.border.neutral.neutral800}`,
        height: '3px',
        width: '950px',
        position: 'relative',
        top: '30px',
    },
    count: {
        width: '56px',
        height: '56px',
        borderRadius: '50%',
        border: `1px solid ${theme.palette.border.neutral.neutral800}`,
        background: '#fff',
        zIndex: '99',
        "& span": {
            fontSize: '40px',
            color: theme.palette.text.neutral.neutral600,
        },
    },
    sellCard: {
        width: '334px',
        "& h4": {
            fontSize: '32px',
            color: theme.palette.text.neutral.neutral800,
        },
        "& p": {
            color: theme.palette.text.neutral.neutral600,
            lineHeight: '22px',
        },
    },
    btnSecondary: {
        background: theme.palette.background.neutral.neutral800,
        width: '164px',
        color: theme.palette.text.primary.primary50,
    },
    secondaryCardDescription: {
        fontSize: '28px',
        color: theme.palette.text.neutral.neutral800,

    },
    secondaryContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: '110px',
    },
    secondaryCardContainer: {
        width: '626px',
    },
    serialNumber: {
        background: theme.palette.background.primary.primary500,
        boxShadow: '7.4px 7.4px 0px 0px rgba(39, 67, 160, 0.30)',
        width: '64px',
        height: '64px',
        "& span": {
            fontSize: '28px',
            color: '#fff',
        },
    },
    secondaryCardHeading: {
        fontSize: '40px',
        color: theme.palette.text.neutral.neutral800,
        lineHeight: '40px',
    },
    secondaryDescription: {
        lineHeight: '22px',
        color: theme.palette.text.neutral.neutral600,
    },
    profileHeading: {
        color: theme.palette.text.neutral.neutral800,
    },
    contentList: {
        "& li": {
            color: theme.palette.text.neutral.neutral600,
        },
    },
    decContainer: {
        width: '815px',
        "& p": {
            color: theme.palette.text.neutral.neutral600,
            lineHeight: '28px',
        },
    },
    sectionHeading: {
        color: theme.palette.text.neutral.neutral800,
        fontSize: '48px',
    },
    customerContainer: {
        background: theme.palette.background.primary.primary50,
        padding: '62px 70px',
    },
    customerHeading: {
        color: theme.palette.text.primary.primary800,
        fontSize: '40px',
        lineHeight: '40px',
    },
    customerSecondaryHeading: {
        fontSize: '32px',
        color: theme.palette.text.primary.primary800,
    },
    customerDes: {
        color: theme.palette.text.primary.primary900,
        lineHeight: '28px',
    },
    customerBtn: {
        background: theme.palette.background.primary.primary500,
        color: theme.palette.text.primary.primary50,
    },
    buyContainer: {
        width: '100%',
        height: '350px',
        backgroundImage: `url(${sellPageImg})`,
        backgroundSize: 'cover',
        position: 'relative',
    },
    buyHeading: {
        color: theme.palette.text.warning.warning200,
        fontSize: '40px',
    },
    buyDes: {
        color: theme.palette.text.primary.primary50,
    },
    buyBtn: {
        background: '#fff',
    },
    sectionSpacing: {
        margin: '110px 0px',
    },  
}));

const SellOnSteelBazaarTemplate: React.FC = () => {
    const classes = useStyles();

    return (
        <>
            <div className={`${classes.bannerContainer}`}>
                <div className={`${classes.container} ${classes.bannerSellContainer}`}>
                    <h1 className={`${classes.heading} font-semibold`}>Sell Smarter,<br />Grow Faster</h1>
                    <p className={`${classes.bannerDescription} text-2xl font-semibold mt-4`}>Join our global vendor network and <br />unlock new growth opportunities.</p>
                    <button className={`${classes.btn} rounded-xl py-3 px-6 mt-4`}>Start Selling Today</button>
                </div>
            </div>

            <div className={`${classes.buyContainer} py-9 px-16 flex flex-col items-center`}>
                <h4 className={`${classes.buyHeading} text-center`}>Ready to Start Buying ?</h4>
                <p className={`${classes.buyDes} text-center text-5xl mt-4 mb-4`}>Transform your steel procurement experience with our <br />comprehensive platform</p>
                <button className={`${classes.buyBtn} px-6 py-3 rounded-xl mt-3`}>Contact Support</button>
            </div>

            <div className={`${classes.intoContainer} mb-20`}>
                <div className={`${classes.container} flex items-center gap-10 py-14`}>
                    <div className=''>
                        <p className={`${classes.text} font-semibold mb-2`}>Welcome to SteelBazaar</p>
                        <p className={`${classes.primaryText} font-semibold`}>Your Trusted Partner in <br />Steel Business</p>
                    </div>
                    <div className={classes.decContainer}>
                        <p className='text-2xl'>At SteelBazaar, we are dedicated to connecting vendors with buyers through an efficient, transparent, and secure marketplace. With SteelBazaar, you're accessing a comprehensive wholesale platform designed to grow your business and enhance the services you provide to your buyers.
                            Whether you’re looking to expand your reach or streamline your sales processes, SteelBazaar is the go-to platform for the steel industry.
                            Let us help you grow your business while maximizing sales potential.
                        </p>
                    </div>
                </div>
            </div>

            <div className={`${classes.venderSection} ${classes.container}`}>
                <h2 className={`${classes.venderHeading} font-bold text-center mb-5`}>Why Sell on SteelBazaar Seller Central ?</h2>
                <div className='flex justify-between mb-5'>
                    <div className={`${classes.vendorCard} p-4 rounded-2xl flex-col gap-3`}>
                        <img src={cardIcon01} alt="" className='mb-6' />
                        <p className={`${classes.cardText} text-lg font-semibold mb-3`}>Explore New Markets and Customers</p>
                        <p className={`${classes.cardSecondaryText} text-sm mb-2`}>Sell to domestic and international buyers on a trusted platform.</p>
                    </div>
                    <div className={`${classes.vendorCard} p-4 rounded-2xl flex-col gap-3`}>
                        <img src={cardIcon1} alt="" className='mb-6' />
                        <p className={`${classes.cardText} text-lg font-semibold mb-3`}>Maximize Sales with Lead Generation</p>
                        <p className={`${classes.cardSecondaryText} text-sm mb-2`}>Get regular customer inquiries through our integrated lead management.</p>
                    </div>
                    <div className={`${classes.vendorCard} p-4 rounded-2xl flex-col gap-3`}>
                        <img src={cardIcon2} alt="" className='mb-6' />
                        <p className={`${classes.cardText} text-lg font-semibold mb-3`}>Direct Primary Manufacturer Connections</p>
                        <p className={`${classes.cardSecondaryText} text-sm mb-2`}>Negotiate best deals and explore long-term contracts and partnerships.</p>
                    </div>
                    <div className={`${classes.vendorCard} p-4 rounded-2xl flex-col gap-3`}>
                        <img src={cardIcon02} alt="" className='mb-6' />
                        <p className={`${classes.cardText} text-lg font-semibold mb-3`}>Get Instant Payments</p>
                        <p className={`${classes.cardSecondaryText} text-sm mb-2`}>Receive instant payments from the Steelbazaar selected customers if make sales to them.</p>
                    </div>
                </div>
                <div className='flex justify-between mb-9'>
                    <div className={`${classes.vendorCard} p-4 rounded-2xl flex-col gap-3`}>
                        <img src={cardIcon3} alt="" className='mb-6' />
                        <p className={`${classes.cardText} text-lg font-semibold mb-3`}>Seamless Digital Listings</p>
                        <p className={`${classes.cardSecondaryText} text-sm mb-2`}>Easily list, manage, and update product catalogs digitally with real time inputs.</p>
                    </div>
                    <div className={`${classes.vendorCard} p-4 rounded-2xl flex-col gap-3`}>
                        <img src={cardIcon4} alt="" className='mb-6' />
                        <p className={`${classes.cardText} text-lg font-semibold mb-3`}>Digital Order Management System</p>
                        <p className={`${classes.cardSecondaryText} text-sm mb-2`}>Seamlessly manage orders, inventories, and logistics in one unified platform.</p>
                    </div>
                    <div className={`${classes.vendorCard} p-4 rounded-2xl flex-col gap-3`}>
                        <img src={cardIcon5} alt="" className='mb-6' />
                        <p className={`${classes.cardText} text-lg font-semibold mb-3`}>Comprehensive Digital Marketing</p>
                        <p className={`${classes.cardSecondaryText} text-sm mb-2`}>Boost product visibility through targeted promotions and featured listings along with advanced marketing tools .</p>
                    </div>
                    <div className={`${classes.vendorCard} p-4 rounded-2xl flex-col gap-3`}>
                        <img src={cardIcon6} alt="" className='mb-6' />
                        <p className={`${classes.cardText} text-lg font-semibold mb-3`}>Auction Your Surplus Inventory</p>
                        <p className={`${classes.cardSecondaryText} text-sm mb-2`}>Sell excess stock and specialized items via competitive bidding.</p>
                    </div>
                </div>
            </div>

            <div className={`${classes.container}`}>
                <h2 className={`${classes.sectionHeading} font-medium mb-1 mt-20 text-center`}>Start selling on Steelbazaar</h2>
                <p className={`${classes.sectionSecondaryText} text-center text-2xl`}>Your Journey Starts Here …</p>
            </div>
            <div className={`${classes.sellContainer} mt-5 flex justify-center flex-col items-center py-12 mb-20`}>
                <div className={classes.registerContainer}></div>
                <div className={`${classes.container} flex justify-between pb-12`}>
                    <div className={`${classes.sellCard} flex flex-col items-center gap-6`}>
                        <div className={`${classes.count} flex items-center justify-center`}><span className='font-bold'>1</span></div>
                        <div className='text-center'>
                            <h4 className='font-medium mb-2'>Register</h4>
                            <p className='text-lg'>Sign Up as a Vendor providing basic business information.</p>
                        </div>
                    </div>
                    <div className={`${classes.sellCard} flex flex-col items-center gap-6`}>
                        <div className={`${classes.count} flex items-center justify-center`}><span className='font-bold'>2</span></div>
                        <div className='text-center'>
                            <h4 className='font-medium mb-2'>Verify</h4>
                            <p className='text-lg'>Upload necessary documents to Complete the verification process*to ensure a secure marketplace.</p>
                        </div>
                    </div>
                    <div className={`${classes.sellCard} flex flex-col items-center gap-6`}>
                        <div className={`${classes.count} flex items-center justify-center`}><span className='font-bold'>3</span></div>
                        <div className='text-center'>
                            <h4 className='font-medium mb-2'>Launch</h4>
                            <p className='text-lg'>Once verified, you’re ready to list your products and start selling!</p>
                        </div>
                    </div>
                </div>
                <div className={`${classes.btnSecondary} py-3 px-6 rounded-xl`}>
                    <p className='text-lg font-medium'>Register Now</p>
                </div>
            </div>

            <div className={`${classes.container} ${classes.secondaryContainer}`}>
                <div className='flex items-center gap-20'>
                    <div className='flex gap-5'>
                        <div className={`${classes.serialNumber} flex items-center justify-center`}><span className='font-bold'>01</span></div>
                        <div className={`${classes.secondaryCardContainer}`}>
                            <div className='flex gap-3 items-center mb-3'>
                                <h3 className={`${classes.secondaryCardHeading} font-semibold mb-4`}>Create Your Vendor Profile</h3>
                            </div>
                            <div className=''>
                                <h4 className={`${classes.secondaryCardDescription} font-medium  mb-1`}>Build Credibility and Trust</h4>
                                <p className={`${classes.secondaryDescription} text-lg font-medium`}>Showcase Your Expertise: Highlight Strengths, Quality, Range, and Certifications to Attract More Buyers</p>
                                <div>
                                    <h4 className={`${classes.profileHeading} font-medium text-2xl mt-4`}>Profile Inclusions:</h4>
                                    <ul className={classes.contentList}>
                                        <li className='text-lg'>1. Company history</li>
                                        <li className='text-lg'>2. Product details and certifications</li>
                                        <li className='text-lg'>3. Contact information</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <img src={venderProfile} alt="" />
                </div>

                <div className='flex items-center justify-between'>
                    <img src={digitalStore} alt="" />
                    <div className='flex gap-5'>
                        <div className={`${classes.serialNumber} flex items-center justify-center`}><span className='font-bold'>02</span></div>
                        <div className={`${classes.secondaryCardContainer}`}>
                            <div className='flex gap-3 items-center mb-3'>
                                <h3 className={`${classes.secondaryCardHeading} font-semibold mb-4`}>Digital Store Front</h3>
                            </div>
                            <div className=''>
                                <h4 className={`${classes.secondaryCardDescription} font-medium mb-1`}>Showcase Your Best Products and Offers</h4>
                                <p className={`${classes.secondaryDescription} text-lg font-medium`}>Showcase Your Expertise: Highlight Strengths, Quality, Range, and Certifications to Attract More Buyers</p>
                                <div >
                                    <h4 className={`${classes.profileHeading} font-medium text-2xl mt-4`}>Profile Inclusions:</h4>
                                    <ul className={classes.contentList}>
                                        <li className='text-lg'>1. Company history</li>
                                        <li className='text-lg'>2. Product details and certifications</li>
                                        <li className='text-lg'>3. Contact information</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='flex items-center justify-between'>
                    <div className='flex gap-5'>
                        <div className={`${classes.serialNumber} flex items-center justify-center`}><span className='font-bold'>03</span></div>
                        <div className={`${classes.secondaryCardContainer}`}>
                            <div className='flex gap-3 items-center mb-3'>
                                <h3 className={`${classes.secondaryCardHeading} font-semibold mb-4`}>Manage Inventory & Orders</h3>
                            </div>
                            <div className=''>
                                <h4 className={`${classes.secondaryCardDescription} font-medium mb-1`}>Stay in control of your sales</h4>
                                <p className={`${classes.secondaryDescription} text-lg font-medium`}>Effortlessly Manage Your Inventory: Keep Stock Updated, Handle Orders Efficiently, and Ensure Timely Deliveries</p>
                                <div >
                                    <h4 className={`${classes.profileHeading} font-medium text-2xl mt-4`}>Inventory management SKU and USN wise</h4>
                                    <ul className={classes.contentList}>
                                        <li className='text-lg'>1. Real-time Inventory and Order tracking</li>
                                        <li className='text-lg'>2. Automated stock updates</li>
                                        <li className='text-lg'>3. Bar Coding Options</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <img src={inventory} alt="" />
                </div>

                <div className='flex items-center justify-between'>
                    <img src={sales} alt="" />
                    <div className='flex gap-5'>
                        <div className={`${classes.serialNumber} flex items-center justify-center`}><span className='font-bold'>04</span></div>
                        <div className={`${classes.secondaryCardContainer}`}>
                            <div className='flex gap-3 items-center mb-3'>
                                <h3 className={`${classes.secondaryCardHeading} font-semibold mb-4`}>Featured Selling on SteelBazaar</h3>
                            </div>
                            <div className=''>
                                <h4 className={`${classes.secondaryCardDescription} font-medium mb-1`}>Boost Your Brand Visibility</h4>
                                <p className={`${classes.secondaryDescription} text-lg font-medium`}>Enhance your presence with featured listings for top search visibility. Promote exclusive offers to attract buyers and establish trust, positioning your brand as an industry leader.</p>
                                <div >
                                    <h4 className={`${classes.profileHeading} font-medium text-2xl mt-4`}>Boost Your Brand Visibility:</h4>
                                    <ul className={classes.contentList}>
                                        <li className='text-lg'>1. Top Visibility: Stand out in search results easily.</li>
                                        <li className='text-lg'>2. Promote Offers: Highlight discounts for maximum attention.</li>
                                        <li className='text-lg'>3. Build Trust: Establish credibility as a top brand.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className={`${classes.sectionSpacing}`}>
                <div className={`${classes.container} ${classes.customerContainer} px-10`}>
                    <div>
                        <h3 className={`${classes.customerHeading} font-semibold mb-2`}>Access Exclusive Buy Leads</h3>
                        <h4 className={`${classes.customerSecondaryHeading} font-semibold mb-9`}>Connect with Ready-to-Buy Customers</h4>
                        <p className={`${classes.customerDes} text-2xl mb-8`}>SteelBazaar generates curated buy leads based on market demand. Vendors receive these leads directly from SteelBazaar admin, providing you with high-potential sales opportunities to expand your business.</p>
                        <Button label='Contact Support' variant={'primaryContained'}></Button>
                    </div>
                </div>
            </div>

            <div className={`${classes.container} ${classes.secondaryContainer}`}>
                <div className='flex items-center justify-between'>
                    <div className='flex gap-5'>
                        <div className={`${classes.serialNumber} flex items-center justify-center`}><span className='font-bold'>05</span></div>
                        <div className={`${classes.secondaryCardContainer}`}>
                            <div className='flex gap-3 items-center mb-3'>
                                <h3 className={`${classes.secondaryCardHeading} font-semibold mb-4`}>Leverage Auctions for Immediate sales</h3>
                            </div>
                            <div className=''>
                                <h4 className={`${classes.secondaryCardDescription} font-medium mb-1`}>Sell Excess and aged Inventory through Auctions</h4>
                                <p className={`${classes.secondaryDescription} text-lg font-medium`}>Boost Sales and Manage Cash Flow: Leverage SteelBazaar's Auction Feature for Surplus and Specialized Inventory</p>
                                <div >
                                    <h4 className={`${classes.profileHeading} font-medium text-2xl mt-4`}>Auction Highlights:</h4>
                                    <ul className={classes.contentList}>
                                        <li className='text-lg'>1. Real-time competitive bidding</li>
                                        <li className='text-lg'>2. Set reserve prices for your products</li>
                                        <li className='text-lg'>3. Gain visibility among interested buyers</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <img src={levarage} alt="" />
                </div>

                <div className='flex items-center justify-between'>
                    <img src={financeOptions} alt="" />
                    <div className='flex gap-5'>
                        <div className={`${classes.serialNumber} flex items-center justify-center`}><span className='font-bold'>06</span></div>
                        <div className={`${classes.secondaryCardContainer}`}>
                            <div className='flex gap-3 items-center mb-3'>
                                <h3 className={`${classes.secondaryCardHeading} font-semibold mb-4`}>Financing Options for Approved Buyers</h3>
                            </div>
                            <div className=''>
                                <h4 className={`${classes.secondaryCardDescription} font-medium mb-1`}>Get Paid Safely and On Time</h4>
                                <p className={`${classes.secondaryDescription} text-lg font-medium`}>Secure Payment Gateway: Smooth, Transparent Transactions with Immediate Fulfillment Payments and Full Protection</p>
                                <div >
                                    <h4 className={`${classes.profileHeading} font-medium text-2xl mt-4`}>Payment Features:</h4>
                                    <ul className={classes.contentList}>
                                        <li className='text-lg'>1. Quick and secure payouts</li>
                                        <li className='text-lg'>2. Transparent transaction tracking</li>
                                        <li className='text-lg'>3. Dispute resolution services</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='flex items-center justify-between'>
                    <div className='flex gap-5'>
                        <div className={`${classes.serialNumber} flex items-center justify-center`}><span className='font-bold'>07</span></div>
                        <div className={`${classes.secondaryCardContainer}`}>
                            <div className='flex gap-3 items-center mb-3'>
                                <h3 className={`${classes.secondaryCardHeading} font-semibold mb-4`}>Unlock Sales Growth with Curated, High-Quality Leads</h3>
                            </div>
                            <div className=''>
                                <p className={`${classes.secondaryDescription} text-lg font-medium`}>Pre-qualified leads connect vendors with serious buyers & provide insights into market demands, helping to maximize sales & adapt strategies.</p>
                                <div >
                                    <h4 className={`${classes.profileHeading} font-medium text-2xl mt-4`}>Value of High-Quality Leads</h4>
                                    <ul className={classes.contentList}>
                                        <li className='text-lg'>1. Engaged Prospects: Reach only serious, interested buyers.</li>
                                        <li className='text-lg'>2. Precision Matching: Provide leads tailored to vendor products.</li>
                                        <li className='text-lg'>3. Insightful Trends: Stay updated on market shifts for strategic responses.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <img src={leads} alt="" />
                </div>

                <div className='flex items-center justify-between'>
                    <img src={supportAssistance} alt="" />
                    <div className='flex gap-5'>
                        <div className={`${classes.serialNumber} flex items-center justify-center`}><span className='font-bold'>08</span></div>
                        <div className={`${classes.secondaryCardContainer}`}>
                            <div className='flex gap-3 items-center mb-3'>
                                <h3 className={`${classes.secondaryCardHeading} font-semibold mb-4`}>Vendor Support and Assistance</h3>
                            </div>
                            <div className=''>
                                <p className={`${classes.secondaryDescription} text-lg font-medium`}>Secure Payment Gateway: Smooth, Transparent Transactions with Immediate Fulfillment Payments and Full Protection</p>
                                <div >
                                    <h4 className={`${classes.profileHeading} font-medium text-2xl mt-4`}>Support Resources:</h4>
                                    <ul className={classes.contentList}>
                                        <li className='text-lg'>1. Dedicated vendor support</li>
                                        <li className='text-lg'>2. Tutorials, FAQs, and training programs</li>
                                        <li className='text-lg'>3. 24/7 assistance via chat and phone</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className={`${classes.sectionSpacing}`}>
                <div className={`${classes.container} ${classes.customerContainer}`}>
                    <div>
                        <h3 className={`${classes.customerHeading} font-semibold mb-2`}>We're Here to Help You Succeed</h3>
                        <p className={`${classes.customerDes} mb-8`}>SteelBazaar offers comprehensive support to vendors. Our team is available to help with onboarding, product <br /> listings, or any technical issues. Explore our training resources to optimize your sales strategy.</p>
                        <Button label='Register Now' variant={'primaryContained'}></Button>
                    </div>
                </div>
            </div>

            <div className={`${classes.container} ${classes.secondaryContainer}`}>
                <div className='flex items-center justify-between'>
                <div className='flex gap-5'>
                        <div className={`${classes.serialNumber} flex items-center justify-center`}><span className='font-bold'>09</span></div>
                        <div className={`${classes.secondaryCardContainer}`}>
                            <div className='flex gap-3 items-center mb-3'>
                                <h3 className={`${classes.secondaryCardHeading} font-semibold mb-4`}>Boost Your Visibility with Marketing Services</h3>
                            </div>
                            <div className=''>
                                <h4 className={`${classes.secondaryCardDescription} font-medium mb-1`}>Increase Your Sales Potential</h4>
                                <p className={`${classes.secondaryDescription} text-lg font-medium`}>Access Marketing Tools: Boost Your Visibility with Premium Listings, Banner Ads, and Seasonal Promotions</p>
                                <div >
                                    <h4 className={`${classes.profileHeading} font-medium text-2xl mt-4`}>Marketing Opportunities:</h4>
                                    <ul className={classes.contentList}>
                                        <li className='text-lg'>1. Featured product listings</li>
                                        <li className='text-lg'>2. Digital marketing campaigns</li>
                                        <li className='text-lg'>3. Special promotions and events</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <img src={marketing} alt="" />
                </div>

                <div className='flex items-center justify-between'>
                    <img src={stats} alt="" />
                    <div className='flex gap-5'>
                        <div className={`${classes.serialNumber} flex items-center justify-center`}><span className='font-bold'>10</span></div>
                        <div className={`${classes.secondaryCardContainer}`}>
                            <div className='flex gap-3 items-center mb-3'>
                                <h3 className={`${classes.secondaryCardHeading} font-semibold mb-4`}>Monitor Your Performance with Real-Time Analytics</h3>
                            </div>
                            <div className=''>
                                <h4 className={`${classes.secondaryCardDescription} font-medium mb-1`}>Track and Optimize Your Sales</h4>
                                <p className={`${classes.secondaryDescription} text-lg font-medium`}>Access Marketing Tools: Boost Your Visibility with Premium Listings, Banner Ads, and Seasonal Promotions</p>
                                <div >
                                    <h4 className={`${classes.profileHeading} font-medium text-2xl mt-4`}>Analytics Insights:</h4>
                                    <ul className={classes.contentList}>
                                        <li className='text-lg'>1. Sales tracking and reporting</li>
                                        <li className='text-lg'>2. Buyer engagement metrics</li>
                                        <li className='text-lg'>3. Product performance analysis</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='flex items-center justify-between'>
                <div className='flex gap-5'>
                        <div className={`${classes.serialNumber} flex items-center justify-center`}><span className='font-bold'>11</span></div>
                        <div className={`${classes.secondaryCardContainer}`}>
                            <div className='flex gap-3 items-center mb-3'>
                                <h3 className={`${classes.secondaryCardHeading} font-semibold mb-4`}>Build Trust with Feedback & Ratings</h3>
                            </div>
                            <div className=''>
                                <h4 className={`${classes.secondaryCardDescription} font-medium mb-1`}>Your Reputation Matters</h4>
                                <p className={`${classes.secondaryDescription} text-lg font-medium`}>Feedback and Rating System: Build Trust with Positive Reviews to Boost Sales</p>
                                <div >
                                    <h4 className={`${classes.profileHeading} font-medium text-2xl mt-4`}>Feedback System:</h4>
                                    <ul className={classes.contentList}>
                                        <li className='text-lg'>1. Customer reviews and ratings</li>
                                        <li className='text-lg'>2. Tips for managing feedback</li>
                                        <li className='text-lg'>3. Strategies for improving ratings</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <img src={feedback} alt="" />
                </div>
            </div>
            <RequestDemoTemplate />
        </>
    );
};
export default SellOnSteelBazaarTemplate;
