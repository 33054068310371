import React, { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import { ICatalogue, ICurrentCartItem } from "../../pages/CatalogueDetail/CatalogueDetails.page";
import { Checkbox } from '@mui/material';
import { useProductUpcBrandMappingService } from "../../../services/useProductUpcBrandMappingService";
import { CUSTOMER_ROUTES } from '../../../utils/constant';
import { CATALOGUE_TYPE_STATES, HTTP_STATUS } from "../../../utils/types";
import { Link } from "react-router-dom";
import { capitalizeFirstLetter, formatPrice, makeRoute } from "../../../utils/helper";
import Button from "../../atoms/Button/Button";
import FilterChip, { IMultiSelectOption } from "../../atoms/FilterChip/FilterChip";

export interface UploadedImageData {
    id?: number | null;
    location: string;
    path: string;
    resourceId?: number | null;
    resourceType: string;
    mimeType: string;
    createdAt: string;
}

const useStyles = createUseStyles((theme: any) => ({
    border: {
        border: `1px solid ${theme.palette.border.neutral.neutral100}`,
    },
    title: {
        color: theme.palette.text.neutral.neutral900
    },
    catalogueName:{
        color: theme.palette.text.primary.primary900,
    },
    text: {
        color: theme.palette.text.neutral.neutral700
    },
    key: {
        color: theme.palette.text.neutral.neutral600,
        lineHeight: "14px"
    },
    value: {
        color: theme.palette.text.primary.primary600,
        lineHeight: "16px"
    },
    link: {
        color: theme.palette.text.primary.primary500
    },
    highLight: {
        color: theme.palette.text.primary.primary600
    },
    checkBox: {
        "&.MuiButtonBase-root.MuiCheckbox-root": {
            padding: "0px",
        },
        color: theme.palette.text.primary.primary400,
        '&.Mui-checked': {
            color: theme.palette.text.primary.primary400,
        },
    },
    price: {
        color: theme.palette.text.neutral.neutral700
    },
    brandImage: {
        width: "100%",
        height: "43px",
        objectFit: "contain"
    },
    tableHead: {
        background: theme.palette.background.neutral.neutral50
    }
}));

interface SimilarBrandsProps {
    currentCartItem: ICurrentCartItem;
    catalogue: ICatalogue | null;
    catalogueType: CATALOGUE_TYPE_STATES
}

interface InventorySummary {
    warehouseId: number
    warehouseName: string
    netWeight: number
}

interface ISimilarBrands {
    brandImages: UploadedImageData[]
    brandId: number
    brandName: string
    price: number
    updatedAt: string
    catalogueId: number
    inventorySummaries: InventorySummary[]

}

interface IBrandList {
    id: number
    brandName: string
}

const SimilarBrands: React.FC<SimilarBrandsProps> = ({ currentCartItem, catalogue, catalogueType }) => {
    const classes = useStyles();
    const [selectedBrands, setSelectedBrands] = useState<IMultiSelectOption[]>([]);
    const productUpcBrandMappingService = useProductUpcBrandMappingService();
    const [similarBrands, setSimilarBrands] = useState<ISimilarBrands[]>([]);
    const [brands, setBrands] = useState<IBrandList[]>([]);
    const [brandsToDisplay, setBrandsToDisplay] = useState<IBrandList[]>([]);
    const [attributes, setAttributes] = useState<Record<string, any>>({});
    const [displayBrandData, setDisplayBrandData] = useState<number[]>([]);
    const [searchTerm, setSearchTerm] = useState<string>("");

    const updateAttributes = () => {
        let attributes: Record<string, any> = {};
        if (catalogueType === CATALOGUE_TYPE_STATES.MAKE_TO_ORDER) {
            Object.keys(currentCartItem.attributes).forEach((key: any) => {
                if (key.toUpperCase() == "HARDNESS") return;
                attributes[key] = {
                    value: currentCartItem.attributes[key].value,
                    uom: catalogue?.catalogueAttributes.SPECIFICATION.find(attribute => attribute.name.toUpperCase().trim() === key.toUpperCase().trim())?.uom
                };
            })
        } else {
            catalogue?.catalogueAttributes.SPECIFICATION.forEach(attribute => {
                if (attribute.name.trim().toUpperCase() == "HARDNESS") return;
                attributes[attribute.name] = { value: JSON.parse(attribute.attributeOptions as string), uom: attribute.uom };
            })
        }
        setAttributes(attributes);
    }


    const handleBrandCheckBox = (option: IMultiSelectOption[] | null) => {
        if (option) {
            setSelectedBrands(option)
        }
    };

    const getSimilarBrands = async () => {
        let res = await productUpcBrandMappingService.fetchSimilarCataloguesByBrandUpcId(currentCartItem.upc?.id as number);
        if (res.status === HTTP_STATUS.OK) {
            setSimilarBrands(res.data.data)
        }
    }

    useEffect(() => {
        getSimilarBrands();
    }, [catalogue])

    useEffect(() => {
        updateAttributes();
    }, [catalogue])

    useEffect(() => {
        setBrands(similarBrands.filter(el => el).filter(similarBrand => similarBrand.brandName.toUpperCase() !== catalogue?.brand.toUpperCase())
            .map(item => ({ id: item.brandId, brandName: item.brandName })));
    }, [similarBrands])

    useEffect(() => {
        setBrandsToDisplay(brands)
    }, [brands])

    const displayWarehouse = (data: any) => {
        const warehouses = data.inventorySummaries.map((el: any) => capitalizeFirstLetter(el.warehouseName));
        return warehouses.length > 2 ? warehouses.slice(0, 2).join(", ") + "..." : warehouses.join(", ")
    }

    const handleCompare = () => {
        setDisplayBrandData(selectedBrands.map(el => el.value as number));
    }

    const handleSearch = (inputValue: string = "") => {
        setSearchTerm(inputValue);
        if (inputValue.length == 0)
            setBrandsToDisplay(brands);
        else {
            const filteredBrands: any = brands.filter(brand => brand.brandName.toUpperCase().includes(inputValue.toUpperCase()))
            setBrandsToDisplay(filteredBrands)
        }
    }

    return (
        <>
        {similarBrands.length > 1 && ( 
        <div id="scrollToLabel">
            <div className={`${classes.title} text-base font-bold mb-3`}>Comparison With Other Manufacturers</div>
            <div className={`${classes.border} p-4 grid gap-y-5 w-full rounded-xl`}>
                <div className={`${classes.catalogueName} text-2xl font-semibold`}>{catalogue?.name}</div>
                <div className="grid gap-y-3">
                    {Object.keys(attributes as any).length != 0 && <>
                        <div className={`text-sm ${classes.text}`}>Selected Specifications</div>
                        <div className={`p-4 rounded-xl ${classes.border} flex justify-between w-full`}>
                            {(Object.keys(attributes as any) ?? []).map((key: any) => (
                                <div className="justify-center flex flex-col items-center gap-y-1">
                                    <div className={`${classes.key} text-xs`}>{key}</div>
                                    <div className={`text-sm ${classes.value}`}>{`${attributes?.[key].value} ${attributes?.[key].uom ?? ""}`}</div>
                                </div>
                            ))}
                        </div>
                    </>}
                </div>
                <div className="box-border flex flex-col gap-1">
                    <div className={`${classes.text} font-normal`}>Select Available Brand for Comparison:</div>
                    <div className="flex gap-x-5 items-center">
                        <div className="w-full">
                            <FilterChip
                                options={brandsToDisplay.map(brand => ({ label: brand.brandName, value: brand.id }))}
                                label=""
                                value={selectedBrands as any}
                                onchange={handleBrandCheckBox}
                                placeholder="Select brands here"
                                setSearchTerm={handleSearch}
                                textFieldPlaceholder='Search Brands'
                                searchTerm={searchTerm}
                            />
                        </div>
                        <Button variant="tertiaryContained" label="Compare" onClick={handleCompare} />
                    </div>

                    {!!displayBrandData.length && <div className="grid gap-y-2">
                        <div className={`${classes.title} text-sm font-semibold`}>{`Comparing With ${displayBrandData.length} Brands`}</div>
                        <div>
                            <div className={`${classes.tableHead} rounded-lg py-4 grid grid-cols-6 justify-items-center text-sm font-semibold`}>
                                <span className={`${classes.title}`}>Brand</span>
                                <span className={`${classes.title}`}>Ex-location</span>
                                <span className={`${classes.title}`}>Delivery Time</span>
                                <span className={`${classes.title}`}>Price(Rs/MT)<sup>*</sup></span>
                                <span className={`${classes.title}`}>Updated On</span>
                                <span className={`${classes.title}`}>Action</span>
                            </div>
                            {
                                similarBrands.filter(el => displayBrandData.includes(el.brandId)).map((similarBrand) => (
                                    <div className="border-b py-3 grid grid-cols-6 justify-items-center items-center">
                                        <span className="text-center">
                                            {similarBrand?.brandImages.length>0 ?
                                                <img src={similarBrand?.brandImages?.[0]?.path} alt={similarBrand.brandName?.toUpperCase()} className={classes.brandImage} />:
                                                <span className={`${classes.text} text-sm font-normal`}>{similarBrand?.brandName}</span>
                                            }
                                        </span>
                                        <span className={`${classes.text} text-sm font-normal text-ellipsis`}>{displayWarehouse(similarBrand)}</span>
                                        <span className={`${classes.text} text-sm font-normal`}>3-10 days</span>
                                        <span className={`${classes.price} text-sm font-normal`}>{formatPrice(similarBrand.price)}</span>
                                        <span className={`${classes.text} text-sm font-normal`}>{new Date(similarBrand.updatedAt).toISOString().split('T')[0]}</span>
                                        {/* <Link to={makeRoute(CUSTOMER_ROUTES.CATALOGUE_DESCRIPTION, { query: { "catalogueId": similarBrand.catalogueId } })} target="_blank" rel="noopener noreferrer" className={`underline underline-offset-4 ${classes.link} cursor-pointer text-sm`}>
                                            View Details
                                        </Link> */}
                                        <span className={`underline underline-offset-4 ${classes.link} cursor-pointer text-sm`}>View Details</span>
                                    </div>
                                ))
                            }
                            <div className={`text-xs font-medium pt-5 ${classes.highLight}`}>*Prices are subject to terms and condition / Market conditions. Please contact us for more details.</div>
                        </div>
                    </div>}
                </div>
            </div>
         
        </div>
    )}
    </>
    )
};

export default SimilarBrands;