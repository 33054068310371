import { createUseStyles } from "react-jss";
import { PAYMENT_METHODS, PAYMENT_SELECTION, PAYMENT_TERMS, PAYMENT_TERMS_REVIEW_FORM_SECTION,paymentTermOptions } from "../../../utils/constant";
import { useEffect, useState } from "react";
import SelectedRadioIcon from '../../../assets/images/selectedRadio.svg';
import DisabledSelectedRadioIcon from '../../../assets/icons/disabledSelectIcon.svg';
import PaymentIcon from '../../../assets/images/payment.svg';

const useStyles = createUseStyles((theme: any) => ({
    mainCointainer: {
        display: "flex",
        gap:"8px"
    },
    container: {
        display: "flex",
        padding: "25px 34px",
        border: `1px solid ${theme.palette.border.neutral.neutral100}`,
        cursor: "pointer",
    },
    paymentWrapper: {
        borderRadius: "10px",
        background: theme.palette.background.success.success50,
        padding: "9px 0px 6px 8px",
    },
    paymentSelection: {
        color: theme.palette.text.success.success500,
    },
    selectedMethod: {
        border: `2px solid ${theme.palette.border.primary.primary400}`,
    },
    radio: {
        borderRadius: "100px",
        border: `2px solid ${theme.palette.border.neutral.neutral200}`,
    },
    details: {
        color: theme.palette.text.primary.primary800,
        maxWidth: "321px",
    },
    mainheading: {
        color: theme.palette.text.neutral.neutral900,
    },
    subText: {
        color: theme.palette.text.neutral.neutral600,
        maxWidth: "321px",
        fontSize: "16px"
    },
    or: {
        color: theme.palette.text.neutral.neutral900,
        margin: "auto"
    },
    "@media (max-width: 480px)": {
        mainCointainer: {
            display: "grid"
        },
        or: {
            margin: "12px auto"
        },
        container: {
            padding: "16px",
        },
        subText: {
            fontSize: "14px"
        },
        heading: {
            fontSize: "16px"
        },
    },
    comingSoon: {
        background: theme.palette.background.primary.primary50,
        color: theme.palette.text.primary.primary500,

    }
}))
interface IPaymentMethod {
    paymentType?: string | null;
    handlePaymentTypeChange: (value: string) => void;
}
const PaymentDetailsReviewFormTemplate: React.FC<IPaymentMethod> = ({ paymentType, handlePaymentTypeChange }) => {
    const classes = useStyles();

    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<string | null>();

    useEffect(() => {
        if (paymentType != null) {
            if (Object.keys(paymentTermOptions).includes(paymentType)) {
                setSelectedPaymentMethod(paymentType);
            }
        }
    }, [paymentType])

    const handlePaymentTermsClick = (value: string) => () => {
        // setSelectedPaymentMethod(value);
        handlePaymentTypeChange(value);
    };

    const ComingSoonTemplate = (title: string) => {
        return (<div className={`p-2 ${classes.comingSoon} text-xs font-semibold text-center rounded`}>
            {title}
        </div>)
    }
    
    const paymentSelection = (isAvailable: boolean, value: any) => {
        if (isAvailable) {
            if (selectedPaymentMethod === value) {
                return <img src={SelectedRadioIcon} className="w-4 h-4 mb-1" alt="radio" />
            } else {
                return <span className={`${classes.radio} w-4 h-4 mb-1`} />
            }
        } else {
            return <img src={DisabledSelectedRadioIcon} className="w-4 h-4 mb-1 cursor-auto" alt="radio" />
        }
    }

    return (
        <div className={`grid gap-y-3`}>
            <div className="flex justify-between">
                <div className={`${classes.mainheading} my-auto text-lg font-bold`}>{PAYMENT_TERMS.PAYMENT_TERMS}</div>
                {selectedPaymentMethod &&
                    <div className={`${classes.paymentWrapper} flex align-center`}>
                        <img src={PaymentIcon} alt="payment" className="w-8 h-8 my-auto" />
                        <span className={`${classes.paymentSelection} text-sm font-medium p-2`}>{selectedPaymentMethod === paymentTermOptions.CASH ? PAYMENT_SELECTION.CASH_DOWN_PAYMENT : PAYMENT_SELECTION.CREDIT_PAYMENT}</span>
                    </div>}
            </div>
            <div className={`${classes.mainCointainer} items-start`}>
                {PAYMENT_TERMS_REVIEW_FORM_SECTION.map(({ value, heading, subHeading, hasNext, isAvailable }) => (
                    <>
                        <div key={value} className={`${classes.container} flex justify-between rounded-xl ${selectedPaymentMethod === value ? classes.selectedMethod : "border"}`} onClick={isAvailable ? handlePaymentTermsClick(value) : () => null}>
                            <div>
                                <div className="flex justify-between items-center w-full min-h-8">
                                    {paymentSelection(selectedPaymentMethod===value ? true : false, value)}
                                    <div>{isAvailable === false ? ComingSoonTemplate("Coming Soon") : ""}</div>
                                </div>
                                <div className={`${classes.details} font-semibold mb-3`}>{heading}</div>
                                <p className={`${classes.subText} font-normal`}>{subHeading}</p>
                            </div>
                        </div>
                        {hasNext ? <div className={`${classes.or} text-lg font-semibold w-6 h-6 text-center `}>{PAYMENT_TERMS.OR}</div> : ""}
                    </>
                ))}
            </div>
        </div>
    )
}

export default PaymentDetailsReviewFormTemplate;