import { TableBody, TableContainer, TableCell, TableHead, TableRow, Table } from '@mui/material';
import { createUseStyles } from 'react-jss';
import editIcon from "../../../assets/images/editIcon.svg"
import { ISlitting } from './SlittingCustomizationPopup';

interface SlittingCustomizationTableTemplateProps {
    valueAddedService: ISlitting[];
    setDialogOpen: (open: boolean) => void;
    setEditState: (open: boolean) => void;
}

const useStyles = createUseStyles((theme: any) => ({
    heading:{
        color: theme.palette.text.neutral.neutral900,
    },
    tableHead: {
        padding: "12px 16px",
        background: theme.palette.background.neutral.neutral50,
    },
    tableHeadCell: {
        fontSize: "14px",
        fontWeight: 600,
        lineHeight: "16px",
        padding: "12px 0px",
        color: theme.palette.text.primary.primary900,
        textAlign: "center",
        '&:first-child': {
            borderTopLeftRadius: '8px',
            borderBottomLeftRadius: '8px',
          },
          '&:last-child': {
            borderTopRightRadius: '8px',
            borderBottomRightRadius: '8px',
          },
          "&.MuiTableCell-root":{
            borderBottom: "none"
          }
    },
    tableCell: {
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "16px",
        padding: "12px",
        color: theme.palette.text.neutral.neutral700,
        textAlign: "center",
    },
    tableContaier: {

    },
    "@media (max-width: 480px)": {
        tableContaier: {
            width: "840px"
        },

    },
}))

const SLITTING_HEADING = [
    "Sno.",
    "Width (MM)",
    "Weight of Coil (MT)",
    "CTW Charges/MT",
    "TotalCharges",
]

const SlittingCustomizationTableTemplate: React.FC<SlittingCustomizationTableTemplateProps> = ({ valueAddedService, setDialogOpen, setEditState }) => {

    const classes = useStyles();

    return (
        <>
            <TableContainer className="w-full">
                <div className='grid gap-y-2'>
                    <div className='flex justify-between'>
                        <div className={`${classes.heading} text-base font-medium my-auto`}>Slitting (CTW)</div>
                        <div className='m-2'>
                            <img src={editIcon} alt="" onClick={(e) => { setDialogOpen(true); setEditState(true) }} />
                        </div>
                    </div>
                    <div className={`overflow-x-auto overflow-hidden w-full`}>
                        <div className={`${classes.tableContaier}`}> 
                            <Table className="w-full" size="small">
                                <TableHead className={classes.tableHead}>
                                    <TableRow>
                                        {SLITTING_HEADING.map((item, index) => (
                                            <TableCell key={index} className={`${classes.tableHeadCell} !py-4 first:pl-3 first:text-left last:pr-3 last:text-right`}>{item}</TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {valueAddedService.map((data: any, index: number) => (
                                        <TableRow key={index} sx={{ 'border-b &:last-child td, &:last-child th': { border: 0 } }}>
                                            <TableCell className={`${classes.tableCell} first:pl-8 first:text-left`}>{index + 1}</TableCell>
                                            <TableCell className={classes.tableCell}>{data.width}</TableCell>
                                            <TableCell className={classes.tableCell}>{data.weightOfCoil}</TableCell>
                                            <TableCell className={classes.tableCell}>{data.chargesPerMT}</TableCell>
                                            <TableCell className={`${classes.tableCell} last:pr-8 last:text-right`}>{data.totalCharges}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </div>
                    </div>
                </div>
            </TableContainer>
        </>
    )
}

export default SlittingCustomizationTableTemplate