import * as React from 'react';
import { useEffect, useState } from 'react';
import * as yup from "yup";
import { createUseStyles } from 'react-jss';
import Button from '../../atoms/Button/Button';
import { useFormik } from 'formik';
import { HTTP_STATUS } from '../../../utils/types';
import { useBusinessProfileService } from '../../../services/useBusinessProfileService';
import { useSnackbar } from '../../../hooks/useSnackBar';
import { useGstinService } from '../../../services/useGstService';
import { createGstAddressLines } from '../../../utils/helper';
import { REGEX } from '../../../utils/constant';
import TextFieldV2 from '../../atoms/Input/TextFieldV2';
import whiteSearchIcon from "../../../assets/icons/whiteSearchIcon.svg"

const useStyles = createUseStyles((theme: any) => ({
    container: {
        border: `1px solid ${theme.palette.border.neutral.neutral100}`,
        borderRadius: "8px",
    },
    primary_container: {
        border: `1px solid ${theme.palette.border.neutral.neutral100}`,
    },
    container_heading: {
        color: theme.palette.text.primary.primary900,
    },
    card_content: {
        color: theme.palette.text.neutral.neutral600,
    },
    search_container: {
        background: theme.palette.background.primary.primary500,
        padding: "12px",
        borderRadius: "12px",
        height: "46px",
        marginTop: "24px"
    },
    borderBottom: {
        borderBottom: `1px solid ${theme.palette.border.neutral.neutral100}`,
    }
}));

export interface GstAddress {
    line1: string;
    line2: string;
    pincode: string;
    city: string;
    state: string;
}
export interface IgstDetails {
    gstin: string;
    pan: string;
    address: GstAddress;
}

interface BussinessGstEditTemplateProps {
    gstformik: any;
    updateBusinessProfileSection: any,
    businessProfile: any,
    onSuccess: () => void;
    onBack: () => void;
}

const BussinessProfileGSTEditTemplate: React.FC<BussinessGstEditTemplateProps> = ({ updateBusinessProfileSection, businessProfile, onSuccess, onBack }) => {
    const classes = useStyles();
    const bussinessProfileService = useBusinessProfileService();
    const gstinService = useGstinService();
    const [gstDetails, setgstDetailsTo] = useState<IgstDetails>();
    const { showSnackbar } = useSnackbar();

    const gstSchema = yup.object().shape({
        gstin: yup.string().required('GST is required').matches(REGEX.GSTIN, 'Enter valid GST'),
        pan: yup.string(),
        address: yup.object().shape({
            line1: yup.string().required('Address is required'),
            pincode: yup.string().required('Pincode is required'),
            city: yup.string().required('City is required'),
            state: yup.string().required('State is required'),
        })
    });

    const getPANFromGSTIn = (gstin: string) => {
        if (gstin.length >= 15) {
            return gstin.substring(2, gstin.length - 3);
        }
        return gstin;
    };

    const gstformik = useFormik<IgstDetails>({
        initialValues: {
            gstin: businessProfile?.gstin || '',
            pan: getPANFromGSTIn(businessProfile?.gstin),
            address: {
                line1: businessProfile?.address?.line1 || '',
                line2: businessProfile?.address?.line2 || '',
                pincode: businessProfile?.address?.pincode || '',
                city: businessProfile?.address?.city || '',
                state: businessProfile?.address?.state || '',
            }
        },
        validationSchema: gstSchema,
        validateOnChange: true,
        validateOnBlur: true,
        onSubmit: async (values: any) => {
            try {
                gstformik.setSubmitting(true);
                const requestBody = {
                    gstin: values?.gstin,
                    pan: values?.pan,
                }
                const businessProfile = await bussinessProfileService.updateBusinessProfile(requestBody, "GST");

                showSnackbar("success", "Business profile updated successfully");
                onBack();
            } catch (error) {
                showSnackbar("error", `Business profile update failed: ${error}`);
            } finally {
                gstformik.setSubmitting(false);
            }
        },
    });

    useEffect(() => {
        getBusinessProfile();
    }, []);

    const handleUpdateDetails = (field: string, value: any) => {
        gstformik.setFieldValue(field, value);
    };

    const handleGstChange = (value: string) => {
        getGstin(value);
    };

    const getBusinessProfile = async () => {
        try {
            const businessProfileResponse = await bussinessProfileService.getBusinessProfile();
            if (businessProfileResponse.status === HTTP_STATUS.OK) {
                const profileData = businessProfileResponse?.data?.data;
                setgstDetailsTo(profileData);
                gstformik.setValues((prev) => ({
                    ...prev,
                    gstin: profileData?.gstin || '',
                    pan: getPANFromGSTIn(profileData?.gstin),
                    address: {
                        line1: profileData.address.line1,
                        line2: profileData.address.line2,
                        pincode: profileData.address.pincode,
                        city: profileData.address.city,
                        state: profileData.address.state,
                    }
                }));
            }
        } catch (error) {
            showSnackbar('error', 'Business Profile fetch failed');
        }
    };

    const getGstin = async (gstin: string) => {
        try {
            const payload = {
                "gstin": gstin
            }
            const gstDetailsResponse = await gstinService.getGstin(payload);
            if (gstDetailsResponse.status === HTTP_STATUS.OK) {
                const details = gstDetailsResponse?.data?.data;
                const gstSplitAddress = createGstAddressLines(details);
                gstformik.setFieldValue("address.line1", gstSplitAddress[0]);
                gstformik.setFieldValue("address.line2", gstSplitAddress[1]);
                gstformik.setFieldValue("address.pincode", details.pincode);
                gstformik.setFieldValue("address.city", details.city[0]);
                gstformik.setFieldValue("address.state", details.state[0][0]);
            }
        } catch (error) {
            showSnackbar('error', 'Failed to fetch GST details');
        }
    };

    return (
        <form onSubmit={gstformik.handleSubmit}>
            <div className={`${classes.container} p-4 grid gap-y-6 rounded-xl w-full`}>
                <div className={`flex justify-between items-center pb-6 ${classes.borderBottom}`}>
                    <h2 className={`${classes.container_heading} text-2xl font-medium`}>GST Details</h2>
                    <div className='flex gap-4'>
                        <Button variant={'tertiaryContained'} label={'Back'} type="button" onClick={onBack} />
                        <Button variant={'primaryContained'} label={'Save'} type="submit" />
                    </div>
                </div>
                <div className='flex flex-col gap-y-6'>
                    <div className='grid grid-cols-2 gap-x-6'>
                        <div className={`flex gap-x-6 rounded-lg`}>
                            <div className='flex-1'>
                                <TextFieldV2
                                    label="GST Registered Number"
                                    fullWidth
                                    {...gstformik.getFieldProps("gstin")}
                                    error={gstformik.touched.gstin && Boolean(gstformik.errors.gstin)}
                                    required
                                    helperText={gstformik.touched.gstin && gstformik.errors.gstin ? gstformik.errors.gstin : ''}
                                    inputProps={{ readOnly: false }}
                                />
                            </div>
                            <div className={`${classes.search_container}`} onClick={() => handleGstChange(gstformik?.values?.gstin)}>
                                <img src={whiteSearchIcon} alt="whiteSearchIcon" />
                            </div>
                        </div>

                        <TextFieldV2
                            label="PAN"
                            fullWidth
                            {...gstformik.getFieldProps("pan")}
                            error={gstformik.touched.pan && Boolean(gstformik.errors.pan)}
                            inputProps={{ readOnly: true }}
                        // required
                        // helperText={gstformik.touched.pan && gstformik.errors.pan ? gstformik.errors.pan : ''}
                        />
                    </div>

                    <div className={`${classes.primary_container} p-4 grid gap-y-6 rounded-xl`}>
                        <p className={`${classes.container_heading} font-medium text-base`}>Principal Place of Business</p>
                        <div className='grid gap-y-6'>
                            <TextFieldV2
                                label="Address Line 1"
                                fullWidth
                                {...gstformik.getFieldProps("address.line1")}
                                error={gstformik.touched.address?.line1 && Boolean(gstformik.errors.address?.line1)}
                                required
                                helperText={gstformik.touched.address?.line1 && gstformik.errors.address?.line1 ? gstformik.errors.address?.line1 : ''}
                                inputProps={{ readOnly: true }}
                            />
                            <TextFieldV2
                                label="Address Line 2"
                                fullWidth
                                {...gstformik.getFieldProps("address.line2")}
                                error={gstformik.touched.address?.line2 && Boolean(gstformik.errors.address?.line2)}
                                required
                                helperText={gstformik.touched.address?.line2 && gstformik.errors.address?.line2 ? gstformik.errors.address?.line2 : ''}
                                inputProps={{ readOnly: true }}
                            />
                            <div className='grid grid-cols-3 gap-x-6'>
                                <TextFieldV2
                                    label="Pincode"
                                    fullWidth
                                    {...gstformik.getFieldProps("address.pincode")}
                                    error={gstformik.touched.address?.pincode && Boolean(gstformik.errors.address?.pincode)}
                                    required
                                    helperText={gstformik.touched.address?.pincode && gstformik.errors.address?.pincode ? gstformik.errors.address?.pincode : ''}
                                    inputProps={{ readOnly: true }}
                                />
                                <TextFieldV2
                                    label="City"
                                    fullWidth
                                    {...gstformik.getFieldProps("address.city")}
                                    error={gstformik.touched.address?.city && Boolean(gstformik.errors.address?.city)}
                                    required
                                    helperText={gstformik.touched.address?.city && gstformik.errors.address?.city ? gstformik.errors.address?.city : ''}
                                    inputProps={{ readOnly: true }}
                                />
                                <TextFieldV2
                                    label="State"
                                    fullWidth
                                    {...gstformik.getFieldProps("address.state")}
                                    error={gstformik.touched.address?.state && Boolean(gstformik.errors.address?.state)}
                                    required
                                    helperText={gstformik.touched.address?.state && gstformik.errors.address?.state ? gstformik.errors.address?.state : ''}
                                    inputProps={{ readOnly: true }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    );
};

export default BussinessProfileGSTEditTemplate;