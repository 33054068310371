import React from 'react';
import { createUseStyles } from 'react-jss';
import { Link } from 'react-router-dom';

interface SocialMediaLink {
    key: string;
    link: string;
    logo: string;
}

interface SocialMediaLinksProps {
    schema: SocialMediaLink[];
    className?: string;
}

const useStyles = createUseStyles((theme: any) => ({
    icon: {
        color: theme.palette.text.primary.primary600,
        width: "24px",
        height: "24px",
        border: `1px solid ${theme.palette.border.primary.primary200}`
    },
}));

const SocialMediaLinks: React.FC<SocialMediaLinksProps> = ({ schema, className }) => {
    const classes = useStyles();
    return (
        <div className={`flex gap-x-2  ${className}`}>
            {schema.map((item) => (
                <Link key={item.key} to={item.link} target="_blank" rel="noopener noreferrer">
                    <div className={`${classes.icon} rounded-full cursor-pointer flex justify-center content-center`}>
                        <img src={item.logo} alt={item.key} className={`m-1.5`} />
                    </div>
                </Link>
            ))}
        </div>
    );
};

export default SocialMediaLinks;