import { request } from ".";
import { useAuthenticatedUser } from "../hooks/useAuthenticatedUser";
import { API_METHOD } from "../utils/constant";
import { replaceUrlParams } from "../utils/helper";

interface ICatalogueParams {
  page: number;
  size: number;
  sort?: string;
  name?: string;
  productType?:string
}
export interface ICatalogueAttributes {
  minValue: string | null;
  maxValue: string | null;
  values: string[] | number[] | null;
}

export interface ICatalogueRequestBody {
  name?: string;
  standardIds?: number[] | null;
  brandIds?: number[] | null;
  gradeIds?: number[] | null;
  manufacturerIds?: number[] | null;
  classes?: string[] | null;
  catalogueTypes?: string[] | null;
  shapes?: string[] | null;
  categoryId?: number | string | null;
  attributes?: { [key: string]: ICatalogueAttributes;}
  catalogueIds?: number[] | null;
  secondaryCatalogueIds?: number[] | null;
}

export const CATALOGUE_URLS = {
  GET_ALL_UPCS_BY_CATALOGUE_ID: "/catalogue/:catalogueId/upcs",
  GET_CATALOGUE_BY_ID: "/catalogue/:catalogueId",
  GET_ALL_CATALOGUE: "/catalogues",
  GET_VENDOR_CATALOGUE: "/vendor/:vendorId/catalogues",
  GET_VENDOR_CATALOGUE_BY_ID: "/vendors/:vendorId/catalogue/:catalogueId",
  GET_SECONDARY_CATALOGUE_BY_ID: "/secondary-catalogue/:secondaryCatalogueId",
  GET_SECONDARY_LOTS_CATALOUGE: "/secondary-products/:catalogueId",
  GET_SECONDARY_LOTS_CATALOUGE_BY_ID: "/secondarylots-catalogue/:secondaryCatalogueId"
};

export const useCatalogueService = () => {
  const { user: authenticatedUser } = useAuthenticatedUser();
  const v3BaseUrl=process.env.REACT_APP_API_V3_URL

  const getAllUpcsByCatalogueId = (catalogueId: number) => {
    return request(API_METHOD.GET, replaceUrlParams(CATALOGUE_URLS.GET_ALL_UPCS_BY_CATALOGUE_ID, { catalogueId } ), authenticatedUser );
  };

  const getCatalogueById = (catalogueId: number) => {
    return request(API_METHOD.GET, replaceUrlParams(CATALOGUE_URLS.GET_CATALOGUE_BY_ID, { catalogueId } ), authenticatedUser );
  };

  const getAllCatalogues = (params: ICatalogueParams, data: ICatalogueRequestBody) => {
    return request(API_METHOD.POST, CATALOGUE_URLS.GET_ALL_CATALOGUE, authenticatedUser, data, { params } );
  };

  const getVendorCatalogues = (vendorId:number|undefined, params: ICatalogueParams, data: ICatalogueRequestBody) => {
    return request(API_METHOD.POST, replaceUrlParams(CATALOGUE_URLS.GET_VENDOR_CATALOGUE, {vendorId} ), authenticatedUser, data, { params } );
  };

  const getVendorCatalogueById = (vendorId:number|undefined, catalogueId: number) => {
    return request(API_METHOD.GET, replaceUrlParams(CATALOGUE_URLS.GET_VENDOR_CATALOGUE_BY_ID, {vendorId, catalogueId} ), authenticatedUser, null, null, null, v3BaseUrl );
  };

  const getSecondaryCatalogueById = (secondaryCatalogueId: number) => {
    return request(API_METHOD.GET, replaceUrlParams(CATALOGUE_URLS.GET_SECONDARY_CATALOGUE_BY_ID, { secondaryCatalogueId }), authenticatedUser);
  }

  const getSecondaryProductsByCatalogueId = (catalogueId: number) => {
    return request(API_METHOD.GET, replaceUrlParams(CATALOGUE_URLS.GET_SECONDARY_LOTS_CATALOUGE, { catalogueId }), authenticatedUser);
  }

  const getSecondaryLotsCatalogueById = (secondaryCatalogueId: number) => {
    return request(API_METHOD.GET, replaceUrlParams(CATALOGUE_URLS.GET_SECONDARY_LOTS_CATALOUGE_BY_ID, { secondaryCatalogueId }), authenticatedUser);
  }

  return {
    getAllUpcsByCatalogueId,
    getCatalogueById,
    getAllCatalogues,
    getSecondaryCatalogueById,
    getSecondaryProductsByCatalogueId,
    getSecondaryLotsCatalogueById,
    getVendorCatalogues,
    getVendorCatalogueById
  };
};
