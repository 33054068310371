import React from "react";
import { Route, Routes } from "react-router-dom";
import { Construction } from "@mui/icons-material";
import PrivacyPolicy from "../Policies/PrivacyPolicy.page";
import SellOnSteelBazaarTemplate from "./SellOnSteelBazaar";
import SteelBazaarServices from "./SteelBazaarServices";
import ContactUsTemplate from "./ContactUs";
import SupportCareTemplate from "./SupportCare.template";
import TermsAndConditions from "../Policies/TermsAndConditions.page";
import AboutUsTemplate from "../AboutUs/AboutUs";
import BuyOnSteelBazaar from "./BuyOnSteelBazaar";
import HelpCenter from "./HelpCenter";
import TradeAssurance from "./TradeAssurance";
import RequestForQuotationPage from "../../template/Home/RequestForQuotation.page";

const StaticPagesRoutes: React.FunctionComponent = () => {
    return (
        <Routes>
            <Route path={"/buy-on-steelbazaar"} element={<BuyOnSteelBazaar />} />
            <Route path={"/sell-on-steelbazaar"} element={<SellOnSteelBazaarTemplate />} />
            <Route path={"/about-us"} element={<AboutUsTemplate />} />
            <Route path={"/services"} element={<SteelBazaarServices />} />
            <Route path={"/contact-us"} element={<ContactUsTemplate />} />
            <Route path={"/support-and-care"} element={<SupportCareTemplate />} />
            <Route path={"/help-center"} element={<HelpCenter />} />
            <Route path={"/trade-assurance"} element={<TradeAssurance />} />
            <Route path={"/privacy-policy"} element={<PrivacyPolicy />} />
            <Route path={"/terms-and-conditions"} element={<TermsAndConditions />} />
            <Route path={"/rfq"} element={<RequestForQuotationPage />} />

        </Routes>
    );
};

export default StaticPagesRoutes;