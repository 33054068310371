import React, { useState } from 'react'
import { PocSectionProps } from './KeyPersonDetails.template'
import { createUseStyles } from 'react-jss';
import { FormikErrors } from 'formik';
import Button from '../../../atoms/Button/Button';
import { HTTP_STATUS, KEY_PERSON_DETAIL_STATES, WHATSAPP_LINK } from '../../../../utils/types';
import ImageUploadModalTemplate from '../../OnBoarding/ImageUploadModal.template';
import { useFileService } from '../../../../services/useFileService';
import { DOCUMENT_RESOURCE_TYPE, PREFIX, SOCIAL_LINKS } from '../../../../utils/constant';
import { useSnackbar } from '../../../../hooks/useSnackBar';
import noProfileImage from '../../../../assets/images/noProfileImage.svg';
import TextFieldV2 from '../../../atoms/Input/TextFieldV2';
import SelectV2 from '../../../atoms/Select/SelectV2';
import Checkbox from '@mui/material/Checkbox';
import deleteOutlinedPrimary800 from '../../../../assets/icons/deleteOutlinedPrimary800.svg';
import addIconOutlinedPrimary50 from "../../../../assets/icons/addIconOutlinedPrimary50.svg"
import { useParams } from 'react-router-dom';
import { IMultiSelectOption } from '../../../atoms/FilterChip/FilterChip';
import { IKeyDetail, ISocialMediaLink } from '../../../pages/DashBoard/KeyPersonDetails.page';
import MultiSelectInput2 from '../../../atoms/MultiSelectInput/MultiSelectInput2';
import { InputAdornment } from '@mui/material';

export interface Link {
    type: string;
    url: string;
};

const useStyles = createUseStyles((theme: any) => ({

    container: {
        border: `1px solid ${theme.palette.border.neutral.neutral100}`
    },
    imageContainer: {
        '&:hover $editButton': {
            opacity: 1,
            color: 'blue',
        },
    },
    editButton: {
        background: theme.palette.background.primary.primary800,
        opacity: 0,
        transition: 'opacity 0.3s ease',
        padding: "10px",
        gap: "8px",
        borderRadius: "6px",
        zIndex:1
    },
    heading: {
        color: theme.palette.text.primary.primary900
    },
    imageHeading: {
        color: theme.palette.text.neutral.neutral700
    },
    text: {
        color: theme.palette.text.neutral.neutral700
    },
    checkedInputColor: {
        color: theme.palette.text.primary.primary400,
        '&.Mui-checked': {
            color: theme.palette.text.primary.primary500,
        },
    },
    select: {
        width: '384px !important'
    },
    title: {
        color: theme.palette.text.neutral.neutral700,
    },
    social: {
        color: theme.palette.text.neutral.neutral700
    },
    deleteButton: {
        border: `1px solid ${theme.palette.border.primary.primary800}`
    },
    borderBottom: {
        borderBottom: `1px solid ${theme.palette.border.neutral.neutral100}`,
    },
    mobile: {
        "& .MuiTypography-root": {
            color: theme.palette.text.neutral.neutral400
        }
    },
}));

const AddNewKeyPersonDetilsTemplate: React.FC<PocSectionProps> = ({ formik, setCurrentSectionTo }) => {

    const classes = useStyles();
    const fileService = useFileService();
    const { showSnackbar, SnackBarComponent } = useSnackbar();

    const params = useParams();
    const id = Number(params.id);

    const [isImageModalOpen, setIsImageModalOpen] = useState<boolean>(false);
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [selectedSocialLinks, setSelectedSocialLinks] = useState<{ [index: number]: Link[] }>({});
    const [currentIndex, setCurrentIndex] = useState<number | null>(null);
    const [errors, setErrors] = useState<{ [key: number]: { [key: string]: string } }>({});

    const handleImageModalOpen = (index: number) => {
        setCurrentIndex(index);
        setIsImageModalOpen(true);
    };

    const handleImageModalClose = () => {
        setIsImageModalOpen(false);
    };

    const handleMultiSelectChange =
        (index: number) => (selectedOptions: IMultiSelectOption[]) => {
            const updatedSocialLinks: ISocialMediaLink = {};
            selectedOptions.forEach((option) => {
                updatedSocialLinks[option.value] = '';
            });
            formik.setFieldValue(`newPocs.${index}.socialLinks`, updatedSocialLinks);
        };

    const handleTextFieldChange =
        (key: string, index: number) => (event: any) => {
            const value = event.target.value;
            formik.setFieldValue(`newPocs.${index}.socialLinks.${key}`, value);

        };

    const handleWhatsAppLinkChange = (value: any, index: number) => {
        const currentValue = formik.values.newPocs[index].whatsAppLink;
        formik.setFieldValue(`newPocs.${index}.whatsAppLink`, currentValue === value ? null : value);
    };


    const addNewForm = () => {
        const emptyPoc = { name: '', email: '', mobile: '', department: '', designation: '', profilePic: '', countryCode: '+91', socialLinks: {}, isAuthorized: false, alternateEmail: '', alternateMobile: '', prefix: '', modeOfCommunication: '', whatsAppLink: '' };
        formik.setFieldValue('newPocs', [...formik.values.newPocs, emptyPoc]);
    }

    const validatePoc = (pocs: IKeyDetail[], newPocs: IKeyDetail[], type: string) => (event: any) => async (index: number) => {
        const { name, value } = event.target;
        const nameField = name.split(".")[0];
        formik.setFieldValue(`${nameField}[${index}].${type}`, value);

        const field = name.split('.').pop();
        if (!field) return;

        const currentErrors = { ...errors };
        currentErrors[index] = currentErrors[index] || {};

        let errorMessage = '';
        const fieldsToCheck: (keyof IKeyDetail)[] = ['email', 'alternateEmail', 'mobile', 'alternateMobile'];

        if (!value) {
            currentErrors[index][type] = '';
            setErrors(currentErrors);
            formik.setFieldTouched(`newPocs[${index}].${type}`, false, false);
            return;
        }

        if (pocs.some((poc: any) => poc[field] === value) || newPocs.some((poc: any, idx: number) => idx !== index && poc[field] === value)) {
            errorMessage = `${field.charAt(0).toUpperCase() + field.slice(1)} already exists`;
        }
        for (let checkField of fieldsToCheck) {
            if (checkField !== field && newPocs[index]?.[checkField] === value) {
                errorMessage = `${field.charAt(0).toUpperCase() + field.slice(1)} cannot be the same as ${checkField.charAt(0).toUpperCase() + checkField.slice(1)}`;
            }
        }

        currentErrors[index][type] = errorMessage;
        setErrors(currentErrors);

        if (errorMessage) {
            formik.setFieldValue(`newPocs[${index}].${type}`, null);
            formik.setFieldTouched(`newPocs[${index}].${type}`, true, false);
        }
    };

    const isFormValid = () => {
        if (!formik.values.newPocs || !Array.isArray(formik.values.newPocs)) {
            return false;
        }

        return formik.values.newPocs.every((poc: any, index: number) => {
            const pocErrors = formik.errors.newPocs?.[index];
            if (typeof pocErrors === 'string') {
                return false;
            }
            const hasValidFields = Object.keys(poc).every(key => {
                const keyTyped = key as keyof IKeyDetail;
                return !(pocErrors && pocErrors[keyTyped]);
            });
            return hasValidFields;
        });
    };

    const handleSave = () => {
        if (isFormValid()) {
            formik.handleSubmit();
            setCurrentSectionTo(KEY_PERSON_DETAIL_STATES.VIEW_KEY_PERSON_DETAILS)
        }
        else {
            showSnackbar("error", "Please resolve all validation errors before submitting.");
        }
    };

    const handleSubmit = async (event: any) => {
        try {
            if (selectedFile) {
                const presignedUrlResponse = await fileService.generatePresignedUrl(selectedFile.name, DOCUMENT_RESOURCE_TYPE.BUSINESS_PROFILE_KEY_PERSON_DETAILS, 1);
                if (presignedUrlResponse && presignedUrlResponse.data && presignedUrlResponse.data.data && presignedUrlResponse.data.data.presignedUrl) {
                    const presignedUrl = presignedUrlResponse.data.data.presignedUrl;
                    const imageUploadResponse = await fileService.uploadImageUsingPreSignedUrls(selectedFile, presignedUrl);
                    const pocProfilePicUrl = await fileService.getImagePreSignedUrl(presignedUrlResponse.data.data.objectKey);
                    if (pocProfilePicUrl.status === HTTP_STATUS.OK) {
                        formik.setFieldValue(`newPocs[${currentIndex}].profilePic`, pocProfilePicUrl?.data?.data);
                    }
                } else {
                    showSnackbar("error", 'Failed to generate presigned URL')
                }
            }
        } catch (error) {
            showSnackbar("error", 'File upload failed:')

        } finally {
            setIsImageModalOpen(false);
            setSelectedFile(null);
        }
    };

    const deletePoc = async (index: number) => {
        const newPocs = [...formik.values.newPocs];
        newPocs.splice(index, 1);
        formik.setFieldValue('newPocs', newPocs);
    };

    const handleBackToViewPage = () => {
        formik.setFieldValue('newPocs', []);
        setCurrentSectionTo(KEY_PERSON_DETAIL_STATES.VIEW_KEY_PERSON_DETAILS);
    };

    return (
        <form>
            {isImageModalOpen &&
                <ImageUploadModalTemplate
                    dialogOpen={isImageModalOpen}
                    setDialogOpen={handleImageModalClose}
                    onSubmit={(e: any) => handleSubmit(e)}
                    selectedFile={selectedFile}
                    setSelectedFile={setSelectedFile}
                />}
            {formik.values?.newPocs?.map((poc: any, index: number) => (
                <div key={index} className={`${classes.container} p-4 grid gap-y-6 mb-4 rounded-xl`}>
                    <div className={`${classes.borderBottom} pb-6 flex justify-between`}>
                        <div className={`text-lg font-medium my-auto ${classes.heading}`}>Key Person Details {index + 1}</div>
                        <div className="flex items-center gap-x-8 ">
                            <div>
                                <Checkbox
                                    id={`isAuthorized-${index}`}
                                    name={`poc[${index}].isAuthorized`}
                                    className={` ${classes.checkedInputColor}`}
                                    checked={poc.isAuthorized}
                                    onChange={(e) => formik.setFieldValue(`poc[${index}].isAuthorized`, e.target.checked)} />
                                <label className='text-base my-auto'>Authorized Person</label>
                            </div>

                            <div className={`p-3 ${classes.deleteButton} rounded-xl`}>
                                <img src={deleteOutlinedPrimary800} alt="deleteOutlinedPrimary800" className="blink-on-hover blink-on-click" onClick={() => deletePoc(index)} />
                            </div>
                        </div>
                    </div>
                    <div className='grid gap-y-6'>
                        <span className={`text-base font-medium ${classes.imageHeading}`}>Key Person Images</span>

                        <div className='flex gap-x-6'>
                            <div className={`${classes.imageContainer} rounded-lg w-[140px] h-[160px] flex relative justify-between`}>
                                <div>
                                    <img src={poc?.profilePic || noProfileImage} alt="Profile" className="w-full h-full object-cover" />
                                </div>
                                <img src={addIconOutlinedPrimary50} alt="Profile" className={`${classes.editButton} cursor-pointer absolute top-2 right-2 z-20`} onClick={() => handleImageModalOpen(index)} />
                            </div>

                            <div className='grid gap-y-5 flex-1'>
                                <div className={`${classes.borderBottom} flex flex-col gap-y-3 pb-5`}>
                                    <div className='flex gap-x-3'>
                                        <div className='grid gap-y-1 w-[20%]'>
                                            <SelectV2
                                                {...formik.getFieldProps(`newPocs.${index}.prefix`)}
                                                variant="outlined"
                                                label="Prefix"
                                                value={formik.values.newPocs[index]?.prefix}
                                                id="sort"
                                                options={PREFIX}
                                                placeholder='Select Prefix'
                                                fullWidth
                                                />
                                        </div>
                                        <div className='grid gap-y-1 w-[80%]'>
                                            <TextFieldV2
                                                label="Name"
                                                placeholder="Enter Name"
                                                variant="outlined"
                                                fullWidth
                                                {...formik.getFieldProps(`newPocs.${index}.name`)}
                                                error={
                                                    formik.touched.newPocs?.[index]?.name &&
                                                    Boolean(
                                                        formik.errors.newPocs &&
                                                        Array.isArray(formik.errors.newPocs) &&
                                                        (formik.errors.newPocs as FormikErrors<IKeyDetail>[])[index]?.name
                                                    )
                                                }
                                                helperText={
                                                    formik.touched.newPocs?.[index]?.name &&
                                                    formik.errors.newPocs &&
                                                    Array.isArray(formik.errors.newPocs) &&
                                                    (formik.errors.newPocs as FormikErrors<IKeyDetail>[])[index]?.name
                                                }
                                                />
                                        </div>
                                    </div>
                                    <div className='flex gap-x-3'>
                                        <div className='grid gap-y-1 w-[50%]'>
                                            <TextFieldV2
                                                label="Department"
                                                placeholder="Enter Department"
                                                variant="outlined"
                                                {...formik.getFieldProps(`newPocs.${index}.department`)}
                                                error={
                                                    formik.touched.newPocs?.[index]?.department &&
                                                    Boolean(
                                                        formik.errors.newPocs &&
                                                        Array.isArray(formik.errors.newPocs) &&
                                                        (formik.errors.newPocs as FormikErrors<IKeyDetail>[])[index]?.department
                                                    )
                                                }
                                                helperText={
                                                    formik.touched.newPocs?.[index]?.department &&
                                                    formik.errors.newPocs &&
                                                    Array.isArray(formik.errors.newPocs) &&
                                                    (formik.errors.newPocs as FormikErrors<IKeyDetail>[])[index]?.department
                                                }
                                                />
                                        </div>
                                        <div className='grid gap-y-1 w-[50%]'>
                                            <TextFieldV2
                                                label="Designation"
                                                placeholder="Enter Designation"
                                                variant="outlined"
                                                {...formik.getFieldProps(`newPocs.${index}.designation`)}
                                                error={
                                                    formik.touched.newPocs?.[index]?.designation &&
                                                    Boolean(
                                                        formik.errors.newPocs &&
                                                        Array.isArray(formik.errors.newPocs) &&
                                                        (formik.errors.newPocs as FormikErrors<IKeyDetail>[])[index]?.designation
                                                    )
                                                }
                                                helperText={
                                                    formik.touched.newPocs?.[index]?.designation &&
                                                    formik.errors.newPocs &&
                                                    Array.isArray(formik.errors.newPocs) &&
                                                    (formik.errors.newPocs as FormikErrors<IKeyDetail>[])[index]?.designation
                                                }
                                                />
                                        </div>
                                    </div>
                                </div>

                                <div className={`flex flex-col gap-y-3`}>
                                    <div className='flex gap-x-3'>
                                        <div className='grid w-[50%]'>
                                            <TextFieldV2
                                                label="Email ID"
                                                placeholder="Enter email"
                                                variant="outlined"
                                                {...formik.getFieldProps(`newPocs.${index}.email`)}
                                                error={
                                                    formik.touched.newPocs?.[index]?.email &&
                                                    Boolean(
                                                        formik.errors.newPocs &&
                                                        Array.isArray(formik.errors.newPocs) &&
                                                        (formik.errors.newPocs as FormikErrors<IKeyDetail>[])[index]?.email
                                                    )
                                                }
                                                helperText={
                                                    errors[index]?.email || (
                                                        formik.touched.newPocs?.[index]?.email &&
                                                        formik.errors.newPocs &&
                                                        Array.isArray(formik.errors.newPocs) &&
                                                        (formik.errors.newPocs as FormikErrors<IKeyDetail>[])[index]?.email
                                                    )
                                                }
                                                onChange={(event) => validatePoc(formik.values.pocs, formik.values.newPocs, 'email')(event)(index)}
                                            />
                                        </div>
                                        <div className='grid w-[50%]'>
                                            <TextFieldV2
                                                label="Alternate Email ID"
                                                placeholder="Enter email"
                                                variant="outlined"
                                                {...formik.getFieldProps(`newPocs.${index}.alternateEmail`)}
                                                error={
                                                    formik.touched.newPocs?.[index]?.alternateEmail &&
                                                    Boolean(
                                                        formik.errors.newPocs &&
                                                        Array.isArray(formik.errors.newPocs) &&
                                                        (formik.errors.newPocs as FormikErrors<IKeyDetail>[])[index]?.alternateEmail
                                                    )
                                                }
                                                helperText={
                                                    errors[index]?.alternateEmail || (
                                                        formik.touched.newPocs?.[index]?.alternateEmail &&
                                                        formik.errors.newPocs &&
                                                        Array.isArray(formik.errors.newPocs) &&
                                                        (formik.errors.newPocs as FormikErrors<IKeyDetail>[])[index]?.alternateEmail
                                                    )
                                                }
                                                onChange={(event) => validatePoc(formik.values.pocs, formik.values.newPocs, 'alternateEmail')(event)(index)}
                                            />
                                        </div>
                                    </div>
                                    <div className='flex gap-x-3'>
                                        <div className='flex items-end gap-x-2 w-[50%]'>
                                            <TextFieldV2
                                                label="Phone Number"
                                                placeholder="Enter number"
                                                variant="outlined"
                                                {...formik.getFieldProps(`newPocs.${index}.mobile`,)}
                                                error={
                                                    formik.touched.newPocs?.[index]?.mobile &&
                                                    Boolean(
                                                        formik.errors.newPocs &&
                                                        Array.isArray(formik.errors.newPocs) &&
                                                        (formik.errors.newPocs as FormikErrors<IKeyDetail>[])[index]?.mobile
                                                    )
                                                }
                                                inputProps={{
                                                    maxLength: 10,
                                                }}
                                                helperText={
                                                    errors[index]?.mobile || (
                                                        formik.touched.newPocs?.[index]?.mobile &&
                                                        formik.errors.newPocs &&
                                                        Array.isArray(formik.errors.newPocs) &&
                                                        (formik.errors.newPocs as FormikErrors<IKeyDetail>[])[index]?.mobile
                                                    )
                                                }
                                                onChange={(event) => validatePoc(formik.values.pocs, formik.values.newPocs, 'mobile')(event)(index)}
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start" className={`${classes.mobile} text-base font-normal`}>+91</InputAdornment>,
                                                }}
                                                />
                                            <label className={`${classes.text} text-sm w-[50%]`}>
                                                <Checkbox
                                                    sx={{ padding: 0, width: "18px", height: "18px", marginRight: "8px" }}
                                                    checked={formik.values.newPocs[index].whatsAppLink === WHATSAPP_LINK.PRIMARY}
                                                    onChange={() => handleWhatsAppLinkChange(WHATSAPP_LINK.PRIMARY, index)}
                                                    id={`mobile-${index}`}
                                                    className={`${classes.checkedInputColor}`}
                                                    />
                                                Available On Whatsapp</label>
                                        </div>
                                        <div className='flex gap-x-2 items-end w-[50%]'>
                                            <TextFieldV2
                                                label="Alternate Phone Number"
                                                placeholder="Enter number"
                                                variant="outlined"
                                                {...formik.getFieldProps(`newPocs.${index}.alternateMobile`)}
                                                error={
                                                    formik.touched.newPocs?.[index]?.alternateMobile &&
                                                    Boolean(
                                                        formik.errors.newPocs &&
                                                        Array.isArray(formik.errors.newPocs) &&
                                                        (formik.errors.newPocs as FormikErrors<IKeyDetail>[])[index]?.alternateMobile
                                                    )
                                                }
                                                inputProps={{
                                                    maxLength: 10,
                                                }}
                                                helperText={
                                                    errors[index]?.alternateMobile || (
                                                        formik.touched.newPocs?.[index]?.alternateMobile &&
                                                        formik.errors.newPocs &&
                                                        Array.isArray(formik.errors.newPocs) &&
                                                        (formik.errors.newPocs as FormikErrors<IKeyDetail>[])[index]?.alternateMobile
                                                    )
                                                }
                                                onChange={(event) => validatePoc(formik.values.pocs, formik.values.newPocs, 'alternateMobile')(event)(index)}
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start" className={`${classes.mobile} text-base font-normal`}>+91</InputAdornment>,
                                                }}
                                                />

                                            <label className={`${classes.text} text-sm w-[50%]`}>
                                                <Checkbox
                                                    sx={{ padding: 0, width: "18px", height: "18px", marginRight: "8px" }}
                                                    checked={formik.values.newPocs[index].whatsAppLink === WHATSAPP_LINK.SECONDARY}
                                                    onChange={() => handleWhatsAppLinkChange(WHATSAPP_LINK.SECONDARY, index)}
                                                    id={`alternateMobile-${index}`}
                                                    className={` ${classes.checkedInputColor}`}
                                                    />
                                                Available On Whatsapp</label>
                                        </div>
                                    </div>


                                </div>

                            </div>
                        </div>
                    </div>


                    <div className={`grid gap-y-1`}>
                        <div className={classes.select}>
                            <MultiSelectInput2
                                options={SOCIAL_LINKS.map((link) => ({
                                    label: link.label,
                                    value: link.label,
                                }))}
                                label="Social Media Links"
                                value={Object.keys(poc.socialLinks).map((value) => ({
                                    label: value,
                                    value: value,
                                }))}
                                onchange={handleMultiSelectChange(index)}
                                placeholder='Select Social Media Links'
                            />
                        </div>
                        {Object.keys(formik.values.newPocs[index].socialLinks).map((socialTag) => (
                            <TextFieldV2
                                {...formik.getFieldProps(`newPocs[${index}].socialLinks[${socialTag}]`)}
                                variant="outlined"
                                label={
                                    <span className='flex gap-1 text-base font-medium !mt-5 !mb-2'>
                                        <span className={classes.title}>Enter Link </span>
                                        <span className={classes.social}>({socialTag})</span>
                                    </span>
                                }
                                value={formik.values.newPocs[index].socialLinks.socialTag}
                                onChange={handleTextFieldChange(socialTag, index)}
                                error={
                                    formik.touched.newPocs?.[index]?.socialLinks?.[socialTag] &&
                                    ((formik.errors.newPocs as any)?.[index]?.socialLinks?.[socialTag])
                                }
                                helperText={
                                    Boolean(formik.touched.newPocs?.[index]?.socialLinks) &&
                                    ((formik.errors.newPocs as any)?.[index]?.socialLinks?.[socialTag])
                                }
                            />
                        )
                        )}
                    </div>
                </div>
            ))}
            <div className='flex justify-end gap-x-3'>
                <Button
                    variant="tertiaryContained"
                    label="Back"
                    onClick={handleBackToViewPage}
                />
                <Button
                    variant="tertiaryContained"
                    label="Add New Key Person"
                    onClick={addNewForm}
                />
                <Button
                    variant="primaryContained"
                    label="Add"
                    onClick={handleSave}
                    disabled={!isFormValid()}
                />
            </div>
        </form>
    )
}
export default AddNewKeyPersonDetilsTemplate