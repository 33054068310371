import React, { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import Button from "../../atoms/Button/Button";
import ProductViewTemplate from "../DashBoard/Inventory Management/ProductView.template";
import TabsV2, { ITabsSchema } from "../../molecules/TabsV2/TabsV2";
import { IVendorProductRequestBodyDTO, usePostProductService } from "../../../services/usePostProductService";
import { IProductData } from "../DashBoard/Inventory Management/SelectProduct.template";
import { useSnackbar } from "../../../hooks/useSnackBar";
import { useNavigate, useParams } from "react-router-dom";
import DeliveryInformationTemplate from "./DeliveryInformation.template";
import { useFormik } from "formik";
import { IExistingProduct, postProductValidationSchema } from "./AddExistingProduct.template";
import { useAuthenticatedUser } from "../../../hooks/useAuthenticatedUser";
import { capitalizeFirstLetter } from "../../../utils/helper";
import { HTTP_STATUS, STATUS } from "../../../utils/types";
import { VENDOR_PRODUCT_ROUTES, statusOptions } from "../../../utils/constant";
import SuccessFailurePopup from "../../molecules/SuccessFailurePopup/SuccessFailurePopup";
import deletePopupIcon from '../../../assets/images/deletePopupIcon.svg';
import { useVendorInventoryService } from "../../../services/useVendorInventoryService";

interface IWarehouseDetails {
    warehouseId: number;
    warehouseName: string;
    minimumOrderQuantity: number;
    deliveryTimeFrame: string;
}

export interface IProductSpecifications {
    name: string;
    minValue: string;
    maxValue: string;
    uom: string;
}

export interface IDetails {
    id: number;
    upcCode: string;
    attributes: {
        productSpecification: IProductSpecifications[];
    };
}

export interface IproductDetails {
    id: number;
    warehouseWiseProductDetails: IWarehouseDetails[];
    standardProductDetails: IDetails;
    productCategoryImageURL: string;
    productUpcTitle: string;
    productId: number;
    productType: string;
    status: string;
}

const useStyles = createUseStyles((theme: any) => ({
    heading: {
        color: theme.palette.text.primary.primary950,
    },
    heading1: {
        color: theme.palette.text.primary.primary400,
    },
    container: {
        border: `1px solid ${theme.palette.border.neutral.neutral100}`
    },
}));


const ExistingProductUpdateTemplate: React.FC<any> = ({ onVendorProductSubmit, redirectToVendorProductList }) => {

    const classes = useStyles();
    const { user } = useAuthenticatedUser();
    const navigate = useNavigate();
    const params = useParams();
    const postProductService = usePostProductService();
    const vendorInventoryService = useVendorInventoryService();
    const { showSnackbar, SnackBarComponent } = useSnackbar();

    const [activeTab, setActiveTabTo] = useState("1");
    const [productDetails, setproductDetailsTo] = useState<IproductDetails | null>(null);
    const [productsData, setProductsData] = useState<IProductData | null>(null);
    const [status, setStatus] = useState<string>("");
    const [isDeletePopupVisible, setDeletePopVisibleTo] = useState(false);
    const vendorProductId = Number(params.vendorProductId);
    const [initialValues, setInitialValues] = useState<IExistingProduct>({
        productId: null,
        classType: '',
        status: '',
        warehouseWiseProductDetails: []
    });
    const [warehouses, setWarehousesTo] = useState<{ id: number, name: string }[]>([{ id: 0, name: '' }]);

    const formatProductPrices = (warehouses: { id: number, name: string }[], vendorInventoryData: any) => {
        const formattedProductPrices = warehouses.map((warehouse) => {
            const matchedProductPrice = vendorInventoryData.find((inventory: any) => inventory.warehouseId === warehouse.id);
            return matchedProductPrice
                ? {
                    warehouseId: matchedProductPrice.warehouseId,
                    deliveryTimeFrame: matchedProductPrice.deliveryTimeFrame,
                    minimumOrderQuantity: matchedProductPrice.minimumOrderQuantity,
                    warehouseName: warehouse.name
                }
                : {
                    warehouseId: warehouse.id,
                    deliveryTimeFrame: "IMMEDIATE",
                    minimumOrderQuantity: 0,
                    warehouseName: warehouse.name
                };
        });
        return formattedProductPrices;
    };
    
    const getVendorProductDetails = async () => {
        try {
            if (user?.businessId) {
                const productResponse = await postProductService.getProductById(user?.businessId, vendorProductId);
                if (productResponse?.data?.data) {
                    const content: IproductDetails = productResponse.data.data;
                    const warehouseDetails = content?.warehouseWiseProductDetails;
                    const formattedProductPrices = formatProductPrices(warehouses, warehouseDetails);
                    content.warehouseWiseProductDetails = formattedProductPrices;
                    setInitialValues({
                        productId: content?.id,
                        classType: content?.productType,
                        status: content?.status,
                        warehouseWiseProductDetails: content?.warehouseWiseProductDetails.map((warehouse) => ({
                            minimumOrderQuanity: warehouse?.minimumOrderQuantity,
                            warehouseId: warehouse?.warehouseId,
                            deliveryTimeFrame: warehouse?.deliveryTimeFrame
                        }))
                    });
                    setproductDetailsTo(content);
                    setStatus(content.status === STATUS.ACTIVE ? STATUS.INACTIVE : STATUS.ACTIVE);
                    setProductsData({
                        id: content.id,
                        productId: content.productId,
                        skuCode: content.standardProductDetails.upcCode,
                        productName: content.productUpcTitle,
                        standardProductDetails: content.standardProductDetails
                    });
                } else {
                    throw new Error("Product fetch failed");
                }
            }
            else {
                showSnackbar("error", "Failed to fetch business profile id.")
            }

        } catch (error) {
            console.error("Error fetching product details:", error);
            setproductDetailsTo(null);
        }
    };
    
    const confirmInactive = async () => {
        if (user?.businessId) {
            try {
                const upsertResponse = await postProductService.upsertVendorProduct(user?.businessId, { productId: productDetails?.productId ?? 0, classType: productDetails?.productType ?? "", status: status?? "", warehouseWiseProductDetails: productDetails?.warehouseWiseProductDetails ?? [] });
                if (upsertResponse?.status === HTTP_STATUS.OK) {
                    showSnackbar('success', 'Product Updated Successfully');
                }
                else {
                    showSnackbar('error', 'Failed to Update Product');
                    throw new Error("Failed to Update Product");
                }
            } catch (error) {
                console.error("error", `Failed to Update Product`)
            }
            setDeletePopVisibleTo(false);
            navigate(VENDOR_PRODUCT_ROUTES.POST_PRODUCT);
        }
        else {
            showSnackbar('error', 'Failed to Update Product');
        }
    }

    const handleInactiveOnClick = () => {
        setDeletePopVisibleTo(true);
    }

    const cancelInactive = () => {
        setDeletePopVisibleTo(false);
    };

    const formik = useFormik<IExistingProduct>({
        validateOnMount: true,
        enableReinitialize: true,
        initialValues,
        validationSchema: postProductValidationSchema,
        onSubmit: async (values) => {
            const productRequestBody: IVendorProductRequestBodyDTO = {
                productId: productDetails?.productId ?? 0,
                classType: values.classType,
                status: values.status,
                warehouseWiseProductDetails: values.warehouseWiseProductDetails
                    .filter(detail => !(detail.minimumOrderQuanity === 0 && detail.deliveryTimeFrame === "IMMEDIATE"))
                    .map(detail => ({
                        ...detail,
                        minimumOrderQuantity: detail.minimumOrderQuanity ?? 0,
                    })),
            };
            try {
                if (user?.businessId) {
                    onVendorProductSubmit(user?.businessId, productRequestBody);
                }
                else {
                    showSnackbar("error", "Business Profile fetch failed.");
                }
            } catch (error) {
                showSnackbar("error", "Failed to add product");
            }
        },
    });

    const schema: ITabsSchema[] = productDetails?.warehouseWiseProductDetails?.map((warehouse: any, index: number) => ({
        label: capitalizeFirstLetter(warehouse?.warehouseName),
        component: <DeliveryInformationTemplate warehouseId={warehouse.warehouseId} formik={formik} index={index} />,
    })) || [];

    const handleTabChange = (tab: string | number) => {
        setActiveTabTo(tab.toString());
    };
    const handleOnSave = () => {
        if (formik.errors && formik.errors.warehouseWiseProductDetails && formik.errors.warehouseWiseProductDetails.length > 0) {
            showSnackbar("error", "Minimum weight is required and must be greater than and equal to 0");
            return;
        }
        const isVendorProductDetailsValid = formik.values.warehouseWiseProductDetails.every(
            (vendorDetail: any) =>
                vendorDetail.minimumOrderQuanity !== undefined && vendorDetail.minimumOrderQuanity === 0
        );
        if (isVendorProductDetailsValid) {
            showSnackbar('error', "Minimum order quantity must be greater than zero, and at least one warehouse must have minimum order quantity greater than zero.")
            return;
        }
        formik.handleSubmit()
    };

    const fetchWarehouses = async () => {
        if (user?.businessId) {
            try {
                const warehousesResponse = await vendorInventoryService.getAllWarehouseMappedVendor({ vendorId: user?.businessId, status: STATUS.ACTIVE });
                if (warehousesResponse.status === HTTP_STATUS.OK) {
                    const warehouses = warehousesResponse?.data?.data?.content;
                    setWarehousesTo(warehouses);
                }
            } catch (error) {
                showSnackbar('error', 'Warehouses fetch failed');
            }
        }
    };

    useEffect(() => {
        if (warehouses.length >0  && user?.businessId)
            getVendorProductDetails();
    }, [params.vendorProductId, warehouses, user?.businessId]);

    useEffect(() => {
        fetchWarehouses();
    }, [user?.businessId]);

    useEffect(() => {
        document.getElementById("main")?.scroll(0, 0); 
    }, []);

    return (
        <div>
             {isDeletePopupVisible && (
                <SuccessFailurePopup
                    width="458px"
                    height="365px"
                    variant="Failure"
                    heading={`${statusOptions.find(option => option.value === status)?.label} Product?`}
                    description={`Are you sure you want to make this product ${statusOptions.find(option => option.value === status)?.label}?`}
                    setShowPopup={setDeletePopVisibleTo}
                    button1={{
                        text: 'Yes',
                        variant: 'tertiaryContained',
                        size: 'large',
                        onClick: confirmInactive,
                    }}
                    button2={{
                        text: 'No',
                        variant: 'secondaryContained',
                        size: 'large',
                        onClick: cancelInactive,
                    }}
                />
            )}
            <div className="flex justify-between">
                <div className="flex">
                    <div className={`${classes.heading} font-medium text-lg`}>View Product: </div>
                    <div className={`${classes.heading1} font-medium text-lg ml-1`}>{productDetails?.standardProductDetails.upcCode}</div>
                </div>
                <div className="flex justify-end">  <Button label={`Make Product ${statusOptions.find(option => option.value === status)?.label}`} variant="secondaryContained" onClick={handleInactiveOnClick} /></div>
            </div>
            <div className="mt-6">
                <ProductViewTemplate product={productsData} />
            </div>
            <div className="grid mt-6 gap-y-3">
            <span className="font-medium text-2xl">Product Details</span>
            <div className={` ${classes.container} p-4 rounded-xl`}>
            {schema.length > 0 && <TabsV2 schema={schema} value={activeTab} setValue={handleTabChange} />}
            </div>
            </div>
            <div className="flex gap-x-4 mt-6 justify-end">
                <Button label={"Back"} variant="tertiaryContained" onClick={() => { navigate(`${VENDOR_PRODUCT_ROUTES.POST_PRODUCT}/${user?.businessId}/view/${vendorProductId}`); }} />
                <Button label={"Save"} variant="primaryContained" onClick={handleOnSave} />
            </div>
        </div>
    );
};

export default ExistingProductUpdateTemplate;