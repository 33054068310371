import React, { ReactNode } from 'react';
import Button from '../../atoms/Button/Button';
import { createUseStyles } from 'react-jss';

interface FormSubmitProps {
    onSubmit: () => void;
    buttonText: string;
    children: ReactNode;
    header: boolean | ReactNode;
    disabled: boolean;
    heading: string;
}

const useStyles = createUseStyles((theme: any) => ({
    body: {
        border: `1px solid ${theme.palette.border.neutral.neutral100}`,
    },
    title:{
        color: theme.palette.text.primary.primary900,
    }
}));

const FormSubmit: React.FC<FormSubmitProps> = ({ onSubmit, buttonText, children, header, disabled, heading }) => {
    const classes = useStyles();
    const HeaderBool = () => {
        return (
            <div className='grid gap-y-6'>
                <div className='flex justify-between pb-6 border-b'>
                    <div className={`${classes.title} my-auto text-lg font-medium`}>{heading}</div>
                    <Button variant='primaryContained' label={buttonText} onClick={onSubmit} disabled={disabled} />
                </div>

                {
                    header === false ? (
                        <div className='item-center' > {children}</div>
                    ) : null
                }

                {
                    header === true ? (
                        <div className='item-center'>{children}</div>
                    ) : null
                }
            </div>
        );
    }

    const HeaderNode = () => {
        return (
            <>
                <div>{header}</div>
                <div className='flex item-center'>{children}</div>
            </>
        );
    }

    return (
        <div className={`${classes.body} rounded-lg`}>
            <div className='p-4'>
                {typeof header === 'boolean' ? <HeaderBool /> : <HeaderNode />}
            </div>
        </div >
    );
};

export default FormSubmit;