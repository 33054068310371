import { Tab, Box } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import React, { ReactNode } from 'react'
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles((theme: any) => ({
  primary500: {
    color: "#FD6202",
  },
  selectedTab: {
    color: "#331400",
    "&.Mui-selected": {
      background: "#FFEFE6",
      backgroundColor: theme.palette.background.primary.primary50,
      color: "#331400",
      fontWeight: `500 !important`,
      zIndex: '2 !important',
      fontSize: "16px"
    },
  },
  homemenu: {
    position: 'relative',
    color: theme.palette.text.neutral.neutral600,
    cursor: 'pointer',
    '&:hover $dropDownContent': {
        display: 'block',
    },
},
  defaultTab: {
    color: `#E6E6E6 !important`,
    backgroundColor: `#E6E6E6 !important`,
    height: '1px !important',
    fontWeight: `400 !important`,
    left: '0px !important',
    width: '100% !important',
  },
  labelTab: {
    textTransform: 'none !important',
  },
  tabContainer: {
    "& .MuiTabs-flexContainer": {
      display: 'flex !important',
      gap: '20px !important',
    },
"& .css-1h9z7r5-MuiButtonBase-root-MuiTab-root" :{
padding: "16px 8px !important"
} }
}));

export interface ITabsSchema {
  label: string,
  component: ReactNode,
  disabled?: boolean
  hasIcon?: ReactNode
}

interface TabsProps {
  schema: ITabsSchema[],
  value: string,
  setValue: (value: any) => void;
  fullWidth?: boolean;
  selectedTabStyle?: string
}

const TabsV3: React.FC<TabsProps> = ({ schema, value, setValue, fullWidth = true, selectedTabStyle }) => {
  const classes = useStyles();
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(parseInt(newValue));
  };

  return (
    <Box className='w-full' >
      <TabContext value={value}>
            <div className={`${fullWidth ? 'w-full' : 'w-max'}`}> 
                <TabList
                    onChange={handleChange}
                    aria-label="lab API tabs example"
                    TabIndicatorProps={{ className: `${classes.defaultTab}` }}
                    className={`${classes.tabContainer} relative`}
                >
                    {schema.map((tab, index) => (
                        <Tab
                            label={
                                <div className="flex items-center gap-x-2 ">
                                    {tab.hasIcon && <div className={classes.homemenu}>{tab.hasIcon}</div>}
                                    <span>{tab.label}</span>
                                </div>
                            }
                            value={(index + 1).toString()}
                            key={index}
                            className={`${classes.selectedTab} ${classes.labelTab} flex gap-4 !text-base !font-normal`}
                            disabled={tab.disabled ?? false}
                        />
                    ))}
                </TabList>
            </div>
            {schema.map((tab, index) => (
                <TabPanel className={"!px-0 !py-3"} value={(index + 1).toString()} key={index}>
                    {tab.component}
                </TabPanel>
            ))}
        </TabContext>
    </Box>
  );
}
export default TabsV3