import React from 'react';
import { createUseStyles } from 'react-jss';
import visionBanner from '../../../assets/images/visionBanner.svg';
import verified from '../../../../src/assets/icons/verified.svg';
import verifiedTick from '../../../../src/assets/icons/verifiedTick.svg';
import user from '../../../../src/assets/icons/user.svg';
import rupee from '../../../../src/assets/icons/rupee.svg';
import signUp from '../../../../src/assets/images/signUp.svg';
import Button from '../../atoms/Button/Button';
import tickIcon from '../../../../src/assets/icons/tickIcon.svg'
import sellPageImg from '../../../../src/assets/images/sellPageImg.svg';
import RequestDemoTemplate from '../../template/Home/RequestDemo/RequestDemo.template';

const useStyles = createUseStyles((theme: any) => ({
    bannerContainer: {
        width: '100%',
        height: '650px',
        backgroundImage: `url(${visionBanner})`,
        backgroundSize: 'cover',
        position: 'relative',
    },
    container: {
        width: '1297px',
        margin: '0 auto',
    },
    bannerSellContainer: {
        padding: '150px 0px',
    },
    heading: {
        fontSize: '56px',
        lineHeight: '66px',
        color: theme.palette.text.primary.primary50,
    },
    bannerDescription: {
        color: theme.palette.text.primary.primary50,
    },
    btn: {
        background: '#fff',
    },
    intoContainer: {
        backgroundColor: theme.palette.background.neutral.neutral50,
    },
    text: {
        fontSize: '32px',
        color: theme.palette.text.primary.primary800,
    },
    primaryText: {
        fontSize: '40px',
        color: theme.palette.text.neutral.neutral400,
    },
    decContainer: {
        width: '815px',
        "& p": {
            color: theme.palette.text.neutral.neutral600,
            lineHeight: '28px',
        },
    },
    card: {
        background: theme.palette.background.complementary.complementary100,
        width: '280px',
        height: '230px',
        "& p": {
            fontSize: '28px',
            color: theme.palette.text.complementary.complementary800,
        },
    },
    cardSectionHeading: {
        color: theme.palette.text.primary.primary700,
    },
    sectionSubHeading: {
        color: theme.palette.text.primary.primary700,
    },
    cardSectionDescription: {
        color: theme.palette.text.complementary.complementary900,
    },
    secondaryHeading: {
        color: theme.palette.text.primary.primary700,
        fontSize: '40px',
    },
    headingText: {
        color: theme.palette.text.neutral.neutral800,
    },
    primaryDescription: {
        color: theme.palette.text.neutral.neutral800,
        lineHeight: '28px',
    },
    SecondaryHeadingText: {

    },
    textContainer: {
        position: 'relative',
        "&::before": {
            content: '""', // 'content' should either be empty or a string. Images are handled by backgroundImage.
            position: 'absolute',
            width: '26px', // Set width of the image
            height: '26px', // Set height of the image
            top: '3px',
            bottom: 0,
            left: '-38px',
            borderRadius: '4px',
            backgroundImage: `url(${tickIcon})`, // Set the image here
            backgroundSize: 'contain', // Ensures the image scales properly
            backgroundRepeat: 'no-repeat', // Ensures the image doesn't repeat
            marginRight: '8px', // Add spacing between the image and the text
        },
    },
    primaryDescriptionMain: {
        marginLeft: '-37px',
        marginBottom: '20px',
    },
    customerContainer: {
        padding: '62px 70px',
        background: theme.palette.background.primary.primary50,
        "& h3": {
            color: theme.palette.text.primary.primary800,
            fontSize: '40px',
        },
        "& h4": {
            color: theme.palette.text.primary.primary900,
        },
    },
    buyContainer: {
        width: '1297px',
        height: '350px',
        backgroundImage: `url(${sellPageImg})`,
        backgroundSize: 'cover',
        position: 'relative',
        marginTop: '20px',
        marginBottom: '20px',
    },
    buyHeading: {
        color: theme.palette.text.warning.warning200,
        fontSize: '40px',
    },
    buyDes: {
        color: theme.palette.text.primary.primary50,
    },
    buyBtn: {
        background: '#fff',

    },
}));

const BuyOnSteelBazaar: React.FC = () => {
    const classes = useStyles();

    return (
        <>
            <div className={`${classes.bannerContainer}`}>
                <div className={`${classes.container} ${classes.bannerSellContainer}`}>
                    <h1 className={`${classes.heading} mb-8`}>Save Your Steel <br /> Procurement Costs with us</h1>
                    <p className={`${classes.bannerDescription} text-2xl mb-10`}>Explore our digital platform for customized steel solutions, <br /> flexible finance options , and real-time tracking."
                    </p>
                    <Button variant={'tertiaryContained'} label='Start Buying Now'></Button>
                </div>
            </div>

            <div className={`${classes.intoContainer} mb-20`}>
                <div className={`${classes.container} flex items-center gap-10 py-14`}>
                    <div className=''>
                        <p className={`${classes.text} font-semibold mb-2`}>Welcome to SteelBazaar</p>
                        <p className={`${classes.primaryText} font-semibold`}>Your Trusted Partner in <br />Steel Business</p>
                    </div>
                    <div className={classes.decContainer}>
                        <p className='text-2xl'>At SteelBazaar, we are dedicated to connecting vendors with buyers through an efficient, transparent, and secure marketplace. With SteelBazaar, you're accessing a comprehensive wholesale platform designed to grow your business and enhance the services you provide to your buyers.
                            Whether you’re looking to expand your reach or streamline your sales processes, SteelBazaar is the go-to platform for the steel industry.
                            Let us help you grow your business while maximizing sales potential.
                        </p>
                    </div>
                </div>
            </div>

            <div className={`${classes.container}`}>
                <h2 className={`${classes.cardSectionHeading} text-5xl font-bold text-center mb-2`}>Why Buy from SteelBazaar?</h2>
                <h5 className={`${classes.sectionSubHeading} text-2xl text-center`}>"One-Stop Solution for All Your Steel Needs"</h5>
                <p className={`${classes.cardSectionDescription} mt-6 mb-9 text-center text-2xl`}>SteelBazaar offers a comprehensive, end-to-end steel procurement experience tailored for businesses of all sizes. By leveraging digital technology, we ensure transparency, efficiency, and customized solutions. </p>
                <div className={`${classes.container} flex justify-between pb-9`}>
                    <div className={`${classes.card} px-6 py-8 rounded-xl`}>
                        <img src={user} alt="" />
                        <p className='mt-6 font-semibold'>Direct access to top manufacturer</p>
                    </div>
                    <div className={`${classes.card} px-6 py-9 rounded-xl`}>
                        <img src={verifiedTick} alt="" />
                        <p className='mt-6 font-semibold'>Real-time pricing and availability</p>
                    </div>
                    <div className={`${classes.card} px-6 py-9 rounded-xl`}>
                        <img src={rupee} alt="" />
                        <p className='mt-6 font-semibold'>Flexible funding solutions</p>
                    </div>
                    <div className={`${classes.card} px-6 py-9 rounded-xl`}>
                        <img src={verified} alt="" />
                        <p className='mt-6 font-semibold'>Custom manufacturing </p>
                    </div>
                </div>
            </div>
            <div className={`${classes.container}`}>
                <h2 className={`${classes.cardSectionHeading} text-5xl font-bold text-center mb-2`}>How to Buy on SteelBazaar?</h2>
                <h5 className={`${classes.sectionSubHeading} text-2xl text-center mb-9`}>"Step-by-Step Buying Process"</h5>
                <div className='flex justify-between items-center'>
                    <div className='w-1/2 pr-10'>
                        <h3 className={`${classes.secondaryHeading} mb-4 font-semibold`}>1. Create Your Account</h3>
                        <p className='mb-5 leading-5'>Begin your journey with SteelBazaar by setting up your account. <br /> Provide basic information about your business and create a secure <br /> profile. This is your gateway to accessing our extensive range of steel <br /> products and services.</p>
                        <Button variant={'primaryContained'} label='Create Your Account'></Button>
                    </div>
                    <div>
                        <img src={signUp} alt="" />
                    </div>
                </div>

                <div className='flex justify-between mt-10 items-center'>
                    <div>
                        <img src={signUp} alt="" />
                    </div>
                    <div className='w-1/2 pr-10'>
                        <h3 className={`${classes.secondaryHeading} mb-4 font-semibold`}>2. Complete Your Profile</h3>
                        <p className='mb-5 leading-5'>Enhance your experience by completing your profile. Add detailed <br /> information about your company, including industry-specific<br />requirements and preferences. This helps us tailor our offerings to<br />better meet your needs.
                        </p>
                        <Button variant={'primaryContained'} label='Complete Profile'></Button>
                    </div>
                </div>

                <div className='flex justify-between mt-12 mb-12 items-center'>
                    <div className='w-1/2 '>
                        <h3 className={`${classes.secondaryHeading} mb-4 font-semibold`}>3. Start Exploring Digital Catalogs</h3>
                        <h6 className={`${classes.headingText} text-2xl mb-1 font-bold`}>Compare Prices in Real-Time:</h6>
                        <p className={`${classes.primaryDescription} text-2xl mb-1`}>Get live updates on pricing, ensuring you make cost-effective decisions.</p>
                        <h6 className={`${classes.headingText} text-2xl mb-1 font-bold`}>Select Your Preferred Manufacturer:</h6>
                        <p className={`${classes.primaryDescription} text-2xl mb-1`}>Choose from our network of verified, top-tier steel manufacturers.</p>
                        <h6 className={`${classes.headingText} text-2xl mb-1 font-bold`}>Place Your Order:</h6>
                        <p className={`${classes.primaryDescription} text-2xl mb-1`}>Once you've selected your product, place your order with just a few clicks.</p>
                        <h6 className={`${classes.headingText} text-2xl mb-1 font-bold`}>Track Your Order in Real-Time: </h6>
                        <p className={`${classes.primaryDescription} text-2xl mb-1`}>Monitor your order from production to delivery with full transparency.</p>
                    </div>
                    <div>
                        <img src={signUp} alt="" />
                    </div>
                </div>
                <div>
                    <h2 className={`${classes.cardSectionHeading} text-5xl font-bold text-center mb-2`}>Participate in Auctions for Bulk Buying</h2>
                    <h5 className={`${classes.sectionSubHeading} text-2xl text-center mb-9`}>Get Competitive Prices in Steel Auctions</h5>
                    <div className='flex justify-between mt-12 items-center'>
                        <div>
                            <img src={signUp} alt="" />
                        </div>
                        <div className='w-1/2 '>
                            <h4 className={`${classes.primaryDescriptionMain} text-2xl font-semibold`}>SteelBazaar's auction platform offers you the chance to buy steel in bulk at the most competitive prices :</h4>
                            <div className={`${classes.textContainer} mb-3`}>
                                <h6 className={`${classes.SecondaryHeadingText} text-2xl mb-1 font-bold`}>Exclusive Bulk Deals:</h6>
                                <p className={`${classes.primaryDescription} text-2xl mb-1`}>Access inventory from vendors looking to sell large volumes at competitive rates.</p>
                            </div>

                            <div className={`${classes.textContainer} mb-3`}>
                                <h6 className={`${classes.SecondaryHeadingText} text-2xl mb-1 font-bold`}>Save on Procurement Costs:</h6>
                                <p className={`${classes.primaryDescription} text-2xl mb-1`}>Benefit from reduced procurement costs by winning auctions for your specific steel requirements.</p>
                            </div>

                            <div className={`${classes.textContainer} mb-3`}>
                                <h6 className={`${classes.SecondaryHeadingText} text-2xl mb-1 font-bold`}>Real-Time Bidding:</h6>
                                <p className={`${classes.primaryDescription} text-2xl mb-1`}>Participate in live auctions where you can bid for products and secure deals quickly.</p>
                            </div>

                            <div className={`${classes.textContainer} mb-9`}>
                                <h6 className={`${classes.SecondaryHeadingText} text-2xl mb-1 font-bold`}>Diverse Product Range:</h6>
                                <p className={`${classes.primaryDescription} text-2xl mb-1`}>Bid on a variety of steel products, from raw materials to finished goods, at wholesale rates.</p>
                            </div>
                            <Button variant={'primaryContained'} label='Join Live Auctions'></Button>
                        </div>
                    </div>
                </div>

                <div className='mt-9'>
                    <h2 className={`${classes.cardSectionHeading} text-5xl font-bold text-center mb-2`}>Custom Manufacturing Solutions</h2>
                    <h5 className={`${classes.sectionSubHeading} text-2xl text-center mb-9`}>Tailor-Made Steel Products, From Raw Materials to Finished Goods</h5>
                    <div className='flex justify-between mt-12 items-center'>
                        <div className='w-1/2 '>
                            <h4 className={`${classes.primaryDescriptionMain} text-2xl font-semibold`}>SteelBazaar provides custom manufacturing options to meet your specific needs. Whether you need unique shapes, sizes, or finishes, our platform allows you to :</h4>
                            <div className={`${classes.textContainer} mb-3`}>
                                <p className={`${classes.primaryDescription} text-2xl mb-1`}>Access inventory from vendors looking to sell large volumes at competitive rates.</p>
                            </div>

                            <div className={`${classes.textContainer} mb-3`}>
                                <p className={`${classes.primaryDescription} text-2xl mb-1`}>Benefit from reduced procurement costs by winning auctions for your specific steel requirements.</p>
                            </div>

                            <div className={`${classes.textContainer} mb-6`}>
                                <p className={`${classes.primaryDescription} text-2xl mb-1`}>Participate in live auctions where you can bid for products and secure deals quickly.</p>
                            </div>

                            <Button variant={'primaryContained'} label='Submit Custom RFQ'></Button>
                        </div>
                        <div>
                            <img src={signUp} alt="" />
                        </div>
                    </div>
                </div>

                <div className='mt-9'>
                    <h2 className={`${classes.cardSectionHeading} text-5xl font-bold text-center mb-2`}>Real-Time Digital Tracking</h2>
                    <h5 className={`${classes.sectionSubHeading} text-2xl text-center mb-9`}>Full Transparency and Real-Time Tracking</h5>
                    <div className='flex justify-between mt-12 items-center'>
                        <div>
                            <img src={signUp} alt="" />
                        </div>
                        <div className='w-1/2 '>
                            <h4 className={`${classes.primaryDescriptionMain} text-2xl font-semibold`}>Stay in control of your procurement process with SteelBazaar's digital platform.Our platform ensures that every step of the process is fully transparent, helping you make informed decisions quickly :</h4>
                            <div className={`${classes.textContainer} mb-3`}>
                                <p className={`${classes.primaryDescription} text-2xl mb-1`}>Track your orders from production to delivery</p>
                            </div>

                            <div className={`${classes.textContainer} mb-3`}>
                                <p className={`${classes.primaryDescription} text-2xl mb-1`}>Receive real-time updates on manufacturing progress and shipping.</p>
                            </div>

                            <div className={`${classes.textContainer} mb-6`}>
                                <p className={`${classes.primaryDescription} text-2xl mb-1`}>Access order history and reports at your convenience</p>
                            </div>

                            <Button variant={'primaryContained'} label='Track Your Orders'></Button>
                        </div>
                    </div>
                </div>

                <div className='mt-10'>
                    <h2 className={`${classes.cardSectionHeading} text-5xl font-bold text-center mb-2`}>Flexible Purchase Finance Options</h2>
                    <h5 className={`${classes.sectionSubHeading} text-2xl text-center mb-9`}>Flexible Financing to Suit Your Business Needs</h5>
                    <div className='flex justify-between mt-12 items-center'>
                        <div className='w-1/2 '>
                            <h4 className={`${classes.primaryDescriptionMain} text-2xl font-semibold`}>At SteelBazaar, we understand that cash flow can be a challenge. That's why we offer flexible funding options to make steel procurement easier :</h4>
                            <div className={`${classes.textContainer} mb-3`}>
                                <h6 className={`${classes.SecondaryHeadingText} text-2xl mb-1 font-bold`}>Financing Solutions</h6>
                                <p className={`${classes.primaryDescription} text-2xl mb-1`}>Access funding options that help you spread the cost of large purchases over time.</p>
                            </div>

                            <div className={`${classes.textContainer} mb-3`}>
                                <h6 className={`${classes.SecondaryHeadingText} text-2xl mb-1 font-bold`}>Pay Later Options</h6>
                                <p className={`${classes.primaryDescription} text-2xl mb-1`}>Defer payments while maintaining smooth operations and production schedules.</p>
                            </div>

                            <div className={`${classes.textContainer} mb-9`}>
                                <h6 className={`${classes.SecondaryHeadingText} text-2xl mb-1 font-bold`}>Credit Facilities</h6>
                                <p className={`${classes.primaryDescription} text-2xl mb-1`}>Secure credit terms to buy steel products without upfront payments, helping you optimize working capital.</p>
                            </div>

                            <Button variant={'primaryContained'} label='Join Live Auctions'></Button>
                        </div>
                        <div>
                            <img src={signUp} alt="" />
                        </div>
                    </div>
                </div>


                <div className={`${classes.container}`}>
                    <div className={`${classes.customerContainer} px-10 mt-12 mb-12`}>
                        <h3 className='mb-0 font-semibold'>Real-Time Pricing, Transparent Deals</h3>
                        <h4 className='mb-5 text-2xl'>Stay ahead of market fluctuations with live pricing and updates</h4>
                        <Button variant={'primaryContained'} label='View Latest Prices'></Button>
                    </div>
                </div>

                <div className='mt-10'>
                    <h2 className={`${classes.cardSectionHeading} text-5xl font-bold text-center mb-2`}>Connect Directly with Top Manufacturers</h2>
                    <h5 className={`${classes.sectionSubHeading} text-2xl text-center mb-9`}>Build Strong Partnerships with Leading Steel Producers</h5>
                    <div className='flex justify-between mt-12 items-center'>
                        <div>
                            <img src={signUp} alt="" />
                        </div>
                        <div className='w-1/2 '>
                            <h4 className={`${classes.primaryDescriptionMain} text-2xl font-semibold`}>SteelBazaar connects you directly with trusted manufacturers. Eliminate middlemen and negotiate better deals. Our platform lets you :</h4>
                            <div className={`${classes.textContainer} mb-3`}>
                                <p className={`${classes.primaryDescription} text-2xl mb-1`}>Browse verified manufacturers for raw materials and finished products</p>
                            </div>

                            <div className={`${classes.textContainer} mb-3`}>
                                <p className={`${classes.primaryDescription} text-2xl mb-1`}>Secure competitive rates through direct negotiation</p>
                            </div>

                            <div className={`${classes.textContainer} mb-9`}>
                                <p className={`${classes.primaryDescription} text-2xl mb-1`}>Gain access to exclusive, high-quality steel products from around the world</p>
                            </div>

                            <Button variant={'primaryContained'} label='View Manufacturers'></Button>
                        </div>
                    </div>
                </div>

                <div className='mt-10'>
                    <h2 className={`${classes.cardSectionHeading} text-5xl font-bold text-center mb-2`}>Order Management & Delivery</h2>
                    <h5 className={`${classes.sectionSubHeading} text-2xl text-center mb-9`}>Seamless Order Management and Timely Deliveries</h5>
                    <div className='flex justify-between mt-12 items-center'>

                        <div className='w-1/2 '>
                            <h4 className={`${classes.primaryDescriptionMain} text-2xl font-semibold`}>Simplify your procurement with our integrated order management system. You can:</h4>
                            <div className={`${classes.textContainer} mb-3`}>
                                <p className={`${classes.primaryDescription} text-2xl mb-1`}>Easily manage orders, from creation to fulfillment</p>
                            </div>

                            <div className={`${classes.textContainer} mb-3`}>
                                <p className={`${classes.primaryDescription} text-2xl mb-1`}>Track deliveries with real-time status updates</p>
                            </div>

                            <div className={`${classes.textContainer} mb-9`}>
                                <p className={`${classes.primaryDescription} text-2xl mb-1`}>Schedule flexible delivery options to meet your project timelines.</p>
                            </div>

                            <Button variant={'primaryContained'} label='Manage Your Orders'></Button>
                        </div>
                        <div>
                            <img src={signUp} alt="" />
                        </div>
                    </div>
                </div>

                <div className='mt-10'>
                    <h2 className={`${classes.cardSectionHeading} text-5xl font-bold text-center mb-2`}>Customer Support & Assistance</h2>
                    <h5 className={`${classes.sectionSubHeading} text-2xl text-center mb-9`}>We're Here to Help</h5>
                    <div className='flex justify-between mt-12 items-center'>
                        <div>
                            <img src={signUp} alt="" />
                        </div>
                        <div className='w-1/2 '>
                            <h4 className={`${classes.primaryDescriptionMain} text-2xl font-semibold`}>Our dedicated support team is available to assist you at every stage. Whether you need help with product selection, placing orders, or tracking shipments, we provide:</h4>
                            <div className={`${classes.textContainer} mb-3`}>
                                <p className={`${classes.primaryDescription} text-2xl mb-1`}>24/7 customer support</p>
                            </div>

                            <div className={`${classes.textContainer} mb-3`}>
                                <p className={`${classes.primaryDescription} text-2xl mb-1`}>Detailed FAQs and video tutorials for self-service</p>
                            </div>

                            <div className={`${classes.textContainer} mb-9`}>
                                <p className={`${classes.primaryDescription} text-2xl mb-1`}>Personalized assistance to ensure you have a seamless experience</p>
                            </div>

                            <Button variant={'primaryContained'} label='Manage Your Orders'></Button>
                        </div>
                    </div>
                </div>


                <div className='mt-10'>
                    <h2 className={`${classes.cardSectionHeading} text-5xl font-bold text-center mb-2`}>Performance Analytics for Smart Buying</h2>
                    <h5 className={`${classes.sectionSubHeading} text-2xl text-center mb-9`}>Optimize Your Procurement with Data-Driven Insights</h5>
                    <div className='flex justify-between mt-12 items-center'>
                        <div>
                            <img src={signUp} alt="" />
                        </div>
                        <div className='w-1/2 '>
                            <h4 className={`${classes.primaryDescriptionMain} text-2xl font-semibold`}>Track and optimize your purchasing decisions with SteelBazaar's analytics tools. Our dashboard gives you access to:</h4>
                            <div className={`${classes.textContainer} mb-3`}>
                                <p className={`${classes.primaryDescription} text-2xl mb-1`}>Order history and trends</p>
                            </div>

                            <div className={`${classes.textContainer} mb-3`}>
                                <p className={`${classes.primaryDescription} text-2xl mb-1`}>Price fluctuations and market insights</p>
                            </div>

                            <div className={`${classes.textContainer} mb-9`}>
                                <p className={`${classes.primaryDescription} text-2xl mb-1`}>Vendor performance and quality tracking</p>
                            </div>

                            <Button variant={'primaryContained'} label='Join Live Auctions'></Button>
                        </div>
                    </div>
                </div>
                <div className={`${classes.container} ${classes.buyContainer} py-9 px-16 flex flex-col items-center !mb-10`}>
                    <h4 className={`${classes.buyHeading} text-center`}>Ready to Start Buying ?</h4>
                    <p className={`${classes.buyDes} text-center text-5xl mt-4 mb-8`}>Transform your steel procurement experience with our comprehensive platform</p>
                    <Button variant={'tertiaryContained'} label='Contact Support'></Button>
                </div>
            </div>
            <RequestDemoTemplate />
        </>
    );
};

export default BuyOnSteelBazaar;
