import React from "react";
import { createUseStyles } from "react-jss";
import CatalogueListItemSkeletonTemplate from "../CatalogueDetail/CatalogueListItemSkeleton.template";

const useStyles = createUseStyles((theme: any) => ({
    background: {
        background: theme.palette.background.neutral.neutral100,
    },
}));

const LeadListSkeletonTemplate: React.FC = () => {
    const classes = useStyles();

    return (
        <div className="grid gap-y-6 p-6 border-2 rounded w-full">
            <div className={`h-14 w-full animate-pulse ${classes.background}`}></div>
            <CatalogueListItemSkeletonTemplate />
        </div>
    );
};

export default LeadListSkeletonTemplate;
