import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { createUseStyles } from 'react-jss';
import { FormikProps } from 'formik';
import { IVendorProductPriceForm } from '../../pages/VendorManagement/VendorProductPriceCreate.page';
import { useAuthenticatedUser } from '../../../hooks/useAuthenticatedUser';
import { useVendorInventoryService } from '../../../services/useVendorInventoryService';
import { useSnackbar } from '../../../hooks/useSnackBar';
import { HTTP_STATUS, STATUS } from '../../../utils/types';
import { capitalizeFirstLetter } from '../../../utils/helper';
import Tabs, { ITabsSchema } from '../../molecules/Tabs/Tabs';
import PriceInformationTemplate from './PriceInformation.template';
import TabsV2 from '../../molecules/TabsV2/TabsV2';

const useStyles = createUseStyles((theme: any) => ({
    webContainer: {
        display: "grid"
    },
    activatedProgressBar: {
        backgroundColor: theme.palette.background.primary.primary500
    },
    UnTouchProgressBar: {
        backgroundColor: theme.palette.border.neutral.neutral100
    },
    barHeading: {
        color: theme.palette.text.primary.primary500
    },
    textHeading:{
        color: theme.palette.text.primary.primary900
    },
    selectBox:{
        border: `1px solid ${theme.palette.background.neutral.neutral100}`,
    },
    search: {
        margin: "0",
        background: theme.palette.background.neutral.neutral400,
    },
    searchIcon: {
        color: theme.palette.background.neutral.neutral400
    },
    textHeading2: {
        color: theme.palette.text.primary.primary900
    },
    radioColor: {
        color: theme.palette.text.primary.primary900,
        width: '23%'
    },
    activeProgressBar: {
        backgroundColor: theme.palette.background.primary.primary400
    },
    textHeading3: {
        color: theme.palette.background.primary.primary900
    },
    lineColor: {
        border: `1px solid ${theme.palette.border.neutral.neutral100}`,
    },
    UsnText: {
        color: theme.palette.background.primary.primary500
    },
    titleText: {
        color: theme.palette.text.primary.primary900
    },
}));

export enum INVENTORY_WAREHOUSE {
    DELHI = 1,
    MUMBAI = 2,
}

interface IAddVendorProductPriceFormTemplate {
    formik: FormikProps<IVendorProductPriceForm>;
    buttonShow?: boolean;
    setWareHouseId?: Dispatch<SetStateAction<number>>;
    mode: string
}

export interface IInventoryAddViewProps {
    warehouseId: number;
}

const AddPriceSectionTemplate: React.FC<IAddVendorProductPriceFormTemplate> = ({ formik, buttonShow, setWareHouseId, mode = 'ADD' }) => {

    const classes = useStyles();
    const { user } = useAuthenticatedUser();
    const { showSnackbar, SnackBarComponent } = useSnackbar();
    const vendorInventoryService = useVendorInventoryService();

    const [activeTab, setActiveTab] = useState(INVENTORY_WAREHOUSE.DELHI);
    const [warehouses, setWarehouseTo] = useState<{ id: number, name: string }[]>([{ id: 0, name: '' }]);

    const fetchWarehouses = async () => {
        if (user?.businessId) {
            try {
                const wareHouseResponse = await vendorInventoryService.getAllWarehouseMappedVendor({ vendorId: user?.businessId, status: STATUS.ACTIVE });
                if (wareHouseResponse.status === HTTP_STATUS.OK) {
                    const warehouses = wareHouseResponse?.data?.data?.content;
                    setWarehouseTo(warehouses);
                }
                else {
                    setWarehouseTo([]);
                    showSnackbar('error', 'Warehouses fetch failed');
                }
            } catch (error) {
                showSnackbar('error', 'Warehouses fetch failed');
            }
        }
    };

    const schema: ITabsSchema[] = warehouses?.map((warehouse: { id: number, name: string }, index: number) => ({
        label: capitalizeFirstLetter(warehouse?.name),
        component: <PriceInformationTemplate warehouseId={warehouse.id} formik={formik} index={index} warehouseName={warehouse?.name} mode={mode} />
    }));

    useEffect(() => {
        fetchWarehouses();
    }, [user?.businessId]);

    return (
        <div className='flex flex-col gap-y-3 mt-[20px]'>
            <div className={`${classes.textHeading2} text-2xl font-medium`}>Inventory Details</div>
            <div className={`${classes.selectBox} rounded-xl p-4 grid gap-4`}>
                <div className='w-full relative'>
                    {schema.length > 0 && (
                        <TabsV2 schema={schema} value={(activeTab).toString()} setValue={setActiveTab} />
                    )}
                </div>
            </div>
        </div>
    )
}

export default AddPriceSectionTemplate