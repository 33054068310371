import React from 'react';
import { createUseStyles } from 'react-jss';
import Button from '../../../atoms/Button/Button';
import salesInvoice from '../../../../assets/images/salesInvoice.svg';
import salesInvoice2 from '../../../../assets/images/salesInvoice2.svg';
import salesInvoice3 from '../../../../assets/images/salesInvoice3.svg';

const useStyles = createUseStyles((theme: any) => ({
    container: {
        paddingBottom: "70px"
    },
    heading: {
        color: theme.palette.text.neutral.neutral800,
        lineHeight: "58px",
        "& span": {
            color: theme.palette.text.primary.primary500,
        },
    },
    subheading: {
        fontSize: '32px',
        color: theme.palette.text.neutral.neutral700,
        lineHeight: "38px"
    },
    card: {
        width: '417px',
        '&:nth-child(1)': {
            background: theme.palette.background.warning.warning200,
            color: theme.palette.text.warning.warning900,
        },
        '&:nth-child(2)': {
            background: theme.palette.background.primary.primary100,
            color: theme.palette.text.primary.primary900,
        },
        '&:nth-child(3)': {
            background: theme.palette.background.secondary.secondary100,
            text: theme.palette.text.secondary.secondary900,
        },
    },
    cardTitle: {
        fontSize: '32px',
        fontWeight: '400',
        lineHeight: '38px',
        marginBottom: '10px',
    },
    cardDescription: {
        fontSize: '18px',
        lineHeight: '22px',
        height: "66px"
    },
}));

const cardDetails = [
    {
        title: 'Sales Invoice Discounting',
        description: 'Guaranteed* Save 2-10% , Unlock Big Savings with Our Smart & Automated Procurement Solutions',
        imgSrc: salesInvoice,
    },
    {
        title: 'Purchase Invoice Finance',
        description: 'Maximise your Revenues, Liquidate in shortest time',
        imgSrc: salesInvoice2,
    },
    {
        title: 'Dynamic Discounting',
        description: 'Accelerate Liquidity while Maximizing Savings',
        imgSrc: salesInvoice3,
    },
];

const FinanceCardTemplate: React.FC = () => {
    const classes = useStyles();

    return (
        <div className={`${classes.container} max-container grid gap-y-8`}>
            <div className='grid gap-y-2'>
                <div className={`${classes.heading} text-5xl font-bold text-center`}>Supply Chain <span>Finance Solutions</span></div>
                <div className={`${classes.subheading} text-center`}>Funds Never Short</div>
            </div>
            <div className={`flex justify-between w-[1300px]`}>
                {cardDetails.map((card, index) => (
                    <div className={`${classes.card} flex flex-col justify-between rounded-2xl p-6`} key={index}>
                        <div className={classes.cardTitle}>{card.title}</div>
                        <div className={classes.cardDescription}>{card.description}</div>
                        <div className={`flex justify-between mt-4`}>
                            <a href={``} target="_blank" rel="noopener noreferrer" className='w-fit'>
                                <Button variant="tertiaryContained" label="View All" size='medium' />
                            </a>
                            <img src={card.imgSrc} alt="" />
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default FinanceCardTemplate;