import { createUseStyles } from "react-jss";
import Button from "../../atoms/Button/Button";
import emptyCart from "./../../../assets/images/emptyCart.svg";
import noProductList from "../../../assets/images/noProductList.svg";
import emptyWishList from "../../../assets/images/emptyWishlist.svg";

const useStyles = createUseStyles((theme: any) => ({
    boldText: {
        color: theme.palette.text.neutral.neutral800,
    },
    normalText: {
        color: theme.palette.text.neutral.neutral600,
    },
}));

type Variant = "Cart" | "Wishlist" | "Product List";

interface NoProductProps {
    text: string;
    subText?: string;
    label?: string;
    variant?: Variant,
    onClick?: () => void,
}

const NoProduct: React.FC<NoProductProps> = ({ text, subText, label, variant, onClick }) => {
    const classes = useStyles();

    const getIconByVariant = () => {
        switch (variant) {
            case "Cart":
                return emptyCart;
            case "Wishlist":
                return emptyWishList;
            case "Product List":
                return noProductList;
            default:
                return emptyCart;
        }
    };

    return (
        <div className="grid gap-y-6">
            {getIconByVariant() && (
                <img className="mx-auto" src={getIconByVariant() || ''} alt="popup-icon" />
            )}

            <div className="grid gap-y-2 mx-auto text-center">
                <div className={`${classes.boldText} text-2xl font-semibold`}>{text}</div>
                { subText &&
                    <div className={`${classes.normalText} font-medium text-base w-[380px]`}>{subText}</div>
                }
            </div>

            { label &&
                <div className="m-auto">
                    <Button fullWidth variant='primaryContained' label={label} onClick={onClick}/>
                </div>
            }
        </div>
    );
};

export default NoProduct;