import React, { useState } from 'react'
import { createUseStyles } from 'react-jss';
import { ITabsSchema } from '../../molecules/Tabs/Tabs';
import { useNavigate } from 'react-router-dom';
import { INVENTORY_MANAGEMENT_LISTING } from '../../../utils/types';
import Button from '../../atoms/Button/Button';
import ComingSoon from '../../atoms/ComingSoon/ComingSoon';
import TabsV2 from '../../molecules/TabsV2/TabsV2';
import { CUSTOMER_ROUTES, PRODUCT_CLASS_TYPES } from '../../../utils/constant';
import VendorProductPriceListTemplate from '../../template/VendorManagement/VendorProductPriceList.template';

const useStyles = createUseStyles((theme: any) => ({
  section: {
    color: theme.palette.text.primary.primary900,
  }
}));

export interface IPriceManagementProps {
  activeTab: string;
}

const VendorProductPriceListPage: React.FC = () => {

  const classes = useStyles();
  const navigate = useNavigate();

  const [activeTab, setActiveTab] = useState(INVENTORY_MANAGEMENT_LISTING.STANDARD_CATALOGUE);
  const schema: ITabsSchema[] = [
    { label: `Standard`, component: <VendorProductPriceListTemplate activeTab={PRODUCT_CLASS_TYPES.STANDARD} /> },
    { label: `Non Standard`, component: <ComingSoon /> },
    { label: 'Commercial Scrap', component: <ComingSoon /> },
    { label: 'Melting Scrap', component: <ComingSoon /> }
  ]

  const redirectToVendorProductPriceCreate = () => {
    navigate(CUSTOMER_ROUTES.VENDOR_PRODUCT_PRICE_CREATE);
  }

  return (
    <div className={"grid gap-y-6 w-full "}>
      <div className='flex '>
        <div className={`${classes.section} text-lg font-medium w-full`}>Price Management</div>
        <Button
          className={`w-1/6`}
          variant="primaryContained"
          label="Add Price"
          size = {"medium"}
          onClick={redirectToVendorProductPriceCreate}
        />
      </div>
      <div className='w-full'>
        <TabsV2 schema={schema} value={(activeTab).toString()} setValue={setActiveTab} />
      </div>
    </div>
  )
}

export default VendorProductPriceListPage