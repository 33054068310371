import React from 'react';
import { createUseStyles } from 'react-jss';
import { Link } from 'react-router-dom';
import { CUSTOMER_ROUTES } from '../../../utils/constant';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

interface TermsConditionsReviewProps {
    description:string;
}

const useStyles = createUseStyles((theme: any) => ({
    mainContainer:{
        rowGap: "16px",
    },
    container: {
        border: `1px solid ${theme.palette.border.neutral.neutral100}`
    },
    title: {
        color: theme.palette.text.neutral.neutral900,
    },
    content: {
        color: theme.palette.text.neutral.neutral600
    },
    "@media (max-width: 480px)": {
        mainContainer:{
            rowGap: "12px",
        },
        webText: {
            display: "none"
        },

    },
}));

const TermsConditionsReviewTemplate: React.FC<TermsConditionsReviewProps> = ({description}) => {

    const classes = useStyles();

    return (
        <div className={`${classes.mainContainer} grid`}>
            <div className={`${classes.title} font-inter text-lg font-semibold`}>Sales Terms and Conditions</div>
            <div className={`${classes.container} flex flex-col rounded-3xl px-6 py-4 items-start gap-2`}>
                <div className={`${classes.content} font-inter text-base font-normal`}>{description}</div>
                {/* <Link to={CUSTOMER_ROUTES.TERMS_AND_CONDITIONS} target="_blank" className={`${classes.webText} pt-2 text-lg font-medium cursor-pointer`}>View Terms and Conditions
                    <NavigateNextIcon className={`${classes.icon} ml-2`} />
                    <NavigateNextIcon />
                </Link>

                <Link to={CUSTOMER_ROUTES.TERMS_AND_CONDITIONS} target="_blank" className={`${classes.mobileText} pt-2 text-lg font-medium cursor-pointer`}>View More
                    <NavigateNextIcon className={`${classes.icon} ml-2`} />
                    <NavigateNextIcon />
                </Link> */}
            </div>
        </div>
    );
};

export default TermsConditionsReviewTemplate;