import React, { useState } from 'react'
import cardBoardFilled from '../../../assets/images/filledCardBoard.svg'
import { createUseStyles } from 'react-jss'
interface NoDataFrameTemplate {
    title: string,
    message: string
}

const useStyles = createUseStyles((theme: any) => ({
    container: {
        backgroundColor: "rgba(242, 242, 243, 0.40)"
    },
    title: {
        color: theme.palette.text.primary.primary900
    },
    message: {
        color: theme.palette.text.neutral.neutral400
    }
}))

const NoDataFrameTemplate: React.FC<NoDataFrameTemplate> = ({ title, message }) => {
    const classes = useStyles();
    return (
        <div className={`${classes.container} py-8 px-3 grid gap-y-4 justify-center items-center rounded-xl`}>
            <img className='mx-auto' src={cardBoardFilled} alt="" />
            <div className={`${classes.title} text-[24px] font-medium mx-auto`}>{title}</div>
            <div className={`${classes.message} text-base mx-auto`}>{message}</div>
        </div>
    )
}

export default NoDataFrameTemplate