import React, { useEffect, useMemo, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { Checkbox } from '@mui/material';
import ProductDetailsDropdownTemplate from './ProductDetailsDropdown.template';
import { IVendorCatalogueAddForm } from '../../pages/VendorCatalogue/VendorCatalogueAdd.page';
import { FormikProps } from 'formik';
import { useVendorCatalogueService } from '../../../services/useVendorCatalogueService';
import { useSnackbar } from '../../../hooks/useSnackBar';
import { HTTP_STATUS } from '../../../utils/types';
import { useAuthenticatedUser } from '../../../hooks/useAuthenticatedUser';

interface CustomisationDetailsTemplateProps {
    warehouseId: number;
    formik: FormikProps<IVendorCatalogueAddForm>;
    index: number;
    name: string;
}

interface IVasService {
    id: number;
    name: string;
}

export interface IImageDetails {
    id: number;
    path: string;
}
export interface ICustomisationDetails {
    machineId: number;
    machineName: string;
    locationName: string;
    locationId: number;
    minWidth: number;
    maxWidth: number;
    machinePricePerMT: number;
    vasMachineImageDetails: IImageDetails[];
}

const useStyles = createUseStyles((theme: any) => ({
    heading: {
        color: theme.palette.text.neutral.neutral800,
    },
    selectBox: {
        border: `1px solid ${theme.palette.background.neutral.neutral100}`,
    },
    checkBox: {
        "&.MuiCheckbox-root.Mui-checked": {
            color: theme.palette.background.primary.primary400,
        },
        "&.MuiButtonBase-root.MuiCheckbox-root": {
            padding: "0px"
        }
    },
    label: {
        color: theme.palette.text.primary.primary800
    },
    borderBottom: {
        borderBottom: `1px solid ${theme.palette.background.neutral.neutral100}`,
    }
}));


const CustomisationDetailsTemplate: React.FC<CustomisationDetailsTemplateProps> = ({ name, warehouseId, formik, index }) => {
    const classes = useStyles();
    const [vasService, setVasService] = useState<IVasService[]>([]);
    const [customisationDetails, setCustomisationDetails] = useState<ICustomisationDetails[]>([])
    const { showSnackbar, SnackBarComponent } = useSnackbar();
    const vendorCatalogueService = useVendorCatalogueService();
    const [selectedService, setSelectedService] = useState<number>()

    const { user } = useAuthenticatedUser();

    const handleCheckboxChange = (id: number, name:string, description:string) => {
        setSelectedService(id);
        const existingDetails = formik.values.catalogueProductDetails[index].productServicesDetails.filter((item) => item.locationId!==null);
        const isDuplicate = existingDetails.some((item) => item.locationId === id);

        if (!isDuplicate) {
            formik.setFieldValue(
                `catalogueProductDetails[${index}].productServicesDetails`,
                [
                    ...existingDetails,
                    {
                        locationId: id,
                        machineId: null,
                        name: name,
                        description: description || null,
                    },
                ]
            );
        }
    };

    useEffect(() => {
        for (let i = formik.values.catalogueProductDetails.length; i <= index; i++) {
            const newCatalogueProductDetails = {
                productId: null,
                warehouseId: null,
                maximumRetailPrice: null,
                offerSalePrice: null,
                deliveryTerms: '',
                packagingType: '',
                productDiscountDetails: [{
                    locationId: null,
                    minQuantity: null,
                    maxQuantity: null,
                    minDiscount: null,
                    maxDiscount: null,
                }],
                productServicesDetails: [{
                    locationId: null,
                    machineId: null,
                    name:null,
                    description:null
                }],
                cGst: null,
                sGst: null,
                iGst: null,
                tax3: null,
            }
            formik.setFieldValue('catalogueProductDetails', [...formik.values.catalogueProductDetails, newCatalogueProductDetails]);
        }
    }, [index])

    const getCatalogueVasService = async () => {
        try {
            const ServiceResponse = await vendorCatalogueService.getCatalogueVasService({ level: 2 }, {
                superServiceIds: null,
                mainServiceIds: null,
                serviceCategoryIds: null
            });
            if (ServiceResponse.status === HTTP_STATUS.OK) {
                const vasServiceData = ServiceResponse?.data?.data?.content;
                setVasService(vasServiceData);
            }
        } catch (error) {
            showSnackbar('error', 'Service fetch failed');
        }
    };

    const getCustomisationDetails = async () => {
        try {
            if (user?.businessId && selectedService) {
                const serviceResponse = await vendorCatalogueService.getVasCustomisation(user.businessId, selectedService);
                if (serviceResponse.status === HTTP_STATUS.OK) {
                    const vasServiceData = serviceResponse?.data?.data;
                    const filteredData = vasServiceData.filter((detail: ICustomisationDetails) => detail.locationName === name);
                    setCustomisationDetails(filteredData);
                }
            }
        } catch (error) {
            showSnackbar('error', 'Customisation Details fetch failed');
        }
    };

    useEffect(() => {
        getCatalogueVasService();
    }, []);

    useEffect(() => {
        getCustomisationDetails();
    }, [selectedService,]);

    useEffect(() => {
        const productDetails = formik.values.catalogueProductDetails;
        productDetails.forEach(detail => {
            if (detail.warehouseId === warehouseId) {
                const serviceDetail = detail.productServicesDetails.find(service => service.locationId === warehouseId);
                if (serviceDetail && serviceDetail?.serviceCategoryId) {
                    setSelectedService(serviceDetail?.serviceCategoryId);
                }
            }
        });
    }, [formik.values.catalogueProductDetails, index]);

    return (
        <div className={`${classes.selectBox} mt-4 p-3 rounded-xl`}>
            <div className={`${classes.heading} font-medium text-base mb-3`}>Select Customization</div>
            <div className='flex gap-x-6'>
                {vasService?.map((item: any, index2: number) => (
                    <div >
                        <div key={item.id} className={`${classes.selectBox} p-4 rounded-xl`}>
                            <Checkbox
                                id={item.id.toString()}
                                checked={selectedService === item.id || formik.values.catalogueProductDetails[index]?.productServicesDetails?.some((serviceDetail) => serviceDetail.serviceCategoryId === item.id)}
                                onChange={() => handleCheckboxChange(item.id,item?.name,item?.description)}
                                className={`${classes.checkBox} mr-2`}
                            />
                            <label className={`${classes.label} font-medium text-base ml-3 mr-8`}>{item.name}</label>
                        </div>
                    </div>
                ))}
            </div>
            <div className={`${classes.borderBottom} my-6`} />
            {customisationDetails?.map((details: ICustomisationDetails, newIndex: number) => (
                <ProductDetailsDropdownTemplate data={details} formik={formik} newIndex={newIndex} index={index} selectedService={selectedService} />
            ))}
        </div>
    );
};

export default CustomisationDetailsTemplate;
