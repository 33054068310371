import React, { useState } from 'react';
import { createUseStyles } from 'react-jss';
import homeBanner from '../../../../assets/images/homeBanner.jpg';
import TextField from '../../../atoms/Input/TextField';
import outlinedIcon1 from '../../../../assets/icons/outlinedIcon1.png';
import outlinedIcon2 from '../../../../assets/icons/outlinedIcon2.svg';
import outlinedIcon3 from '../../../../assets/icons/outlinedIcon3.svg';
import outlinedIcon4 from '../../../../assets/icons/outlinedIcon4.svg';
import SearchIcon from '@mui/icons-material/Search';
import Animation from './BannerAnimation';

const useStyles = createUseStyles((theme: any) => ({
    container: {
        width: '100%',
        height: '553px',
        backgroundImage: `url(${homeBanner})`,
        backgroundSize: 'cover',
        padding: "70px 0px"
    },
    primaryContainer: {
        width: "1300px",
    },
    secondaryContainer: {
        width: '1300px',
        padding: "40px 0px"
    },
    statsMainContainer: {
        width: '1300px',
        padding: "20px 0px 60px 0px"
    },
    statsContainer: {
        padding: "25px",
        border: `1px dashed #AEB0B6`,
        borderRadius: "12px"
    },
    statsHeading: {
        fontSize: '32px',
        color: theme.palette.text.neutral.neutral800,
        lineHeight: '38px',
        "& span": {
            color: theme.palette.text.secondary.secondary600,
        },
    },
    sliderContent: {
        "& p": {
            fontSize: '32px',
            color: theme.palette.text.primary.primary900,
            lineHeight: '38px'
        },
        "& span": {
            fontSize: '18px',
            color: theme.palette.text.neutral.neutral600,
            lineHeight: '22px'
        },
    },
    heading: {
        fontSize: '56px',
        color: theme.palette.text.primary.primary50,
        lineHeight: '66px',
    },
    subheading: {
        color: theme.palette.text.primary.primary50,
    },
    inputSearch: {
        border: `0.8px solid ${theme.palette.border.primary.primary500}`,
        borderRadius: '45px',
        overflow: 'hidden',
        background: 'white',
        width: '670px',
        "& .MuiInputBase-root": {
            border: 'none',
            background: 'white',
        }
    },
    btn: {
        background: theme.palette.background.primary.primary500,
        color: "white",
        borderRadius: '50px',
        height: '48px',
        width: '152px',
    },
    chip: {
        "& span": {
            background: "rgba(255, 255, 255, 0.10)",
            border: `1px solid ${theme.palette.border.neutral.neutral50}`,
            color: theme.palette.text.neutral.neutral50,
            borderRadius: '28px',
            lineHeight: "16.32px"
        }
    },
    smallContainer: {
        "& span": {
            color: theme.palette.text.primary.primary800,
            lineHeight: "22px"
        },
    },
    selectedValue: {
        color: theme.palette.text.primary.primary50,
        borderBottom: `1px solid ${theme.palette.border.warning.warning400}`,
    },
    unselectedValue: {
        color: theme.palette.text.primary.primary50,
    },
    banneSearchIcon: {
        color:  theme.palette.text.neutral.neutral400,
    },
}));

const HomePage: React.FC = () => {
    const classes = useStyles();
    const data = [
        {
            name: "Raw / Semi Finished",
            value: 0,
        },
        {
            name: "Finished Products",
            value: 1,
        },
    ]
    
    const [selectedTab, setSelectedTab] = useState<string>('Raw / Semi Finished');
    const handleToggleClick = (item: any) => {
        setSelectedTab(item.name);
        const sectionMap: { [key: string]: string } = {
            "Raw / Semi Finished": "semiFinished",
            "Finished Products": "finished",
        };
        const sectionId = sectionMap[item.name];
        if (sectionId) {
            const label = document.getElementById(sectionId);
            if (label) {
                label.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }
        }

    };

    return (
        <>
            <div className={`${classes.container}`}>
                <div className={`${classes.primaryContainer} grid gap-y-8 mx-auto`}>
                    <div className='relative h-[200px]'>
                        <h1 className={`${classes.heading} font-bold w-1/2`}>Future Ready Digital Steel Commerce</h1>
                        <Animation />
                    </div>
                    <div className='grid gap-y-5 w-fit'>
                        <div className={` flex gap-x-10 mx-auto`}>
                            {data.map((item: any, index: any) => (
                                <div
                                    key={item.name}
                                    className={`py-2 justify-center items-center ${selectedTab === item.name ? `${classes.selectedValue} font-medium text-2xl cursor-pointer text-center` : `${classes.unselectedValue} font-medium text-2xl cursor-pointer`}`}
                                    onClick={() => handleToggleClick(item)}
                                >
                                    {item.name}
                                </div>

                            ))}
                        </div>

                        <div className={`${classes.inputSearch} flex items-center justify-between h-fit`}>
                            <div className='flex w-full items-center gap-0 ml-3'>
                                <SearchIcon className={`${classes.banneSearchIcon}`} />
                                <div className='w-full'>
                                    <TextField fullWidth className='' placeholder='Search anything in steel...' />
                                </div>
                            </div>
                            <a href="#" className={`${classes.btn} !text-base !mr-1 flex justify-center items-center`} >Search</a>
                        </div>
                        <div className={`${classes.chip} flex gap-3 mx-auto`}>
                            <span className='text-sm font-medium px-3 py-2'>Hot Rolled</span>
                            <span className='text-sm font-medium px-3 py-2'>Cold Rolled</span>
                            <span className='text-sm font-medium px-3 py-2'>Color Coated</span>
                            <span className='text-sm font-medium px-3 py-2'>TMT</span>
                            <span className='text-sm font-medium px-3 py-2'>Beams</span>
                        </div>
                    </div>
                </div>
            </div>

            <div className={`${classes.secondaryContainer} flex justify-between gap-x-32 mx-auto`}>
                <div className={`${classes.smallContainer} flex gap-x-3 items-center`}>
                    <img src={outlinedIcon1} alt="" />
                    <span className='text-lg font-semibold'>Smart Trade and Transparent Deals</span>
                </div>
                <div className={`${classes.smallContainer} flex gap-x-3 items-center`}>
                    <img src={outlinedIcon2} alt="" />
                    <span className='text-lg font-semibold'>Digital Catalogs with Real Time Prices</span>
                </div>
                <div className={`${classes.smallContainer} flex gap-x-3 items-center`}>
                    <img src={outlinedIcon3} alt="" />
                    <span className='text-lg font-semibold'>Trusted Brands & Assured Quality</span>
                </div>
                <div className={`${classes.smallContainer} flex gap-x-3 items-center`}>
                    <img src={outlinedIcon4} alt="" />
                    <span className='text-lg font-semibold'>Flexible and tailored Finance Solutions</span>
                </div>
            </div>

            <div className={`${classes.statsMainContainer} mx-auto`}>
                <div className={`${classes.statsContainer} flex justify-between mx-auto gap-x-28`}>
                    <div className={`${classes.statsHeading} text-4xl font-bold w-1/2`}>Products & Services <span>tailored to your needs.</span></div>
                    <div className={`flex justify-between w-1/2`}>
                        <div className={`${classes.sliderContent} grid gap-y-2`}>
                            <p className='font-bold'>200M+</p>
                            <span>Products</span>
                        </div>
                        <div className={`${classes.sliderContent} grid gap-y-2`}>
                            <p className='font-bold'>200K+</p>
                            <span>Sellers</span>
                        </div>
                        <div className={`${classes.sliderContent} grid gap-y-2`}>
                            <p className='font-bold'>5,900</p>
                            <span>Product Categories</span>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
export default HomePage;
