import React, { useMemo } from "react";
import { createUseStyles } from "react-jss";
import ShareIcon from "@mui/icons-material/Share";
import noImage from '../../../assets/images/noImage.jpg';
import ProductUpcItemTemplate from "../ProductUpc/ProductUpcItem.template";
import OrderProductItemTemplate from "./OrderProductItem.template";
import { Product } from "./OrderList.template";
import { snakeCaseToTitleCase, sortSpecificationAttributes, toInr } from "../../../utils/helper";
import { TableBody, TableContainer, TableCell, TableHead, TableRow, Paper, Table } from '@mui/material';
import SecondaryProductUpcItemTemplate from "../ProductUpc/SecondaryProductUpcItem";
import Button from "../../atoms/Button/Button";
import SecondaryLotProductTemplate from "../ProductUpc/SecondaryLotProduct.template";
import useMetaDataService from "../../../hooks/useMetaDataService";

const useStyles = createUseStyles((theme: any) => ({
    iconContainer: {
        background: theme.palette.background.primary.primary50,
    },
    title: {
        background: theme.palette.background.primary.primary50,
        color: theme.palette.text.neutral.neutral500,
    },
    attributes: {
        backgroundColor: theme.palette.background.primary.primary50
    },
    webContainer: {
        display: "flex"
    },
    mobileContainer: {
        display: "none"
    },
    "@media (max-width: 476px)": {
        mobileContainer:{
          display: "grid",
        },
        webContainer: {
          display : "none"
        },
        title: {
            color: theme.palette.text.primary.primary500,
            background: "none"
        },
      },
}));

const gridTemplate = {
    xs: 4,
    sm: 4,
    md: 4,
    lg: 4,
    xl: 4,
};

interface OrderItemTemplateProps {
    product: Product;
    label?: string;
    onClick?: any;
    isShowSku?: boolean;
};

const OrderItemTemplate: React.FC<OrderItemTemplateProps> = ({ product, label, onClick, isShowSku = false }) => {
    const classes = useStyles();
    const { metaData: filterData } = useMetaDataService();
    const productAttributes = useMemo(() => {
        if(product.attributes){
            if (product.attributes.SPECIFICATION) {
                const sortedSpecificationAttributes = sortSpecificationAttributes(product.attributes.SPECIFICATION, filterData.attributes);
                return <ProductUpcItemTemplate data={sortedSpecificationAttributes} gridTemplate={gridTemplate} />
            }
            else if (product.attributes.productSpecification) {
                return <SecondaryProductUpcItemTemplate data={product.attributes.productSpecification} gridTemplate={gridTemplate} />
            } else {
                return <SecondaryLotProductTemplate data={product.attributes} gridTemplate={gridTemplate}/>
            }
        }
        else if(product.attributes === null) {
            return <SecondaryLotProductTemplate data={product.secondaryLotAttributes} gridTemplate={gridTemplate}/>
        }
        
    },[product]);

    return (
        <>
            <div className={`${classes.webContainer} border-2  flex-col rounded-lg`}>
                <div className="flex">

                    <div className="relative w-1/4">
                        <img className="h-52 w-full rounded-l-lg object-cover" src={product?.catalogueImages?.[ 0 ]?.path || product?.catalogueImage || product?.catalogueImages || noImage} alt="" />
                        <div className="flex gap-x-2 pt-1.5 px-1.5 absolute top-0 right-0 ">
                            {/* <div className={`${classes.iconContainer} flex gap-x-2 rounded p-1.5`}>
                                <ShareIcon className="text-sm" />
                            </div> */}
                        </div>
                    </div>
                    <div className="grid gap-y-2 px-6 py-2 w-3/4 mt-0 m-auto">
                        <OrderProductItemTemplate label={label} product={product} onClick={onClick} />
                        <div className="flex flex-col">
                            <div className="flex justify-between h-full">
                                <div className="w-4/6">
                                    {productAttributes}
                                </div> 
                                <div className={`${classes.title} flex items-center justify-center flex-col p-4 rounded-lg gap-3 w-2/6 `} >
                                    <span className="text-sm font-normal">
                                        Quantity: {product?.quantity?.toFixed(3) || product?.totalQuantity?.toFixed(3) } {product.uom}
                                    </span>
                                    {product?.requestPrice && <span className="text-sm font-normal">
                                        Request Price: {toInr(product?.requestPrice)}
                                    </span>}
                                    {product.price && (
                                        <span className="text-base font-medium">Price: {toInr(parseInt(product?.price) || 0)}</span>
                                    )}
                                </div>
                            </div>
                            {isShowSku && <div className={`${classes.attributes} p-1 rounded mt-3`}>
                                <TableContainer className="w-full">
                                    <Table className="w-full" size="small" aria-label="sku detail table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Sr No.</TableCell>
                                                <TableCell >Warehouse</TableCell>
                                                <TableCell >Packaging</TableCell>
                                                <TableCell >Quantity</TableCell>
                                                <TableCell >Price</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {product?.skuData?.map((sku: any, index: any) => (
                                            <TableRow
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell>{index+1}</TableCell>
                                                <TableCell>{sku?.warehouse}</TableCell>
                                                <TableCell>{snakeCaseToTitleCase(sku?.packaging)}</TableCell>
                                                <TableCell>{sku?.quantity+` ${product?.uom || ""}`}</TableCell>
                                                <TableCell>{toInr(sku?.price || 0)}</TableCell>
                                            </TableRow>
                                            ))}

                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>}
                          
                        </div>
                    </div>
                </div>

            </div>

            <div className={`${classes.mobileContainer} border rounded-lg`}>
                
                <img className="w-full h-40 rounded-t-lg object-cover" src={product?.catalogueImages?.[ 0 ]?.path || product?.catalogueImage || product?.catalogueImages || noImage} alt="" />
                
                <div className="grid gap-y-4 px-3 pt-2 pb-4 w-full mt-0 m-auto">
                    <OrderProductItemTemplate product={product} />

                    <div className="">{productAttributes}</div>

                    <div className="flex justify-between">
                        <div className={`${classes.title} grid gap-y-2 my-auto`} >
                            {/* <span className="text-sm font-normal">Quantity: {product?.quantity.toFixed(3)} {product.uom}</span> */}
                            {product.price && (
                                <span className="text-sm font-medium">Price: {toInr(parseInt(product?.price) || 0)}</span>
                            )}
                        </div>

                        {/* <div className="my-auto">
                            {label && (label !== 'Buy again' ? (
                                <Button variant="outlined" label={label} onClick={onClick} />
                            ) : (
                                <Button variant="containedLight" label={label} onClick={onClick} disabled />
                            ))}
                        </div> */}
                       
                    </div> 
                            
                   
                    {isShowSku && <div className={`${classes.attributes} p-1 rounded overflow-x-auto`}>
                        <TableContainer sx={{ width: "600px"}}>
                            <Table className="w-full" size="small" aria-label="sku detail table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Sr No.</TableCell>
                                        <TableCell >Warehouse</TableCell>
                                        <TableCell >Packaging</TableCell>
                                        <TableCell >Quantity</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {product?.skuData?.map((sku: any, index: any) => (
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell>{index+1}</TableCell>
                                        <TableCell>{sku?.warehouse}</TableCell>
                                        <TableCell>{sku?.packaging}</TableCell>
                                        <TableCell>{sku?.quantity+` ${product.uom}`}</TableCell>
                                    </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>}

                   
                </div>
            </div>
        </>
    );
};

export default OrderItemTemplate;


