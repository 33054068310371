import { Grid } from "@mui/material";

import { useLeadService } from "../../../services/useLeadService";
import { useEffect, useRef, useState } from "react";
import { createUseStyles } from "react-jss";
import { useNavigate, useParams } from "react-router-dom";
import { Attribute } from "../../../utils/types";
import SecondaryProductUpcItemTemplate from "../ProductUpc/SecondaryProductUpcItem";
import Carousel from "react-multi-carousel";
import CarouselRef from 'react-multi-carousel';
import classType from "../../../assets/icons/classType.svg";
import grade from "../../../assets/icons/grade.svg";
import shape from "../../../assets/icons/shape.svg";
import { CUSTOMER_ROUTES } from "../../../utils/constant";
import Button from "../../atoms/Button/Button";
import CatalogueImageTemplate, { IImage } from "../CatalogueDetail/CatalogueImage.template";
import CatalogueDetailsTemplate from "../CatalogueDetail/CatalogueDetails.template";

const useStyles = createUseStyles((theme: any) => ({
    container: {
        border: `1px solid ${theme.palette.border.neutral.neutral200}`,
        padding: "24px"
    },
    title: {
        color: theme.palette.text.primary.primary500,
    },
    property: {
        color: theme.palette.text.neutral.neutral500,
    },
    value: {
        color: theme.palette.text.neutral.neutral500,
    },
    detailContainer: {
        display: "flex",
        justifyContent: "space-between",
    },
    detailSubContainer: {
        rowGap: "8px"
    },
    catalogueName: {
        color: theme.palette.text.primary.primary500,
    },
    carouselContainer: {
        "& .react-multiple-carousel__arrow--right": {
            right: "0 !important"
        },
        "& .react-multiple-carousel__arrow--left": {
            left: "0 !important"
        }
    },
    webContainer: {
        display: "flex"
    },
    mobileContainer: {
        display: "none"
    },
    "@media (max-width: 476px)": {
        webContainer: {
            display: "none"
        },
        mobileContainer: {
            display: "grid"
        },
        container: {
            border: "none",
            padding: "0px",
            marginTop: "56px"
        },
        detailContainer: {
            display: "grid",
            rowGap: "16px"
        },
        detailSubContainer: {
            rowGap: "4px"
        },
        carouselContainer: {
            "& .react-multiple-carousel__arrow--right": {
                right: "0 !important"
            },
            "& .react-multiple-carousel__arrow--left": {
                left: "0 !important"
            },
            "& .react-multi-carousel-dot-list": {
                top: "225px"
            },
            maxWidth: "287px",
            boxShadow: `rgba(0, 0, 0, 0.35) 0px 5px 20px`,
        },
    },
}));

export interface LeadUpc {
    id: number;
    name: string;
    classType: string;
    brand: string;
    standard?: string;
    grade: string;
    manufacturer: string;
    attributes: UpcAttribute;
    warehouse: string;
    uom: string;
    catalogueImages: IImage[];
    quantity?: string;
    packagingType: string;
}

export interface UpcAttribute {
    SPECIFICATION?: Attribute[];
    productSpecification?: Attribute[];
}

const gridTemplate = {
    xs: 4,
    sm: 4,
    md: 3,
    lg: 2,
    xl: 2,
};

const catalogueDetailsGridTemplate = {
    xs: 6,
    sm: 6,
    md: 6,
    lg: 6,
    xl: 6,
};

const responsive = {
    desktop: {
        breakpoint: { max: 3000, min: 1280 },
        items: 4,
        partialVisibilityGutter: 10
    },
    tablet: {
        breakpoint: { max: 1280, min: 980 },
        items: 3,
        partialVisibilityGutter: 10
    },
    mobile: {
        breakpoint: { max: 980, min: 670 },
        items: 2,
        partialVisibilityGutter: 10
    },
    "sm-mobile": {
        breakpoint: { max: 670, min: 0 },
        items: 1
    }
};

const SecondaryLeadUpcViewTemplate: React.FC = () => {
    const classes = useStyles();
    const navigate = useNavigate();
    const leadService = useLeadService();
    const params = useParams();
    const [leadUpc, setLeadUpcTo] = useState<LeadUpc | null>(null);

    const loadLeadUpc = async () => {
        try {
            const leadUpcResponse = await leadService.getSecondaryLeadUpcById(Number(params.leadUpcId));
            if (leadUpcResponse?.data?.data) {
                const formattedLeadData: LeadUpc = {
                    ...leadUpcResponse.data.data,
                    name: leadUpcResponse.data.data.catalogueName,
                };
                setLeadUpcTo(formattedLeadData);
            } else {
                throw new Error("Rfqs fetch failed");
            }
        } catch (error) {
            setLeadUpcTo(null);
        }
    };

    const carouselRef = useRef<CarouselRef>(null);

    const CATALOGUE_DETAILS_SCHEMA = [{ label: "grade", icon: grade }, { label: "classType", icon: classType }, { label: "shape", icon: shape }];

    useEffect(() => {
        loadLeadUpc();
    }, [params.leadUpcId]);

    const handleBack = () => {
        navigate(`${CUSTOMER_ROUTES.DASHBOARD}${CUSTOMER_ROUTES.LEAD_SECONDARY}`)
    }

    return (
        <div className="grid gap-y-5">
            <div className={`${classes.container} w-full rounded-lg`}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                        <div className={classes.mobileContainer}>
                            <div>
                                <div className={`${classes.catalogueName} mb-4 text-base font-semibold`}>{leadUpc?.name}</div>
                                {leadUpc && leadUpc?.catalogueImages.length > 0 && <div className={`${classes.carouselContainer} relative rounded-2xl mb-6 mx-auto`}>
                                    <Carousel
                                        responsive={responsive}
                                        showDots={true}
                                        infinite={true}
                                        partialVisible={true}
                                        ref={carouselRef}
                                        removeArrowOnDeviceType={["sm-mobile"]}
                                        renderDotsOutside={true}
                                    >
                                        {leadUpc?.catalogueImages.map((item: any) => (
                                            <img className='rounded-2xl h-56 w-full object-cover' src={item?.path} alt="catalogueImages" />
                                        ))}
                                    </Carousel>
                                </div>}
                            </div>
                        </div>
                        <div className={classes.webContainer}>
                            <CatalogueImageTemplate isSharable={false} catalogueImages={leadUpc?.catalogueImages} />
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={8} lg={8} xl={8} >
                        <div className="grid gap-y-6 mt-0 m-auto">
                            <div>
                                <CatalogueDetailsTemplate catalogue={leadUpc} CATALOGUE_DETAILS_SCHEMA={CATALOGUE_DETAILS_SCHEMA} gridTemplate={catalogueDetailsGridTemplate} />
                            </div>
                            <SecondaryProductUpcItemTemplate data={leadUpc?.attributes?.productSpecification || null} gridTemplate={gridTemplate} />
                            <div className={`${classes.detailContainer}`}>
                                <div className={`${classes.detailSubContainer} grid `}>
                                    <div className={`${classes.property} text-sm font-semibold`}>Warehouse Location</div>
                                    <div className={`${classes.property} text-sm font-normal`}>{leadUpc?.warehouse}</div>
                                </div>
                                <div className={`${classes.detailSubContainer} grid `}>
                                    <div className={`${classes.property} text-sm font-semibold`}>Packaging Types</div>
                                    <div className={`${classes.property} text-sm font-normal`}>{leadUpc?.packagingType}</div>
                                </div>
                                <div className={`${classes.detailSubContainer} grid `}>
                                    <div className={`${classes.property} text-sm font-semibold`}>Quantity</div>
                                    <div className={`${classes.property} text-sm font-normal`}>{leadUpc?.quantity}</div>
                                </div>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </div>
            <div className="flex justify-end">
                <Button variant="tertiaryContained" label="Back" onClick={handleBack} />
            </div>
        </div>
    );
};

export default SecondaryLeadUpcViewTemplate;
