import React from "react";
import { createUseStyles } from "react-jss";
import { Grid } from "@mui/material";
import { getEnumValue, keyToLabel } from "../../../utils/helper";
import { LeadUpc } from "../Lead/LeadUpcView.template"
import { SecondaryLotLeadCatalogueDetails } from "../Lead/SecondaryLotLeadView.template";
import { ICatalogue } from "../../pages/CatalogueDetail/MultipleUpcCatalogueDetails.page";
import { IGridTemplate } from "../../organisms/GridView/GridView.organism";
import { ISecondaryCatalogue } from "../Customization/SelectCustomization.template";

const useStyles = createUseStyles((theme: any) => ({
	maxContainer: {
	},
	container: {
		border: `1px solid ${theme.palette.border.neutral.neutral800}`,
		width: "100%"
	},
	title: {
		color: theme.palette.text.primary.primary900,
		fontSize: "24px"
	},
	// property: {
	// 	color: theme.palette.text.primaryLightAccent,
	// 	fontSize: "14px"
	// },
	// value: {
	// 	color: theme.palette.text._primaryDarkAccent,
	// 	fontSize: "12px"
	// },
	// "@media (max-width: 476px)": {
	// 	container: {
	// 		width: "100%",
	// 	},
	// 	title: {
	// 		display: "none"
	// 	},
	// 	property: {
	// 		fontSize: "14px"
	// 	},
	// 	value: {
	// 		fontSize: "12px"
	// 	}
	// }
}));

interface ICatalogueTemplateProps {
	catalogue: ICatalogue | LeadUpc | null | ISecondaryCatalogue | SecondaryLotLeadCatalogueDetails;
	CATALOGUE_DETAILS_SCHEMA: any;
	gridTemplate: IGridTemplate;
}

const CatalogueDetailsTemplate: React.FC<ICatalogueTemplateProps> = ({ catalogue, CATALOGUE_DETAILS_SCHEMA, gridTemplate }) => {

	const classes = useStyles();

	return (
		<div className="grid gap-y-6">
			<div className={ `${ classes.title } font-semibold`}>{ catalogue?.name }</div>
			{/* <div className={ `grid p-4 gap-y-2 ${ classes.container } rounded-2xl` }>
				<div>
					<Grid container spacing={ 2 }>
						{ CATALOGUE_DETAILS_SCHEMA.map((key: any, index: number) => (
							<Grid item xs={ gridTemplate.xs } sm={ gridTemplate.sm } md={ gridTemplate.md } lg={ gridTemplate.lg } xl={ gridTemplate.xl } key={ key.label }>
								<div className="flex gap-x-2 ">
									<div className="my-auto w-5"><img src={ key.icon } className="m-auto" alt="" /></div>
									<div className="grid ">
										<div className={ `${ classes.property } font-medium` }>{ getEnumValue((catalogue as any)?.[key.label] || "") }</div>
										<div className={ `${ classes.value } font-normal text-start` }>{ keyToLabel(key.label) }</div>
									</div>
								</div>
							</Grid>
						))}
					</Grid>
				</div>
			</div> */}
		</div>
	);
};

export default CatalogueDetailsTemplate;