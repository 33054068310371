import { createUseStyles } from "react-jss";
import { CONNECT_WITH_US_POPUP } from "../../../utils/constant";

const useStyles = createUseStyles((theme: any) => ({
    contactUsWrapper: {
        display: "flex",
        width: "325px",
        flexDirection: "column",
        border: `1px solid ${theme.palette.border.neutral.neutral100}`,
        background: "white",
        boxShadow: `2px 2px 12px 0px rgba(0, 0, 0, 0.12)`,
        position: "fixed",
        zIndex: 14,
        top: "220px",
        cursor: "default",
        padding: "18px",
    },
    detailsWrapper: {
        display: "flex",
        flexDirection: "column",
        color: theme.palette.text.neutral.neutral800,
    },
    subText: {
        color: theme.palette.text.neutral.neutral600,
     
    },
}))

const ContactUsPopup: React.FC = () => {

    const classes = useStyles();
    
    return (
        <div className={`${classes.contactUsWrapper} contact-us-popup gap-6 rounded-2xl items-start`}>
            {CONNECT_WITH_US_POPUP.map(({ icon, text, country, mobile, email, description }) => (
                <div className="flex gap-x-6">
                    <img src={icon} alt="icon"/>
                    <div className={`${classes.detailsWrapper} items-start my-auto`}>
                        <span className="text-sm font-medium leading-4 mb-1.5">{text}</span>
                        <span className={`text-sm font-medium leading-4 ${classes.subText}`}>{`${mobile ? country + " " + mobile : email}`}</span>
                        <p className={`text-xs font-normal line ${classes.subText}`}>{description}</p>
                    </div>
                </div>
            ))}
        </div>
    )
}

export default ContactUsPopup;