import React from 'react';
import { createUseStyles } from 'react-jss';
import tradeAssurance from '../../../assets/images/tradeAssurance.jpg';
import paymentCard from '../../../../src/assets/images/paymentCard.svg';
import Button from '../../atoms/Button/Button';
import refund from '../../../../src/assets/images/refund.svg';
import work from '../../../../src/assets/images/work.svg';
import sellPageImg from '../../../../src/assets/images/sellPageImg.svg';

const useStyles = createUseStyles((theme: any) => ({
    bannerContainer: {
        width: '100%',
        height: '650px',
        backgroundImage: `url(${tradeAssurance})`,
        backgroundSize: 'cover',
        position: 'relative',
    },
    container: {
        width: '1297px',
        margin: '0 auto',
    },
    bannerSellContainer: {
        padding: '10% 0px'
    },
    heading: {
        fontSize: '56px',
        lineHeight: '66px',
        color: theme.palette.text.primary.primary50,
    },
    bannerDescription: {
        color: theme.palette.text.primary.primary50,
    },
    text: {
        fontSize: '32px',
        color: theme.palette.text.primary.primary800,
    },
    coverageContainer: {
        background: theme.palette.background.neutral.neutral50,
        padding: '56px 70px'
    },
    coverageDescription: {
        "& h3": {
            fontSize: '40px',
            lineHeight: '48px',
            color: theme.palette.text.primary.primary700,
        },
        "& p": {
            lineHeight: '22px',
            color: theme.palette.text.primary.primary900,
        },
    },
    coverageSecondaryContainer: {

    },
    coverageContent: {
        "& span": {
            color: theme.palette.text.primary.primary700,
            fontSize: '40px',
        },
        "& p": {
            color: theme.palette.text.primary.primary900,
        },
    },
    headingText: {
        color: theme.palette.text.primary.primary700,
    },
    serialNumber: {
        background: theme.palette.background.primary.primary500,
        boxShadow: '7.4px 7.4px 0px 0px rgba(39, 67, 160, 0.30)',
        width: '64px',
        height: '64px',
        "& span": {
            fontSize: '28px',
            color: '#fff',
        },
    },
    purchaseContent: {
        "& span": {
            color: theme.palette.text.primary.primary900,
        },
        "& p ": {
            color: theme.palette.text.neutral.neutral700,
        },
    },
    tradeContainer: {
        background: theme.palette.background.neutral.neutral50,
        padding: '70px',
    },
    tradeHeading: {
        color: theme.palette.text.neutral.neutral700,
    },
    tradeCard: {
        padding: '30px 20px',
        backgroundColor: '#fff',
        border: `1px solid ${theme.palette.border.neutral.neutral200}`,
        borderRadius: '14px',
        width: '400px',
        "&  h5": {
            fontSize: '28px',
            color: theme.palette.text.neutral.neutral900,
        },
        "&  p": {
            color: theme.palette.text.neutral.neutral700,
        },
    },
    cardsSection: {
        padding: '80px 0px',
    },
    secondaryCards: {
        "& h4": {
            fontSize: '40px',
            color: theme.palette.text.neutral.neutral800,
        },
        "& p": {
            color: theme.palette.text.neutral.neutral800,
            lineHeight: '22px',
        },

    },
    buyContainer: {
        height: '350px',
        backgroundImage: `url(${sellPageImg})`,
        backgroundSize: 'cover',
        position: 'relative',
        "& p": {
            color: theme.palette.text.primary.primary50,
            fontSize: '28px',
        },
    },
    btn: {
        background: theme.palette.background.complementary.complementary600,
    },
}));

const TradeAssurance: React.FC = () => {

    const classes = useStyles();

    return (
        <>
            <div className={`${classes.bannerContainer}`}>
                <div className={`${classes.container} ${classes.bannerSellContainer}`}>
                    <h1 className={`${classes.heading} mb-4 font-semibold`}>SteelBazaar Trade <br /> Assurance</h1>
                    <p className={`${classes.bannerDescription} text-2xl mb-10 font-semibold`}>Complete Protection from Payment to Delivery</p>
                </div>
            </div>

            <div className={`${classes.coverageContainer}`}>
                <div className={`${classes.container} flex items-center`}>
                    <div className={`${classes.coverageDescription} flex flex-col gap-14 w-1/2 pr-4`}>
                        <h3 className='font-medium'>Comprehensive Coverage <br /> Across Every Stage of Your <br /> Purchase</h3>
                        <p className='text-lg font-normal'>With SteelBazaar's Trade Assurance, every part of your <br /> buying journey is protected. We ensure you have peace of <br /> mind from order placement to delivery.</p>
                    </div>
                    <div className={`${classes.coverageSecondaryContainer} flex flex-wrap w-1/2`}>
                        <div className={`${classes.coverageContent} w-1/2 mb-20`}>
                            <span className='font-semibold'>10,000+</span>
                            <p className='text-2xl'>Trade Assurance Orders Processed</p>
                        </div>
                        <div className={`${classes.coverageContent} w-1/2`}>
                        <span className='font-semibold'>5,000+</span>
                            <p className='text-2xl'>Tons of Steel Purchased</p>
                        </div>
                        <div className={`${classes.coverageContent} w-1/2`}>
                        <span className='font-semibold'>1,000+</span>
                            <p className='text-2xl'>Verified Suppliers</p>
                        </div>
                        <div className={`${classes.coverageContent} w-1/2`}>
                        <span className='font-semibold'>50,000+</span>
                            <p className='text-2xl'>Products Available</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='pt-20 pb-20'>
                <div className={`${classes.container}`}>
                    <h2 className={`${classes.headingText} text-5xl font-bold mb-[50px] text-center`}>How We Protect Your Purchases</h2>
                    <div className='flex flex-wrap gap-y-16'>
                        <div className='flex gap-[30px] w-1/2 '>
                            <div className={`${classes.serialNumber} flex items-center justify-center`}><span className='font-bold'>01</span></div>
                            <div className={`${classes.purchaseContent}`}>
                                <span className='text-2xl'>Verified Suppliers</span>
                                <p className='text-lg'>We only work with suppliers who meet our strict quality and <br />reliability standards.</p>
                            </div>
                        </div>
                        <div className='flex gap-[30px] w-1/2'>
                            <div className={`${classes.serialNumber} flex items-center justify-center`}><span className='font-bold'>02</span></div>
                            <div className={`${classes.purchaseContent}`}>
                                <span className='text-2xl'>Guaranteed Delivery</span>
                                <p className='text-lg'>Get your products on time, or we'll step in to resolve it.</p>
                            </div>
                        </div>
                        <div className='flex gap-[30px] w-1/2'>
                            <div className={`${classes.serialNumber} flex items-center justify-center`}><span className='font-bold'>03</span></div>
                            <div className={`${classes.purchaseContent}`}>
                                <span className='text-2xl'>Secure Payments</span>
                                <p className='text-lg'>Your payment is held securely and only released when you <br />confirm satisfactory delivery.</p>
                            </div>
                        </div>
                        <div className='flex gap-[30px] w-1/2'>
                            <div className={`${classes.serialNumber} flex items-center justify-center`}><span className='font-bold'>04</span></div>
                            <div className={`${classes.purchaseContent}`}>
                                <span className='text-2xl'>Resolution Support</span>
                                <p className='text-lg'>In case of any disputes, our team is here to mediate and <br />provide solutions quickly.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className={`${classes.tradeContainer}`}>
                <div className={`${classes.container}`}>
                    <h2 className={`${classes.tradeHeading} mb-9 text-center text-5xl font-bold`}>What's Covered by Trade Assurance?</h2>
                    <div className='flex gap-12'>
                        <div className={`${classes.tradeCard} flex flex-col gap-2`}>
                            <h5 className='font-semibold'>Safe & Easy Payments</h5>
                            <p className='text-base'>Pay through secure methods, and we'll hold the payment until you're happy with the delivery.</p>
                        </div>
                        <div className={`${classes.tradeCard} flex flex-col gap-2`}>
                            <h5 className='font-semibold'>Money-Back Guarantee</h5>
                            <p className='text-base'>If your products don't meet your expectations or specifications, we ensure a refund or replacement.</p>
                        </div>
                        <div className={`${classes.tradeCard} flex flex-col gap-2`}>
                            <h5 className='font-semibold'>After-Sales Protections</h5>
                            <p className='text-base'>We offer post-delivery support to ensure your orders meet your standards, and assist with any claims if needed.</p>
                        </div>
                    </div>
                    <div className='flex justify-center mt-9'>
                    <Button variant={'primaryContained'} label="Learn More" className={classes.btn}></Button>
                    </div>
                </div>
            </div>

            <div className={`${classes.cardsSection}`}>
                <div className={classes.container}>
                    <div className='flex gap-20 items-center mb-20'>
                        <div className={`${classes.secondaryCards} pr-10`}>
                            <h4 className='mb-4 font-medium'>Safe & Easy Payments</h4>
                            <p className='text-lg font-normal'>We offer multiple secure payment options so you can transact with confidence. Our Trade Assurance holds your payment securely and only releases it when you confirm you've received the order as promised.</p>
                        </div>
                        <img src={paymentCard} alt="" />
                    </div>
                    <div className='flex gap-20 items-center mb-20 mt-18'>
                        <img src={refund} alt="" />
                        <div className={`${classes.secondaryCards} pr-10`}>
                            <h4 className='mb-4 font-medium'>Money-Back Policy</h4>
                            <p className='text-lg font-normal'>In case the products don't match your specifications or the quality is not as agreed upon, SteelBazaar guarantees a refund or replacement. Your satisfaction is our top priority, and we're committed to resolving any issues that arise.</p>
                        </div>
                    </div>
                    <div className='flex gap-20 items-center mt-18'>
                        <div className={`${classes.secondaryCards} pr-10`}>
                            <h4 className='mb-4 font-medium'>After-Sales Protections</h4>
                            <p className='text-lg font-normal'>Our support doesn't end with delivery. We provide after-sales protections to help with any concerns regarding your order, whether it's product quality, delivery issues, or claims processing. Our dedicated team ensures quick and reliable assistance, giving you peace of mind even after the purchase is complete.</p>
                        </div>
                        <img src={work} alt="" />
                    </div>
                </div>
            </div>
            <div className={`${classes.buyContainer} w-full flex justify-center items-center`}>
                    <p >Trade confidently with SteelBazaar-where every transaction is backed by our Trade <br /> Assurance for a safer, smoother, and more secure steel purchasing experience.</p>
                </div>
        </>
    );
};
export default TradeAssurance;
