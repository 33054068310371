import { Tab, Box } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import React, { ReactNode } from 'react'
import { createUseStyles } from 'react-jss';

export interface ITabsSchema {
    label: string,
    component: ReactNode,
    disabled?: boolean
}

interface TabsProps {
    schema: ITabsSchema[],
    value: string,
    setValue: (value: any) => void;
    fullWidth?: boolean;
    selectedTabStyle?: string
}

const useStyles = createUseStyles((theme: any) => ({
    selectedTab: {
        color: theme.palette.text.neutral.neutral400,
        "&.Mui-selected": {
            background: theme.palette.text.primary.primary50,
            color: `${theme.palette.text.primary.primary400} !important`,
            borderBottom: `2px solid ${theme.palette.border.primary.primary400} !important`,
            fontWeight: `500 !important`,
            borderRadius: '8px 8px 0 0',
            zIndex: '2 !important',
            fontSize: "16px"
        },
    },
    defaultTab: {
        color: `${theme.palette.text.neutral.neutral50} !important`,
        backgroundColor: `${theme.palette.text.neutral.neutral100} !important`,
        height: '1px !important',
        left: '0px !important',
        width: '100% !important',
    },
    labelTab: {
        textTransform: 'none !important',
    },
    tabContainer: {
        "& .MuiTabs-flexContainer": {
            display: 'flex !important',
            gap: '8px !important',
        },
    }
}));

const TabsV2: React.FC<TabsProps> = ({ schema, value, setValue, fullWidth = true, selectedTabStyle }) => {
    const classes = useStyles();
    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(parseInt(newValue));
    };

    return (
        <Box className='w-full' >
            <TabContext value={value}>
                <div className={`${fullWidth ? 'w-full' : 'w-max'}`}>
                    <TabList onChange={handleChange} aria-label="lab API tabs example" TabIndicatorProps={{ className: `${classes.defaultTab}` }} className={`${classes.tabContainer} relative`}>
                        {schema.map((tab, index) => (
                            <Tab label={tab['label']} value={(index + 1).toString()} key={index} className={`${classes.selectedTab} ${selectedTabStyle} ${classes.labelTab} flex gap-4 !text-base !font-normal`} disabled={tab['disabled'] ?? false} />
                        ))}
                    </TabList>
                </div>
                {schema.map((tab, index) => (
                    <TabPanel className={"!px-0 !py-0"} value={(index + 1).toString()} key={index}>{tab['component']}</TabPanel>
                ))}
            </TabContext>
        </Box>
    );
}
export default TabsV2