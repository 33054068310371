import React from 'react';
import { createUseStyles } from 'react-jss';
import noLocation from '../../../assets/images/noLocation.svg';
import Button from '../../atoms/Button/Button';
import { LOCATION_STATES } from '../../../utils/types';
import { FormikProps } from 'formik';
import { ILocationGstinForm } from '../../pages/DashBoard/BussinessLocation.page';

export interface LocationSectionProps {
    setCurrentSectionTo: (section: LOCATION_STATES) => void;
    formik: FormikProps<ILocationGstinForm>;
}

const useStyles = createUseStyles((theme: any) => ({
    container: {
        background: theme.palette.background.neutral.neutral50,
    },
    heading: {
        color: theme.palette.text.primary.primary800,
    }
}));

const AddBusinessLocationTemplate: React.FC<LocationSectionProps> = ({ formik, setCurrentSectionTo }) => {
    const classes = useStyles();
    return (
        <div className={`${classes.container} py-8 flex justify-center  rounded-3xl`}>
            <div className='flex flex-col items-center'>
                <img src={noLocation} alt="locationImage" className='mb-8' />
                <div className={`mb-4 text-base font-semibold ${classes.heading}`}>You haven't provide a location for your business.</div>
                <Button variant="primaryContained" label="Add Location" onClick={() => { setCurrentSectionTo(LOCATION_STATES.ADD_LOCATION_NO) }} />
            </div>
        </div>
    )
};

export default AddBusinessLocationTemplate;