import { POLICY_SECTION } from "../../../utils/constant";
import { createUseStyles } from "react-jss";
import React from "react";
import AccessToThePlatform from "../../template/PrivacyPolicy/AccessToThePlatform.template";
import General from "../../template/PrivacyPolicy/General.template";
import LogisticAndFulfillmentServicesTemplate from "../../template/PrivacyPolicy/LogisticsAndFulfillmentServicesPolicy.template";
import PaymentsTemplate from "../../template/PrivacyPolicy/Payments.template";
import TermsAndConditionsIntroduction from "../../template/PrivacyPolicy/TermsAndConditionsIntoduction.template";
import UseOfThePlatformTemplate from "../../template/PrivacyPolicy/UseOfThePlatform.template";
import YourProfileAndYourInformation from "../../template/PrivacyPolicy/YourProfileAndYourInformation.template";
import ContentPreviewer, { IContentComponent } from "../../organisms/ContentPreviewer/ContentPreviewer";


const useStyles = createUseStyles((theme: any) => ({
    contentStyle: {
        // color: theme.palette.text._primaryDarkAccent,
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '28px',
    },
    boxStyle: {
        padding: '14px 10px',
        borderRadius: '8px',
        background: theme.palette.background.complementary.complementary960,
        marginTop: '16px',
        marginBottom: '24px',
    },
    subHeading: {
        color: theme.palette.text.complementary.complementary800,
        fontSize: '18px',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: '120%',
    },
}));

const TermsAndConditions: React.FC = () => {
    const classes = useStyles();
    const TermsAndConditionsSchem: IContentComponent[] = [
        {
            Key: POLICY_SECTION.TERMS_INTRODUCTION,
            component: <TermsAndConditionsIntroduction {...classes}/>
        },
        {
            Key: POLICY_SECTION.GENERAL,
            component: <General  header={POLICY_SECTION.GENERAL} {...classes} />
        },
        {
            Key: POLICY_SECTION.ACCESS_TO_THE_PLATFORM,
            component: <AccessToThePlatform header={POLICY_SECTION.ACCESS_TO_THE_PLATFORM} {...classes} />
        },
        {
            Key: POLICY_SECTION.YOUR_PROFILE_AND_YOUR_INFORMATION,
            component: <YourProfileAndYourInformation {...classes} header={POLICY_SECTION.YOUR_PROFILE_AND_YOUR_INFORMATION} />
        },
        {
            Key: POLICY_SECTION.USE_OF_THE_PLATFORM,
            component: <UseOfThePlatformTemplate {...classes} header={POLICY_SECTION.USE_OF_THE_PLATFORM} />
        },
        {
            Key: POLICY_SECTION.PAYMENTS,
            component: <PaymentsTemplate {...classes} header={POLICY_SECTION.PAYMENTS} />
        },
        {   
            Key: POLICY_SECTION.LOGISTICS_AND_FULFILLMENT_SERVICES,
            component: <LogisticAndFulfillmentServicesTemplate {...classes} header={POLICY_SECTION.LOGISTICS_AND_FULFILLMENT_SERVICES}/>
        }
       
    ]

    return <ContentPreviewer policySchema={TermsAndConditionsSchem}/>;
}

export default TermsAndConditions;