import { FormikProps } from 'formik'
import React from 'react'
import { IMaterialOtherPreference } from './MaterialOtherPreferences.template';
import { createUseStyles } from 'react-jss';
import Button from '../../../atoms/Button/Button';
import { MODE } from '../../../../utils/types';
import { capitalizeFirstLetter } from '../../../../utils/helper';
import { IMaterialBrandManufacturer } from './MaterialBrandManufacturer.template';

interface MaterialBrandManufacturerViewTemplateProps {
    formik: FormikProps<IMaterialBrandManufacturer>
    setModeTo: (value: MODE) => void;
    mode: MODE;
    setIsFormModeTo: (value: boolean) => void;
}

const useStyles = createUseStyles((theme: any) => ({
    primary500: {
        color: theme.palette.text.primary.primary500,
    },
    heading: {
        color: theme.palette.text.primary.primary800
    },
    sectionBackground: {
        background: 'white',
        border: `1px solid ${theme.palette.border.neutral.neutral100}`
    },
    tertiary700: {
        color: theme.palette.text.primary.primary700
    },
    borderTop: {
        borderTop: `1px solid ${theme.palette.border.neutral.neutral100}`
    },
    selectedBrand:{
        color: theme.palette.text.neutral.neutral900
    }
}));

const MaterialBrandManufacturerViewTemplate: React.FC<MaterialBrandManufacturerViewTemplateProps> = ({ formik, setModeTo, mode, setIsFormModeTo }) => {
    const classes = useStyles();

    const handleEdit = () => {
        setIsFormModeTo(true);
    }

    return (
        <div className={`p-6 rounded-xl w-full ${classes.sectionBackground}`}>
           <div className={`grid gap-3 w-full`}>
                <div className={`w-full flex justify-between items-center`}>
                    <span className={`${classes.heading} text-lg font-medium`}>Manufacturer/Brand Preference</span>
                    <Button variant='tertiaryContained' label={`${mode !== MODE.ADD ? 'Edit' : '+Add'}`} className='!font-normal' onClick={handleEdit} />
                </div>
                {mode !== MODE.ADD && <div className={`grid gap-6 w-full pt-3 ${classes.borderTop}`}>
                    <div className='w-full'>
                        <div className={`${classes.tertiary700} font-normal text-base flex gap-4  `}>
                            <span className={`${classes.selectedBrand}`}>Selected Brands: </span>
                            <div className='flex  '>
                                {formik.values.brands?.map((option, index) => (
                                    <div className='flex '>
                                        <span className={`${classes.primary500}`}>{capitalizeFirstLetter(option?.label?.toLowerCase())}</span>
                                        {!(formik.values?.brands?.length - 1 === index) && <span className='mx-4'> | </span>}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>}
            </div>
        </div>
    )
}

export default MaterialBrandManufacturerViewTemplate