import React, { useEffect, useRef, useState } from 'react';
import { createUseStyles } from 'react-jss';
import Button from '../../../atoms/Button/Button';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import CarouselRef from 'react-multi-carousel';
import rightArrow from "../../../../assets/icons/rightArrow.svg";
import leftArrow from "../../../../assets/icons/leftArrow.svg"
import { useCategoryService } from '../../../../services/useCategoryService';
import { makeRoute, useDebounce } from '../../../../utils/helper';
import CatalogueGridItemSkeleton from '../../CatalogueList/CatalogueGridItemSkeleton.template';
import NoProduct from '../../../molecules/NoProduct/NoProduct';
import { useNavigate } from 'react-router-dom';
import { CUSTOMER_ROUTES, DEBOUNCE_TIME } from '../../../../utils/constant';
import { CATALOGUE_TYPE_STATES, CLASS_STATES, IPagination } from '../../../../utils/types';
import { useCatalogueService } from '../../../../services/useCatalogueService';
import { Catalogue } from '../../CatalogueList/CatalogueList.template';
import CatalogueGridItemV2 from '../CatalogueGridItemV2.template';

const responsive = {
    superLargeDesktop: {
        breakpoint: { max: 4000, min: 3000 },
        items: 5,
        partialVisibilityGutter: 0
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 6,
        partialVisibilityGutter: 40
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 4,
        partialVisibilityGutter: 10
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 3,
        partialVisibilityGutter: 0
    },
};

const useStyles = createUseStyles((theme: any) => ({
    mainContainer: {
        backgroundColor: theme.palette.background.neutral.neutral50,
        padding: "50px 0px 70px 0px"
    },
    headingContainer: {
        paddingBottom: "10px",
        borderBottom: `1px solid ${theme.palette.border.neutral.neutral200}`
    },
    heading: {
        color: theme.palette.background.primary.primary900,
        lineHeight: "34px",
        fontSize: "28px"
    },
    slide: {
        width: '156px',
        rowGap: "15px",
        color: theme.palette.background.primary.primary900,
        "&:hover": {
            color: theme.palette.background.primary.primary500,
        },
    },
    tab: {
        marginRight: "24px"
    },
    image: {
        width: "100%",
        height: "158px",
        borderRadius: "100px",
        backgroundColor: "white",
        border: `1px solid ${theme.palette.border.neutral.neutral100}`
    },
    categoryName: {
        // color: theme.palette.background.primary.primary900,
        lineHeight: "20px",
        fontSize: "16px"
    },
    carouselContainer: {
        "& .react-multiple-carousel__arrow--right": {
            right: "0 !important"
        },
        "& .react-multiple-carousel__arrow--left": {
            left: "0 !important"
        },
        "& .react-multi-carousel-item": {
        },
        "& .react-multi-carousel-list": {
            width: "1184px",
            margin: "0 auto",
        },
        "& .react-multi-carousel-list li": {
            width: "fit-content !important",
        },
    },
    customButtonContainer: {
        marginTop: "-10%",
    },
    customArrow: {
        borderRadius: "50%",
        width: "48px",
        height: "48px",
        cursor: "pointer",
        transition: "box-shadow 0.3s ease-in-out",
        boxShadow: `0px 6px 12px 2px rgba(10, 18, 41, 0.16), 0px 2px 4px 1px rgba(10, 18, 41, 0.04)`,
    },
    tabContainer: {
        borderTop: `1px solid ${theme.palette.border.neutral.neutral200}`,
        columnGap: "30px"
    },
    selectedValue: {
        color: theme.palette.text.primary.primary800,
        background: theme.palette.background.primary.primary50,
        transition: "color 0.3s ease-in-out, background 0.3s ease-in-out",
        padding: "8px 12px",
        border: `1px solid ${theme.palette.border.primary.primary200}`,
    },
    unselectedValue: {
        color: theme.palette.text.primary.primary800,
        border: `1px solid ${theme.palette.border.primary.primary200}`,
        "&:hover": {
            background: theme.palette.background.primary.primary50,
        },
    },
    catalogueCard:{
        backgroundColor: "white"
    }
}));

export interface ICasourselItem {
    path: string;
    id: number | string;
    label: string;
}

const RawSemiFinishedTemplate: React.FC = () => {

    const classes = useStyles();
    const navigate = useNavigate();
    const carouselRef = useRef<CarouselRef>(null);
    const catagoryService = useCategoryService();
    const catalogueService = useCatalogueService();
    const [loading, setLoading] = useState<boolean>(true);
    const [categories, setCategoriesTo] = useState<ICasourselItem[]>([]);
    const [selectedCategory, setSelectedCategory] = useState<ICasourselItem | null>(null);
    const [catalogues, setCataloguesTo] = useState<Catalogue[]>([]);
    const [pagination, setPaginationTo] = useState<IPagination>({
        size: 10,
        totalRecords: 0,
        page: 0,
    });


    const ButtonGroup = ({ next, previous, carouselState }: any) => {
        const { currentSlide, totalItems, slidesToShow } = carouselState;
        const isFirstSlide = currentSlide === 0;
        const isLastSlide = currentSlide === totalItems - slidesToShow;

        return (
            <div className={`${classes.customButtonContainer} flex justify-between items-center w-full`}>
                {!isFirstSlide && (
                    <div className={`${classes.customArrow} bg-white flex justify-center items-center`} onClick={previous}>
                        <img src={leftArrow} alt="" />
                    </div>
                )}
                {!isLastSlide && (
                    <div className={`${classes.customArrow} bg-white flex justify-center items-center ml-auto`} onClick={next}>
                        <img src={rightArrow} alt="" />
                    </div>
                )}
            </div>
        );
    };

    const CATEGORY_LEVEL = {
        PRODUCT_CATEGORY: 2,
    };

    const loadCategories = async () => {
        try {
            const params = {
                level: CATEGORY_LEVEL.PRODUCT_CATEGORY,
                limit: 100,
            };
            const { data: categoriesResponse } = await catagoryService.getAllCategories(params);
            if (categoriesResponse?.data?.length) {
                setCategoriesTo(categoriesResponse.data);
                setSelectedCategory(categoriesResponse.data[0]);
            } else {
                throw new Error("Categories fetch failed");
            }
        } catch (error) {
            setCategoriesTo([]);
            setSelectedCategory(null);
        }
    };

    useEffect(() => {
        loadCategories();
    }, []);

    const fetchCatalogues = async () => {
        try {
            setLoading(true);
            const payload: any = {
                classes: [CLASS_STATES.STANDARD, CLASS_STATES.NONSTANDARD],
                catalogueTypes: [CATALOGUE_TYPE_STATES.MAKE_TO_ORDER],
                categoryId: selectedCategory?.id ?? null
            };

            const cataloguesResponse = await catalogueService.getAllCatalogues(
                {
                    page: pagination.page,
                    size: pagination.size,
                    sort: "createdAt,desc",
                },
                payload
            );
            if (cataloguesResponse?.data?.data?.content?.length) {
                const { content } = cataloguesResponse.data.data;
                setCataloguesTo(content);
                setLoading(false);
            } else {
                throw new Error("Catalogue fetch failed");
            }
        } catch (error) {
            setCataloguesTo([]);
            setLoading(false);
        }
    };

    useEffect(() => {
        if (selectedCategory?.id) fetchCatalogues();
    }, [selectedCategory, pagination.page, pagination.size]);

    const data = [
        {
            name: "Most Popular",
            value: 0,
        },
        {
            name: "Hot Selling",
            value: 1,
        },
        {
            name: "Best Reviewed",
            value: 2,
        }
    ]
    const [selectedTab, setSelectedTab] = useState<string>('Most Popular');

    const handleClick = (item: any) => {
        setSelectedTab(item.name);
        setPaginationTo({
            size: 10,
            totalRecords: 0,
            page: item.value,
        });
    };

    const handleViewAllClick = () => {
        navigate(makeRoute(CUSTOMER_ROUTES.CATALOGUE_LISTING, { query: { "catalogueTypes": CATALOGUE_TYPE_STATES.MAKE_TO_ORDER, "categoryId": selectedCategory?.id } }));
    };

    const handleCategoryClick = (item: any) => () => {
        setSelectedCategory(item);
    }

    const debouncedHandleCategoryClick = useDebounce((item) => {
        handleCategoryClick(item)();
    }, DEBOUNCE_TIME.PLP_CATEGORIES);
    return (
        <div id='semiFinished' className={`${classes.mainContainer} w-full `}>
            <div className='w-[1300px] mx-auto grid gap-y-5'>
                <div className={`${classes.headingContainer} flex justify-between`}>
                    <div className={`${classes.heading} font-semibold my-auto`}>Raw / Semi Finished</div>
                    <Button className='!rounded-none !pr-3' variant="tertiaryText" label={"View All"} onClick={handleViewAllClick} />
                </div>
                <div className={`${classes.carouselContainer} relative`} style={{ height: "195px" }}>
                    <Carousel
                        responsive={responsive}
                        showDots={false}
                        infinite={false}
                        partialVisible={false}
                        ref={carouselRef}
                        arrows={false}
                        removeArrowOnDeviceType={["sm-mobile"]}
                        renderButtonGroupOutside={true}
                        customButtonGroup={<ButtonGroup />}
                    >
                        {categories.map((item: any, index: any) => (
                            <div className={` ${classes.slide} h-full grid cursor-pointer mr-6 ${index !== categories.length - 1 ? classes.tab : ''}`} key={item.id} onClick={() => debouncedHandleCategoryClick(item)} >
                                <img className={`${classes.image}`} src={item.path} alt="categoryImage" />
                                <div className={`${classes.categoryName} text-center`}>{item.label}</div>
                            </div>
                        ))}
                    </Carousel>
                </div>
                <div className={`${classes.tabContainer} flex pt-5`}>
                    {data.map((item: any, index: any) => (
                        <div
                            key={item.name}
                            className={`px-4 py-2 justify-center items-center rounded-lg ${selectedTab === item.name ? `${classes.selectedValue} font-medium text-sm cursor-pointer text-center p-1` : `${classes.unselectedValue} font-medium text-sm cursor-pointer`}`}
                            onClick={() => handleClick(item)}
                        >
                            {item.name}
                        </div>

                    ))}
                </div>

                <div className="grid">
                    {loading ? (
                        <div className="grid grid-cols-5 gap-4">
                            {[null, null, null, null, null].map((item: any, index) => (
                                <div key={index}>
                                    <CatalogueGridItemSkeleton />
                                </div>
                            ))}
                        </div>
                    ) : catalogues.length > 0 ? (
                        <div className="grid grid-cols-5 gap-4">
                            {catalogues.map((item: any, index: number) => (
                                <div key={item?.id || index}>
                                    <CatalogueGridItemV2 data={item} styles={classes.catalogueCard}/>
                                </div>
                            ))}
                        </div>
                    ) : (
                        <div className="mt-4">
                            <NoProduct text="No Catalogs" variant="Product List" />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default RawSemiFinishedTemplate;