import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from "yup";
import { useSnackbar } from '../../../../../hooks/useSnackBar';
import { IUSC, USCPayload, useVASMasterCategoryService } from '../../../../../services/useVASMasterCategoryService';
import { GENERIC_EXCEPTION_CODE, MODE, STATUS, VALUE_ADDED_SERVICE_ROUTES } from '../../../../../utils/constant';
import { makeRoute } from '../../../../../utils/helper';
import { HTTP_STATUS } from '../../../../../utils/types';
import USCViewTemplate from '../../../../template/DashBoard/ValueAddedService/USC/USCView.template';
import { useAuthenticatedUser } from '../../../../../hooks/useAuthenticatedUser';

const validationSchema = Yup.object().shape({
    name: Yup.string().max(30, "USC Name cannot be more than 30 characters").required("USC Name is required"),
    description: Yup.string().max(500, "Description cannot be more than 500 characters").required("Description is required"),
    superService: Yup.object().shape({
        id: Yup.number().required('Super Service is required'),
        label: Yup.string().required('Super Service is required')
    }).required('Super Service is required'),
    mainService: Yup.object().shape({
        id: Yup.number().required('Main Service is required'),
        label: Yup.string().required('Main Service is required')
    }).required('Main Service is required'),
    productService: Yup.object().shape({
        id: Yup.number().required('Service Category is required'),
        label: Yup.string().required('Service Category is required')
    }).required('Service Category is required'),
    subAttributeIds: Yup.object()
    .test(
      'hasAtLeastOneKey',
      'Check at least one checkbox',
      value => value && Object.keys(value).length > 0
    )
    .required('Sub Attribute is required'),
});

const USCViewPage: React.FC = () => {
    const { showSnackbar, SnackBarComponent } = useSnackbar();
    const { user } = useAuthenticatedUser();
    const vasMasterCategoryService = useVASMasterCategoryService();
    const [usc, setUsc] = useState<IUSC | null>(null)
    const navigate = useNavigate();
    const params = useParams();

    const loadUsc = async () => {
        vasMasterCategoryService.getUSCById(Number(params.id))
            .then(res => {
                if (res.status === HTTP_STATUS.OK) {
                    setUsc(res.data.data)
                }
            }).catch((error) => {
                setUsc(null);
                console.error("Error Fetching USC: ", error);
                showSnackbar('error', "Error while fetching USC data");
            })
    }

    useEffect(() => {
        loadUsc()
    }, [Number(params.id)])

    const onBack = () => {
        navigate(makeRoute(VALUE_ADDED_SERVICE_ROUTES.VALUE_ADDED_SERVICE_LIST, { query: { type: 'USC' } }));
    }

    const updateUSC = async (uscRequestBody: USCPayload) => {
        const requestBody: any = {
            serviceCategoryId: uscRequestBody?.productServiceId,
            name: uscRequestBody?.name,
            description: uscRequestBody?.description,
            status: uscRequestBody?.status,
            subAttributeIds: uscRequestBody?.subAttributeIds,
            vendorId: user?.businessId,
        }
        vasMasterCategoryService.updateUSC(Number(params.id), requestBody)
            .then(res => {
                if (res.status === HTTP_STATUS.OK) {
                    showSnackbar('success', "Status Updated");
                    // onBack();
                } else if (res.data.exceptionCode === GENERIC_EXCEPTION_CODE.DUPLICATE_ENTRY) {
                    showSnackbar('error', "USC already exists. Please try again");
                }
            }).catch((error) => {
                showSnackbar('error', "Failed to update USC");
            })
    }

    const subAttributeIds = usc?.attributes.reduce((acc: any, attribute: any) => {
        attribute.subAttributes.forEach((subAttr: any) => {
            acc[subAttr.id] = {
                minTolerance: 0,
                maxTolerance: 0
            };
        });
        return acc;
    }, {});

    const formik = useFormik<USCPayload>({
        initialValues: {
            name: usc?.name ?? "",
            uscCode: usc?.uscCode ?? "",
            status: usc?.status ?? STATUS.ACTIVE,
            description: usc?.description ?? "",
            productServiceId: usc?.services?.id ?? null,
            superService: {
                label: usc?.services?.ancestors?.superParentService?.name ?? "",
                id: usc?.services?.ancestors?.superParentService?.id ?? ""
            },
            mainService: {
                label: usc?.services?.ancestors?.parentService?.name ?? "",
                id: usc?.services?.ancestors?.parentService?.id ?? ""
            },
            productService: {
                label: usc?.services?.name ?? "",
                id: usc?.services?.name ?? "",
            },
            attributes: usc?.attributes ?? [],
            subAttributeIds: subAttributeIds ?? {},
            vendorId: user?.businessId,
        },
        validationSchema,
        onSubmit: async (values) => {
            updateUSC({ ...values, status: values.status })
        }
    })

    useEffect(() => {
        formik.setValues({
            name: usc?.name ?? "",
            uscCode: usc?.uscCode ?? "",
            status: usc?.status ?? STATUS.ACTIVE,
            description: usc?.description ?? "",
            productServiceId: usc?.services?.id ?? null,
            superService: {
                label: usc?.services?.ancestors?.superParentService?.name ?? "",
                id: usc?.services?.ancestors?.superParentService?.id ?? ""
            },
            mainService: {
                label: usc?.services?.ancestors?.parentService?.name ?? "",
                id: usc?.services?.ancestors?.parentService?.id ?? ""
            },
            productService: {
                label: usc?.services?.name ?? "",
                id: usc?.services?.id ?? "",
            },
            attributes: usc?.attributes ?? [],
            subAttributeIds: subAttributeIds ?? {},
            vendorId: user?.businessId,
        });
    }, [usc]);

    return (
        <div>
            {SnackBarComponent}
            <USCViewTemplate mode={MODE.VIEW} onBack={onBack} formik={formik} />
        </div>
    )
}

export default USCViewPage