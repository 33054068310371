import React, { useEffect, useState } from 'react';
import { IImage } from '../../template/CatalogueDetail/CatalogueImage.template';
import { createUseStyles, useTheme } from 'react-jss';
import ImageGroup from '../../molecules/ImageGroup/ImageGroup';
import NoImage from '../../../assets/images/noImage.jpg'

interface IImagePreviewerProps {
    images?: IImage[];
    defaultActiveIndex: number;
    height: any;
    width: any;
}

const useStyles = createUseStyles({
    container: {
        maxWidth:({width}:any) => width || "344px",
        display: "flex"
    },
    selectedImage: {
        width: ({width}:any) => width || "344px",
        height: ({height}:any) => height || "299px",
        border:({theme}:any) => `1px solid ${theme.palette.border.neutral.neutral100}`,
    },
    "@media (max-width: 480px)": {
         selectedImage: {
            width: "100%",
            maxWidth: "250px",
            maxHeight: "250px",
        }, 
    }
});

const ImagePreviewer: React.FC<IImagePreviewerProps> = ({ images, height, width, defaultActiveIndex }) => {

    const theme = useTheme<Jss.Theme>();
    const classes = useStyles({theme,height,width});

    const [activeIndex, setActiveIndexTo] = useState(defaultActiveIndex);
    const [imagesToBeDisplayed, setImagesToBeDisplayedTo] = useState<IImage[] | []>([]);

    const handleImageClick = (index: number) => {
        setActiveIndexTo(index);
    };

    useEffect(() => {
        if (images)
            setImagesToBeDisplayedTo(images?.slice(0, Math.min(4, images.length)));
    }, [images])

    return (
        <div className='grid gap-y-4 w-full' >
            <div className=''>
                <img className={`mx-auto object-cover rounded-xl ${classes.selectedImage}`} src={(imagesToBeDisplayed && imagesToBeDisplayed[activeIndex]?.path) ?? NoImage} alt="No Image" />
            </div>
            <div className={`${classes.container} w-full mx-auto`}>
                <ImageGroup images={imagesToBeDisplayed} activeIndex={activeIndex} onClick={handleImageClick} />
            </div>
        </div>
    )
}
export default ImagePreviewer;
