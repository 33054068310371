import { FormikProps, useFormik } from 'formik';
import { useEffect, useState } from 'react'
import * as Yup from "yup";
import VendorCatalogueFormTemplate from '../../template/VendorCatalogue/VendorCatalogueForm.template';
import { IVendorCatalogueAddServiceForm, useVendorCatalogueService } from '../../../services/useVendorCatalogueService';
import { useAuthenticatedUser } from '../../../hooks/useAuthenticatedUser';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from '../../../hooks/useSnackBar';
import { VENDOR_CATALOGUE_FORM_STATES, HTTP_STATUS } from '../../../utils/types';
import { GENERIC_EXCEPTION_CODE, REGEX, STATUS, VENDOR_CATALOGUE_ROUTES } from '../../../utils/constant';
import { useVendorInventoryService } from '../../../services/useVendorInventoryService';

export interface IQuantityDiscount {
    locationId: number | null;
    minQuantity: number | null;
    maxQuantity: number | null;
    minDiscount: number | null;
}
interface IProductServiceDetails {
    locationId: number | null;
    machineId: number | null;
    serviceCategoryId: number | null;
    name?:string | null,
    description?:string | null
}

export interface IcatalogueProductDetails {
    productId: number | null;
    warehouseId: number | null;
    maximumRetailPrice: number | null;
    offerSalePrice: number | null;
    deliveryTerms: string;
    packagingType: string;
    productDiscountDetails: IQuantityDiscount[];
    productServicesDetails: IProductServiceDetails[];
    cGst: number | null;
    sGst: number | null;
    iGst: number | null;
    tcs: number | null;
    tax3: number | null;
}
export interface IVendorCatalogueAddForm {
    name: string;
    description: string;
    catalogueType: string;
    productType: string;
    productId: number | null;
    paymentTerms: string;
    status: string;
    termsConditions: string;
    catalogueProductDetails: IcatalogueProductDetails[];
}
export interface IVendorCatalogueAddProps {
    formik: FormikProps<IVendorCatalogueAddForm>;
    mode?: string;
}

const VendorCatalogueAddPage = () => {
    const [currentSection, setCurrentSection] = useState<VENDOR_CATALOGUE_FORM_STATES>(VENDOR_CATALOGUE_FORM_STATES.CATALOGUE_TYPE);
    const vendorCatalogueService = useVendorCatalogueService();
    const { showSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const { user } = useAuthenticatedUser();
    const [warehouses, setWarehousesTo] = useState<{ id: number, name: string }[]>([{ id: 0, name: '' }]);
    const [initialValuesSet, setInitialValuesSet] = useState(false);
    const vendorInventoryService = useVendorInventoryService();

    const initialValues: IVendorCatalogueAddForm = {
        name: '',
        description: '',
        catalogueType: 'SINGLE_PRODUCT',
        productType: 'STANDARD',
        productId: null,
        paymentTerms: '',
        termsConditions: '',
        status: '',
        catalogueProductDetails: warehouses.map((warehouse: { id: number, name: string }) => ({
            productId: null,
            warehouseId: warehouse.id,
            maximumRetailPrice: 0,
            offerSalePrice: 0,
            deliveryTerms: '',
            packagingType: 'WITHOUT_PACKAGING',
            productDiscountDetails: [{
                locationId: null,
                minQuantity: 0,
                maxQuantity: 0,
                minDiscount: 0,
            }],
            productServicesDetails: [{
                locationId: null,
                machineId: null,
                serviceCategoryId: null,
                name:null,
                description:null
            }],
            cGst: 0,
            sGst: 0,
            iGst: 0,
            tcs: 0,
            tax3: 0,
        }))
    };

    const fetchWarehouses = async () => {
        if (user?.businessId) {
            try {
                const warehousesResponse = await vendorInventoryService.getAllWarehouseMappedVendor({ vendorId: user.businessId, status: STATUS.ACTIVE });
                if (warehousesResponse.status === HTTP_STATUS.OK) {
                    const warehousesData = warehousesResponse?.data?.data?.content;
                    setWarehousesTo(warehousesData);
                    if (!initialValuesSet) {
                        formik.setValues({
                            ...formik.values,
                            catalogueProductDetails: warehousesData.map((warehouse: { id: number, name: string }) => ({
                                productId: null,
                                warehouseId: warehouse.id,
                                maximumRetailPrice: 0,
                                offerSalePrice: 0,
                                deliveryTerms: '.',
                                packagingType: 'WITHOUT_PACKAGING',
                                productDiscountDetails: [
                                    {
                                        locationId: null,
                                        minQuantity: 0,
                                        maxQuantity: 0,
                                        minDiscount: 0,
                                    },
                                ],
                                productServicesDetails: [
                                    {
                                        locationId: null,
                                        machineId: null,
                                        name:null,
                                        description:null
                                    },
                                ],
                                cGst: 0,
                                sGst: 0,
                                iGst: 0,
                                tcs: 0,
                                tax3: 0,
                            })),
                        });
                        setInitialValuesSet(true);
                    }
                }
            } catch (error) {
                showSnackbar('error', 'Warehouses fetch failed');
            }
        }
    };

    const validationSchema = Yup.object().shape({
        catalogueType: Yup.string().required('Catalogue Type is required'),
        description: Yup.string().required('Description is required'),
        productType: Yup.string().required('Product Type is required'),
        productId: Yup.number().required('Please Select a Product'),
        paymentTerms: Yup.string().required('Payment Terms are required'),
        termsConditions: Yup.string().required('Terms and Conditions are required'),
        catalogueProductDetails: Yup.array().of(
            Yup.object().shape({
                maximumRetailPrice: Yup.number()
                    .required('Maximum Retail Price is required')
                    .min(0, 'Maximum Retail Price must be greater than or equal to zero.').test('is-decimal-precision', 'Max two decimal places.', (value) => {
                        return REGEX.TWO_DECIMAL.test(value.toString());
                    }),
                offerSalePrice: Yup.number()
                    .required('Offer Sale Price is required')
                    .min(0, 'Offer Sale Price must be greater than or equal to zero.').test('is-decimal-precision', 'Max two decimal places.', (value) => {
                        return REGEX.TWO_DECIMAL.test(value.toString());
                    })
                    .test('is-less-than-maximumRetailPrice', 'Offer Sale Price must be less than Maximum Retail Price.', function (value) {
                        const { maximumRetailPrice } = this.parent;
                        return value <= maximumRetailPrice;
                    }),
                productDiscountDetails: Yup.array().of(
                    Yup.object().shape({
                        minQuantity: Yup.number()
                            .required('Minimum Quantity is required')
                            .min(0, 'Must be greater than or equal to zero.').test('is-decimal-precision', 'Max two decimal places.', (value) => {
                                return REGEX.TWO_DECIMAL.test(value.toString());
                            })
                            .integer('Must be an integer')
                            .test('min-quantity-greater-than-prev-max',
                                'Must be greater than prev value',
                                function (value) {
                                    const { path, options } = this;
                                    const matches = path.match(/\d+/g);
                                    if (!matches || matches.length < 2) return true;
                                    const productIndex = parseInt(matches[0], 10);
                                    const discountIndex = parseInt(matches[1], 10);
                                    if (discountIndex === 0) return true;
                                    const previousMax = options.context?.catalogueProductDetails[productIndex]?.productDiscountDetails[discountIndex - 1]?.maxQuantity;
                                    return value > previousMax;
                                }
                            ),
                        maxQuantity: Yup.number()
                            .required('Maximum Quantity is required')
                            .min(0, 'Must be greater than or equal to zero.')
                            .test('is-decimal-precision', 'Max two decimal places.', (value) => {
                                return REGEX.TWO_DECIMAL.test(value.toString());
                            })
                            .integer('Must be an integer')
                            .test('is-greater-than-min', 'Must exceed Min. Quantity', function (value) {
                                const { minQuantity } = this.parent;
                                return (value === 0 && minQuantity === 0) || (value > minQuantity);
                            }),
                        minDiscount: Yup.number()
                            .required('Minimum Discount is required')
                            .min(0, 'Must be greater than or equal to zero.').test('is-decimal-precision', 'Max two decimal places.', (value) => {
                                return REGEX.TWO_DECIMAL.test(value.toString());
                            }),
                    })
                ),
                // productServicesDetails: Yup.array().of(
                //     Yup.object().shape({
                //         machineId: Yup.number()
                //             .optional()
                //     })
                // ),
                cGst: Yup.number()
                    .required('CGST is required')
                    .min(0, 'Must be greater than or equal to zero.').test('is-decimal-precision', 'Max two decimal places.', (value) => {
                        return REGEX.TWO_DECIMAL.test(value.toString());
                    }),
                sGst: Yup.number()
                    .required('SGST is required')
                    .min(0, 'Must be a positive number').test('is-decimal-precision', 'Max two decimal places.', (value) => {
                        return REGEX.TWO_DECIMAL.test(value.toString());
                    }),
                iGst: Yup.number()
                    .required('IGST is required')
                    .min(0, 'Must be a positive number').test('is-decimal-precision', 'Max two decimal places.', (value) => {
                        return REGEX.TWO_DECIMAL.test(value.toString());
                    }),
                tcs: Yup.number()
                    .required('TCS is required')
                    .min(0, 'Must be a positive number').test('is-decimal-precision', 'Max two decimal places.', (value) => {
                        return REGEX.TWO_DECIMAL.test(value.toString());
                    }),
                tax3: Yup.number()
                    .required('Tax is required')
                    .min(0, 'Must be a positive number').test('is-decimal-precision', 'Max two decimal places.', (value) => {
                        return REGEX.TWO_DECIMAL.test(value.toString());
                    }),
            })
        )
    });
    const formik = useFormik<IVendorCatalogueAddForm>({
        initialValues,
        validationSchema,
        onSubmit: async () => {
            const filteredCatalogueProductDetails = formik.values.catalogueProductDetails.filter(
                (productDetails) => !(productDetails.maximumRetailPrice === 0 && productDetails.offerSalePrice === 0)
            );
            const body: IVendorCatalogueAddServiceForm = {
                name: formik.values.name,
                description: formik.values.description,
                catalogueType: formik.values.catalogueType,
                productType: formik.values.productType,
                paymentTerms: formik.values.paymentTerms,
                status: formik.values.status,
                termsAndConditions: formik.values.termsConditions,
                catalogueProductDetails: filteredCatalogueProductDetails.map((productDetails) => ({
                    vendorProductId: Number(formik.values.productId) ?? 0,
                    warehouseId: productDetails.warehouseId ?? 0,
                    marketRetailPrice: productDetails.maximumRetailPrice ?? 0,
                    offerSalePrice: productDetails.offerSalePrice ?? 0,
                    paymentType: formik.values.paymentTerms,
                    deliveryTerms: productDetails.deliveryTerms,
                    packagingTypeEnum: productDetails.packagingType,
                    productChargesDetailsRequestBodyDTOs: [
                        {
                            locationId: productDetails.warehouseId ?? 0,
                            chargeType: "TCS",
                            rate: productDetails.tcs ?? 0,
                            rateType: "PERCENTAGE"
                        },
                        {
                            locationId: productDetails.warehouseId ?? 0,
                            chargeType: "CGST",
                            rate: productDetails.cGst ?? 0,
                            rateType: "PERCENTAGE"
                        },
                        {
                            locationId: productDetails.warehouseId ?? 0,
                            chargeType: "SGST",
                            rate: productDetails.sGst ?? 0,
                            rateType: "PERCENTAGE"
                        },
                        {
                            locationId: productDetails.warehouseId ?? 0,
                            chargeType: "IGST",
                            rate: productDetails.iGst ?? 0,
                            rateType: "PERCENTAGE"
                        },
                        {
                            locationId: productDetails.warehouseId ?? 0,
                            chargeType: "TAX_3",
                            rate: productDetails.tax3 ?? 0,
                            rateType: "PERCENTAGE"
                        }
                    ],
                    productDiscountDetailsRequestBodyDTOs: productDetails.productDiscountDetails.map((discount) => ({
                        locationId: productDetails.warehouseId ?? 0,
                        minQuantity: discount.minQuantity ?? 0,
                        maxQuantity: discount.maxQuantity ?? 0,
                        discountType: "PERCENTAGE",
                        minDiscount: discount.minDiscount ?? 0,
                        maxDiscount: discount.minDiscount ?? 0,
                    })),
                    productServicesDetailsRequestBodyDTOs: productDetails.productServicesDetails.map((machine) => ({
                        locationId: productDetails.warehouseId ?? 0,
                        machineId: machine.machineId ?? 0,
                    }))
                }))
            }
            if (user?.businessId) {
                try {
                    const vendorCatalogueCreateResponse = await vendorCatalogueService.createVendorCatalogue(user?.businessId, body);
                    if (vendorCatalogueCreateResponse.status === HTTP_STATUS.OK) {
                        navigate(VENDOR_CATALOGUE_ROUTES.MAKE_CATALOGUE);
                    }
                    else if (vendorCatalogueCreateResponse?.data?.exceptionCode === GENERIC_EXCEPTION_CODE.DUPLICATE_ENTRY) {
                        showSnackbar("error", "Catalogue already exists.")
                    }
                } catch (error) {
                    showSnackbar("error", `Failed to create Catalogue.Please try again`)
                }
            }
        },
    });

    useEffect(() => {
        fetchWarehouses();
    }, [user?.businessId]);
    return (
        <VendorCatalogueFormTemplate formik={formik} mode={'ADD'} />
    )
}

export default VendorCatalogueAddPage