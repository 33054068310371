import React from "react";
import { createUseStyles } from "react-jss";
import Button from "../../atoms/Button/Button";
import { getFormattedDate, toInr, REQUEST_ORDER_STATUS } from "../../../utils/helper";
import KeyboardArrowUpRoundedIcon from '@mui/icons-material/KeyboardArrowUpRounded';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import { STATUS } from "../../../utils/constant";
import { Order } from "./OrderList.template";

const useStyles = createUseStyles((theme: any) => ({
    webContainer: {
        display: "flex",
        background: theme.palette.background.primary.primary50,
    },
    subText: {
        color: theme.palette.text.neutral.neutral600,
    },
    title: {
        color: theme.palette.text.primary.primary400,
    },
    completed: {
        color: theme.palette.text.success.success500,
    },
    ongoing: {
        color: theme.palette.text.neutral.neutral500,
    },
    cancelled: {
        color: theme.palette.text.secondary.secondary500,
      },
    mobileContainer:{
        display: "none",
      },
      "@media (max-width: 476px)": {
        mobileContainer:{
          display: "flex",
          background: theme.palette.background.primary.primary300,
        },
        webContainer: {
          display : "none"
        }
    },
}));
interface OrderDetailsTemplateProps {
    order: Order | null;
    source: "LIST" | "VIEW";
    onClick?: any;
    show?: boolean;
    setShow?: any;
}

const OrderDetailsTemplate: React.FC<OrderDetailsTemplateProps> = ({ order, source, onClick, show, setShow }) => {

    const classes = useStyles();

    let statusTextColor;
    if (order?.orderStatus === STATUS.ONGOING) {
      statusTextColor = classes.ongoing;
    } else if (order?.orderStatus === STATUS.COMPLETED) {
      statusTextColor = classes.completed;
    } else if (order?.orderStatus === STATUS.CANCELLED) {
      statusTextColor = classes.cancelled;
    }
    return (
        <>
            <div className={ `${ classes.webContainer } flex justify-between p-3 rounded`}>
                <div className="flex gap-x-6">
                    <div className="grid">
                        <div className={`${classes.subText} text-sm font-normal`}>
                            Order Status
                        </div>
                        <div className={`${statusTextColor} text-sm font-medium`}>
                            {order && REQUEST_ORDER_STATUS(order?.orderStatus)}
                        </div>
                    </div>

                    <div className="grid">
                        <div className={`${classes.subText} text-sm font-normal`}>
                            Order Placed
                        </div>
                        <div className={`${classes.subText} text-sm font-medium`}>
                        {order && getFormattedDate(order?.createdAt)}
                        </div>
                    </div>

                    <div className="grid">
                        <div className={`${classes.subText} text-sm font-normal`}>
                            Total
                        </div>
                        <div className={`${classes.subText} text-sm font-medium`}>
                            {toInr(order?.totalPrice || 0)}
                        </div>
                    </div>
                </div>
                <div className="">
                    <div className={`${classes.subText} text-sm font-normal`}>Order # {order?.id}</div>
                    {source === "VIEW" ? null : (
                        <Button
                            variant="tertiaryContained"
                            label="View order Details"
                            onClick={onClick}
                        />
                    )}
                </div>
            </div>

            <div className={ `${ classes.mobileContainer } justify-between p-2 rounded` }>
                <div className="grid gap-y-2">
                    <div className="grid h-fit">
                        <div className={ `${ classes.subText } text-xs font-normal` }>Order Status</div>
                        <div className={ `${ classes.subText } ${ statusTextColor } text-xs font-medium` }>{ order && REQUEST_ORDER_STATUS(order?.orderStatus) }</div>
                    </div>
                    <div className="grid">
                        <div className={ `${ classes.subText } text-xs font-normal` }>Total</div>
                        <div className={ `${ classes.subText } text-xs font-medium` }>{ toInr(order?.totalPrice || 0) }</div>
                    </div>
                </div> 

                <div className="grid gap-y-2">
                    <div className="flex justify-between gap-x-2">
                        <div className="grid">
                            <div className={ `${ classes.subText } text-xs font-normal` }>Order Placed</div>
                            <div className={ `${ classes.subText } text-xs font-medium` }>{ order && getFormattedDate(order?.createdAt) }</div>
                        </div>
                        <div className="flex gap-x-2 cursor-pointer">
                            {source === "VIEW" ? null : (
                                show ? <KeyboardArrowUpRoundedIcon sx={ { fontSize: 30 } } onClick={() => setShow(false)} /> : <KeyboardArrowDownRoundedIcon sx={ { fontSize: 30 }} onClick={() => setShow(true)} />
                            )}
                        </div>
                    </div>
                    <div className="">
                        <div className={ `${ classes.subText } text-xs font-normal`}>Order # { order?.id }</div>
                        {source === "VIEW" ? null : (
                            <Button className="!p-2 !h-6 !font-semibold !text-indigo-900" variant="primaryContained" label="View order Details" onClick={ onClick } />
                        )}
                    </div>
                </div>
            </div>
        </>    
    );   
};
export default OrderDetailsTemplate;