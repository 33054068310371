import { Button } from '@mui/joy';
import React from 'react';
import { createUseStyles } from 'react-jss';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import InfoIcon from '@mui/icons-material/Info';
import visionBanner from '../../../../src/assets/images/visionBanner.svg';

const useStyles = createUseStyles((theme: any) => ({
    container: {
        width: '1297px',
        margin: '0 auto',
        boxShadow: '0px 0px 5px #00000020',
    },  
    primaryContainer: {

    },
    heading: {
        color: theme.palette.text.complementary.complementary400,
    },
    header: {
        // background: theme.palette.background.complementary.complementary500,
        height: '350px',
        backgroundImage: `url(${visionBanner})`,
        backgroundSize: 'cover',
        padding: "70px 0px",
        "& h2": {
            color: theme.palette.text.primary.primary960,
        },
        "& p": {
            color: theme.palette.text.neutral.neutral100,
        },
    },

}));


const SupportCareTemplate: React.FC = () => {
    const classes = useStyles();

    return (
        <div className={`${classes.primaryContainer}`}>
            <div className={`${classes.header} text-left p-4 mb-6`}>
                <div className={`${classes.container} pt-12`}>
                <h2 className='text-3xl mb-3 font-bold'>Support and Care</h2>
                <p>You experiance is our priority at SteelBazaar.</p>
                </div>
            </div>
            <div className={`${classes.container} p-4`}>
                <div className='mb-4'>
                    <h3 className={`${classes.heading} text-2xl font-bold mb-4`}>Help Center</h3>
                    <p className='mb-3'>Find answers to frequently asked questions and get detailed guides on our products, services, and policies. The <br /> Help Centre is your one-stop resource for all the information you need.</p>
                    <Button><HelpOutlineIcon className='mr-2' /> Visit the Help Center</Button>
                </div>
                <div className='mb-4'>
                    <h3 className={`${classes.heading} text-2xl font-bold mb-4`}>Live Chat</h3>
                    <p className='mb-3'>Have a question? Our support team is available in real-time to assist you. Get immediate help for any inquiries or <br /> issues related to your order, products, or services.</p>
                    <Button><ChatBubbleIcon className='mr-2' />Start Live Chat</Button>
                </div>
                <div className='mb-4'>
                    <h3 className={`${classes.heading} text-2xl font-bold mb-4`}>WhatsApp Us</h3>
                    <p className='mb-3'>For quick and convenient support, message us on WhatsApp. Our team is ready to help with your queries, <br /> making it easier to connect on the go.</p>
                    <Button><WhatsAppIcon className='mr-2' />WhatsApp Us</Button>
                </div>
                <div className='mb-4'>
                    <h3 className={`${classes.heading} text-2xl font-bold mb-4`}>Order Status</h3>
                    <p className='mb-3'>Curious about the status of your order? Check the progress and get real-time updates on shipping, delivery, more.</p>
                    <Button><LocalShippingIcon className='mr-2' />Check Order Status</Button>
                </div>
                <div className='mb-4'>
                    <h3 className={`${classes.heading} text-2xl font-bold mb-4`}>Refunds</h3>
                    <p className='mb-3'>We aim for 100% satisfaction, but if something doesn't meet your expectations, our refund process is quick and <br /> easy. Learn more about how to request a refund.</p>
                    <Button><KeyboardReturnIcon className='mr-2' />Refund Process</Button>
                </div>
                <div className='mb-4'>
                    <h3 className={`${classes.heading} text-2xl font-bold mb-4`}>Complaints</h3>
                    <p className='mb-3'>If you have any concerns or issues, we're here to listen and resolve them swiftly. Submit a complaint, and our <br /> team will ensure a prompt response.</p>
                    <Button><InfoIcon className='mr-2' />Submit a Complaint</Button>
                </div>

            </div>
        </div>
    );
};

export default SupportCareTemplate;
