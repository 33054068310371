import { request } from ".";
import { useAuthenticatedUser } from "../hooks/useAuthenticatedUser";
import { API_METHOD } from "../utils/constant";
import { replaceUrlParams } from "../utils/helper";

interface ILeadParams {
	page: number;
	size: number;
	sort?: string;
    catalogueName?: string;
    status?: string;
    date?: string | null;
}

interface IStatusBodyRequest {
    status: string;
}

export const CATALOGUE_URLS = {
    GET_ALL_LEADS: "/rfqs",
    GET_LEAD_BY_LEAD_ID_AND_UPC_ID: "rfq/upc/:leadUpcId",
    GET_QUOTATIONS_BY_LEAD_ID: "rfq/:rfqId/quotations",
    GET_QUOTATION: "rfq/quotation/:quotationId",
    UPDATE_QUOTATION: "rfq/quotation/:quotationId",
    GET_ALL_SECONDARY_LEADS: "/rfqs-secondary",
    GET_SECONDARY_LEAD_BY_LEAD_ID_AND_UPC_ID: "rfq-secondary/upc/:leadUpcId",
    GET_SECONDARY_QUOTATION: "rfq-secondary/quotation/:quotationId",
    GET_ALL_SECONDARY_LOT_LEADS: "/rfqs-secondary-lots",
    GET_SECONDARY_LOT_LEAD_BY_LEAD_ID_AND_UPC_ID: "/search/secondary-products/:secondaryCatalogueId",
    GET_SECONDARY_LOT_QUOTATION: "rfq-secondary-lot/quotation/:quotationId",
};

export const useLeadService = () => {
    const { user: authenticatedUser } = useAuthenticatedUser();

    const getAllLeads = (params: ILeadParams) => {
        return request(API_METHOD.GET, CATALOGUE_URLS.GET_ALL_LEADS, authenticatedUser, null, { params } );
    };  

    const getLeadUpcById = ( leadUpcId: number ) => {
        return request(API_METHOD.GET, replaceUrlParams(CATALOGUE_URLS.GET_LEAD_BY_LEAD_ID_AND_UPC_ID, { leadUpcId } ), authenticatedUser );
    };

    const getQuotationByLeadId = ( rfqId: number ) => {
        return request(API_METHOD.GET, replaceUrlParams(CATALOGUE_URLS.GET_QUOTATIONS_BY_LEAD_ID, { rfqId } ), authenticatedUser );
    };

    const getQuotationById = (quotationId: number) => {
        return request(API_METHOD.GET, replaceUrlParams(CATALOGUE_URLS.GET_QUOTATION, { quotationId } ), authenticatedUser );
    };

    const updateQuotation = (quotationId: number, data:IStatusBodyRequest) => {
        return request(API_METHOD.PUT, replaceUrlParams(CATALOGUE_URLS.UPDATE_QUOTATION, { quotationId } ), authenticatedUser, data );
    };

    const requestForQuotation = (paymentType:string) => {
        return request(API_METHOD.POST, CATALOGUE_URLS.GET_ALL_LEADS, authenticatedUser, {paymentType});
    };

    const getAllSecondaryLeads = (params: ILeadParams) => {
        return request(API_METHOD.GET, CATALOGUE_URLS.GET_ALL_SECONDARY_LEADS, authenticatedUser, null, { params } );
    };

    const getSecondaryLeadUpcById = ( leadUpcId: number ) => {
        return request(API_METHOD.GET, replaceUrlParams(CATALOGUE_URLS.GET_SECONDARY_LEAD_BY_LEAD_ID_AND_UPC_ID, { leadUpcId } ), authenticatedUser );
    };

    const getSecondaryQuotationById = (quotationId: number) => {
        return request(API_METHOD.GET, replaceUrlParams(CATALOGUE_URLS.GET_SECONDARY_QUOTATION, { quotationId } ), authenticatedUser );
    };

    const getSecondaryLotQuotationById = (quotationId: number) => {
        return request(API_METHOD.GET, replaceUrlParams(CATALOGUE_URLS.GET_SECONDARY_LOT_QUOTATION, { quotationId } ), authenticatedUser );
    };

    const getAllSecondaryLotLeads = (params: ILeadParams) => {
        return request(API_METHOD.GET, CATALOGUE_URLS.GET_ALL_SECONDARY_LOT_LEADS, authenticatedUser, null, { params } );
    };

    const getSecondaryLotLeadByCatalogueName = ( secondaryCatalogueId: number  ) => {
        return request(API_METHOD.GET, replaceUrlParams(CATALOGUE_URLS.GET_SECONDARY_LOT_LEAD_BY_LEAD_ID_AND_UPC_ID, { secondaryCatalogueId } ), authenticatedUser );
    };

    return {
        getAllLeads,
        getLeadUpcById,
        getQuotationByLeadId,
        getQuotationById,
        requestForQuotation,
        updateQuotation,
        getAllSecondaryLeads,
        getSecondaryLeadUpcById,
        getSecondaryQuotationById,
        getSecondaryLotQuotationById,
        getAllSecondaryLotLeads,
        getSecondaryLotLeadByCatalogueName
    };
};