import React, { useState, useRef } from 'react';
import ContentPreviewerHeading from './ContentPreviewerHeading';
import ContentPreviewerPanelItem from './ContentPreviewerPanelItem';
import { createUseStyles } from 'react-jss';

export interface IContentComponent {
    Key: string;
    component: React.ReactNode;
}

interface ContentPreviewerProps {
    policySchema: IContentComponent[];
}

const useStyles = createUseStyles((theme: any) => ({
    container: {
        display: 'flex',
        gap: '8px'
    },
    leftContainer: {
        width: "30%",
    },

    rightContainer: {
        width: "70%",
        display: "grid",
        rowGap: "16px"
    },
}));

const ContentPreviewer: React.FC<ContentPreviewerProps> = ({ policySchema }) => {
    const classes = useStyles();
    const [activeIndex, setActiveIndex] = useState(0);
    const contentRefs = useRef<Array<HTMLDivElement | null>>([]);

    const scrollToContent = (index: number) => {
        if (contentRefs.current[index])  contentRefs.current[index]?.scrollIntoView({ behavior: 'smooth' });
    };

    return (
        <div className="grid gap-y-6 m-auto max-container mt-6">
            <div className="flex flex-col lg:flex-row gap-x-8">
                <div className={`${classes.leftContainer} p-4 grid gap-y-3  h-fit sticky top-4 z-10 border rounded-md`}>
                    {policySchema.map((policy, index) => (
                        <ContentPreviewerHeading
                            key={index}
                            policy={policy}
                            active={ activeIndex === index }
                            onClick={() => {
                                setActiveIndex(index);
                                scrollToContent(index);
                            }}
                            isClickable={index !== 0}
                        />
                    ))}
                </div>
            <div className={classes.rightContainer}>
                {policySchema.map((policy, index) => (
                    <div key={index} ref={ref => (contentRefs.current[index] = ref)} >
                        <ContentPreviewerPanelItem component={policy.component} />
                    </div>
                ))}
            </div> 
            </div>
        </div>
    )
}

export default ContentPreviewer;
