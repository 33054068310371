import React, { useEffect, useState } from "react";
import { StoreFrontGstDetailsTemplate } from "../../template/StoreFront/StoreFrontGstDetails.template";
import { createUseStyles } from "react-jss";
import TextFieldV2 from "../../atoms/Input/TextFieldV2";
import Button from "../../atoms/Button/Button";
import { InputAdornment } from "@mui/material";
import { useBusinessProfileService } from "../../../services/useBusinessProfileService";
import { HTTP_STATUS } from "../../../utils/types";
import { useSnackbar } from "../../../hooks/useSnackBar";
import { IBusinessProfile } from "../DashBoard/StoreFrrontAboutUs.page";
import userAvatar from "../../../assets/icons/userAvatar.svg";
import CallButton from "../../atoms/Button/CallButton";

const useStyles = createUseStyles((theme: any) => ({
    mainHeading: {
        color: theme.palette.text.primary.primary900,
    },
    containerHeading: {
        color: theme.palette.text.primary.primary800,
    },
    containerBorder: {
        border: `1px solid ${theme.palette.border.neutral.neutral100}`,
        borderRadius: "12px",
    },
    phoneNumber: {
        color: theme.palette.text.neutral.neutral200,
    },
    detailsLabel: {
        color: theme.palette.text.neutral.neutral600,
    },
}));

const StoreFrontContactUs: React.FC = () => {
    const classes = useStyles();
    const { showSnackbar, SnackBarComponent } = useSnackbar();
    const bussinessProfileService = useBusinessProfileService();

    const [businessProfile, setBusinessProfileTo] =
        useState<IBusinessProfile | null>(null);

    const getBusinessProfile = async () => {
        try {
            const businessProfileResponse =
                await bussinessProfileService.getBusinessProfile();
            if (businessProfileResponse.status === HTTP_STATUS.OK) {
                const profileData = businessProfileResponse?.data?.data;
                setBusinessProfileTo(profileData);
            }
        } catch (error) {
            showSnackbar("error", "Business Profile fetch failed");
        }
    };

    useEffect(() => {
        getBusinessProfile();
        document.getElementById('main')?.scroll(0,0)
    }, []);
    return (
        <div className="mb-20">
            <StoreFrontGstDetailsTemplate />
            <div>
                <div className={`${classes.mainHeading} text-2xl font-semibold py-6`}>
                    Contact Us
                </div>
                <div className="flex gap-x-6">
                    <div
                        className={`${classes.containerBorder} w-[50%] p-6 flex flex-col gap-y-6`}
                    >
                        <div className={`${classes.containerHeading} text-2xl font-medium`}>
                            Send your message
                        </div>
                        <div className="flex flex-col gap-y-2">
                            <TextFieldV2
                                label={"Name"}
                                size="small"
                                placeholder={"Please enter your full name"}
                            />
                            <TextFieldV2
                                label={"Email"}
                                size="small"
                                placeholder={"Please share your Email ID"}
                            />
                            <TextFieldV2
                                label={"Phone Number"}
                                size="small"
                                placeholder={"Enter Phone Number"}
                                InputProps={{
                                    className: "h-[49px] text-base ",
                                    startAdornment: (
                                        <InputAdornment position="start" className={``}>
                                            <div
                                                className={`${classes.phoneNumber} font-normal !-mr-3 !-ml-0.5`}
                                            >
                                                +91
                                            </div>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            <TextFieldV2
                                label={"Message"}
                                size="small"
                                placeholder={"Please enter the content for your enquiry"}
                                multiline
                                rows={4}
                            />
                        </div>
                        <div className="flex gap-x-3">
                            <Button
                                className={``}
                                label="Send Now"
                                variant="primaryContained"
                                size="large"
                            />
                        </div>
                    </div>
                    <div className="w-[50%] flex flex-col gap-y-6">
                        <div
                            className={`${classes.containerBorder} p-6 flex flex-col gap-y-6`}
                        >
                            <div
                                className={`${classes.containerHeading} text-2xl font-medium`}
                            >
                                Company Details
                            </div>
                            <div className={`flex flex-col gap-y-4`}>
                                <div>
                                    <div className={`${classes.detailsLabel}`}>Name</div>
                                    <div className="capitalize text-lg">
                                        {businessProfile?.name.toLowerCase()}
                                    </div>
                                </div>
                                <div>
                                    <div className={`${classes.detailsLabel}`}>Address</div>
                                    <div className="capitalize text-lg">
                                        {businessProfile?.address.line1.toLowerCase() +
                                            ", " +
                                            businessProfile?.address.line2.toLowerCase()}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            className={`${classes.containerBorder} p-6 flex flex-col gap-y-6`}
                        >
                            <div
                                className={`${classes.containerHeading} text-2xl font-medium`}
                            >
                                Contact Us
                            </div>
                            <div className="flex gap-4">
                                <div>
                                    <img
                                        className="rounded-xl"
                                        width={"74px"}
                                        height={"74px"}
                                        src={userAvatar}
                                        alt="user avatar"
                                    />
                                </div>
                                <div className="flex flex-col justify-center">
                                    <div className="flex items-center">
                                        <div
                                            className={`${classes.mainHeading} text-lg font-semibold capitalize`}
                                        >
                                            {businessProfile?.user?.prefix
                                                ? businessProfile?.user?.prefix?.toLowerCase() + " "
                                                : ""}
                                            {businessProfile?.user?.fullName?.toLowerCase()}
                                        </div>
                                    </div>
                                    <div
                                        className={`${classes.detailsLabel} flex gap-1 capitalize`}
                                    >
                                        {businessProfile?.user?.department?.toLowerCase()}
                                    </div>
                                    <div
                                        className={`${classes.detailsLabel} flex gap-1 capitalize`}
                                    >
                                        {businessProfile?.user?.designation?.toLowerCase()}
                                    </div>
                                </div>
                            </div>
                            <hr />
                            <div className="flex justify-between">
                                <div>
                                    <div className={`${classes.detailsLabel}`}>Mobile Phone</div>
                                    <div className="text-lg">{businessProfile?.user?.countryCode+" "+businessProfile?.user?.mobileNumber}</div>
                                </div>
                                <div>
                                    <div className={`${classes.detailsLabel}`}>Whatsapp No</div>
                                    <div className="text-lg">{businessProfile?.user?.countryCode+" "+businessProfile?.user?.whatsappNumber}</div>
                                </div>
                                {businessProfile?.user?.mobileNumber && <CallButton label={`Call ${businessProfile?.user?.mobileNumber}`} />}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default StoreFrontContactUs;
