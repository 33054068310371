import React, { useContext, useState } from "react";
import { createUseStyles } from "react-jss";
import Button from "../../atoms/Button/Button";
import { useNavigate } from "react-router-dom";
import { CART_LIFE_CYCLE, CLASS_TYPES, CUSTOMER_ROUTES } from "../../../utils/constant";
import noImage from "../../../assets/images/noImage.jpg"
import { formatUOM, formatUOMv2, makeRoute, sortAttributes } from "../../../utils/helper";
import { CLASS_STATES, HTTP_STATUS } from "../../../utils/types";
import useMetaDataService from "../../../hooks/useMetaDataService";
import { useAuthenticatedUser } from "../../../hooks/useAuthenticatedUser";
import { WishlistContext } from "../../../contexts/WishlistContext";
import { useWishlistService } from "../../../services/useWishlistService";
import yellowVerifiedIcon from "../../../assets/icons/yellowVerifiedUser.svg"
import blueVerifiedIcon from "../../../assets/icons/blueVerifiedUser.svg"
import moment from 'moment';

interface GridItemProps {
    data: any;
    isImageVisible?: boolean;
    styles?: string;
    label?: string;
    vendorDetails?:any
}
export interface IStyle {
    [key: string]: string
}
const useStyles = createUseStyles((theme: any) => ({
    container: {
        border: `1px solid ${theme.palette.border.neutral.neutral100}`,
        transition: "box-shadow 0.3s ease-in-out",
        "&:hover": {
            boxShadow: "0px 5px 16px 0px rgba(8, 15, 52, 0.08)"
        },
    },
    imageContainer: {
        height: "185px",
    },
    iconContainer: {
        top: "12px",
        right: "12px",
        backgroundColor: theme.palette.background.secondary.secondary50,
        borderRadius: "4px",
        color: theme.palette.text.secondary.secondary700,
        lineHeight: "14px",
        border: `1px solid ${theme.palette.border.secondary.secondary100}`,
    },
    catalogueName: {
        color: theme.palette.text.primary.primary900,
        lineHeight: "16px",
        letterSpacing: "0.14px",
    },
    attributeTitle: {
        color: theme.palette.text.neutral.neutral600,
        lineHeight: "13.8px",
        letterSpacing: "0.12px"
    },
    attributeValue:{
        color: theme.palette.text.primary.primary900,
    },
    price: {
        color: theme.palette.text.neutral.neutral700,
        lineHeight: "13.8px",
        letterSpacing: "0.12px"
    },
    priceValue: {
        color: theme.palette.text.primary.primary600,
        lineHeight: "20.7px",
        letterSpacing: "0.5px",
        position:"relative",
        "&::after":{
            content:'"*"',
            position:"absolute",
            right:"-10px",
            color: theme.palette.text.secondary.secondary500,
        }
    },
    location: {
        color: theme.palette.text.neutral.neutral600,
    },
    updatedDate: {
        color: theme.palette.text.neutral.neutral500,
        fontSize:"10px"
    },
    moq: {
        color: theme.palette.text.neutral.neutral700,
        lineHeight: "13.8px",
        letterSpacing: "0.12px"
    },
    borderTop: {
        borderTop: `1px solid ${theme.palette.border.neutral.neutral100}`,
    },
    shadow: {
        background: "linear-gradient(0deg, rgba(10, 18, 41, 0.00) 0%, rgba(10, 18, 41, 0.40) 100%)",
    },
}));

const StoreFrontGridItem: React.FC<GridItemProps> = ({ data, vendorDetails, isImageVisible = true, styles, label }) => {
    const classes = useStyles();
    const navigate = useNavigate();
    const { metaData: filterData } = useMetaDataService();

    const onViewCatalogue = (event: any) => {
        navigate(makeRoute(CUSTOMER_ROUTES.STORE_FRONT_CATALOGUE_VIEW, { query: { "catalogueId": data.id } }))
    }

    const sortedAttributes = sortAttributes(data.attributes, filterData.attributes);
    const { user } = useAuthenticatedUser();
    const wishlistContextData = useContext(WishlistContext);
    const wishlistService = useWishlistService();
    const handleFavoriteClick = () => (event: any) => {
        if (event.target.closest("#child")) {
            updateWishlist();
        }
    };

    const handleContactNow = () => {
        const span = Array.from(document.querySelectorAll('span'))
            .find(el => el.innerText.trim() === 'Contact Us');

        if (span) {
            span.click();
        }
    }

    const updateWishlist = async () => {
        try {
            const payload: any = {
                userId: user?.id,
                favourites: {
                    catalogueIds: [...wishlistContextData.favourites.catalogueIds],
                    secondaryCatalogueIds: [...wishlistContextData.favourites.secondaryCatalogueIds]
                }
            };
            if (data.classType === CLASS_STATES.STANDARD || data.classType === CLASS_STATES.NONSTANDARD) {
                payload.favourites.catalogueIds = payload.favourites.catalogueIds.filter((cId: number) => cId !== data.id);
            } else if (data.classType === CLASS_STATES.SECONDARY) {
                payload.favourites.secondaryCatalogueIds = payload.favourites.secondaryCatalogueIds.filter((cId: number) => cId !== data.id);
            }
            const wishlistResponse = await wishlistService.updateWishlist(payload);
            if (wishlistResponse?.status === HTTP_STATUS.OK) {
                await wishlistContextData.syncWishlist(CART_LIFE_CYCLE.REMOVE_PRODUCT);
                return true;
            } else {
                throw new Error("Wishlist Details fetch failed");
            }
        } catch (error) {
            return false;
        }
    };

    const [hovered, setHovered] = useState<boolean>(false)

    return (
        <div className={`grid bg-white ${classes.container} ${styles} relative cursor-pointer rounded-xl`} onMouseEnter={() => setHovered(true)} onMouseLeave={() => setHovered(false)}>
        {isImageVisible && (
                <div className={`${classes.imageContainer} relative mt-0 my-auto z-0 `}>
                    <img className={ `w-full rounded-t-lg h-full ${label === "wishlist" && hovered  ? classes.shadow : ''}`}  src={data?.catalogueImagePath[0]?.path || noImage} alt="CatalogueImage" />
                    {label === "wishlist" && hovered &&
                        <div className={`${classes.iconContainer} flex px-2 py-1 absolute z-20 cursor-pointer text-xs font-medium`} id="child" onClick={handleFavoriteClick()} >Remove</div>
                    }
                </div>
            )}
            <div className={`grid gap-y-2 pt-3 px-4 pb-4`}>
                <div className="grid gap-y-2">
                    <div className={`text-sm font-semibold text-wrap truncate ${classes.catalogueName}`}>{data.name.length > 55 ? data.name.substring(0, 55) + "..." : data.name}</div>
                    <div className="flex justify-between">
                        {sortedAttributes.splice(0, 3).map(({ key, value }) => {
                            let [min, max, uom] = value as string[];
                            min = min.replace(/\.0*$/, '');
                            max = max.replace(/\.0*$/, '');
                            const isRangeZero = min === max || max === "";
                            return (
                                <div className="flex flex-col gap-1">
                                    <div className={`${classes.attributeTitle} text-xs font-normal`}>{`${key}(${uom.toLowerCase()})`}</div>
                                    <div className={`${classes.attributeValue} text-xs font-semibold`}>
                                        {isNaN(Number(min)) || isNaN(Number(max))
                                            ? `${min}${isRangeZero ? '' : `-${max}`}`
                                            : formatUOMv2(Number(min).toFixed(2), uom, isRangeZero ? Number(min).toFixed(2) : `${Number(min).toFixed(2)}-${Number(max).toFixed(2)}`)
                                        }
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
                <div className={`grid`}>
                    <div className="flex justify-between">
                        <div className={`${classes.priceValue} text-lg font-semibold`}>{`${data?.cataloguePrice.toLocaleString('en-IN', {
                            maximumFractionDigits: 0,
                            style: 'currency',
                            currency: 'INR'
                        })}/MT`}</div>
                        <div className={`flex flex-col`}>
                            <div className={`${classes.location} capitalize text-right text-xs font-normal`}>{`Ex-${data?.locationName.toLowerCase()}`}</div>
                            <div className={`${classes.updatedDate} font-normal`}>Updated: {moment(data?.updatedAt).format('DD/MM/YYYY')}</div>
                        </div>
                    </div>
                </div>

                <div className="flex gap-1 items-center">
                    <div className={`${classes.attributeValue} capitalize text-sm font-medium truncate`}>
                        {vendorDetails.vendorName.toLowerCase()}
                    </div>
                    {vendorDetails.isTrusted && <img width={"16px"} height={"16px"} src={yellowVerifiedIcon} alt="verified icon" />}
                    {vendorDetails.isVerified && <img width={"16px"} height={"16px"} src={blueVerifiedIcon} alt="verified icon" />}
                </div>

                <div className="flex gap-3">
                    <Button onClick={onViewCatalogue} className={`w-full`} size="small" label="View More" variant="primaryContained" />
                    <Button onClick={handleContactNow} className={`w-full`} size="small" label="Contact Now" variant="tertiaryContained" />
                </div>
            </div>
        </div>
    );
};

export default StoreFrontGridItem;