import React, { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import useMetaDataService from "../../../hooks/useMetaDataService";
import Button from "../../atoms/Button/Button";
import { CatalogueFilterProps, ICatalogueFilterExtended, ICatelogueFilter } from "./CatalogueFilter.template";
import { CATALOGUE_FILTER_HEADINGS, CATALOGUE_LIST_QUERY, CLASS_STATES } from "../../../utils/types";
import { ResourceFilterMobile } from "../../molecules/ResourceFilter/ResourceFilterMobile";
import filterCrossIcon from '../../../assets/icons/filterCrossIcon.svg'
import { ATTRIBUTES_WITH_RANGE } from "../../../utils/constant";
import { toAllCapsCase } from "../../../utils/helper";

interface CatalogueFilterMobileProps extends CatalogueFilterProps {
    totalRecords: number;
    onDialogClose: () => void;
    onFilterClear: () => void;
}

const catalogueFilterSchema = [
    {
        label: "Class",
        isSearchable: false,
        key: CATALOGUE_LIST_QUERY.CLASSES,
        isExclusive: true,
        isRangeFilter: false
    },
    {
        label: "Sub class",
        isSearchable: false,
        key: CATALOGUE_LIST_QUERY.CATALOGUE_TYPES,
        isExclusive: true,
        isRangeFilter: false
    },
    {
        label: "Brand",
        isSearchable: false,
        key: CATALOGUE_LIST_QUERY.BRANDS,
        isExclusive: false,
        isRangeFilter: false
    },
    {
        label: "Manufacturer",
        isSearchable: false,
        key: CATALOGUE_LIST_QUERY.MANUFACTURERS,
        isExclusive: false,
        isRangeFilter: false
    },
    {
        label: "Standard",
        isSearchable: false,
        key: CATALOGUE_LIST_QUERY.STANDARDS,
        isExclusive: false,
        isRangeFilter: false
    },
    {
        label: "Grade",
        isSearchable: false,
        key: CATALOGUE_LIST_QUERY.GRADES,
        isExclusive: false,
        isRangeFilter: false
    },
    {
        label: "Shape",
        isSearchable: false,
        key: CATALOGUE_LIST_QUERY.SHAPES,
        isExclusive: false,
        isRangeFilter: false
    },
];

const useStyles = createUseStyles((theme: any) => ({
    containerHeader: {
        color: theme.palette.text.neutral.neutral800,
    },
    header: {
        width: 'calc(100% - 47px)',
        padding: '16px 0px',
        marginTop: '-24px'
    }
}));

const CatalogueFilterMobile: React.FC<CatalogueFilterMobileProps> = ({ catalogueFilter, onFilterChange, onDialogClose, totalRecords, onFilterClear }) => {
    const classes = useStyles();
    const { metaData: filterData } = useMetaDataService();

    const [catalogueFilterSchemaState, setCatalogueFilterSchemaStateTo] = useState<any>(catalogueFilterSchema);
    const [isSecondaryClassState, setIsSecondaryClassStateTo] = useState(false);
    const [isNonStandardCatalogueClassState, setIsNonStandardCatalogueClassStateTo] = useState(false);
    const [filteredSchemaState, setFilteredSchemaStateTo] = useState([]);

    const handleChange = (filterName: CATALOGUE_LIST_QUERY | string) => (id: number | CATALOGUE_LIST_QUERY, isSelected: boolean, value?: string) => {
        onFilterChange(filterName, id, isSelected, value);
    };

    useEffect(() => {
        const isSecondaryClass = catalogueFilter?.classes[0] === CLASS_STATES.SECONDARY;
        const isNonStandardCatalogueClass = catalogueFilter?.classes[0] === CLASS_STATES.NONSTANDARD;
        const filteredSchema = isSecondaryClass ? catalogueFilterSchemaState.filter((filter: any) => ![CATALOGUE_FILTER_HEADINGS.BRAND, CATALOGUE_FILTER_HEADINGS.MANUFACTURER, CATALOGUE_FILTER_HEADINGS.STANDARD].includes(filter.label)) : catalogueFilterSchemaState;
        setIsSecondaryClassStateTo(isSecondaryClass);
        setIsNonStandardCatalogueClassStateTo(isNonStandardCatalogueClass);
        setFilteredSchemaStateTo(filteredSchema)
    }, [catalogueFilter])

    useEffect(() => {
        updateFilterSchema();
    }, [filterData])

    const updateFilterSchema = () => {
        for (const attributeName in filterData?.attributeFilters) {
            !catalogueFilterSchema.find(schema => schema.label === attributeName.trim()) &&
                catalogueFilterSchema.push({
                    label: attributeName.trim() as any,
                    isSearchable: false,
                    key: attributeName as any,
                    isExclusive: false,
                    isRangeFilter: ATTRIBUTES_WITH_RANGE.includes(toAllCapsCase(attributeName.trim())),
                });
        }
        setCatalogueFilterSchemaStateTo(catalogueFilterSchema);
    }

    const getResourceOptionsForFilters = (filterKey: any) => {
        const selectedKey = filterKey === CATALOGUE_LIST_QUERY.CATALOGUE_TYPES ? (isSecondaryClassState ? CATALOGUE_LIST_QUERY.SECONDARY_CATALOGUE_TYPES : (isNonStandardCatalogueClassState ? CATALOGUE_LIST_QUERY.NON_STANDARD_CATALOGUE_TYPES : filterKey)) : filterKey;
        if (filterData.attributeFilters && filterData.attributeFilters.hasOwnProperty(selectedKey)) {
            return filterData.attributeFilters[selectedKey];
        }
        return (filterData as any)[selectedKey];
    }

    const getResourceOptionsAppliedFilterCount = (catalogueFilter: ICatalogueFilterExtended) => {
        let resourceOptionsFilter = 0;
        try {
            for (const [key, values] of Object.entries(catalogueFilter)) {
                if (ATTRIBUTES_WITH_RANGE.includes(toAllCapsCase(key))) {
                    resourceOptionsFilter += ((typeof values[0] === 'string' && values[0].length > 0) || (typeof values[1] === 'string' && values[1].length > 0)) ? 1 : 0;
                } else {
                    resourceOptionsFilter += values.length > 0 ? 1 : 0;
                }
            }
        } catch (error) {
            console.error(error);
        }
        return resourceOptionsFilter;
    }

    return (
        <div className="grid p-6 w-full">
            <div className={`flex justify-between border-b  fixed h-16 bg-white ${classes.header} z-50`}>
                <div className={`${classes.containerHeader} text-lg m-auto ml-0`}>Filters</div>
                <img className="w-4" src={filterCrossIcon} alt='' onClick={onDialogClose} />
            </div>
            <div className="relative h-full overflow-auto z-10 mt-10 pb-6">
                <div className={`gap-y-6`}>
                    {filteredSchemaState && filteredSchemaState.map((filter: any) => (
                        <ResourceFilterMobile
                            key={filter.key}
                            label={filter.label}
                            isSearchable={filter.isSearchable}
                            resourceOptions={filterData && getResourceOptionsForFilters(filter.key)}
                            onChange={handleChange(filter.key)}
                            selectedOptions={catalogueFilter[filter.key as keyof ICatelogueFilter]}
                            isRangeFilter={filter.isRangeFilter}
                        />
                    ))}
                </div>
            </div>

            <div className={`flex justify-between  fixed z-50 bottom-0 bg-white h-16 pt-4 w-full ${classes.header}`}>
                <Button label={`Clear (${totalRecords})`}  onClick={onFilterClear} variant="tertiaryContained" />
                <Button label="Apply" variant="primaryContained"  onClick={onDialogClose} />
            </div>
        </div>
    );
};
export default CatalogueFilterMobile;

