import React from 'react';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles((theme: any) => ({
    container: {
        background: "white",
        position: 'absolute',
        width: '100%',
        top: '146px',
        left: '0',
        zIndex: '99',
    },
    primaryContainer: {
       width: '1297px',
       margin: '0 auto',
    },
    categories: {
        "& h3": {
            color: theme.palette.text.neutral.neutral600,
        }
    },
    categoriesContent: {
        borderTop : `1px solid ${theme.palette.border.neutral.neutral200}`,
        "& ul": {
            "& li": {
                color: theme.palette.text.primary.primary500,
            },
        },
    },
}));

const categoriesData = [
    {
        title: "B2B MARKETPLACE",
        items: ["Trade Listings", "Live eAuctions", "Automated RFQs"]
    },
    {
        title: "PROCUREMENT",
        items: ["SB Maxx", "iProcure", "Inventory Management", "Supply Chain Finance", "Logistics"]
    },
    {
        title: "MANUFACTURING",
        items: ["Manufacturing", "Customisation"]
    },
    {
        title: "SUPPLY CHAIN FINANCE",
        items: ["Invoice Discounting", "Dynamic Discounting", "Business Loans"]
    },
];

const PlateformDropdown: React.FC = () => {
    const classes = useStyles();

    return (
        // <div className={`${classes.container} py-10`}>
        //     <div className={`${classes.primaryContainer} flex justify-between`}>
        //         {categoriesData.map((category, index) => (
        //             <div className={`${classes.categories}`} key={index}>
        //                 <h3 className='text-lg mb-4 font-normal'>{category.title}</h3>
        //                 <div className={classes.categoriesContent}>
        //                     <ul className='mt-10'>
        //                         {category.items.map((item, idx) => (
        //                             <li className='mb-6' key={idx}><a href="" className='text-lg font-medium'>{item}</a></li>
        //                         ))}
        //                     </ul>
        //                 </div>
        //             </div>
        //         ))}
        //     </div>
        // </div>
        <div></div>
    );
};

export default PlateformDropdown;
