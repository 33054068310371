import { FormikProps, useFormik } from "formik";
import { useSnackbar } from "../../../hooks/useSnackBar";
import { useMemo, useState } from "react";
import { ADD_EXISTING_PRODUCT, DELIVERY_INFORMATION, HTTP_STATUS, STATUS } from "../../../utils/types";
import ExistingProductDetailsTemplate from "./ExistingProductDetails.template";
import ProductReviewTemplate from "./ProductReview.template";
import * as Yup from 'yup';
import { IVendorProductRequestBodyDTO, usePostProductService } from "../../../services/usePostProductService";
import { useNavigate } from "react-router-dom";
import { useAuthenticatedUser } from "../../../hooks/useAuthenticatedUser";
import { VENDOR_PRODUCT_ROUTES } from "../../../utils/constant";
import SelectProductTemplateV2 from "../DashBoard/Inventory Management/SelectProductV2.template";

interface IAddExistingProductTemplateProps {
    classType: string;
    warehouses: { id: number, name: string }[];
    setCurrentStep: (currentStep: string | null) => void;
    handleCancel: () => void;
}

export interface IWarehouseWiseProductDetails {
    minimumOrderQuanity: number | null;
    warehouseId: number;
    deliveryTimeFrame: string;
}

export interface IExistingProduct {
    productId: number | null;
    classType: string;
    status: string;
    warehouseWiseProductDetails: IWarehouseWiseProductDetails[];
}

export interface AddVendorProductSectionProps {
    setCurrentSectionTo: (section: ADD_EXISTING_PRODUCT) => void;
    formik: FormikProps<IExistingProduct>;
    warehouses: { id: number, name: string }[];
    onBack: () => void;
    handleCancel: () => void;
    setProductDetailsTo: (details: any) => void;
    productDetails: any;
}

export const postProductValidationSchema = Yup.object().shape({
    productId: Yup.number().nullable().required('Product ID is required'),
    classType: Yup.string().required('Class type is required'),
    status: Yup.mixed().oneOf(Object.values(STATUS)).required('Status is required'),
    warehouseWiseProductDetails: Yup.array().of(
        Yup.object().shape({
            minimumOrderQuanity: Yup.number().min(0, 'Minimum order quantity must be at least 0').required('Minimum order quantity is required'),
            warehouseId: Yup.number().required('Warehouse ID is required'),
            deliveryTimeFrame: Yup.mixed().oneOf(Object.values(DELIVERY_INFORMATION)).required('Delivery time frame is required'),
        })
    )
});

const AddExistingProductTemplate: React.FC<IAddExistingProductTemplateProps> = ({ classType, warehouses, setCurrentStep, handleCancel }) => {

    const navigate = useNavigate();
    const { showSnackbar } = useSnackbar();
    const { user } = useAuthenticatedUser();
    const postProductService = usePostProductService();

    const [currentSection, setCurrentSection] = useState<ADD_EXISTING_PRODUCT>(ADD_EXISTING_PRODUCT.SELECT_PRODUCT);
    const [productDetails, setProductDetailsTo] = useState<any>({});

    const initialValues: IExistingProduct = {
        productId: null,
        classType: classType,
        status: STATUS.ACTIVE,
        warehouseWiseProductDetails: warehouses?.map((warehouse: { id: number, name: string }) => ({
            minimumOrderQuanity: 0,
            warehouseId: warehouse.id,
            deliveryTimeFrame: DELIVERY_INFORMATION.IMMEDIATE
        })) || []
    };

    const formik = useFormik<IExistingProduct>({
        validateOnMount: true,
        enableReinitialize: true,
        initialValues,
        validationSchema: postProductValidationSchema,
        onSubmit: async (values) => {
            const productRequestBody: IVendorProductRequestBodyDTO = {
                productId: values.productId!!,
                classType: values.classType,
                status: values.status,
                warehouseWiseProductDetails: values.warehouseWiseProductDetails
                    .filter(detail => !(detail.minimumOrderQuanity === 0 && detail.deliveryTimeFrame === "IMMEDIATE"))
                    .map(detail => ({
                        ...detail,
                        minimumOrderQuantity: detail.minimumOrderQuanity ?? 0,
                    }))
            };
            try {
                if (user?.businessId) {
                    const response = await postProductService.upsertVendorProduct(user?.businessId, productRequestBody);
                    if (response.status === HTTP_STATUS.OK) {
                        showSnackbar("success", "Product added successfully");
                        navigate(VENDOR_PRODUCT_ROUTES.POST_PRODUCT)
                    } else {
                        throw new Error("Failed to add product");
                    }
                }
                else {
                    showSnackbar("error", "Business Profile fetch failed.");
                }
            } catch (error) {
                showSnackbar("error", "Failed to add product");
            }
        },
    });

    const onBack = () => {
        setCurrentStep(null);
    }

    const addVendorProductSectionProps: AddVendorProductSectionProps = {
        setCurrentSectionTo: setCurrentSection,
        formik: formik,
        warehouses: warehouses,
        onBack: onBack,
        handleCancel: handleCancel,
        setProductDetailsTo: setProductDetailsTo,
        productDetails: productDetails
    }

    const AddVendorExistingProductView = useMemo(() => {
        switch (currentSection) {
            case ADD_EXISTING_PRODUCT.SELECT_PRODUCT:
                return <SelectProductTemplateV2 {...addVendorProductSectionProps} />;
            case ADD_EXISTING_PRODUCT.PRODUCT_DETAILS:
                return <ExistingProductDetailsTemplate {...addVendorProductSectionProps} />
            case ADD_EXISTING_PRODUCT.PRODUCT_REVIEW:
                return <ProductReviewTemplate {...addVendorProductSectionProps} />
            default:
                return <SelectProductTemplateV2 {...addVendorProductSectionProps} />;
        }
    }, [currentSection, formik]);

    return (
        <div className='grid gap-6 justify-items-stretch '>
            {AddVendorExistingProductView}
        </div>
    )
}

export default AddExistingProductTemplate;
