import React from 'react';
import { createUseStyles } from 'react-jss';
import * as Yup from "yup";
import { useFormik } from "formik";
import requestDemoBanner from '../../../../assets/images/requestDemoBanner.jpg';
import Button from '../../../atoms/Button/Button';
import TextFieldV2 from '../../../atoms/Input/TextFieldV2';
import { useSnackbar } from '../../../../hooks/useSnackBar';
import { REGEX } from '../../../../utils/constant';
import { HTTP_STATUS } from '../../../../utils/types';

interface DemoForm {
    name: string;
    email: string;
    mobileNumber: string;
}

const useStyles = createUseStyles((theme: any) => ({
    container: {
        backgroundImage: `url(${requestDemoBanner})`,
        backgroundSize: 'cover',
        padding: "99px 0px"
    },
    primaryContainer: {
        width: '1020px',
    },
    secondaryContainer: {
        borderTopRightRadius: "10px",
        borderBottomRightRadius: "10px",
        padding: "15px 30px",
        background: "rgba(255, 255, 255, 0.90)"
    },
    mainContainer: {
        borderTopLeftRadius: "10px",
        borderBottomLeftRadius: "10px",
        padding: "97px 30px 0px 30px",
        backgroundColor: theme.palette.background.primary.primary500,
    },
    heading: {
        fontSize: '32px',
        color: "white",
        lineHeight: "37.76px",
        letterSpacing: "-0.64px"
    },
    subHeading: {
        fontSize: '20px',
        color: theme.palette.text.neutral.neutral50,
        lineHeight: '23.6px',
        letterSpacing: "-0.4px"
    },
    subText: {
        color: theme.palette.text.neutral.neutral50,
        letterSpacing: "0.16px",
        lineHeight: '22px',
    },
    errorMessage: {
        color: theme.palette.text.secondary.secondary500,
    },
}));

const validationSchema = Yup.object().shape({
    name: Yup.string().nullable().matches(REGEX.NAME, 'Enter valid name').required('Name is required'),
    email: Yup.string().email("Invalid email format").matches(REGEX.EMAIL, 'Enter valid Email'),
    mobileNumber: Yup.string().required("Mobile Number is required").matches(/^[6-9]\d{9}$/, "Mobile number must start with either 6,7,8,ot 9 and must be 10 digits only"),
});

const RequestDemoTemplate: React.FC = () => {
    const classes = useStyles();
    const { showSnackbar, SnackBarComponent } = useSnackbar()

    const onDemoSubmit = async (demoRequestBody: DemoForm) => {
        // demoService.demoRequest(demoRequestBody)
        // .then(res => {
        //     if (res.status === HTTP_STATUS.OK) {
        //         showSnackbar('success', "Demo Request Saved");
        //     } 
        // }).catch((error: any) => {
        //     showSnackbar('error', "Failed to create USC");
        // })
    };

    const formik = useFormik<DemoForm>({
        initialValues: {
            name: "",
            email: "",
            mobileNumber: "",
        },
        validationSchema,
        onSubmit: async (values, { setSubmitting }) => {
            try {
                const demoRequestBody: any = {
                    name: values.name,
                    email: values.email,
                    mobileNumber: values.mobileNumber,
                }
                onDemoSubmit(demoRequestBody);
            } catch (error) {

            } finally {
                setSubmitting(false);
            }
        },
    });

    return (
        <div id='scrollToLabel' className={`${classes.container} w-full `}>
            <div className={`${classes.primaryContainer} grid grid-cols-2 mx-auto`}>
                <div className={`${classes.mainContainer} `}>
                    <div className='grid gap-y-4 h-fit'>
                        <div className='grid gap-y-1 h-fit'>
                            <div className={`${classes.heading} font-medium`}>Request a Demo</div>
                        </div>
                        <div className='grid h-fit'>
                            <div className={`${classes.subText} text-lg`}>Explore how Steel Bazaar can transform your<br />business with a quick personalised demo.</div>
                        </div>
                    </div>
                </div>
                <div className={`${classes.secondaryContainer} w-full `}>
                    <form onSubmit={formik.handleSubmit} className='grid gap-y-5 w-full'>
                        <div className='grid gap-y-5'>
                            <div className="grid w-full">
                                <TextFieldV2
                                    id="name"
                                    placeholder="Enter Here"
                                    label="Name"
                                    {...formik.getFieldProps("name")}
                                />
                                {formik.touched && formik.touched.name && formik.errors && formik.errors.name && (
                                    <div className={classes.errorMessage}>
                                        <small>{formik.errors.name}</small>
                                    </div>
                                )}
                            </div>
                            <div className="grid w-full">
                                <TextFieldV2
                                    id="email"
                                    placeholder="Enter Here"
                                    label="E-Mail ID"
                                    {...formik.getFieldProps("email")}
                                />
                                {formik.touched && formik.touched.email && formik.errors && formik.errors.email && (
                                    <div className={classes.errorMessage}>
                                        <small>{formik.errors.email}</small>
                                    </div>
                                )}
                            </div>
                            <div className="grid w-full">
                                <TextFieldV2
                                    id="mobileNumber"
                                    placeholder="Enter Here"
                                    label="Mobile No."
                                    {...formik.getFieldProps("mobileNumber")}
                                />
                                {formik.touched && formik.touched.mobileNumber && formik.errors && formik.errors.mobileNumber && (
                                    <div className={classes.errorMessage}>
                                        <small>{formik.errors.mobileNumber}</small>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div>
                            <Button variant={'primaryContained'} label="Schedule a Demo Now" type="submit" />
                        </div>
                    </form>
                </div>  
            </div>
        </div>
    );
};

export default RequestDemoTemplate;