import { useContext, useEffect, useRef, useState } from "react";
import { createUseStyles } from "react-jss";
import { useCatalogueService } from "../../../services/useCatalogueService";
import { ICartRequestBody, useCartService } from "../../../services/useCartService";
import { useLocation, useSearchParams } from "react-router-dom";
import { CartContext } from "../../../contexts/CartContext";
import { CATALOGUE_TYPE_STATES, CLASS_STATES, HTTP_STATUS } from "../../../utils/types";
import CatalogueImageTemplate, { IImage } from "../../template/CatalogueDetail/CatalogueImage.template";
import Breadcrumbs from "../../atoms/BreadCrumbs/BreadCrumbs";
import { ICurrentCartItem } from "./CatalogueDetails.page";
import { makeRoute, snakeCaseToTitleCase, sortAttributes } from "../../../utils/helper";
import TextField from "../../atoms/Input/TextField";
import SecondaryProducProperties from "../../template/CatalogueDetail/SecondaryProducProperties.template";
import { useAuthenticatedUser } from "../../../hooks/useAuthenticatedUser";
import CarouselRef from "react-multi-carousel";
import SecondaryPriceDetailsTemplate from "../../template/CatalogueDetail/SecondaryPriceDetails.template";
import { CART_LIFE_CYCLE, CUSTOMER_ROUTES, PAYMENT_METHODS, REGEX, UNIT } from "../../../utils/constant";
import TermsAndConditionsCarousel from "../../template/CatalogueDetail/TermsAndConditionsCarousel.template";
import Disclaimer from '../../template/CatalogueDetail/Disclaimer.template';
import { SecondarySKUPackingListTemplate } from "../../template/CatalogueDetail/SecondarySKUPackingList.template";
import SecondaryLotProductAttributesTemplate from "../../template/CatalogueDetail/SecondaryLotProductAttributes.template";
import { IProductSpecification } from "./SecondaryCatalogueDetails.page";
import useMetaDataService from "../../../hooks/useMetaDataService";
import AddToFavouriteTemplate from "../../template/CatalogueDetail/AddToFavourite.template";
import SelectCustomizationTemplate, { IValueAddedService } from "../../template/Customization/SelectCustomization.template";
import PaymentDetailsTemplate from "../../template/CatalogueDetail/PaymentDetails.template";
import DeliveryDetailsTemplate from "../../template/CatalogueDetail/DeliveryDetails.template";
import TermsConditionsTemplate from "../../template/CatalogueDetail/TermsConditions.template";
import TextFieldV2 from "../../atoms/Input/TextFieldV2";
import ContactUs from "../../molecules/ContactUs/ContactUs.page";
import FAQTemplate from "../../template/CatalogueDetail/FAQ.template";

export interface IAttribute {
    [key: string]: [string, string, string];
}

export interface IProductClassification {
    name: string;
    value: string;
}

export interface ISecondaryAttributes {
    productSpecification: IProductSpecification[];
    productClassification: IProductClassification[];
}

export interface ISecondaryCatalogue {
    id: number;
    secondarySkuId: number;
    catalogueName: string;
    minimumQuantity: number;
    availableQuantity: number;
    application: string;
    defectDetails: string;
    additionalProductDetails: string;
    keywords: string;
    price: any;
    packagingType: String[];
    termsConditions: string;
    status: string;
    attributes: any;
    warehouseName: string;
    grade: string;
    catalogueImages: IImage[];
    name: string;
    warehouseId: number;
    uom: string;
    netWeight: number;
    grossWeight: number;
    shape?: string;
    gradeName?: string;
    catalogueType?: string;
    isRepresentationImage: boolean;
    customization: String[];
    skuCode: string;
}

const useStyles = createUseStyles((theme: any) => ({
    leftContainer: {
    },
    middleChild: {
        width: "61.18%"
    },
    rightChild: {
        width: "29.15%",
    },
    catalogueName: {
        color: theme.palette.text.primary.primary900,
        lineHeight: "28px"
    },
    value: {
        color: theme.palette.text.neutral.neutral700,
        fontSize: "16px",
        lineHeight: "20px"
    },
    webContainer: {
        display: "grid",
        padding: "0px 70px 108px 70px"
    },
    mobileContainer: {
        display: "none",
    },
    inputContainer: {
        display: "flex",
        columnGap: "24px"
    },
    noOfSKU: {
        color: theme.palette.text.neutral.neutral100
    },
    inputField: {
        marginTop: "-10px"
    },
    borderBottom: {
        borderBottom: `1px solid ${theme.palette.border.neutral.neutral100}`,
    },
    "@media (max-width: 476px)": {
        webContainer: {
            display: "none"
        },
        mobileContainer: {
            display: "grid"
        },
        catalogueName: {
            color: theme.palette.text.primary.primary900,
        },
        inputContainer: {
            display: "grid",
            rowGap: "16px"
        },
        value: {
            fontSize: "14px",
        },
    }
}));

const responsive = {
    desktop: {
        breakpoint: { max: 3000, min: 1280 },
        items: 4,
        partialVisibilityGutter: 10
    },
    tablet: {
        breakpoint: { max: 1280, min: 980 },
        items: 3,
        partialVisibilityGutter: 10
    },
    mobile: {
        breakpoint: { max: 980, min: 670 },
        items: 2,
        partialVisibilityGutter: 10
    },
    "sm-mobile": {
        breakpoint: { max: 670, min: 0 },
        items: 1
    }
};

const SecondaryLotCatalogueDetails: React.FC = () => {

    const classes = useStyles();

    const { user, syncAuthDialogActive } = useAuthenticatedUser();

    const cartContext = useContext(CartContext);

    const catalogueService = useCatalogueService();
    const cartService = useCartService();

    const location = useLocation();

    const [queryParams] = useSearchParams()

    const [secondaryCatalogueId, setSecondaryCatalogueIdTo] = useState<number | null>(null);
    const [secondaryCatalogueSpecification, setSecondaryCatalogueSpecificationTo] = useState<any>(null);
    const [secondaryLotCatalogue, setsecondaryLotCatalogueTo] = useState<ISecondaryCatalogue | null>(null);
    const [secondaryProducts, setsecondaryProductsTo] = useState<ISecondaryCatalogue[] | null>([]);
    const [packagingTypes, setPackagingTypesTo] = useState<{ [key: string]: string }>({});
    const [productSpecification, setProductSpecification] = useState<IProductSpecification[] | null>([]);
    const [currentCartItem, setCurrentCartItemTo] = useState<ICurrentCartItem>({
        attributes: {},
        upc: null,
        warehouse: null,
        packaging: "WITHOUT_PACKAGING",
        quantity: "",
        secondarySkuId: null,
        paymentType: PAYMENT_METHODS.CASH_PAYMENT
    });
    const { metaData: filterData } = useMetaDataService();
    const imageCarousel = useRef<CarouselRef>(null);

    const gridTemplate = { xs: 12, sm: 6, md: 4, lg: 4, xl: 4 };

    const [valueAddedService, setValueAddedServiceTo] = useState<IValueAddedService>({
        shearing: [],
        slitting: [],
    })

    const [paymentType, setPaymentType] = useState<string | null>(PAYMENT_METHODS.CASH_PAYMENT);
    const [productCombination, setProductCombination] = useState<boolean | null>(null);

    const handlePaymentTypeChange = (paymentTypeMethod: string) => {
        setCurrentCartItemTo({
            ...currentCartItem,
            paymentType: paymentTypeMethod
        });
        setPaymentType(paymentTypeMethod);
    };

    const handleQuantityChange = (quantity: string) => {
        if (REGEX.QUANTITY.test(quantity) || quantity === '') {
            setCurrentCartItemTo({
                ...currentCartItem,
                quantity
            });
        }
    };

    const handleAddToCart = async () => {
        if (!user) {
            syncAuthDialogActive()
            return;
        }
        const requiredQuantity = parseFloat(currentCartItem.quantity);
        if (isNaN(requiredQuantity)) {
            return;
        }
        if (secondaryLotCatalogue && currentCartItem && currentCartItem.warehouse && currentCartItem.paymentType) {
            let cartRequestBody: ICartRequestBody = {
                catalogueName: secondaryLotCatalogue.catalogueName,
                upcId: null,
                warehouseId: secondaryLotCatalogue.warehouseId,
                quantity: requiredQuantity,
                uom: secondaryLotCatalogue.uom || UNIT.UOM,
                packagingType: currentCartItem.packaging,
                secondarySkuId: secondaryLotCatalogue?.secondarySkuId,
                paymentType: currentCartItem.paymentType
            }
            await cartService.saveToCart(cartRequestBody);
            await cartContext.syncCart(CART_LIFE_CYCLE.ADD_PRODUCT);
        }
    }

    useEffect(() => {
        const catalogueIdString = queryParams.get('secondaryCatalogueId');
        if (catalogueIdString !== null) {
            const parsedCatalogueId = parseInt(catalogueIdString, 10);
            if (!isNaN(parsedCatalogueId)) {
                setSecondaryCatalogueIdTo(parsedCatalogueId);
            }
        }
    }, [location.search])

    useEffect(() => {
        if (secondaryCatalogueId) {
            catalogueService.getSecondaryCatalogueById(secondaryCatalogueId)
                .then((response) => {
                    if (response.status === HTTP_STATUS.OK) {
                        setSecondaryCatalogueSpecificationTo(response.data.data ? response.data.data : null)
                        setSecondaryCatalogueSpecificationTo((prevSecondaryCatalogue: ISecondaryCatalogue | null) => ({
                            ...(prevSecondaryCatalogue as ISecondaryCatalogue),
                            name: prevSecondaryCatalogue?.catalogueName || "",
                        }))
                        setPackagingTypesTo(Object.fromEntries(response.data.data?.packagingType?.map((key: string) => [key, key])) ?? {});
                    }
                })
                .catch((error) => {
                    console.error('Error', error);
                    setSecondaryCatalogueSpecificationTo(null);
                });
        }
    }, [secondaryCatalogueId])

    useEffect(() => {
        if (secondaryCatalogueId) {
            catalogueService.getSecondaryProductsByCatalogueId(secondaryCatalogueId)
                .then((response) => {
                    if (response.status === HTTP_STATUS.OK) {
                        setsecondaryProductsTo(response?.data?.data?.content ? response?.data?.data?.content : null)
                    }
                })
                .catch((error) => {
                    console.error('Error', error);
                    setsecondaryProductsTo(null);
                });
        }
    }, [secondaryCatalogueId])

    useEffect(() => {
        if (secondaryCatalogueId) {
            catalogueService.getSecondaryLotsCatalogueById(secondaryCatalogueId)
                .then((response) => {
                    if (response.status === HTTP_STATUS.OK && response?.data?.data?.id !== undefined) {
                        setsecondaryLotCatalogueTo({
                            ...(response?.data?.data as ISecondaryCatalogue),
                            name: response?.data?.data?.catalogueName || "",
                        })
                        setCurrentCartItemTo(prevCartItem => ({ ...prevCartItem, packaging: response?.data?.data?.packagingType[0] || null, secondarySkuId: response?.data?.data?.secondarySkuId, warehouse: response?.data?.data?.warehouseName, quantity: String(response?.data?.data?.minimumQuantity) || "" }));
                        setProductSpecification(convertAttributeToProductSpecifications(response?.data?.data?.attributes));
                    }
                })
                .catch((error) => {
                    console.error('Error', error);
                    setsecondaryLotCatalogueTo(null);
                });
        }
    }, [secondaryCatalogueId])

    function convertAttributeToProductSpecifications(attribute: IAttribute): IProductSpecification[] {
        const productSpecifications: IProductSpecification[] = [];
        for (const specification in attribute) {
            const specificationValue = attribute[specification];
            productSpecifications.push({
                name: specification.trim(),
                minValue: specificationValue[0],
                maxValue: specificationValue[1],
                uom: specificationValue[2].toLowerCase()
            });
        }
        return productSpecifications;
    }
    return (
        <div>
            <div className={`${classes.webContainer} max-container m-auto`}>
                <div className="flex justify-between">
                    <Breadcrumbs crumbs={[
                        {
                            link: makeRoute(CUSTOMER_ROUTES.CATALOGUE_LISTING, { query: { classes: CLASS_STATES.SECONDARY, catalogueTypes: CATALOGUE_TYPE_STATES.MAKE_TO_ORDER } }),
                            label: "secondary Lots"

                        },
                        {
                            link: "",
                            label: "View"
                        }
                    ]} />
                    <div className="flex justify-end items-end">
                        <ContactUs />
                    </div>
                </div>
                <div className="flex gap-x-6">
                    <div className={`grid mt-6 flex-1 ${classes.leftContainer}`}>
                        <div className="w-full flex gap-x-6">
                            <div className="grid gap-y-5 h-fit">
                                <CatalogueImageTemplate isSharable={false} catalogueImages={secondaryLotCatalogue?.catalogueImages} isRepresentationImage={secondaryLotCatalogue?.isRepresentationImage} />
                                <TermsAndConditionsCarousel />
                                <AddToFavouriteTemplate id={secondaryLotCatalogue?.id} catalogueType={CLASS_STATES.SECONDARY} />
                            </div>
                            <div className={`grid gap-y-6 h-fit mt-0 m-auto ${classes.middleChild}`}>
                                <div className={`${classes.catalogueName} text-2xl font-semibold`}>{secondaryLotCatalogue?.name}</div>
                                <div className={`${classes.catalogueName} text-base font-medium border rounded-2xl p-4`}>
                                    Total Number of SKUs :
                                    <span className={`ml-4 font-bold text-lg ${classes.catalogueName}`}>{secondaryProducts && secondaryProducts.length}</span>
                                </div>
                                <SecondaryLotProductAttributesTemplate secondaryProducts={sortAttributes(secondaryProducts, filterData.attributes)} />
                                <div className="flex gap-x-3">
                                    <div className="grid gap-y-1"style={{minWidth:"170px"}} >
                                        <TextFieldV2
                                            label="Packaging"
                                            value={snakeCaseToTitleCase(currentCartItem.packaging)}
                                            onChange={(event) => {
                                                setCurrentCartItemTo({
                                                    ...currentCartItem,
                                                    packaging: event.target.value,
                                                });
                                            }}
                                            fullWidth
                                            InputProps={{
                                                disableUnderline: true,
                                                readOnly: true,
                                            }}
                                        />
                                    </div>
                                    <div className="grid gap-y-1">
                                        <TextFieldV2
                                            label="Location"
                                            type="text"
                                            InputProps={{
                                                disableUnderline: true,
                                                readOnly: true,
                                            }}
                                            value={secondaryLotCatalogue?.warehouseName}
                                            fullWidth
                                            required
                                            disabled
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={`py-10 ${classes.borderBottom}`}><SecondarySKUPackingListTemplate secondaryProducts={secondaryProducts}/></div>
                        <div className={`py-10 ${classes.borderBottom}`}><PaymentDetailsTemplate paymentType={paymentType} handlePaymentTypeChange={handlePaymentTypeChange} /></div>
                        <div className={`py-10 ${classes.borderBottom}`}><SecondaryProducProperties productSpecification={productSpecification || []} productClassification={secondaryLotCatalogue?.attributes?.productClassification}/></div>
                        <div className={`py-10 ${classes.borderBottom}`}><DeliveryDetailsTemplate /></div>
                        <div className={`py-10 ${classes.borderBottom}`}><FAQTemplate /></div>
                        <div className={`py-10 ${classes.borderBottom}`}><TermsConditionsTemplate /></div>
                        <div className="pt-10" id="scrollToDisclaimer"><Disclaimer /></div>
                    </div>
                    <div className={classes.rightChild}>
                        <div className={`pt-6 sticky top-[150px] grid`}>
                            {secondaryLotCatalogue && (
                                <SecondaryPriceDetailsTemplate
                                    handleAddToCart={handleAddToCart}
                                    paymentType={currentCartItem.paymentType}
                                    minimumPrice={parseFloat(secondaryLotCatalogue?.price?.default)}
                                    maximumPrice={parseFloat(secondaryLotCatalogue?.price?.default)}
                                    quantity={currentCartItem.quantity}
                                    warehousename={secondaryLotCatalogue?.warehouseName}
                                    gst={18}
                                    uom={currentCartItem?.warehouse?.uom?.toString() || ""}
                                    moq={secondaryLotCatalogue?.minimumQuantity}
                                    handleQuantityChange={handleQuantityChange}
                                    showAlteredQuantity={false}
                                    productCombination={productCombination}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>

            <div className={`gap-y-6 ${classes.mobileContainer}`}>
                <div className={`grid gap-y-6 px-4`}>
                    <div className="">
                        <Breadcrumbs crumbs={[
                            {
                                link: makeRoute(CUSTOMER_ROUTES.CATALOGUE_LISTING, { query: { classes: CLASS_STATES.SECONDARY, catalogueTypes: CATALOGUE_TYPE_STATES.MAKE_TO_ORDER } }),
                                label: "secondary Lots"

                            },
                            {
                                link: "",
                                label: "View"
                            }
                        ]} />
                    </div>
                    <div className="w-full grid mb-2">
                        <div className={`${classes.catalogueName} mb-4 text-base font-semibold`}>{secondaryLotCatalogue?.name}</div>
                        <CatalogueImageTemplate isSharable={false} catalogueImages={secondaryLotCatalogue?.catalogueImages} isRepresentationImage={secondaryLotCatalogue?.isRepresentationImage} />
                    </div>
                    {/* <AttributeSelectionTemplate
                        catalogue={catalogue}
                        catalogueUpc={catalogueUpc}
                        currentCartItem={currentCartItem}
                        currentAttributes={currentCartItem.attributes}
                        setCurrentCartItemTo={setCurrentCartItemTo}
                    /> */}
                    <div className="border rounded-2xl p-4">
                        Total Number of SKUs :
                        <span className={`ml-4 font-bold text-lg ${classes.noOfSKU}`}>{secondaryProducts && secondaryProducts.length}</span>
                    </div>

                    <SecondaryLotProductAttributesTemplate
                        secondaryProducts={sortAttributes(secondaryProducts, filterData.attributes)}
                    />
                    <SecondarySKUPackingListTemplate secondaryProducts={secondaryProducts}
                    />

                    <div className='border-t'> </div>
                    <div className={`${classes.inputContainer}`}>
                        <TextFieldV2
                            label="Packaging"
                            value={snakeCaseToTitleCase(currentCartItem.packaging)}
                            onChange={(event) => {
                                setCurrentCartItemTo({
                                    ...currentCartItem,
                                    packaging: event.target.value,
                                });
                            }}
                            fullWidth
                            disabled={true}
                        />
                        <TextFieldV2
                            label="Location"
                            disabled={true}
                            value={secondaryLotCatalogue?.warehouseName}
                            fullWidth
                            required
                        />
                    </div>


                    <div className='border-t'> </div>
                    <SecondaryProducProperties
                        productSpecification={productSpecification || []}
                        productClassification={
                            secondaryLotCatalogue?.attributes?.productClassification
                        }
                    />
                    <div className='border-t'> </div>
                    <div className={``}><PaymentDetailsTemplate paymentType={paymentType} handlePaymentTypeChange={handlePaymentTypeChange} /></div>
                    <div className='border-t'> </div>
                    <div className={``}><DeliveryDetailsTemplate /></div>
                    <div className='border-t'> </div>
                    <div className={``}><TermsConditionsTemplate /></div>
                    <div className='border-t'> </div>
                    <div className={``} id="scrollToDisclaimer"><Disclaimer /></div>
                </div>

                <div className="w-full sticky bottom-24 z-50">
                    {secondaryLotCatalogue && (
                        <SecondaryPriceDetailsTemplate
                            handleAddToCart={handleAddToCart}
                            paymentType={currentCartItem.paymentType}
                            minimumPrice={parseFloat(secondaryLotCatalogue?.price?.default)}
                            maximumPrice={parseFloat(secondaryLotCatalogue?.price?.default)}
                            quantity={currentCartItem.quantity}
                            warehousename={secondaryLotCatalogue?.warehouseName}
                            gst={18}
                            uom={currentCartItem?.warehouse?.uom?.toString() || ""}
                            moq={secondaryLotCatalogue?.minimumQuantity}
                            handleQuantityChange={handleQuantityChange}
                            showAlteredQuantity={false}
                            productCombination={productCombination}
                        />
                    )}
                </div>

            </div>
        </div>
    );
}

export default SecondaryLotCatalogueDetails;