import React, { useEffect, useState } from 'react'
import { CUSTOMER_ROUTES, MODE, initialPaginationValues } from '../../../../utils/constant';
import { CLASS_STATES, HTTP_STATUS, IPagination } from '../../../../utils/types';
import EditIcon from '../../../../assets/icons/editPenOutlinedIconPrimary500.svg'
import DeleteIconn from '../../../../assets/icons/deleteIconOutlinedPrimary500.svg'
import ViewEyeIcon from '../../../../assets/icons/viewOutlinedPrimary500.svg'
import { useNavigate } from 'react-router-dom';
import { makeRoute } from '../../../../utils/helper';
import { createUseStyles } from 'react-jss';
import Button from '../../../atoms/Button/Button';
import { useMaterialRequirementService } from '../../../../services/useMaterialRequirementService';
import { useSnackbar } from '../../../../hooks/useSnackBar';
import MaterialListingActionTemplate from './MaterialListingAction.template';
import { IAttribute } from '../../../pages/CatalogueDetail/MultipleUpcCatalogueDetails.page';
import { ColumnType } from '../../../organisms/table';
import CustomRadioGroup from '../../../molecules/CustomRadioGroup/CustomRadioGroup';
import TableV2 from '../../../organisms/TableV2';
import NoDataFrameTemplate from '../NoDataFrame.template';
import SuccessFailurePopup from '../../../molecules/SuccessFailurePopup/SuccessFailurePopup';

interface RawMaterialListingTableTemplateProps {
    category: { id: number, label: string },
}

export interface IMaterialRequirementListing {
    id: number,
    productCode: string;
    categoryId: number;
    classType: string;
    standardId: string;
    standard?: string;
    gradeId: string;
    grade?: string;
    shape: string;
    attributes: IAttribute[];
    applicationType: string;
    otherSpecificRequirement: string;
    primarySourcingPurpose: string;
    frequency: string;
    quantity: number;
    preferredDeliveryDate: string;
    preferredDeliveryLocation: string;
    customization: string;
    tradeType: string;
}

const radioGroupOption = [
    { value: "STANDARD", label: "Standard" },
    { value: "NONSTANDARD", label: "Customized" },
]

const useStyles = createUseStyles((theme: any) => ({
    primary500: {
        color: theme.palette.text.primary.primary500,
    },
    radioColor: {
        color: theme.palette.background.primary.primary500
    }
}));

const RawMaterialListingTableTemplate: React.FC<RawMaterialListingTableTemplateProps> = ({ category }) => {
    const classes = useStyles();
    const [selectedClassType, setSelectedClassTypeValue] = useState<string>('STANDARD');
    const [pagination, setPagination] = useState<IPagination>(initialPaginationValues);
    const [materialRequirements, setMaterialRequirements] = useState<IMaterialRequirementListing[]>([]);
    const materialRequirementService = useMaterialRequirementService();
    const [isDialogActive, setIsDialogueActive] = useState<boolean>(false);
    const [dialogueMode, setDialogueMode] = useState<string>(MODE.EDIT);
    const [selectedId, setSelectedId] = useState<number | null>(null);

    const [showDeleteAllPopUp, setShowDeleteAllPopUp] = useState(false);
    const [selectedItemId, setSelectedItemId] = useState<number | null>(null);

    const { showSnackbar, SnackBarComponent } = useSnackbar();
    const navigate = useNavigate();

    const getMaterialRequirements = () => {
        materialRequirementService.getAllMaterialRequirements({ categoryId: category.id, classType: selectedClassType }, { size: pagination.size, page: pagination.page })
            .then(res => {
                if (res?.status === HTTP_STATUS.OK) {
                    setPagination({
                        ...pagination,
                        totalPages: res?.data?.data?.totalPages,
                        totalRecords: res?.data?.data?.totalElements
                    });
                    setMaterialRequirements(res?.data?.data?.content ?? [])
                }
            }).catch((error) => {
                showSnackbar('error', "Error while fetching material requirements");
            })
    }

    useEffect(() => {
        getMaterialRequirements();
    }, [category, selectedClassType, pagination.page, pagination.size])

    useEffect(() => {
        !isDialogActive && getMaterialRequirements();
    }, [isDialogActive])

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedClassTypeValue(event.target.value);
    };

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setPagination((prevPagination: IPagination) => ({
            ...prevPagination,
            page: newPage
        }));
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setPagination((prevPagination: IPagination) => ({
            ...prevPagination,
            page: 0,
            size: parseInt(event.target.value, 10)
        }));
    };

    const handleDeleteConfirmed = () => {
        if (selectedItemId === null) return;
        materialRequirementService.deleteMaterialRequirementById(selectedItemId)
            .then((res) => {
                if (res?.status === HTTP_STATUS.OK) {
                    getMaterialRequirements();
                    showSnackbar('success', "Successfully deleted material requirement")
                }
            }).catch((error) => {
                showSnackbar('error', "Error while deleting material requirements");
            })
        setShowDeleteAllPopUp(false);
    };

    const handleDelete = (id: number) => () => {
        setSelectedItemId(id);
        setShowDeleteAllPopUp(true);
    }

    const handleDeleteCancel = () => {
        setShowDeleteAllPopUp(false);
        setSelectedItemId(null);
    };

    const Action = (id: number) => (
        <div className='flex items-center justify-center gap-x-3' title=''>
            <button onClick={handleDelete(id)}><img src={DeleteIconn} alt={DeleteIconn} /></button>
            <button onClick={handleAction(id, MODE.VIEW)}><img src={ViewEyeIcon} alt={ViewEyeIcon} /></button>
            <button onClick={handleAction(id, MODE.EDIT)}><img src={EditIcon} alt={EditIcon} /></button>
        </div>
    )

    const ProductCodeColumn = (id: number, productCode: string) => (
        <div onClick={handleAction(id, MODE.VIEW)}>
            <span>{productCode}</span>
        </div>
    )

    const handleAction = (id: number, mode: string) => () => {
        setDialogueMode(mode);
        setSelectedId(id);
        setIsDialogueActive(true);
    }

    const getTableAttributeValue = (attributes: IAttribute[], value: 'Thickness' | 'Width' | 'Length') => {
        return attributes.find((attribute: IAttribute) => attribute.name === value)?.attributeOptions ?? '-';
    }

    const getTableColumns = () => [
        { label: "S No.", key: "id", type: "number" as ColumnType, props: { className: '' } },
        { label: `${selectedClassType === CLASS_STATES.STANDARD ? "UPC Code" : "MPC Code"}`, key: "productCode", type: "custom" as ColumnType, props: { className: `underline font-medium ${classes.primary500} cursor-pointer` } },
        { label: "Standard", key: "standard", type: "customm" as ColumnType, props: { className: '' } },
        { label: "Grade", key: "grade", type: "custom" as ColumnType, props: { className: '' } },
        { label: "Thickness\n(mm)", key: "thickness", type: "custom" as ColumnType, props: { className: 'flex flex-col break-word' } },
        { label: "Width\n(mm)", key: "width", type: "custom" as ColumnType, props: { className: '' } },
        { label: "Length\n(mm)", key: "length", type: "custom" as ColumnType, props: { className: '' } },
        { label: `Monthly\nRequirement`, key: "monthlyRequirement", type: "custom" as ColumnType, props: { className: '' } },
        { label: "Action", key: "action", type: "custom" as ColumnType, props: { className: '' } }
    ]

    const getRecords = () => materialRequirements.map((materialRequirement: IMaterialRequirementListing, index) => [
        pagination.page * pagination.size + index + 1,
        ProductCodeColumn(materialRequirement.id, materialRequirement.productCode),
        materialRequirement.standard,
        materialRequirement.grade,
        getTableAttributeValue(materialRequirement.attributes, 'Thickness') || '-',
        getTableAttributeValue(materialRequirement.attributes, 'Width') || '-',
        getTableAttributeValue(materialRequirement.attributes, 'Length') || '-',
        materialRequirement?.quantity || '-',
        Action(materialRequirement.id)
    ])

    const getSchema = () => ({
        id: "1",
        pagination: {
            total: pagination.totalRecords,
            currentPage: pagination.page,
            isVisible: true,
            limit: pagination.size,
            handleChangePage: handleChangePage,
            handleChangeRowsPerPage: handleChangeRowsPerPage
        },
        columns: getTableColumns() ?? []
    })

    const handleAddProductByCategory = () => {
        navigate(`/dashboard/my-material/add?category=${category.label.split(" ").join("-")}:${category.id}`)
    }

    return (
        <div className='grid gap-y-6 pt-6'>
            {SnackBarComponent}
            {isDialogActive && <MaterialListingActionTemplate isDialogActive={isDialogActive} mode={dialogueMode} toggleDialogue={setIsDialogueActive} id={selectedId} />}
            <div className='flex justify-between'>
                <CustomRadioGroup
                    options={radioGroupOption}
                    value={selectedClassType}
                    onChange={handleChange}
                    className={classes.radioColor} />
                <div className='my-auto'>
                    <Button variant='tertiaryContained' label={'+Add New'} onClick={handleAddProductByCategory} />
                </div>
            </div>
            <div className='w-full'>
                {materialRequirements.length ? <TableV2 schema={getSchema()} records={getRecords()} />
                    : <NoDataFrameTemplate title="No Result Found" message="You haven't provided Raw material for your business. " />}
            </div>
            {
                showDeleteAllPopUp &&
                <SuccessFailurePopup
                    setShowPopup={setShowDeleteAllPopUp}
                    variant="Delete"
                    heading="Delete Product?"
                    description="Are you sure you want to delete this Product?"
                    button1={{
                        text: "Yes",
                        variant: "tertiaryContained",
                        size: "large",
                        onClick: handleDeleteConfirmed,
                    }}
                    button2={{
                        text: "No",
                        variant: "secondaryContained",
                        size: "large",
                        onClick: handleDeleteCancel,
                    }}
                />

            }
        </div>
    )
}

export default RawMaterialListingTableTemplate