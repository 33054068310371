import React from 'react';
import { createUseStyles } from 'react-jss';
import aboutUsBanner from '../../../assets/images/aboutUsBanner.jpg';
import mission from '../../../assets/images/mission.svg';
import vision from '../../../assets/images/vision.svg';
import { ClassNames } from '@emotion/react';
import aboutSteelBazaar from '../../../assets/images/aboutSteelBazaar.svg'
import AipoweredTemplate from '../../template/Home/AiPowered/Aipowered.template';
import RequestDemoTemplate from '../../template/Home/RequestDemo/RequestDemo.template';

const useStyles = createUseStyles((theme: any) => ({
    bannerContainer: {
        width: '100%',
        height: '650px',
        backgroundImage: `url(${aboutUsBanner})`,
        backgroundSize: 'cover',
        position: 'relative',
        marginTop: '0px',
    },
    visionContainer: {
        width: '1297px',
        margin: '0 auto',
    },
    heading: {
        fontSize: '48px',
        color: theme.palette.text.primary.primary50,
        lineHeight: '66px',
        position: 'relative',
        fontWeight: '700',
        top: '150px',
    },
    primaryText: {
        color: theme.palette.text.primary.primary50,
        fontSize: '18px',
        lineHeight: '15px',
    },
    sectionHeading: {
        color: theme.palette.text.primary.primary800,
        fontSize: '40px',
        lineHeight: 'normal',
    },
    secondaryContainer: {
        width: '800px',
    },
    description: {

    },
    cardDescription: {
        color: theme.palette.text.neutral.neutral600,
        lineHeight: '28px',
    },
    container: {
        width: '1297px',
        margin: '0 auto',
    },
    bannerBtn: {

    },
    missionBanner: {
        background: '#F1F6FC',
        padding: '55px 0px',
    },
    misisonHeading: {
        color: theme.palette.text.neutral.neutral900,
        fontSize: '54px',
    },
    visionSecondaryContainer: {
        "& h3": {
            fontSize: '40px',
            color: theme.palette.text.neutral.neutral900,
        },
        "& p": {

        },
    },
}));

const AboutUsTemplate: React.FC = () => {

    const classes = useStyles();

    return (
        <>
            <div>
                <div className={`${classes.bannerContainer} relative`}>
                    <div className='w-120'>
                        <h1 className={`${classes.heading} ${classes.visionContainer} mt-9 font-semibold`}>Excellence through<br />Advanced Sourcing,<br />Custom Manufacturing<br />& Digital Supply Chain Solutions
                        </h1>
                    </div>
                </div>

                <div className={`${classes.visionContainer} flex gap-16 pt-16`}>
                    <div className='w-fit'>
                        <div className='w-full'><img src={aboutSteelBazaar} alt="" /></div>
                    </div>
                    <div className={`${classes.secondaryContainer}`}>
                        <h2 className={`${classes.sectionHeading} mb-3 font-semibold`}>About Steel Bazaar</h2>
                        <div className={`${classes.description}`}>
                            <p className={`${classes.cardDescription} text-base mb-4`}>Steelbazaar is a transformative B2B marketplace that simplifies and enhances the steel trading industry. Our integrated platform connects buyers and sellers across the globe through innovative solutions and state-of-the-art services, ensuring a seamless experience for all stakeholders in the steel supply chain. Whether you're a manufacturer, distributor, or large enterprise, Steelbazaar is your digital partner for growth and efficiency.</p>
                            <p className={`${classes.cardDescription} text-base mb-4`}>Our dual business model approach-Seller Central (3P) and Vendor Central (1P)-offers flexible pathways for businesses to sell steel products directly or through the Steelbazaar marketplace. This approach caters to varying business needs and enables optimised distribution and sales across a dynamic, responsive marketplace.</p>
                            <p className={`${classes.cardDescription} text-base`}>Steelbazaar is committed to empowering the steel industry through a comprehensive suite of digital tools, real-time analytics, and end-to-end support. Our mission is to build a transparent and resilient ecosystem that promotes growth, innovation, and efficiency in every transaction.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className={classes.container}>
                <AipoweredTemplate />
            </div>
            <div className={classes.missionBanner}>
                <div className={classes.container}>
                    <h2 className={`${classes.misisonHeading} font-medium text-center`}>Inspired by Purpose, Driven by Innovation</h2>
              
                <div className={`${classes.visionSecondaryContainer} flex gap-10 items-center mt-20`}>
                    <div className='w-full'>
                        <img src={vision} alt="" />
                    </div>
                    <div className='w-full'>
                        <h3 className='font-medium mb-4'>Our Vision</h3>
                        <p>Our vision is to be the most trusted and innovative partner in the steel industry, enabling seamless procurement and manufacturing while promoting sustainability and growth for our customers globally. SteelBazaar is committed to setting new standards in transparency, customer satisfaction, and technological advancement.</p>
                    </div>
                </div>
                <div className={`${classes.visionSecondaryContainer} flex gap-10 items-center mt-20`}>
                    <div className='w-full'>
                        <h3 className='font-medium mb-4'>Our Mission</h3>
                        <p>To empower businesses in the steel industry by combining comprehensive trade and manufacturing solutions, enabling cost-effective sourcing, efficient production, and innovative digital services that support every step of their growth journey.</p>
                    </div>
                    <div className='w-full'>
                            <img src={mission} alt="" />
                    </div>
                </div>
                </div>
            </div>
            <RequestDemoTemplate />
        </>
    );
};
export default AboutUsTemplate;
