interface AccessToThePlatformProps {
    contentStyle?: string;
    boxStyle?: string;
    subHeading?: string;
    header?: string;
}

const AccessToThePlatform: React.FC<AccessToThePlatformProps> = ({ contentStyle, header, boxStyle, subHeading }) => {
    return (
        <>
            <div className={boxStyle}>
                <span className={subHeading}>{header}</span>
            </div>
            <div className={contentStyle}>
                <p>
                    2.1 When You use the Platform or send emails or other data to SteelBazaar, You agree that you are communicating through electronic records and consent to receive communications via electronic records or otherwise periodically to the provided electronic or residential address. SteelBazaar may communicate with you via email or other modes of communication, electronic or otherwise.
                </p>
                <br />
                <p>
                    2.2 SteelBazaar will make reasonable efforts to ensure uninterrupted availability of the Platform and error-free transmissions. However, due to the inherent nature of the internet, this cannot be guaranteed. The Platform may be occasionally suspended or restricted for repairs, maintenance, or the introduction of new facilities, upgrades, or services without prior notice. SteelBazaar reserves the right to refuse access to the Platform to new users or terminate access for existing Users at any time without assigning any reason.
                </p>
            </div>
        </>
    )
}

export default AccessToThePlatform;