import React, { useState } from 'react'
import Button from '../../../atoms/Button/Button';
import SelectV2 from '../../../atoms/Select/SelectV2';
import { createUseStyles } from 'react-jss';
import { FormikProps } from 'formik';
import { IMaterialOtherPreference } from './MaterialOtherPreferences.template';
import { CITY_OPTIONS, PREFERRED_DELIVERY_DATE, TRADE_TYPE } from '../../../../utils/constant';
import MultiSelectInput2, { IMultiSelectOption } from '../../../atoms/MultiSelectInput/MultiSelectInput2';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { MODE } from '../../../../utils/types';
import ErrorMessage from '../../../atoms/ErrorMessage/ErrorMessage';
import { ICustomization } from '../../Customization/SelectCustomization.template';

interface MaterialOtherPreferencesFormTemplateProps {
    handleCustomization: (name: string) => (e: any) => void;
    handleSelectChange: (key: string) => (event: any) => void;
    handleMultiSelectChange: (key: string) => (option: IMultiSelectOption[]) => void;
    customizations: ICustomization[];
    formik: FormikProps<IMaterialOtherPreference>
}

const useStyles = createUseStyles((theme: any) => ({
    primary500: {
        color: theme.palette.text.primary.primary500,
    },
    heading: {
        color: theme.palette.text.primary.primary700
    },
    tertiary100Border: {
        borderColor: theme.palette.border.neutral.neutral100
    },
    sectionBackground: {
        background: 'white',
        border: `1px solid ${theme.palette.border.neutral.neutral100}`
    },
    checkedInputColor: {
        color: theme.palette.text.primary.primary400,
        '&.Mui-checked': {
            color: theme.palette.text.primary.primary500,
        },
    },
    borderTop: {
        borderTop: `1px solid ${theme.palette.border.neutral.neutral100}`
    },
}));
const MaterialOtherPreferencesFormTemplate: React.FC<MaterialOtherPreferencesFormTemplateProps> = ({ customizations, formik, handleCustomization, handleSelectChange, handleMultiSelectChange }) => {
    const classes = useStyles();
    return (
        <form className={` p-6 grid gap-6 rounded-xl ${classes.sectionBackground}`} onSubmit={formik.handleSubmit}>
            <div className={`grid gap-3`}>
                <div className={`w-full flex justify-between items-center`}>
                    <span className={`${classes.heading} text-lg font-medium`}>Other Preference</span>
                    <Button variant='tertiaryContained' type="submit" label="Save" className='!font-normal' />
                </div>
                <div className={`${classes.tertiary100Border} border-b`}></div>
                <div className={`grid grid-cols-2 gap-6`}>
                    <SelectV2
                        {...formik.getFieldProps('preferredDeliveryTime')}
                        options={PREFERRED_DELIVERY_DATE.map((option: any) => ({ label: option.label, value: option.value }))}
                        label="Delivery time after order"
                        value={formik.values.preferredDeliveryTime}
                        onChange={handleSelectChange('preferredDeliveryTime')}
                        fullWidth
                        placeholder='Select Delivery Date'
                        error={formik.touched.preferredDeliveryTime && Boolean(formik.errors.preferredDeliveryTime)}
                        helperText={formik.errors?.preferredDeliveryTime}
                    />
                    <MultiSelectInput2
                        {...formik.getFieldProps('preferredDeliveryLocation')}
                        options={CITY_OPTIONS.map(option => ({ label: option.label, value: option.id }))}
                        label="Preferred Delivery Location"
                        value={formik.values.preferredDeliveryLocation}
                        onchange={handleMultiSelectChange('preferredDeliveryLocation')}
                        fullWidth
                        placeholder='Select Delivery Location'
                        error={formik.touched.preferredDeliveryLocation && Boolean(formik.errors.preferredDeliveryLocation)}
                        helperText={formik.errors?.preferredDeliveryLocation as string}
                    />
                </div>
            </div>
            <div className={`grid gap-3`}>
                <div className={`w-full flex justify-between items-center`}>
                    <span className={`${classes.heading} text-lg font-medium`}>Select Customization</span>
                </div>
                <div >
                    <div className={`grid gap-x-3 grid-cols-4`}>
                        {
                            customizations.map((customization) => (
                                <FormControlLabel
                                    control={<Checkbox
                                        sx={{ padding: 0, width: "30px", height: "30px", }}
                                        className={`${classes.checkedInputColor}`} />}
                                    label={customization.name}
                                    checked={formik.values.customization?.includes(customization.name)}
                                    className={`rounded-lg px-3 py-2 border !ml-0 ${classes.tertiary100Border}`}
                                    {...formik.getFieldProps('customization')}
                                    onChange={handleCustomization(customization.name)}
                                />
                            ))
                        }
                        {formik.touched.customization && Boolean(formik.errors.customization) && <ErrorMessage message={formik?.errors?.customization as string} />}
                    </div>
                </div>
            </div>
            <div className={`grid gap-3`}>
                <div className={`w-full flex justify-between items-center`}>
                    <span className={`${classes.heading} text-lg font-medium`}>Import/Export</span>
                </div>

                <div className={`grid gap-6 grid-cols-2`}>
                    <SelectV2
                        {...formik.getFieldProps('tradeType')}
                        options={TRADE_TYPE.map(option => ({ label: option.label, value: option.value }))}
                        label="Trade Type"
                        value={formik.values.tradeType}
                        onChange={handleSelectChange('tradeType')}
                        placeholder='Select Trade Type'
                        error={formik.touched.tradeType && Boolean(formik.errors.tradeType)}
                        helperText={formik.errors?.tradeType}
                    />
                </div>
            </div>
        </form>
    )
}

export default MaterialOtherPreferencesFormTemplate