import * as React from 'react';
import { useEffect, useState } from 'react';
import { createUseStyles } from "react-jss";
import { useOrderService } from '../../../services/useOrderService';
import { Pagination } from "@mui/material";
import OrderDetailsTemplate from './OrderDetails.template';
import OrderItemTemplate from './OrderItem.template';
import { Attribute } from '../../../utils/types';
import SearchIcon from "@mui/icons-material/Search";
import { Charges } from '../Lead/QuotationView.template';
import Select from '../../atoms/Select/Select';
import { CUSTOMER_ROUTES, STATUS, dateFilter } from '../../../utils/constant';
import TextField from '../../atoms/Input/TextField';
import LeadListSkeletonTemplate from '../Lead/LeadListSkeleton.template';
import NoProduct from '../../molecules/NoProduct/NoProduct';
import { useNavigate } from 'react-router-dom';
import { CATALOGUE_TYPE_STATES, CLASS_STATES } from "../../../utils/types";
import { makeRoute } from '../../../utils/helper';
export interface Product {
	id: number;
	catalogueName: string;
	brand: string;
	standard?: string;
	grade?: string;
	manufacturer?: string;
	attributes: UpcAttributes;
	warehouse: string;
	uom: string;
	price?: string;
	catalogueImages?: any;
	quantity: number;
	packingType: string;
	catalogueImage?: string;
	skuData?: any;
	totalQuantity: number;
	secondaryLotAttributes: any;  
	requestPrice?: number; 
	secondaryCatalogueId: number;
	secondaryProductDetails: any;
	catalogueType: string;
	classType: string;
	catalogueId: number;
}

export interface Order {
	id: number;
	orderStatus: string;
	createdAt: Date;
	paymentStatus: string;
	totalPrice: number;
	products: Product[];
	secondaryProducts: Product[];
	charges: Charges[];
}

export interface UpcAttributes {
	SPECIFICATION?: Attribute[];
	productSpecification?: Attribute[];
}

interface IPagination {
	size: number;
	totalRecords: number;
	page: number;
}

const useStyles = createUseStyles((theme: any) => ({
	title: {
		color: theme.palette.text.primary.primary900,
	},
	pagination: {
		"& .MuiPaginationItem-root": {
		  color: theme.palette.text.neutral.neutral500,
		  border: `1px solid ${theme.palette.text.neutral.neutral500}`,
		  fontFamily: "Inter",
		  fontSize: "14px",
		  fontWeight: 700,
		},
		"& .MuiPaginationItem-page.Mui-selected": {
		  backgroundColor: "white",
		  color: theme.palette.text.neutral.neutral500,
		  border: `1px solid ${theme.palette.border.neutral.neutral500}`,
		  fontFamily: "Inter",
		  fontSize: "14px",
		  fontWeight: 700,
		},
	  },
	activeView: {
		color: theme.palette.text.primary.primary300,
		boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.25)",
	},
	tabTitle: {
		color: theme.palette.text.neutral.neutral500,
	},
	activeTabTitle: {
		color: theme.palette.text.primary.primary400,
		borderBottom: `2px solid ${theme.palette.text.primary.primary400}`
	},
	dateSelect: {
		backgroundColor: theme.palette.background.primaryLight,
		"& .MuiOutlinedInput-input": {
			paddingTop: "7px !important",
			paddingBottom: "7px !important",
		},
		"& .MuiOutlinedInput-notchedOutline": { 
		  border: 0
		}
	},
	searchInput: {
		"& .MuiOutlinedInput-input": {
			paddingTop: "12.5px !important",
			paddingBottom: "12.5px !important",
		}
	},
	mobileContainer: {
		display: "none",
	},
	webContainer: {
		display : "grid"
	},
	searchIcon:{
		background: "#D6E1FF"
	},
	tabContainer:{
	},
	"@media (max-width: 476px)": {
		mobileContainer:{
		  display: "grid",
		},
		webContainer: {
		  display : "none"
		},
		tabContainer:{
		  scrollbarWidth: "none",
		}
	},
}));

const orderStatusTabs = [
	{
		label: "All Orders",
		status: ""
	},
	{
		label: "Delivered Order",
		status: STATUS.COMPLETED
	},
	{
		label: "Pending Order",
		status: STATUS.ONGOING
	}
];

const OrderListTemplate: React.FC = () => {

	const orderService = useOrderService();
	const [orders, setOrders] = useState<Order[]>([]);
	const [activeTabIndex, setActiveTabIndexTo] = useState<number>(0);
	const classes = useStyles();
	const [pagination, setPaginationTo] = useState<IPagination>({
		size: 10,
		totalRecords: 0,
		page: 0
	});
	const [loading, setLoading] = useState<boolean>(true);

	const refreshOrders = async () => {
		try {
			setLoading(true);
			const params = {
				page: pagination.page,
				size: pagination.size,
				sort: "createdAt,desc",
				status: orderStatusTabs[activeTabIndex].status,
				date: lastDate,
				catalogueName: catalogueName
			};
			const ordersResponse = await orderService.getAllOrders(params);
			if (ordersResponse?.data?.data?.content?.length) {
				const { content, totalElements } =
					ordersResponse.data.data;
				setOrders(content);
				setPaginationTo((prevPagination: IPagination) => ({
					...prevPagination,
					totalRecords: totalElements
				}));
				setLoading(false);
			} else {
				throw new Error("Categories fetch failed");
			}
		} catch (error) {
			setOrders([]);
			setLoading(false);
		}
	};

	const handleTabChange = (currentTabIndex: number) => () => {
		setActiveTabIndexTo(currentTabIndex);
	}

	const handlePageChange = (
		event: React.ChangeEvent<unknown>,
		page: number
	) => {
		setPaginationTo((prevPagination: IPagination) => ({
			...prevPagination,
			page: page - 1,
		}));
	};

	const [dateFilterValue, setDateFilterValueTo] = useState<number>(0);
	const [lastDate, setLastDateTo] = useState<string | null>(null);
	const handleChange = (event: any) => {
	  setDateFilterValueTo(event.target.value);
	  if (event.target.value === 0) {
		setLastDateTo(null);
	  } else {
		const today = new Date();
		const newStartDate = new Date(today);
		newStartDate.setMonth(today.getMonth() - event.target.value);
		setLastDateTo(newStartDate.toISOString().split('T')[0]);
	  }
	};

	const [catalogueName, setCatalogueNameTo] = useState("");
	const handleSearch = (event: any) => {
		const { name, value } = event.target;
	 if (name === "catalogueName") {
		  setCatalogueNameTo(value);
		}
	};

	useEffect(() => {
		refreshOrders();
		document.getElementById('main')?.scroll(0,0)
	}, [pagination.page, pagination.size, activeTabIndex, lastDate, catalogueName]);

	const navigate = useNavigate();

    const handleViewOrder = (id: number) => () => {
        navigate(`${CUSTOMER_ROUTES.DASHBOARD}${CUSTOMER_ROUTES.ORDER}/${id}`);
    }

	const [orderVisibility, setOrderVisibility] = useState<{ [key: number]: boolean }>({});

	const handleViewAllClick = ( product: Product) => {
		// if (event.target.closest("#child")) return;
        if (product.classType === CLASS_STATES.STANDARD || product.classType === CLASS_STATES.NONSTANDARD) {
            if (product.catalogueType === CATALOGUE_TYPE_STATES.CURRENT_INVENTORY) {
                navigate(makeRoute(CUSTOMER_ROUTES.CATALOGUE_DESCRIPTION, { query: { "catalogueId": product.catalogueId } }))
            }
            else if (product.catalogueType === CATALOGUE_TYPE_STATES.MAKE_TO_ORDER) {
                navigate(makeRoute(CUSTOMER_ROUTES.CATALOGUE_MTO_VIEW, { query: { "catalogueId": product.catalogueId } }));
            }else{
                console.log("click",product.classType,"product.catalogueType", product.catalogueType,"product",product);
            }
        }
        else if (product.classType === CLASS_STATES.SECONDARY) {
            if (product.catalogueType === CATALOGUE_TYPE_STATES.MAKE_TO_ORDER) {
                navigate(makeRoute(CUSTOMER_ROUTES.SECONDARY_CATALOGUE_LOT_VIEW, { query: { "secondaryCatalogueId": product.catalogueId } }));
            }
            else {
                navigate(makeRoute(CUSTOMER_ROUTES.SECONDARY_CATALOGUE_DESCRIPTION, { query: { "secondaryCatalogueId": product.catalogueId } }));
            }
        }
	  };

	return (
		<>
			<div className={`${classes.webContainer} w-full gap-y-6`}>
				<div className="flex items-center justify-between border-b-2 pb-6">
					<h2 className={ `${ classes.title } text-2xl` }>All Orders</h2>
					<div className="flex items-center gap-5">
						<TextField
							label="Enter a keyword to search a product..."
							variant="outlined"
							type="text"
							name="catalogueName"
							value={ catalogueName }
							onChange={ handleSearch }
							className={ `${ classes.searchInput } w-80` }
						/>
					</div>
				</div>
				<div className='flex justify-between'>
					<div className="flex gap-x-5 border-b-2 w-fit">
						{ orderStatusTabs.map((orderStatus, index) => (
							<span
								key={ orderStatus.status }
								onClick={ handleTabChange(index) }
								className={ `cursor-pointer ${ activeTabIndex === index ? `${ classes.activeTabTitle } pb-3` : `${ classes.tabTitle }` }` }
							>
								{ orderStatus.label }
							</span>
						))}
					</div>
					<div className=''>
						<Select
							label=""
							value={dateFilterValue}
							onChange={handleChange}
							className={`${classes.dateSelect}`}
							name="dateFilter"
							id="dateFilter"
							options={dateFilter}
							variant={'outlined'}/>
					</div>
				</div>
				{
					loading ?
						([null, null, null].map(() => (
							<div className="grid gap-y-6"><LeadListSkeletonTemplate /></div>
						))) : orders && orders.length > 0 ? (
							orders.map((order: Order) => (
								order.products.length > 0 && 
								<div key={ order.id } className="grid gap-y-6 p-6 rounded border-2">
									<OrderDetailsTemplate order={ order } source="LIST" onClick={ handleViewOrder(order?.id) } />
									{ order?.products?.map((product: Product) => (
										<OrderItemTemplate
											label={ "Buy again" }
											key={ product.id }
											product={ product }
											onClick={() => handleViewAllClick(product)}
										/>
									))}
								</div>
							))) : (
							<NoProduct text="No Orders" subText='Explore our exclusive products and shop them.' label='Start Exploring' />
						)}
					<Pagination
						className={ `${ classes.pagination } m-auto mt-6` }
						variant="outlined"
						shape="rounded"
						count={ Math.ceil(pagination.totalRecords / pagination.size) }
						page={ pagination.page + 1 }
						onChange={ handlePageChange }
					/>
			</div>

			<div className={`${classes.mobileContainer} w-full gap-y-4 mx-auto`}>

			    <div className="flex justify-end">
			        <div className=''>
						<Select
							label=""
							value={dateFilterValue}
							onChange={handleChange}
							className={`${classes.dateSelect}`}
							name="dateFilter"
							id="dateFilter"
							options={dateFilter}
							variant={'outlined'}/>
					</div>
				</div>	

				<div className="flex items-center gap-2">
					<div className="flex-1">
						<TextField
							label="Enter a keyword to search a product..."
							variant="outlined"
							type="text"
							name="catalogueName"
							value={ catalogueName }
							onChange={ handleSearch }
							className={ `${ classes.searchInput } w-full` }
						/>
					</div>
					<div className={`${classes.searchIcon} py-3 px-3.5 rounded-md`}>
						<SearchIcon className="text-base text-indigo-900" />
					</div>
				</div>
				
				<div className={`${classes.tabContainer} overflow-x-auto border-b-2`}>
                    <div className="flex gap-x-5 ">
						{ orderStatusTabs.map((orderStatus, index) => (
							<span
								key={ orderStatus.status }
								onClick={ handleTabChange(index) }
								className={ `cursor-pointer flex-none ${ activeTabIndex === index ? `${ classes.activeTabTitle } pb-1 text-xs` : `${ classes.tabTitle } text-xs` }` }
							>
								{ orderStatus.label }
							</span>
						))}
					</div>
				</div>
				{
					loading ?
						([null, null, null].map(() => (
							<div className="grid gap-y-6"><LeadListSkeletonTemplate /></div>
						))) : orders && orders.length > 0 ? (
							orders.map((order: Order) => (
								order.products.length > 0 && 
								<div key={ order.id } className="grid gap-y-4 rounded">
									<OrderDetailsTemplate 
										order={ order } 
										source="LIST" 
										onClick={ handleViewOrder(order?.id) }
										show={orderVisibility[order.id] || false}
										setShow={() => setOrderVisibility(prevState => ({ ...prevState, [order.id]: !prevState[order.id] }))}
									/>
									{ order?.products?.map((product: Product) => (
										<div className={`${orderVisibility[order.id] ? "hidden" : ""}`} key={product.id}>
											<OrderItemTemplate
												label={ "Buy again" }
												key={ product.id }
												product={ product }
											/>
										</div>	
									))}
								</div>
							))) : (
							<NoProduct text="No Orders" subText='Explore our exclusive products and shop them.' label='Start Exploring' />
						)}
					<Pagination
						className={ `${ classes.pagination } m-auto mt-6` }
						variant="outlined"
						shape="rounded"
						count={ Math.ceil(pagination.totalRecords / pagination.size) }
						page={ pagination.page + 1 }
						onChange={ handlePageChange }
					/>
			</div>
		</>	
	);
};

export default OrderListTemplate;