import React, { useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import TextFieldV2 from '../../atoms/Input/TextFieldV2';
import CustomRadioGroup from '../../molecules/CustomRadioGroup/CustomRadioGroup';
import { PACKAGING_TYPES } from '../../../utils/constant';
import { FormikErrors, FormikProps } from 'formik';
import { VENDOR_CATALOGUE_FORM_STATES } from '../../../utils/types';
import { IcatalogueProductDetails, IVendorCatalogueAddForm } from '../../pages/VendorCatalogue/VendorCatalogueAdd.page';
import { FormHelperText } from '@mui/material';

interface IDeliveryTermsTemplateProps {
    setCurrentSectionTo: (section: VENDOR_CATALOGUE_FORM_STATES) => void;
    formik: FormikProps<IVendorCatalogueAddForm>;
    index: number;
    warehouseId: number;
}
const useStyles = createUseStyles((theme: any) => ({
    textHeading2: {
        color: theme.palette.text.primary.primary900
    },
    radioColor: {
        color: theme.palette.background.primary.primary400,
        width: '23%',
        marginTop: "16px"
    },
    helperText: {
        position: 'absolute',
        bottom: '-20px',
    },
}));



const DeliveryTermsTemplate: React.FC<IDeliveryTermsTemplateProps> = ({warehouseId, formik, setCurrentSectionTo, index }) => {
    const classes = useStyles();
    const [packagingType, setPackagingType] = useState<string>("");


    const handleChangePackaging = (event: React.ChangeEvent<HTMLInputElement>) => {
        formik.setFieldValue(`catalogueProductDetails.${index}.packagingType`, event.target.value);
        setPackagingType(event.target.value);
    };

    useEffect(() => {
        for (let i = formik.values.catalogueProductDetails.length; i <= index; i++) {
            const newCatalogueProductDetails = {
                productId: null,
                warehouseId: null,
                maximumRetailPrice: null,
                offerSalePrice: null,
                deliveryTerms: '',
                packagingType: '',
                productDiscountDetails: [{
                    locationId: null,
                    minQuantity: null,
                    maxQuantity: null,
                    minDiscount: null,
                    maxDiscount: null,
                }],
                productServicesDetails: [{
                    locationId: null,
                    machineId: null
                }],
                cGst: null,
                sGst: null,
                iGst: null,
                tax3: null,
            }
            formik.setFieldValue('catalogueProductDetails', [...formik.values.catalogueProductDetails, newCatalogueProductDetails]);
        }
    }, [index])

    useEffect(()=>{
        formik.setFieldValue(`catalogueProductDetails.${index}.warehouseId`,warehouseId);
    },[warehouseId])

    return (
        <div className='mt-4'>
            <TextFieldV2
                {...formik.getFieldProps(`catalogueProductDetails.${index}.deliveryTerms`)}
                label={"Delivery Terms"}
                size="small"
                placeholder={"Enter data"}
                multiline
                rows={4}
                error={
                    formik.touched.catalogueProductDetails && formik.touched.catalogueProductDetails?.[index]?.deliveryTerms &&
                    Boolean(formik.errors.catalogueProductDetails && Array.isArray(formik.errors.catalogueProductDetails) &&
                        (formik.errors.catalogueProductDetails as FormikErrors<IcatalogueProductDetails>[])[index]?.deliveryTerms
                    )
                }
                helperText={
                    formik.touched.catalogueProductDetails && formik.touched.catalogueProductDetails?.[index]?.deliveryTerms &&
                    Array.isArray(formik.errors.catalogueProductDetails) &&
                    (formik.errors.catalogueProductDetails as FormikErrors<IcatalogueProductDetails>[])[index]?.deliveryTerms
                }
                FormHelperTextProps={{ className: `${classes.helperText}` }}
            />
            <div className='mt-6'>
                <text className={`${classes.textHeading2} text-base font-medium `}>Packaging Type</text>
              
                <CustomRadioGroup
                  {...formik.getFieldProps(`catalogueProductDetails.${index}.packagingType`)}
                    options={PACKAGING_TYPES}
                    onChange={handleChangePackaging}
                    className={`${classes.radioColor} `}
                />
                {formik?.touched?.catalogueProductDetails && formik?.touched?.catalogueProductDetails?.[index]?.packagingType &&
                Array.isArray(formik.errors.catalogueProductDetails) &&
                (formik?.errors?.catalogueProductDetails as FormikErrors<IcatalogueProductDetails>[])[index]?.packagingType &&
                        <FormHelperText error className='top-half '>{(formik?.errors?.catalogueProductDetails as FormikErrors<IcatalogueProductDetails>[])[index]?.packagingType}</FormHelperText>
                    }
              
            </div>
        </div>
    );
};

export default DeliveryTermsTemplate;
