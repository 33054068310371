import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import CloseIcon from "@mui/icons-material/Close";
import { createUseStyles } from "react-jss";
import Button from "../../atoms/Button/Button";
import { useVendorInventoryService } from "../../../services/useVendorInventoryService";
import { HTTP_STATUS, IPagination } from "../../../utils/types";
import { useSnackbar } from "../../../hooks/useSnackBar";
import { initialPaginationValues } from "../../../utils/constant";
import TableV2, { ColumnType } from "../../organisms/TableV2";

interface ViewDetailsModalTemplateProps {
  vendorId:number | undefined;
  productId:number | undefined;
  productCategory:string | undefined;
  selectedWarehouse:any;
  dialogOpen: boolean;
  setDialogOpen: (open: boolean) => void;
}

const useStyles = createUseStyles((theme: any) => ({
  container: {
    "& .MuiDialog-paper": {
      width: "783px !important",
      maxWidth: "783px !important",
      borderRadius: "12px",
    },
  },
  mainContainer: {
    padding: "24px",
  },
  productName: {
    fontSize: "24px",
    fontWeight: "500",
    color: theme.palette.text.primary.primary800,
  },
  skuID: {
    color: theme.palette.text.neutral.neutral700,
    fontSize: "16px",
    fontWeight: "400",
    marginTop: "8px",
  },
  hr: {
    margin: "16px 0px",
  },
  category:{
    color:theme.palette.text.neutral.neutral700
  },
  categoryName:{
    color:theme.palette.text.primary.primary800
  },
  closeIcon: {
    color: theme.palette.background.neutral.neutral200,
  },
}));
  
const ViewDetailsModalTemplate: React.FC<ViewDetailsModalTemplateProps> = ({
  vendorId,
  productId,
  productCategory,
  selectedWarehouse,
  dialogOpen,
  setDialogOpen,
}) => {
  const classes = useStyles();
  const { showSnackbar, SnackBarComponent } = useSnackbar();
  const vendorInventoryService = useVendorInventoryService()
  const [pagination, setPagination] = useState<IPagination>(initialPaginationValues);
  const [usnData, setUsnData]=useState<any>([])

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const getUsnDetails = async () => {
    if(vendorId && productId && selectedWarehouse?.warehouseId){
      try{
        const id=vendorId
        const warehouseId=selectedWarehouse.warehouseId
        const usnDetails = await vendorInventoryService.getWarehouseUsnDetails(id,warehouseId, productId);
        if(usnDetails.status===HTTP_STATUS.OK){
          const { content, totalElements, totalPages } = usnDetails?.data?.data;
          setPagination({
            ...pagination,
            totalPages: totalPages,
            totalRecords: totalElements
          });
          setUsnData(content);
        }
      } catch(error){
          showSnackbar('error', "USN Details fetch failed")
      }
    }
  }

  const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setPagination((prevPagination) => ({
        ...prevPagination,
        pageSize: newRowsPerPage
    }));
  };

  const handlePaginationChange = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPagination((prevPagination) => ({
        ...prevPagination,
        currentPage: newPage
    }));
  };

  const schema = {
    id: "1",
    title: "",
    pagination: {
        total: pagination?.totalRecords,
        currentPage: pagination?.page,
        isVisible: false,
        limit: pagination?.size,
        handleChangePage: handlePaginationChange,
        handleChangeRowsPerPage: handleRowsPerPageChange,
    },
    columns: [
        { label: "S.No.", key: "id", type: "number" as ColumnType, props: { className: '' } },
        { label: "USC ID", key: "usnCode", type: "string" as ColumnType, props: { className: '' } },
        { label: "Net Weight (MT)", key: "netWeight", type: "number" as ColumnType, props: { className: '' } },
    ],
  };

  const records = usnData?.map((usn: any, index: number) => [
    pagination.page * pagination.size + index + 1,
    usn?.usnCode,
    usn?.netWeight
]);

  useEffect(() => {
    getUsnDetails()
  },[vendorId,productId,selectedWarehouse?.warehouseId])

  return (
    <div>
      <Dialog
        fullWidth
        className={classes.container}
        open={dialogOpen}
        onClose={handleDialogClose}
      >
        <div className={classes.mainContainer}>
          <div className="flex justify-between items-center">
            <div className={classes.productName}>
                Product Details USN wise
            </div>
            <div className={`cursor-pointer`} onClick={handleDialogClose}>
              <CloseIcon className={`${classes.closeIcon}`} />
            </div>
          </div>
          <div className={classes.skuID}>Location: {selectedWarehouse?.name}</div>
          <hr className={classes.hr} />
          <div className={`${classes.category} mb-2 font-medium`}>Product Category: <span className={`${classes.categoryName}`}>{productCategory}</span></div>

          <TableV2 schema={schema} records={records} />
          <Button
            variant="primaryContained"
            label="Go Back"
            className={`float-right !mt-6`}
            size="medium"
            onClick={handleDialogClose}
          />
        </div>
      </Dialog>
    </div>
  );
};

export default ViewDetailsModalTemplate;
