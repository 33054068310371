import { POLICY_SECTION } from "../../../utils/constant";

interface YourInformationProps {
    contentStyle?: string;
    boxStyle?: string;
    subHeading?: string;
}

const YourInformation: React.FC<YourInformationProps> = ({ contentStyle, boxStyle, subHeading }) => {
    return (
        <>
            <div className={boxStyle}>
                <span className={subHeading}>{POLICY_SECTION.YOUR_INFORMATION}</span>
            </div>
            <div className={contentStyle}>
                <p>
                    1.1 A prerequisite for every User's utilization and access to the Platform is their acceptance of the terms of use ("Terms of Use"). This acceptance also encompasses agreement with the terms outlined in this Privacy Policy. Users who do not concur with any provisions of the Terms of Use or this Privacy Policy are advised not to accept the Terms of Use and refrain from using the SteelBazaar Platform.
                    <br /> <br />
                    1.2 SteelBazaar collects various types of information about You when You engage with the Platform, including Account Information, Demographic Information, Financial Information, Behavioural Information, and Indirect Information (collectively referred to as "Information"), as well as User Information.
                    <br /><br />
                    1.3 To access the Platform, You are required to furnish details such as Your name, address, third-party sign-in information from other websites (e.g., Facebook, Google, Twitter, or Pinterest), contact number, email address, Know Your Customer (KYC) documents, GST registrations, and related information, along with information about the services You browse and email communications with Us (e.g., product inquiries, orders, transactions, disputes, or complaints) ("Account Information").
                    <br /> <br />
                    1.4 "Personal Information," as defined under the SPDI Rules, refers to any information relating to a natural person capable of identifying that person. If any of the information outlined below falls under Personal Information, You agree that SteelBazaar may collect, use, share, process, and disclose such Personal Information for the purposes stated in this Privacy Policy.
                    <br /> <br />
                    1.5 Information that is not unique to You, such as postal code, IP location, URL, and occupation, may be collected for demographic purposes ("Demographic Information").
                    <br /> <br />
                    1.6 For Services usage and Product purchases, You may need to make payments through credit cards, electronic transfers, debit cards, or other applicable payment modes via SteelBazaar's third-party payment gateway provider. To avail financing options, additional financial information may be required. Third-party payment gateway providers and financiers may collect Financial Information for billing, payment processes, and evaluating financing options.
                    <br /><br />
                    1.7 Information on how You use the Platform, including visited areas, computer hardware and software details, IP address, browser and operating system type, IMEI number, MAC address, domain names, access times, locations, and browsing history, is collected as "Behavioural Information."
                    <br /> <br />
                    1.8 Utilizing certain third-party services on the Platform may necessitate the collection of additional information ("Indirect Information").
                    <br /><br />
                    1.9 Details regarding Your transactions on the Platform, purchase history, passwords, financial information, and any other items classified as "sensitive personal data or information" under the SPDI Rules constitute "User Information."
                    <br /> <br />
                    1.10 SteelBazaar may retain records of telephone calls for administration, research and development, training, business intelligence, business development, or User administration purposes. Telephone records may be shared with third parties when required by law or to provide or facilitate Services.
                    <br /> <br />
                    1.11 Contests and surveys may be conducted to gather information about User preferences. Participation is optional, and responses are kept anonymous.
                    <br /> <br />
                    1.12 SteelBazaar has implemented security measures, including firewalls and transport layer security, to protect Financial and Personal Information from unauthorized access or disclosure. SteelBazaar is not liable for security breaches beyond its reasonable control.
                    <br /> <br />
                    1.13 In case of a merger or acquisition, SteelBazaar may transfer Personal Information to the acquiring party, ensuring reasonable measures for protection.
                    <br /> <br />
                    1.14 Personal and other information may be disclosed or transferred to third parties during reorganization, sale of assets, division, or transfer of the company.
                    <br /> <br />
                    1.15 Disclosure of Personal or Financial Information to governmental institutions may occur when required by law or judicial decree.
                    <br /> <br />
                    1.16 Any changes to this Privacy Policy are effective upon update, and Users are responsible for reviewing it periodically.
                    <br /> <br />
                    1.17 Users consent to SteelBazaar reproducing/publishing testimonials and reviews on the Platform, including the User's name and location.
                    <br /> <br />
                    1.18 If using the Platform on behalf of another entity, Users represent and consent on behalf of that entity to the terms of this Privacy Policy.
                    <br /> <br />
                    1.19 All information provided to SteelBazaar, including Information, is voluntary. Users have the right to withdraw consent as per the terms of this Privacy Policy and the Terms of Use. Users are responsible for exercising caution when sharing Information. SteelBazaar disclaims liability for Services content and actions resulting from User participation. Users agree to waive any claims against SteelBazaar and release any claims relating to the same. <br /> <br />
                </p>
            </div>
        </>

    )
}
export default YourInformation;