import { request } from "."
import { IBusinessDetails } from "../components/template/OnBoarding/BusinessDetails.template";
import { IEntityType } from "../components/template/OnBoarding/EntityType.template";
import { IGstInformation } from "../components/template/OnBoarding/GstInformation.template";
import { IProduct } from "../components/template/OnBoarding/Product.template";
import { IServiceRequirements } from "../components/template/OnBoarding/ServiceRequirements.template";
import { useAuthenticatedUser } from "../hooks/useAuthenticatedUser";
import { API_METHOD } from "../utils/constant";
import { replaceUrlParams } from "../utils/helper";

export const BUSINESS_URLS = {
    CREATE: "/business-profile",
    UPDATE: "/business-profile/:businessProfileId",
    GET: "/business-profile/:businessProfileId",
}

export const useBusinessService = () => {
    const { user } = useAuthenticatedUser();

    const createBusiness = (data: IGstInformation) => {
        return request(API_METHOD.POST, BUSINESS_URLS.CREATE, user, data);
    }

    const updateBusiness = (data: IGstInformation|IBusinessDetails|IEntityType|IProduct|IServiceRequirements, businessProfileId?: number) => {
        return request(API_METHOD.PUT, replaceUrlParams(BUSINESS_URLS.UPDATE, { businessProfileId }), user, data);
    }

    const getBusiness = (businessProfileId: number) => {
        return request(API_METHOD.GET, replaceUrlParams(BUSINESS_URLS.GET, { businessProfileId }), user, null);
    }

    return {
        createBusiness, updateBusiness, getBusiness
    }
};