import React, { useMemo, useState } from 'react'
import { createUseStyles } from 'react-jss';
import { VENDOR_CATALOGUE_FORM_STATES } from '../../../utils/types';
import { FormikProps } from 'formik';
import { IVendorCatalogueAddForm, IVendorCatalogueAddProps } from '../../pages/VendorCatalogue/VendorCatalogueAdd.page';
import SelectProductTypeFormTemplate from './SelectProductTypeForm.template';
import SelectCatalogTypeFormTemplate from './SelectCatalogTypeForm.template';
import SelectProductIdFormTemplate from './SelectProductIdForm.template';
import AddPricingDetailFormTemplate from './AddPricingDetailForm.template';
import CustomisationFormTemplate from './CustomisationForm.template';
import AddCatalogueReviewFormTemplate from './AddCatalogueReviewForm.template';
import { MODE } from '../../../utils/constant';

const useStyles = createUseStyles((theme: any) => ({
    titleText: {
        color: theme.palette.text.primary.primary900
    },
    heading1: {
        color: theme.palette.text.primary.primary400,
    }
}));

export interface VendorCatalogueSectionProp {
    setCurrentSectionTo: (section: VENDOR_CATALOGUE_FORM_STATES) => void;
    formik: FormikProps<IVendorCatalogueAddForm>;
    mode?: string;
}

const VendorCatalogueFormTemplate: React.FC<IVendorCatalogueAddProps> = ({ formik, mode }) => {
    const classes = useStyles();
    const [currentSection, setCurrentSection] = useState<VENDOR_CATALOGUE_FORM_STATES>(VENDOR_CATALOGUE_FORM_STATES.CATALOGUE_TYPE);

    const addVendorInventorySectionProps: VendorCatalogueSectionProp = {
        setCurrentSectionTo: setCurrentSection,
        formik: formik,
        mode: mode,
    };

    const VendorInventorySectionView = useMemo(() => {
        if (mode === MODE.VIEW) {
            return <AddCatalogueReviewFormTemplate {...addVendorInventorySectionProps} />;
        }
        switch (currentSection) {
            case VENDOR_CATALOGUE_FORM_STATES.CATALOGUE_TYPE:
                return <SelectCatalogTypeFormTemplate {...addVendorInventorySectionProps} />;
            case VENDOR_CATALOGUE_FORM_STATES.PRODUCT_TYPE:
                return <SelectProductTypeFormTemplate {...addVendorInventorySectionProps} />;
            case VENDOR_CATALOGUE_FORM_STATES.PRODUCT:
                return <SelectProductIdFormTemplate {...addVendorInventorySectionProps} />;
            case VENDOR_CATALOGUE_FORM_STATES.ADD_PRICING:
                return <AddPricingDetailFormTemplate {...addVendorInventorySectionProps}  />;
            case VENDOR_CATALOGUE_FORM_STATES.CUSTOMIZATION:
                return <CustomisationFormTemplate {...addVendorInventorySectionProps}  />;
            case VENDOR_CATALOGUE_FORM_STATES.REVIEW:
                return <AddCatalogueReviewFormTemplate {...addVendorInventorySectionProps} />;
            default:
                return <SelectProductTypeFormTemplate {...addVendorInventorySectionProps} />;
        }
    }, [currentSection, formik]);

    return (
        <div className='grid gap-6 justify-items-stretch '>
         { mode === MODE.VIEW ?  (
            <div className='flex mb-4'>
            <div className={`${classes.titleText} font-medium text-lg`}>View Catalog: </div>
         <div className={`${classes.heading1} font-medium text-lg ml-1`}>{formik.values.name}</div>
         </div>)
          :
           (<div className={`${classes.titleText} font-medium text-lg`}>Add New Catalog</div>)}
            {VendorInventorySectionView}
        </div>
    )
}

export default VendorCatalogueFormTemplate