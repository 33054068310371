import WhatsAppIcon from "../../../assets/icons/whiteWhatsapp.svg";
import { Link } from 'react-router-dom';
import { NUMBER_URL } from "../../../utils/constant";
import { createUseStyles } from "react-jss";

interface WhatsappLabelProps {
    label:string
}

const useStyles = createUseStyles((theme: any) => ({
    container: {
        padding: "8px 12px",
        backgroundColor: theme.palette.text.success.success600,
    },
    label:{
        color:"white"
    },
}));

const WhatsappLabel: React.FC<WhatsappLabelProps> = ({label}) => {
    const classes = useStyles();
    return (
        <Link to={"#"} target="_blank" rel="noopener noreferrer" style={{ margin: 0,pointerEvents: "none" }}>
            <div className={`${classes.container} rounded-lg flex gap-2 items-center`}>
                <img width={"16px"} height={"16px"} src={WhatsAppIcon} alt="icon" />
                <div className={`${classes.label} text-sm`}>{label}</div>
            </div>
        </Link>
    )
}

export default WhatsappLabel;