import React, { useEffect, useState } from 'react'
import { useSnackbar } from '../../../hooks/useSnackBar';
import { useAuthenticatedUser } from '../../../hooks/useAuthenticatedUser';
import { useBusinessProfileService } from '../../../services/useBusinessProfileService';
import * as Yup from "yup";
import { HTTP_STATUS } from '../../../utils/types';
import { useFormik } from 'formik';
import StatutoryDetailTemplate from '../../template/OnBoarding/StatutoryDetail.template';
import { REGEX } from '../../../utils/constant';
import Shimmer from '../../molecules/ShimmerComponent/Shimmer';

export interface IBankDetails {
    isDefault: boolean;
    accountName: string;
    accountNumber: string;
    bankName: string;
    ifsc: string;
    branch: string;
}
interface Content {
    location: string;
    certificateType: string;
}

export interface IBusinessProfileStatutoryDocs {
    id: number;
    type: string;
    content: Content;
    createdAt: string | null;
    updatedAt: string;
}

export interface IStatutoryDetailsForm {
    bankDetails: IBankDetails[];
    docs : IBusinessProfileStatutoryDocs[];
    isDefault: boolean;
    accountName: string;
    accountNumber: string;
    bankName: string;
    ifsc: string;
    branch: string;
}

const initialValues = {
    bankDetails: [],
    docs:[],
    isDefault: false,
    accountName: '',
    accountNumber: '',
    bankName: '',
    ifsc: '',
    branch: ''
}
const StatutoryDetailPage: React.FC = () => {
    const { showSnackbar, SnackBarComponent } = useSnackbar();
    const { user: authenticatedUser } = useAuthenticatedUser();
    const bussinessProfileService = useBusinessProfileService();
    const [statutoryDetail, setStatutoryDetailTo] = useState<IStatutoryDetailsForm>(initialValues);
    const [isLoading, setIsLoading]= useState<boolean>(false);
    const [showEditForm, setShowEditForm] = useState(false);
    const [showEditFormBank, setShowEditFormBank] = useState(false);


    const validationSchema = Yup.object().shape({
        accountName: Yup.string()
            .required('Account Name is required')
            .matches(REGEX.NAME, 'Account Name should only contain letters.'),
        accountNumber: Yup.string()
            .required('Account Number is required')
            .matches(REGEX.ACCOUNT_NUMBER, 'Account Number should contain 8 to 16 digits.'),
        bankName: Yup.string()
            .required('Bank Name is required')
            .matches(REGEX.NAME, 'Bank Name should only contain letters.'),
        ifsc: Yup.string()
            .required('IFSC is required')
            .matches(REGEX.IFSC, 'IFSC should be alphanumeric and exactly 11 characters long'),
        branch: Yup.string()
            .required('Branch is required')
            .matches(REGEX.NAME, 'Branch should only contain letters.'),
        isDefault: Yup.boolean(),
    });

    const getBusinessProfile = async () => {
        try {
            const businessProfileResponse = await bussinessProfileService.getBusinessProfile();
            if (businessProfileResponse.status === HTTP_STATUS.OK) {
                const profileData = businessProfileResponse?.data?.data;
                const updatedStatutoryDetail = {
                    bankDetails: profileData?.finance?.bankAccounts || [], 
                    docs: profileData?.docs || [],
                    isDefault: false,
                    accountName: '',
                    accountNumber: '',
                    bankName: '',
                    ifsc: '',
                    branch: '' 
                };
                setStatutoryDetailTo(updatedStatutoryDetail);
                setShowEditForm(updatedStatutoryDetail.docs.length === 0);
                setShowEditFormBank(updatedStatutoryDetail.bankDetails.length===0);
            }
        } catch (error) {
            showSnackbar('error', 'Business Profile fetching failed');
        }
        finally {
            setIsLoading(true);
        }
    };
    
    const formik = useFormik<IStatutoryDetailsForm>({
        initialValues,
        validationSchema,
        onSubmit: async () => {
            const body = [
                ...formik.values.bankDetails,
                { 
                    accountName: formik.values.accountName,
                    accountNumber: formik.values.accountNumber,
                    bankName: formik.values.bankName,
                    branch: formik.values.branch,
                    ifsc: formik.values.ifsc,
                    isDefault: formik.values.isDefault,
                },
            ];
            try {
                await bussinessProfileService.updateBusinessProfile(body, 'BANK_ACCOUNTS');
                formik.resetForm(); 
                getBusinessProfile();
            } catch (error) {
                showSnackbar("error", `Business profile update  failed ${error}`)
            }
        },
    });

    useEffect(() => {
        formik.setValues({
            ...formik.values,
            ...statutoryDetail
        })
    }, [statutoryDetail])

    useEffect(() => {
        getBusinessProfile();
    }, [formik.isSubmitting]);

    const refreshBusinessProfile = ()=>{
        getBusinessProfile();
    }
  
    return (
        isLoading ? (<StatutoryDetailTemplate formik={formik} refreshBusinessProfile={refreshBusinessProfile} showEditForm={showEditForm} showEditFormBank={showEditFormBank} />) : <Shimmer/>
    )
}

export default StatutoryDetailPage