import { useEffect, useState } from 'react'
import * as yup from "yup";
import { useFormik } from 'formik';
import { HTTP_STATUS, MODE } from '../../../../utils/types';
import { GENERIC_EXCEPTION_CODE, MATERIAL_REQUIREMENT_CONSTANT } from '../../../../utils/constant';
import ProdcutCategoryFormTemplate from './ProductCategoryForm.template';
import ProdcutCategoryViewTemplate from './ProductCategoryView.template';
import { IMultiSelectOption } from '../../../atoms/MultiSelectInput/MultiSelectInput2';
import { useSnackbar } from '../../../../hooks/useSnackBar';
import { useMaterialRequirementOtherPreferenceService } from '../../../../services/useMaterialRequirementOtherPreferenceService';

export interface IPreferredProductCategory {
    productCategories: number[]
}

const initialValues = {
    productCategories: [],
}

const validationSchema = yup.object().shape({
    materialRequirement: yup.array().of(
        yup.object().shape({
            productCategories: yup.array()
                .of(yup.number())
                .min(1, 'You must select at least one product category')
                .required('You must select at least one product category'),
        })
    )
})

const ProdcutCategoryTemplate = () => {
    const { showSnackbar, SnackBarComponent } = useSnackbar();
    const [mode, setModeTo] = useState<MODE>(MODE.VIEW);
    const [isFormMode, setIsFormModeTo] = useState<boolean>(false);
    const materialRequirementOtherPreferenceService = useMaterialRequirementOtherPreferenceService();
    const formik = useFormik<IPreferredProductCategory>({
        initialValues,
        validationSchema,
        onSubmit: async (value) => {
            const res = await materialRequirementOtherPreferenceService.updateMaterialRequirementsOtherPreference({productCategories: value.productCategories}, MATERIAL_REQUIREMENT_CONSTANT.PRODUCT_CATEGORY)
            if (res?.status === HTTP_STATUS.OK) {
                setIsFormModeTo(false);
                setModeTo(MODE.EDIT);
                showSnackbar('success', 'Successfully updated preference')
            } else {
                showSnackbar('error', 'Failed to update preference')
            }
        }
    })

    const getMaterialRequirementOtherPreference = async () => {
        const res = await materialRequirementOtherPreferenceService.getMaterialRequirementOtherPreference()
        if (res?.status === HTTP_STATUS.OK) {
            if (res.data.data.productCategories === null)
                setModeTo(MODE.ADD)
            else {
                formik.setValues({
                        productCategories: res?.data?.data?.productCategories,
                })
                setModeTo(MODE.EDIT)
            }
            return await res?.data?.data;
        } else if (res?.status === HTTP_STATUS.BAD_REQUEST && res.data.exceptionCode === GENERIC_EXCEPTION_CODE.DATA_NOT_FOUND) {
            setModeTo(MODE.ADD)
        } else {
            setModeTo(MODE.ADD)
        }
        return null;
    }

    useEffect(() => {
        getMaterialRequirementOtherPreference();
    }, [])

    const handleSelectChange = (key: string) => (option: IMultiSelectOption[]) => {
        formik.setFieldValue(key, option);
    };

    return (
        <>
            {SnackBarComponent}
            {isFormMode ? <ProdcutCategoryFormTemplate
                handleSelectChange={handleSelectChange}
                formik={formik}
            />
                : <ProdcutCategoryViewTemplate formik={formik} setModeTo={setModeTo} mode={mode} setIsFormModeTo={setIsFormModeTo} />}
        </>
    )
}

export default ProdcutCategoryTemplate