import React, { useEffect, useState } from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import NoImage from '../../assets/images/noImage.jpg'
import ImageGroupReviewForm from '../molecules/ImageGroup/_ImageGroupReviewForm';
import { IImage } from '../template/CatalogueDetail/CatalogueImage.template';
interface IImagePreviewerProps {
    images?: IImage[];
    defaultActiveIndex: number;
    height: any;
    width: any;
}

const useStyles = createUseStyles({
    container: {
        maxWidth: "344px",
        display: "flex"
    },
    selectedImage: {
        width:({width}: any) => width,
        height: ({height}: any) =>  height,
        border:({theme}: any) => `1px solid ${theme.palette.text.neutral.neutral200}`,
    },
    "@media (max-width: 480px)": {
         selectedImage: {
            width: "100%",
            maxWidth: "250px",
            maxHeight: "250px",
        }, 
    }
});

const ImagePreviewerReviewForm: React.FC<IImagePreviewerProps> = ({ images, height="299px", width="344px", defaultActiveIndex }) => {

    const theme = useTheme<Jss.Theme>();
    const classes = useStyles({theme,height, width});

    const [activeIndex, setActiveIndexTo] = useState(defaultActiveIndex);
    const [imagesToBeDisplayed, setImagesToBeDisplayedTo] = useState<IImage[] | []>([]);

    const handleImageClick = (index: number) => {
        setActiveIndexTo(index);
    };

    useEffect(() => {
        if (images)
            setImagesToBeDisplayedTo(images?.slice(0, Math.min(4, images.length)));
    }, [images])

    return (
        <div className='grid gap-y-4 w-full' >
            <div className=''>
                <img className={`mx-auto object-cover rounded-xl ${classes.selectedImage}`} src={(imagesToBeDisplayed && imagesToBeDisplayed[activeIndex]?.path) ?? NoImage} alt="No Image" />
            </div>
            <div className={`${classes.container} w-full mx-auto mb-4`}>
                <ImageGroupReviewForm images={imagesToBeDisplayed} activeIndex={activeIndex} onClick={handleImageClick} />
            </div>
        </div>
    )
}
export default ImagePreviewerReviewForm;
