import React, { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import { IProductClassification, IProductSpecification, ISecondaryAttributes } from "../../pages/CatalogueDetail/SecondaryCatalogueDetails.page";
import { keyToLabel } from "../../../utils/helper";
import { ATTRIBUTES_TO_HIDE, ATTRIBUTE_LABEL, COLOR_DATA, MAX_DISPLAY_ITEMS, SEE_MORE_LESS } from "../../../utils/constant";
import { IColorPalette } from "./AttributeSelection.template";
import Button from "../../atoms/Button/Button";

const useStyles = createUseStyles((theme: any) => ({
    mainContainer: {
        rowGap: "12px",
    },
    heading: {
        color: theme.palette.text.neutral.neutral900,
    },
    skuCode:{
        color: theme.palette.text.neutral.neutral900,
    },
    container: {
        rowGap: "12px",
    },
    tabContainer: {
        width: "100%"
    },
    category: {
        fontSize: "18px",
    },
    value: {
        '&:nth-child(2)': {
            borderTop: 'none',
        },
        color: theme.palette.text.neutral.neutral700,
        fontSize: "16px",
    },
    specifications: {
        borderBottom: "none",
    },
    tabTitle: {
        color: theme.palette.text.neutral.neutral400,
    },
    activeTabTitle: {
        color: theme.palette.text.primary.primary400,
        borderBottom: `2px solid ${theme.palette.border.primary.primary400}`,
    },
    lastValue: {
        '&:nth-child(10)': {
            opacity: "40%",
        },
    },
    label: {
        '&:first-child': {
            borderTop: 'none',
        },
        color: theme.palette.text.neutral.neutral900,
        fontSize: "16px"
    },
    lastLabel: {
        '&:nth-child(9)': {
            opacity: "40%",
        },
    },
    borderBottom: {
        borderBottom: `1px solid ${theme.palette.border.primary.primary100}`,
    },
    border: {
        border: `1px solid ${theme.palette.border.neutral.neutral100}`,
    },
    "@media (max-width: 476px)": {
        container: {
            rowGap: "16px",
            padding: "0px",
        },
        activeCategory: {
            borderBottom: `none`,
            paddingBottom: "none"
        },
        seeMoreButton: {
            fontSize: "14px",
        },
        tabContainer: {
            width: "full"
        },
        tabCard: {
            flex: "none",
        },
        value: {
            fontSize: "14px",
        },
        label: {
            fontSize: "14px"
        },
    },
}));

export interface IProductAttributes {
    label: string;
    data: any;

}

const SecondaryProductPropertiesTemplate: React.FC<ISecondaryAttributes> = ({ productSpecification, productClassification, skuCode }) => {
    const classes = useStyles();

    const [showAllSpecifications, setShowAllSpecifications] = useState(false);
    // TODO: We need to add const for mainSpecifications
    const [activeAttributeIndex, setActiveAttributeIndexTo] = useState<string>(keyToLabel("mainSpecifications"));

    const convertPropertiesKeyValuePair = (inputArray: IProductSpecification[]): any => {
        if (!Array.isArray(inputArray)) {
            console.error("inputArray is not an array or is undefined");
            return [];
        }
        const filteredArray = inputArray.filter((item: IProductSpecification) => !ATTRIBUTES_TO_HIDE.includes(item.name.trim().toUpperCase()));
        const propertiesKeyValuePairs = filteredArray?.map((item: IProductSpecification) => ({
            name: item?.name,
            min: item?.minValue,
            max: item?.maxValue,
            uom: item?.uom,
        }));
        return propertiesKeyValuePairs;
    };

    const createClassificationkeyValuePair = (inputArray: IProductClassification[]): { label: string, key: string }[] => {
        const attributesKeyValuePairs = inputArray?.map((item: IProductClassification) => ({
            label: item?.name,
            key: item?.value
        }));
        return attributesKeyValuePairs;
    };

    const categories: IProductAttributes[] = [
        {
            label: "Main Specifications",
            data: convertPropertiesKeyValuePair(productSpecification)
        },
        {
            label: "Classification",
            data: createClassificationkeyValuePair(productClassification)
        },
    ];

    const activeCategoryData = categories.find((category) => keyToLabel(category?.label) === activeAttributeIndex)?.data;

    const handleTabChange = (currentTabIndex: string) => () => {
        setActiveAttributeIndexTo(currentTabIndex);
    };

    const toggleShowAllSpecifications = () => {
        setShowAllSpecifications(!showAllSpecifications);
    };

    const getSpecificationsToDisplay = () => {
        if (showAllSpecifications) {
            return activeCategoryData;
        } else {
            return activeCategoryData && activeCategoryData.slice(0, MAX_DISPLAY_ITEMS.SPECIFICATIONS);
        }
    };

    useEffect(() => {
        const categoriesWithData = categories.filter(
            (category) => category.data && category.data.length > 0
        );
        if (categoriesWithData.length > 0) {
            const initialActiveIndex = keyToLabel(categoriesWithData[0].label);
            setActiveAttributeIndexTo(initialActiveIndex);
        }
    }, []);

    const getAttributeValue = (attributeName: string, attributeValue: string) => {

        try {
            if (attributeName.trim().toLowerCase() !== ATTRIBUTE_LABEL.COLOR.toLowerCase()) {
                return attributeValue;
            }
            const colorPalette = COLOR_DATA[attributeValue as keyof IColorPalette];
            return (
                <div className="ml-1 flex items-center">
                    <div className="w-6 h-4 mr-2 rounded-sm" style={{ backgroundColor: colorPalette.bgColor }}></div>
                    <span>{attributeValue}</span>
                </div>
            )
        } catch (error) {
            return attributeValue;
        }
    }

    const displayAttributeValue = (item: any) => {
        if (item.min === item.max || item.max === "") {
            return (
                <div className="flex justify-center items-center">
                    {getAttributeValue(item.name ?? item.label, item.min)} {item.uom ? `(${item.uom})` : ""}
                </div>
            );
        } else {
            return (
                <div className="flex justify-center items-center">
                    {getAttributeValue(item.name ?? item.label, item.min)} - {getAttributeValue(item.name ?? item.label, item.max)} {item.uom ? `(${item.uom})` : ""}
                </div>
            );
        }
    };

    return (
        <div className={`${classes.mainContainer} grid`}>
            <div className="flex justify-between">
                <div className={`${classes.heading} text-base font-bold`}>Detailed Specifications (As per above selection)</div>
                <div className={`${classes.skuCode} text-sm font-bold `}>SKUID:{skuCode}</div>
            </div>
            <div className={`${classes.container} grid w-full`}>
                <div className={`overflow-x-auto overflow-hidden w-full`}>
                    <div className={`${classes.tabContainer} flex`} >
                        {categories
                            .filter(category => category.data && category.data.length > 0)
                            .map((category, index) => (
                                <span
                                    key={category.label}
                                    onClick={handleTabChange(category.label)}
                                    style={{ padding: '8px 12px' }}
                                    className={`cursor-pointer text-center  ${activeAttributeIndex === category.label ? `${classes.activeTabTitle} text-base font-semibold` : `${classes.tabTitle} text-base font-medium ${classes.borderBottom}`}`}
                                >
                                    {category.label}
                                </span>
                            ))}
                    </div>
                </div>
                {activeCategoryData && <div className={`${!showAllSpecifications && activeCategoryData.length > MAX_DISPLAY_ITEMS.SPECIFICATIONS ? `${classes.specifications} w-full border rounded-2xl` : "w-full border rounded-2xl"}`}>
                    <div className="grid grid-cols-2">
                        {activeCategoryData &&
                            <>
                                {getSpecificationsToDisplay()?.map((item: any, index: number) => (
                                    <>
                                        <div className={`${classes.label} font-medium border-t p-2.5 text-center ${!showAllSpecifications && activeCategoryData.length > MAX_DISPLAY_ITEMS.SPECIFICATIONS && index === MAX_DISPLAY_ITEMS.SPECIFICATIONS - 1 ? classes.lastLabel : ""}`}>
                                            {activeAttributeIndex === keyToLabel("mainSpecifications") ? `${item.name}` : `${item.name ?? item.label}`}
                                        </div>
                                        <div className={`${classes.value} border-l border-t p-2.5 text-center flex justify-center items-center ${!showAllSpecifications && activeCategoryData.length > MAX_DISPLAY_ITEMS.SPECIFICATIONS && index === MAX_DISPLAY_ITEMS.SPECIFICATIONS - 1 ? classes.lastValue : ""}`}>
                                            {activeAttributeIndex === keyToLabel("mainSpecifications") ? displayAttributeValue(item) : `${getAttributeValue(item.name ?? item.label, item.min ?? item.key)}`}
                                        </div>
                                    </>
                                ))}
                            </>}
                    </div>
                </div>}
            </div>
            {activeCategoryData && activeCategoryData.length > MAX_DISPLAY_ITEMS.SPECIFICATIONS && (
                <div className='flex justify-center'>
                    <Button size='medium' variant='secondaryText'  onClick={toggleShowAllSpecifications} label={showAllSpecifications ? SEE_MORE_LESS.SEE_LESS : SEE_MORE_LESS.SEE_MORE} />
                </div>
            )}
        </div>
    );
}

export default SecondaryProductPropertiesTemplate;