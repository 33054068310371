import React, { useEffect, useState } from 'react'
import { IMaterialRequirementFormik, IMaterialAttribute, IMaterialRequirement } from '../../../pages/DashBoard/MyMaterialProduct.page';
import { FormikErrors, FormikProps } from 'formik';
import { createUseStyles } from 'react-jss';
import { useCategoryService } from '../../../../services/useCategoryService';
import { useAdminMasterService } from '../../../../services/useAdminMasterService';
import { HTTP_STATUS, Attribute } from '../../../../utils/types';
import { useSnackbar } from '../../../../hooks/useSnackBar';
import AddMaterialFormTemplate from './AddMaterialForm.template';
import Button from '../../../atoms/Button/Button';
import { useConfigurationService } from '../../../../services/useConfigurationService';
import { StringSchema } from 'yup';
import { MODE } from '../../../../utils/constant';
import { IStandards } from '../../Rfq/ProductRequirementForm.template';
import { CustomAccordion, IStatus } from '../../../molecules/Accordion/CustomAccordion';

interface AddMaterialProductTemplateProps {
    handleOnBack: () => void;
    handleOnNext: () => void;
    formik: FormikProps<IMaterialRequirementFormik>
}

const useStyles = createUseStyles((theme: any) => ({
    highlightedText: {
        color: theme.palette.text.primary.primary400
    },
    tertiary100Border: {
        border: `1px solid ${theme.palette.border.neutral.neutral100}`
    },
    darkHeading: {
        color: theme.palette.text.primary.primary800
    },
    borderTop:{
        borderTop: `1px solid ${theme.palette.border.neutral.neutral100}`
    }
}));

export interface IConfigurationShape {
    name: string,
    label: string,
    prefix: string
}

const AddMaterialProductTemplate: React.FC<AddMaterialProductTemplateProps> = ({ formik, handleOnBack, handleOnNext }) => {
    const classes = useStyles();
    const adminMasterService = useAdminMasterService();
    const { showSnackbar, SnackBarComponent } = useSnackbar();
    const [attributes, setAttributes] = useState<IMaterialAttribute[]>([]);
    const [attributesOriginal, setAttributesOrigianl] = useState<IMaterialAttribute[]>([]);
    const [activeAccordion, setActiveAccordion] = useState<number>(0);
    const adminService = useAdminMasterService();
    const [standards, setStandards] = useState<IStandards[]>([]);
    const [standardList, setStandardList] = useState<number[]>([]);
    const loadProductCategory = () => {
        adminMasterService.getAllCategories({ id: formik.values?.categoryId, level: 2, page: 0, size: 1 })
            .then(res => {
                if (res?.status === HTTP_STATUS.OK) {
                    setStandardList(res.data.data.content?.[0].standards)
                    parseAttribute(res?.data?.data?.content?.[0].attributeDetails?.SPECIFICATION ?? []);
                }
            }).catch((error) => {
                showSnackbar('error', "Error Fetching Category");
            })
    }

    const loadStandard = (inputValue?: string) => {
        adminService.getAllStandards({ search: inputValue ?? '', page: 0, size: 100, sort: 'createdAt,desc' })
            .then(res => {
                if (res?.status === HTTP_STATUS.OK)
                    setStandards(res?.data?.data?.content.filter((standard: any) => standardList.includes(standard.id)))
            }).catch((error) => {
                console.error("Error Fetching Standards: ", error);
            })
    }

    const parseAttribute = (attributes: IMaterialAttribute[]) => {
        attributes.forEach((attribute: IMaterialAttribute) => {
            if (typeof attribute.attributeOptions === 'string')
                attribute.attributeOptions = JSON.parse(attribute.attributeOptions);
        })
        const attribute = JSON.parse(JSON.stringify(attributes));
        const attributeOriginal = JSON.parse(JSON.stringify(attributes));

        setAttributes(attribute);
        setAttributesOrigianl(attributeOriginal)
        const materialRequirementData: IMaterialRequirement[] = formik?.values?.noOfRows ? Array.from({ length: parseInt(formik?.values?.noOfRows.toString()) }, (_, i) => (
            {
                id: i,
                productCode: "",
                categoryId: null,
                classType: "",
                standardId: null,
                gradeId: null,
                shape: "",
                attributes: attributes.map((attribute: IMaterialAttribute) => ({ ...attribute, attributeOptions: null })),
                applicationType: "",
                otherSpecificRequirement: "",
                primarySourcingPurpose: "",
                frequency: "",
                quantity: null,
                standard: null,
                grade: null,
                isDuplicate: false
            }
        )) : [];
        formik.setFieldValue('materialRequirement', materialRequirementData);
    }

    const handleCurrentActiveAccordion = (index: number) => {
        setActiveAccordion(activeAccordion === index ? -1 : index);
    }

    const handleOnDelete = (id: number) => {
        const filteredMaterialRequirements = formik.values.materialRequirement.filter((materialRequirement, i) => materialRequirement.id != id)
        formik.setFieldValue('materialRequirement', filteredMaterialRequirements);
        formik.setFieldValue('noOfRows', filteredMaterialRequirements.length);
        formik.setFieldValue(`deletedRowIndex`, [...formik.values.deletedRowIndex as number[], id]);
    }

    useEffect(() => {
        loadProductCategory();
        loadStandard();
    }, [])

    const getFormStatus = (formik: FormikProps<IMaterialRequirementFormik>, index: number): IStatus => {
        if (formik.values?.materialRequirement?.[index]?.isDuplicate) {
            return { message: "Duplicate combination, Please fill the unique combination", type: "error" }
        } else if (formik?.touched?.materialRequirement?.[index])
            if (formik.errors?.materialRequirement?.[index] === undefined)
                return { message: "Filled", type: "success" }
            else
                return { message: "Please fill the data", type: "error" }
        else {
            return { message: "", type: "error" }
        }
    }

    const checkValidSubmit = (errors: FormikErrors<IMaterialRequirementFormik>) => {
        const error = (errors.materialRequirement as FormikErrors<IMaterialRequirement>[])?.filter((_, i) => !formik.values.deletedRowIndex?.includes(i))
        if (!formik.values.noOfRows) return true
        else if (!!error?.filter(el => el).length) return true
        else if (formik.values.noOfRows !== 0 && !!error?.filter(el => el).length) return true
        else return false
    }

    const handleAddAnotherProduct = () => {
        const newProduct = {
            id: formik.values.materialRequirement.length,
            productCode: "",
            categoryId: null,
            classType: "",
            standardId: null,
            gradeId: null,
            shape: "",
            attributes: attributes.map((attribute: IMaterialAttribute) => ({ ...attribute, attributeOptions: null })),
            applicationType: "",
            otherSpecificRequirement: "",
            primarySourcingPurpose: "",
            frequency: "",
            quantity: null,
            standard: null,
            grade: null,
            isDuplicate: false
        }
        formik.setFieldValue('materialRequirement', [
            ...formik.values.materialRequirement,
            newProduct,
        ]);
        formik.setFieldValue('noOfRows', (formik.values?.noOfRows as number) + 1)
    }

    return (
        <div className={`grid gap-y-3`}>
            {SnackBarComponent}
            <div className='flex justify-between'>
                <div className={`text-2xl font-medium ${classes.highlightedText}`}>{formik.values.categoryName}</div>
                <div className={`text-case font-normal my-auto ${classes.darkHeading}`}>No of product selected: <span>{formik.values.noOfRows}</span></div>
            </div>
            <form onSubmit={formik.handleSubmit} className={`grid gap-y-6`}>
                <div className={`flex flex-col gap-y-8 `}>
                    {attributes && (formik.values.materialRequirement as IMaterialRequirement[] ?? [])?.map((materialRequirement: IMaterialRequirement, index: number) =>
                    (
                        <CustomAccordion
                            index={materialRequirement?.id as number}
                            expanded={activeAccordion}
                            component={
                                <div className={`${classes.borderTop} flex flex-col gap-6 mx-6 mb-6 pt-3 -mt-6`}>
                                    <AddMaterialFormTemplate formik={formik} index={index} attributes={attributes ?? []} attributesOriginal={attributesOriginal ?? []} setAttributes={setAttributes} standards={standards} loadStandard={loadStandard} mode={MODE.ADD} />
                                </div>}
                            heading={`Product Detail ${index + 1}`}
                            onChange={handleCurrentActiveAccordion}
                            onDelete={handleOnDelete}
                            isDeletable
                            status={getFormStatus(formik, materialRequirement?.id as number)}
                        />))}
                </div>
                <div className='flex justify-end gap-x-3'>
                    <Button label='Back' variant='tertiaryContained' onClick={handleOnBack} />
                    <Button label='+ Add Another Product' type='button' variant='tertiaryContained' onClick={handleAddAnotherProduct} />
                    <Button label='Add' variant='secondaryContained' type='submit' disabled={checkValidSubmit(formik.errors)} />
                </div>
            </form>
        </div>
    )
}

export default AddMaterialProductTemplate