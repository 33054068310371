import React, { useEffect, useMemo, useState } from "react";
import { createUseStyles } from "react-jss";
import { IAttribute, ICatalogue, ICatalogueUpc, ICurrentCartItem } from "../../pages/CatalogueDetail/CatalogueDetails.page";
import { sortMultipleUpcAttributes, sortSpecificationAttributes } from "../../../utils/helper";
import useMetaDataService from "../../../hooks/useMetaDataService";
import resetIcon from "../../../assets/images/reset.svg";
import { ATTRIBUTE_LABEL, ATTRIBUTE_SECTION_HEADING, COLOR_DATA } from "../../../utils/constant";
import { json } from "react-router-dom";
import { Warehouse } from "@mui/icons-material";
import IconButton from "../../atoms/Button/IconButton";

const useStyles = createUseStyles((theme: any) => ({
	header: {
		borderBottom: `1px solid ${theme.palette.border.neutral.neutral100}`,
	},
	title: {
		color: theme.palette.text.neutral.neutral900,
	},
	selectedValue: {
		color: theme.palette.text.primary.primary800,
		background: theme.palette.background.primary.primary50,
		transition: "color 0.3s ease-in-out, background 0.3s ease-in-out",
		border: `1px solid ${theme.palette.border.primary.primary200}`,
	},
	unselectedValue: {
		color: theme.palette.text.primary.primary800,
		border: `1px solid ${theme.palette.border.primary.primary200}`,
		"&:hover": {
			background: theme.palette.background.primary.primary50,
        },
	},
	// outOfStock: {
	// 	width: "112px",
	// 	height: "36px",
    //     borderRadius: "24px",
    //     background: theme.palette.v3.background.secondaryLightAccent,
    //     padding: "6px 16px",
    //     justifyContent: "center",
    //     alignItems: "center",
	// 	textAlign: "center",
    // },
    // outOfStockValue: {
    //     color: theme.palette.v3.text._secondaryDark,
    // },
	uom: {
		color: theme.palette.text.neutral.neutral600,
	},
	"@media (max-width: 476px)": {
		title: {
			fontSize: "14px"
		},
		uom: {
			fontSize: "12px"
		},
		selectedValue: {
			fontSize: "14px"
		},
		unselectedValue: {
			fontSize: "14px"
		},
	},
	selectAttribute : {
		color: theme.palette.text.neutral.neutral900,
	},
}));

export interface IColorPalette {
    [key: string]: {
      bgColor: string;
      labelColor: string;
    };
};

export interface IAttributeForm {
	[key: string]: {
		id: number;
		options: { [key: string]: boolean };
		uom: string;
	};
};

interface AttributeSelectionTemplateProps {
	currentAttributes: Record<string, any>;
	setCurrentCartItemTo: any;
	catalogueUpc: ICatalogueUpc[] | null;
	catalogue: ICatalogue | null;
	currentCartItem: ICurrentCartItem;
	setProductCombination: any;
}

const AttributeSelectionTemplate: React.FC<AttributeSelectionTemplateProps> = ({ currentAttributes, setCurrentCartItemTo, catalogueUpc, catalogue, currentCartItem, setProductCombination }) => {

	const classes = useStyles();
	const [attributeForm, setAttributeFormTo] = useState<IAttributeForm[]>([]);
	const [isUpcCombinationPresent, setIsUpcCombinationPresentTo] = useState<boolean>(false);
	const [productAttributesRankWise, setProductAttributesRankWiseTo] = useState<any>([]);
	const [sortedCatalogueSpecificationAttributes, setSortedCatalogueSpecificationAttributes] = useState<any>([]);
	const { metaData: filterData } = useMetaDataService();
	
	useEffect(() => {
		setProductAttributesRankWiseTo(filterData.attributes);
		setSortedCatalogueSpecificationAttributes(catalogue ? sortSpecificationAttributes(catalogue.catalogueAttributes.SPECIFICATION, productAttributesRankWise) : [])
	}, [catalogue, filterData.attributes])


	const upcAttributeMap = useMemo(() => {
		const computedUpcAttributeMap: Record<string, any> = {};
		catalogueUpc?.forEach((item: ICatalogueUpc) => {
			const sortedCatalogueUpcSpecificationAttributes = sortSpecificationAttributes(item.attributes.SPECIFICATION, productAttributesRankWise);
			const attributes = sortedCatalogueUpcSpecificationAttributes;
			if (attributes) {
				const key = attributes?.map((attribute: IAttribute) => `${attribute?.name.trim()}:${attribute?.attributeOptions}`).join("~");
				computedUpcAttributeMap[key] = item;
			}
		});
		return computedUpcAttributeMap;
	}, [catalogueUpc, productAttributesRankWise]);

	const findUpcByAttributes = (targetAttributes: any) => {
		const specification =  catalogue ? sortSpecificationAttributes(catalogue.catalogueAttributes.SPECIFICATION, productAttributesRankWise) : [];
		if (specification) {
			const key = specification
				.map((attribute: IAttribute) => {
					const value = targetAttributes[attribute.name.trim()]?.value;
					return `${attribute.name.trim()}:${value}`;
				})
				.join("~");
			if (upcAttributeMap[key]) {
				setCurrentCartItemTo((prevCartItem: ICurrentCartItem) => ({
					...prevCartItem,
					warehouse: upcAttributeMap[key]?.warehouseDetails[0],
				}));
				setProductCombination(true);
				return upcAttributeMap[key];
			} else {
				setCurrentCartItemTo((prevCartItem: ICurrentCartItem) => ({
					...prevCartItem,
					upc: null,
					warehouse: null
				}));
				console.error("Error: No matching UPC found");
				setProductCombination(false);
				return false;
			}
		} else {
			console.error("Error: SPECIFICATION is undefined");
			return false;
		}
	};

	const handleAttributeClick = (attributeName: string, selectedValue: string, id: number) => {
		const updatedAttributes = { ...currentAttributes };
		updatedAttributes[attributeName] = { id, value: selectedValue };
		let numberOfAttributes = sortedCatalogueSpecificationAttributes.length;
		setCurrentCartItemTo((prevCartItem: any) => ({
			...prevCartItem,
			attributes: updatedAttributes,
		}));
		if (numberOfAttributes === Object.keys(updatedAttributes)?.length) {
			const matchingUpc = findUpcByAttributes(updatedAttributes);
			if (matchingUpc !== false) {
				setCurrentCartItemTo((prevCartItem: any) => ({
					...prevCartItem,
					upc: matchingUpc,
					quantity: matchingUpc?.minimumOrderQuantity || "0",
					attributes: updatedAttributes
					
				}));
				setProductCombination(true);

			} else {
				setCurrentCartItemTo((prevCartItem: any) => ({
					...prevCartItem,
					upc: null,
					quantity: ""
					
				}));
				setProductCombination(false);
				console.error("Error: No matching UPC found");
			}
		}
	};

	const checkUpcCombination = () => {
		if (Object.keys(currentAttributes)?.length === 0) return false;
		let totalAtrributeLength = sortedCatalogueSpecificationAttributes?.length;
		const hardnessAttribute = sortedCatalogueSpecificationAttributes.find((attribute:any) => attribute.name === "Hardness");
		if (hardnessAttribute) {
			totalAtrributeLength = totalAtrributeLength - 1;
		}
		if (totalAtrributeLength === Object.keys(currentAttributes)?.length && currentCartItem?.upc != null  && currentCartItem?.warehouse != null) {
			return true;
		}
		return false;
	}
	
	const handleReset = () => {
		const hardnessAttribute = sortedCatalogueSpecificationAttributes.find((attribute: any) => attribute.name === "Hardness");
		if (hardnessAttribute) {
			const attributeOptions = JSON.parse(hardnessAttribute?.attributeOptions.toString());
			attributeOptions.sort();
			handleAttributeClick(hardnessAttribute.name, attributeOptions[0], hardnessAttribute.id);
			setCurrentCartItemTo((prevCartItem: ICurrentCartItem) => ({
				...prevCartItem,
				attributes: {
					[hardnessAttribute.name]: { id: hardnessAttribute.id, value: attributeOptions[0] }
				},
				upc: null
			}));
		}
		else {
			setCurrentCartItemTo((prevCartItem: ICurrentCartItem) => ({
				...prevCartItem,
				attributes: {},
				upc: null
			}));
		}
	};
	

	useEffect(() => {
		const initialAttributeObject: { [key: string]: { [key: string]: { id: number, value: boolean } } } = {};
		sortedCatalogueSpecificationAttributes?.forEach((attribute: IAttribute) => {
			initialAttributeObject[attribute?.name.trim()] = {};
			const attributeOptions = JSON.parse(attribute?.attributeOptions.toString());
			attributeOptions.sort((a: string, b: string) => parseFloat(a) - parseFloat(b));
			attributeOptions?.forEach((option: string) => {
				initialAttributeObject[attribute?.name.trim()][option.toString()] = { id: attribute.id, value: true };
			});
		});

		const updatedAttributeObject: IAttributeForm = {};
		Object.keys(initialAttributeObject)?.forEach((attributeName) => {
			const attributeData = sortedCatalogueSpecificationAttributes?.find((attribute: IAttribute) => attribute.name.trim() === attributeName.trim());
			if (attributeData) {
				const attributeOptions = initialAttributeObject[attributeName];
				const formattedObj: IAttributeForm[string] = {
					id: attributeData.id,
					options: {},
					uom: attributeData?.uom || ""
				};
				if (Object.keys(attributeOptions).length > 0) {
					const formattedOptions: { [key: string]: boolean } = {};
					Object.keys(attributeOptions)?.forEach((option) => {
						formattedOptions[option] = attributeOptions[option]?.value;
					});
					formattedObj.options = formattedOptions;
				}
				updatedAttributeObject[attributeName] = formattedObj; 
			}
		});
		const attributesMap =  sortMultipleUpcAttributes(updatedAttributeObject, productAttributesRankWise);
		setAttributeFormTo(attributesMap);
		}, [catalogue, sortedCatalogueSpecificationAttributes]);
	
	useEffect(() => {
		const isUpcPresent = checkUpcCombination();
		setIsUpcCombinationPresentTo(isUpcPresent);
	},[currentAttributes]);

	useEffect(()=>{
		const hardnessAttribute = sortedCatalogueSpecificationAttributes.find((attribute:any) => attribute.name === "Hardness");
		if (hardnessAttribute) {
			const attributeOptions = JSON.parse(hardnessAttribute?.attributeOptions.toString());
			attributeOptions.sort()
			handleAttributeClick(hardnessAttribute.name, attributeOptions[0], hardnessAttribute.id);
		}
	},[sortedCatalogueSpecificationAttributes])

	const getSortedAttributeOptions = (attributeValue: any) => {
		return Object.keys(attributeValue?.options).sort((a, b) => parseFloat(a) - parseFloat(b));
	}

	const getAttributeValue = (attributeName: string, attributeValue: string) => {
        try {
            if(attributeName.trim().toLowerCase() !== ATTRIBUTE_LABEL.COLOR.toLowerCase()) {
                return attributeValue;
            }
            const colorPalette = COLOR_DATA[attributeValue as keyof IColorPalette];
            return (
                <div className="flex items-center">
                    <div className="w-6 h-4 mr-2 rounded-sm" style={{ backgroundColor: colorPalette.bgColor }}></div>
                    <span>{attributeValue}</span>
                </div>
            )
        } catch (error) {
            return attributeValue;
        }
	}

	return (
		<div className="grid gap-y-4">
			<div className={`flex justify-between ${classes.header}`}>
				<div className={`text-base font-semibold my-auto ${classes.selectAttribute}`}>{ATTRIBUTE_SECTION_HEADING.SELECT_ATTRIBUTES}</div>
				{currentCartItem.attributes && Object.keys(currentCartItem?.attributes).length > 0 && 
				<IconButton className="!px-2.5" variant="secondaryText" label={ATTRIBUTE_SECTION_HEADING.RESET_SELECTION} size="medium" startIcon={<img src={resetIcon} alt="reset" />} onClick={handleReset} />}
			</div>

			<>
				{attributeForm?.map((attribute, attributeIndex) => (
					<React.Fragment key={attributeIndex}>
						{Object.entries(attribute).map(([attributeName, attributeValues], entryIndex) => {
							if (attributeName.trim().toLowerCase() === "hardness") {
								return <></>
							} else {
								return (
									<div key={entryIndex} className="grid gap-y-2">
										<div className={`${classes.title} font-semibold text-sm`}>
											{attributeName} {attributeValues?.uom && (
												<span className={`font-normal text-xs ${classes.uom}`}>
													({(attributeValues?.uom).toLowerCase()})
												</span>
											)}
										</div>
										<div className="flex flex-wrap gap-2">
											{getSortedAttributeOptions(attributeValues).map((option, optionIndex) => (
												<div
													key={optionIndex}
													className={`px-4 py-2 justify-center items-center rounded-lg ${currentAttributes?.[attributeName]?.value === option.toString() ? `${classes.selectedValue} font-medium text-sm cursor-pointer text-center p-1` : `${classes.unselectedValue} font-medium text-sm cursor-pointer`}`}
													onClick={() => handleAttributeClick(attributeName, option.toString(), attributeValues?.id)}
												>
													{getAttributeValue(attributeName, option)} 
												</div>
											))}
										</div>
									</div>
								)
							}
						})}
					</React.Fragment>
				))}
			</>
			{/* {attributeForm.length > 0 && !isUpcCombinationPresent  && sortedCatalogueSpecificationAttributes?.length === Object.keys(currentAttributes)?.length && ( currentCartItem?.warehouse == null)  && <div className={`${classes.outOfStock}`}>
				<span className={`${classes.outOfStockValue} font-medium text-sm`}>Out of stock</span>
			</div>} */}
		</div>
	);
};

export default AttributeSelectionTemplate;