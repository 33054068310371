import React from 'react';
import { COMMUNICATION_CHANNEL, CONTACT_PREFERENCE, LANGUAGE } from '../../../utils/constant';
import { createUseStyles } from 'react-jss';
import { SelectChangeEvent } from '@mui/material/Select';
import { Field, FieldProps, FormikErrors } from 'formik';
import * as Yup from 'yup';
import SelectV2 from '../../atoms/Select/SelectV2';

const useStyles = createUseStyles((theme: any) => ({
    error: {
        color: 'red',
        fontSize: '13px',
        marginTop: '4px'
    },
    placeholder: {
        color: theme.palette.text.neutral.neutral400,
        fontSize: '16px'
    }
}));

interface CommunicationPreferenceProps {
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
   formik: any;
}

const CommunicationPreference: React.FC<CommunicationPreferenceProps> = ({ setFieldValue, formik }) => {
    const classes = useStyles();

    return (
        <div className="flex gap-x-6">
            <div className="w-full">
                <Field name="communication">
                    {({ field, form }: FieldProps) => (
                        <>
                            <SelectV2
                                {...field}
                                label="Preferred Communication Channel"
                                disableUnderline={true}
                                displayEmpty
                                placeholder="Select Communication Channel"
                                renderValue={field.value ? undefined : () => <span className={classes.placeholder}>Select Preferred Communication Channel</span>}
                                fullWidth
                                onChange={(event: SelectChangeEvent<any>) => setFieldValue("communication", event.target.value)}
                                options={COMMUNICATION_CHANNEL.map((option) => ({
                                    label: option.label,
                                    value: option.value
                                }))}
                            />
                            {formik.errors.communication && formik.touched.communication && (
                                <div className={classes.error}>{formik.errors.communication}</div>
                            )}
                        </>
                    )}
                </Field>
            </div>
            <div className="w-full">
                <Field name="language">
                    {({ field, form }: FieldProps) => (
                        <>
                            <SelectV2
                                {...field}
                                label="Language Preference"
                                placeholder="Select Language Preference"
                                disableUnderline={true}
                                displayEmpty
                                renderValue={field.value ? undefined : () => <span className={classes.placeholder}>Select language Preference</span>}
                                fullWidth
                                onChange={(event: SelectChangeEvent<any>) => setFieldValue("language", event.target.value)}
                                options={LANGUAGE.map((option) => ({
                                    label: option.label,
                                    value: option.value
                                }))}
                            />
                            {formik.errors.language && formik.touched.language && (
                                <div className={classes.error}>{formik.errors.language}</div>
                            )}
                        </>
                    )}
                </Field>
            </div>
            <div className="w-full">
                <Field name="contact">
                    {({ field, form }: FieldProps) => (
                        <>
                            <SelectV2
                                {...field}
                                label="Contact Preference"
                                placeholder="Select Contact Preference"
                                disableUnderline={true}
                                displayEmpty
                                renderValue={field.value ? undefined : () => <span className={classes.placeholder}>Select Contact Preference</span>}
                                fullWidth
                                onChange={(event: SelectChangeEvent<any>) => setFieldValue("contact", event.target.value)}
                                options={CONTACT_PREFERENCE.map((option) => ({
                                    label: option.label,
                                    value: option.value
                                }))}
                            />
                            {formik.errors.contact && formik.touched.contact  && (
                                <div className={classes.error}>{formik.errors.contact}</div>
                            )}
                        </>
                    )}
                </Field>
            </div>
            
        </div>
    );
};

export default CommunicationPreference;
