import React from 'react'
import { createUseStyles } from 'react-jss';
import { DELIVERY_INFORMATION } from '../../../utils/constant';
import { getFormattedDate } from '../../../utils/helper';
import { IVendorCatalogueUsnDetails } from './PricingDetailForm.template';
import moment from 'moment';

const useStyles = createUseStyles((theme: any) => ({
    heading: {
        color: theme.palette.text.neutral.neutral800,
    },
    selectBox: {
        border: `1px solid ${theme.palette.background.neutral.neutral100}`,
    },
    subHeading: {
        color: theme.palette.text.neutral.neutral600,
    },
}));

interface UsnDetailsViewTemplateProps{
    vendorUsnDetails: IVendorCatalogueUsnDetails | undefined
}

const UsnDetailsViewTemplate: React.FC<UsnDetailsViewTemplateProps> = ({vendorUsnDetails}) => {
    const classes = useStyles();
    
    return (
        <div className={`${classes.selectBox} p-3 rounded-xl `}>
            <div className='flex gap-x-6'>
                <div>
                    <div className={`${classes.heading} font-medium text-base mb-3`}>Inventory Details</div>
                    <div className="flex gap-x-3">
                        <div className={`${classes.selectBox} w-56 rounded-xl p-6`}>
                            <div className={`${classes.subHeading} text-sm`}>Total Stock Available</div>
                            <div className={`${classes.subHeading} font-medium text-base `}>{vendorUsnDetails?.totalStock || 0} MT</div>
                        </div>
                    </div>
                </div>
                <div>
                <div className={`${classes.heading} font-medium text-base mb-3`}>Delivery and Order Details</div>
                    <div className="flex gap-x-3">
                        <div className={`${classes.selectBox} w-56 rounded-xl p-6`}>
                            <div className={`${classes.subHeading} text-sm`}>Delivery Information</div>
                            <div className={`${classes.subHeading} font-medium text-base `}>    {DELIVERY_INFORMATION.find(option => option.value === vendorUsnDetails?.deliveryInformation)?.label || vendorUsnDetails?.deliveryInformation}
                            </div>
                        </div> <div className={`${classes.selectBox} w-56 rounded-xl p-6`}>
                            <div className={`${classes.subHeading} text-sm`}>Minimum Order Quantity</div>
                            <div className={`${classes.subHeading} font-medium text-base `}>{vendorUsnDetails?.minimumOrderQuantity || 0} MT</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`${classes.heading} font-medium text-base mb-3 mt-6`}>Pricing Details</div>
            <div className="flex gap-x-3">
                <div className={`${classes.selectBox} w-60 rounded-xl p-6`}>
                    <div className={`${classes.subHeading} text-sm`}>Reserve Sales Price</div>
                    <div className={`${classes.subHeading} font-medium text-base `}>{`\u20B9`} {vendorUsnDetails?.reserveSalesPrice || 0}</div>
                </div> <div className={`${classes.selectBox} w-60 rounded-xl p-6`}>
                    <div className={`${classes.subHeading} text-sm`}>Price Validity (To)</div>
                    <div className={`${classes.subHeading} font-medium text-base `}>{ vendorUsnDetails?.priceValidityTo? moment(vendorUsnDetails?.priceValidityTo).format("DD-MM-YYYY") : "-"}</div>
                </div>
                <div className={`${classes.selectBox} w-60 rounded-xl p-6`}>
                    <div className={`${classes.subHeading} text-sm`}>Price Validity (From)</div>
                    <div className={`${classes.subHeading} font-medium text-base `}>{vendorUsnDetails?.priceValidityFrom? moment(vendorUsnDetails?.priceValidityFrom).format("DD-MM-YYYY") :"-"}</div>
                </div>
            </div>
        </div>
    )
}

export default UsnDetailsViewTemplate