import React, { useState } from 'react';
import { createUseStyles } from 'react-jss';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import CloseIcon from '@mui/icons-material/Close';
import {
    Select as MuiMultiSelect,
    MenuItem,
    SelectProps as MuiMultiSelectProps,
    InputAdornment,
    MenuProps
} from "@mui/material";
import search from '../../../assets/images/search.svg';
import TextFieldV2 from '../../atoms/Input/TextFieldV2';
import Button from '../../atoms/Button/Button';

const useStyles = createUseStyles((theme: any) => ({
    select: {
        "& .MuiInputBase-input": {
            fontWeight: 400,
            fontSize: "14px",
            padding:"8px 32px 8px 16px !important"
        },
        // border: "1px solid",
        // borderColor: theme.palette.border.secondaryDark,
        borderRadius: '8px !important',
        height: "48px",
        backgroundColor: (props: { hasSelectedOptions: boolean }) =>
            props.hasSelectedOptions ? theme.palette.button.tertiaryContained.background.primary100 : 'transparent',
    },
    label: {
        color: theme.palette.text.neutral.neutral960,
        fontSize: "16px",
        fontWeight: 400,
    },
    placeholder: {
        color: `#333 !important`
    },
    checkedInputColor: {
        color: "#B3B3B3",
        '&.Mui-checked': {
            color: "#FD8235",
        },
    },
    primaryHighlighter: {
        background: 'white',
        color: 'black'
    },
    searchContainer: {
        padding: '8px 16px',
    },
    clearButton: {
        margin: '8px',
    },
    saveButton: {
        margin: '8px',
    },
    divider: {
        color: "#CCCCCC",
    },
}));

export interface IMultiSelectOption {
    label: string;
    value: string | number;
}

interface MultiSelectInputProps extends Omit<MuiMultiSelectProps, "label"> {
    options: IMultiSelectOption[];
    label: string;
    helperText?: string;
    value: IMultiSelectOption[];
    onchange: (key: string, selected: IMultiSelectOption[], clearWithCrossIcon?: boolean) => void;
    name: string;
    placeholder?: string;
    textFieldLabel?: string;
    textFieldPlaceholder?: string;
    TextFieldComponent?: React.ComponentType<any>;
    ClearButtonComponent?: React.ComponentType<any> | null;
    SaveButton?: React.ComponentType<any> | null;
    buttonLabel?: string;
    saveButtonLabel?: string;
    buttonOnClick?: () => void;
    saveButtonOnClick?: () => void;
    searchTerm?: string;
    setSearchTerm?: (value: string) => void;
    isSingleSelect?: boolean;
    maxHeight?: string;
}

const FilterChipV3: React.FC<MultiSelectInputProps> = ({
    options,
    label,
    helperText,
    value,
    onchange,
    name,
    placeholder,
    TextFieldComponent = TextFieldV2,
    textFieldLabel,
    textFieldPlaceholder,
    buttonLabel,
    saveButtonLabel,
    buttonOnClick,
    saveButtonOnClick,
    ClearButtonComponent = Button,
    SaveButton = Button,
    searchTerm,
    setSearchTerm,
    isSingleSelect = false,
    maxHeight = '60%',
    ...props
}) => {
    const hasSelectedOptions = value.length > 0;
    const [open, setOpen] = useState<boolean>(false);
    const classes = useStyles({ hasSelectedOptions });

    const handleChange = (event: any) => {
        const selectedValues = event.target.value as (string | number)[];
        if (isSingleSelect) {
            const selectedOptions = options.filter(option => option.value === selectedValues[selectedValues.length - 1]);
            onchange(name, selectedOptions, false);
        } else {
            const selectedOptions = options.filter(option => selectedValues.includes(option.value));
            onchange(name, selectedOptions, false);
        }
    };

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm && setSearchTerm(event.target.value);
    };

    const handleDelete = (event: React.MouseEvent<SVGSVGElement, MouseEvent>, optionToDelete: IMultiSelectOption) => {
        event.stopPropagation();
        event.preventDefault();
        const updatedOptions = value.filter(option => option.value !== optionToDelete.value);
        onchange(name, updatedOptions, true);
    };

    const handleSaveButtonClick = () => {
        if (saveButtonOnClick) {
            saveButtonOnClick();
        }
        setOpen(false);
    };

    const handleClearButtonClick = () => {
        if (buttonOnClick) {
            buttonOnClick();
        }
        setOpen(false);
    };

    const renderValue = (selected: any) => {
        const selectedValues = selected as (string | number)[];
        const selectedOptions = options.filter(option => selectedValues.includes(option.value));

        if (selectedOptions.length === 0) {
            return <span className={`${classes.placeholder} !font-medium`}>{placeholder}</span>;
        } else if (selectedOptions.length <= 2) {
            return (
                <div className='flex'>
                    {selectedOptions.map((option, index) => (
                        <div key={option.value} className="flex gap-2 items-center">
                            <CloseIcon
                                className='text-base font-medium ml-2'
                                onMouseDown={(event) => {
                                    handleDelete(event, option);
                                }}
                            />
                            <div className="text-sm font-medium">
                                {option.label}
                                {index < selectedOptions.length - 1 && <span className={`ml-2 font-medium ${classes.divider}`}> | </span>}
                            </div>
                        </div>
                    ))}
                </div>
            );
        } else {
            const firstTwo = selectedOptions.slice(0, 2);
            const moreCount = selectedOptions.length - 2;

            return (
                <div className='flex'>
                    {firstTwo.map(option => (
                        <div key={option.value} className="flex gap-2 items-center">
                            <CloseIcon
                                className='text-base font-medium ml-2'
                                onMouseDown={(event) => {
                                    handleDelete(event, option);
                                }}
                            />
                            <div className="text-sm font-medium">
                                {option.label}<span className={`ml-2 font-medium ${classes.divider}`}> | </span>
                            </div>
                        </div>
                    ))}
                    <span className={`py-1 px-2 ml-2 text-sm font-medium rounded ${classes.primaryHighlighter}`}>+{moreCount}</span>
                </div>
            );
        }
    };

    const menuProps: Partial<MenuProps> = {
        PaperProps: {
            style: {
                maxHeight,
                minWidth: "auto"
            },
        },
    };

    return (
        <div className={`flex flex-col gap-2`}>
            {label && (
                <div className={classes.label}>
                    {label}
                </div>
            )}
            <MuiMultiSelect
                id={`multi-select-${label}`}
                disableUnderline={true}
                displayEmpty
                {...props}
                MenuProps={menuProps}
                open={open}
                onOpen={() => setOpen(true)}
                onClose={() => setOpen(false)}
                value={value?.map(option => option?.value)}
                multiple
                size='small'
                className={`${classes.select}`}
                renderValue={(selected) => {
                    return (value?.length || (selected as (number | string)[])?.length) ? renderValue(selected) : <span className={`${classes.placeholder} !font-medium `}>{placeholder}</span>;
                }}
                onChange={handleChange}
            >
                {searchTerm !== undefined && setSearchTerm !== undefined && (
                    <div className={`${classes.searchContainer}`}>
                        <TextFieldV2
                            label={textFieldLabel}
                            size="small"
                            placeholder={textFieldPlaceholder}
                            value={searchTerm}
                            onChange={handleSearchChange}
                            onKeyDown={e => e.stopPropagation()}
                            InputProps={{
                                startAdornment: <InputAdornment position="start" > <img src={search} alt="icon" /></InputAdornment>,
                            }}
                        />
                    </div>
                )}
                {options.map((option: any) => (
                    <MenuItem
                        key={option.value}
                        value={option.value}
                        onKeyDown={e => e.stopPropagation()}
                    >
                        <Checkbox className={classes.checkedInputColor} checked={value.some(selectedOption => selectedOption.value === option.value)} />
                        <ListItemText primary={option.label} />
                    </MenuItem>
                ))}
                <div className='flex justify-between'>
                    {ClearButtonComponent && (
                        <div className={classes.clearButton}>
                            <ClearButtonComponent variant='secondaryText' onClick={handleClearButtonClick} label={buttonLabel} />
                        </div>
                    )}
                    {SaveButton && (
                        <div className={`${classes.saveButton} flex justify-end`}>
                            <SaveButton variant='primaryText' onClick={handleSaveButtonClick} label={saveButtonLabel} />
                        </div>
                    )}
                </div>
            </MuiMultiSelect>
        </div>
    );
}

export default FilterChipV3;
