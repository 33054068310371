import React, { useEffect, useState } from 'react'
import { createUseStyles } from 'react-jss';
import { useSearchParams } from 'react-router-dom';
import Tabs, { ITabsSchema } from '../../molecules/Tabs/Tabs';
import MainRawMaterialsTemplate from '../../template/DashBoard/MyMaterials/MainRawMaterials.template';
import FinishedProductTemplate from '../../template/DashBoard/FinishedProducts/FinishedProduct.template';
import ScrapProductTemplate from '../../template/DashBoard/ScrapProducts/ScrapProduct.template';
import { MY_MATERIAL_LISTING } from '../../../utils/types';

const useStyles = createUseStyles((theme: any) => ({
    section: {
        color: theme.palette.text.primary.primary900,
    }
}));

const MyMaterialListingPage = () => {
    const classes = useStyles();
    const [searchParams, setSearchParams] = useSearchParams();
    const [activeTab, setActiveTab] = useState(MY_MATERIAL_LISTING[searchParams.get('type') as keyof typeof MY_MATERIAL_LISTING] ?? MY_MATERIAL_LISTING.MAIN_RAW_MATERIAL);
    useEffect(() => {
        if (isNaN(activeTab)) return;
        const level = Object.keys(MY_MATERIAL_LISTING).filter(key => isNaN(parseInt(key)))[activeTab - 1];
        setSearchParams((params: URLSearchParams) => {
            params.set('type', level)
            return params;
        })
    }, [activeTab])

    const schema: ITabsSchema[] = [
        { label: 'Main Raw Material', component: <MainRawMaterialsTemplate /> },
        { label: 'Finished Product', component: <FinishedProductTemplate /> },
        { label: 'Scrap', component: <ScrapProductTemplate /> }
    ]

    return (
        <div className={"grid gap-y-5 w-full"}>
            <div className={`${classes.section} text-lg font-medium w-full`}>My Material</div>
            <div className='w-full '>
                <Tabs schema={schema} value={(activeTab).toString()} setValue={setActiveTab} />
            </div>
        </div>
    )
}

export default MyMaterialListingPage