import * as React from 'react';
import { createUseStyles } from 'react-jss';
import Button from '../../atoms/Button/Button';
import OtherInformationEditTemplate from './OtherInformationEdit.template';
import { useState } from 'react';
import noData from '../../../assets/images/noData.svg';
import { INDUSTRY_STATES } from '../../../utils/constant';
import { convertDate, getProfileDisplayData } from '../../../utils/helper';

const useStyles = createUseStyles((theme: any) => ({
    container: {
        border: `1px solid ${theme.palette.border.neutral.neutral100}`,
    },
    primaryContainer: {
        border: `1px solid ${theme.palette.border.neutral.neutral100}`,
    },
    secondaryContainer: {
        border: `1px solid ${theme.palette.border.neutral.neutral50}`,
        background: theme.palette.background.neutral.neutral50,
    },
    container_heading: {
        color: theme.palette.text.primary.primary900,
    },
    card_content: {
        "& span": {
            color: theme.palette.text.neutral.neutral600,
        },
        "& p": {
            color: theme.palette.text.neutral.neutral700,
        }
    },
    secondaryText: {
        color: theme.palette.text.neutral.neutral800,
    },
    borderBottom: {
        borderBottom: `1px solid ${theme.palette.border.neutral.neutral100}`,
    },
    websiteUrl:{
        color: theme.palette.text.secondary.secondary400
    }
}));

interface OtherInformationTemplateProps {
    businessProfile: any,
    handleSection: any,
    updateBusinessProfileSection: (section: string, values: any) => void;
    handleUpdateBusinessProfile: () => void;
}

const OtherInformationTemplate: React.FC<OtherInformationTemplateProps> = ({ businessProfile, handleSection, updateBusinessProfileSection, handleUpdateBusinessProfile }) => {
    const classes = useStyles();
    const [isEditing, setIsEditing] = useState(false);

    const handleEditClick = () => {
        setIsEditing(true);
    };

    const handleSuccess = () => {
        setIsEditing(false);
    };

    const handleBack = () => {
        setIsEditing(false);
        handleUpdateBusinessProfile();
    };

    const getIndustryType = (value: string): string => {
        const industry = INDUSTRY_STATES[value as keyof typeof INDUSTRY_STATES];
        return industry !== undefined ? industry : value;
    }

    return (
        <>
            {isEditing ? (
                <OtherInformationEditTemplate
                    businessProfile={businessProfile}
                    updateBusinessProfileSection={updateBusinessProfileSection}
                    onSuccess={handleSuccess} onBack={handleBack} />
            ) : (
                <>
                    {businessProfile?.totalEmployees != null ? (
                        <div className={`${classes.container} p-4 grid gap-y-6 rounded-xl w-full`}>
                            <div className={`flex justify-between items-center pb-6 ${classes.borderBottom}`}>
                                <h2 className={`${classes.container_heading} text-lg font-medium`}>Other Information</h2>
                                <Button variant={'tertiaryContained'} label={'Edit'} onClick={handleEditClick} />
                            </div>
                            <div className='grid grid-cols-3 gap-x-9 gap-y-6 text-sm'>
                                <div className={`${classes.card_content} grid gap-y-1`}>
                                    <span>Year of Establishment</span>
                                    <p className='font-medium'>{convertDate(businessProfile?.dateOfEstablishment)}</p>
                                </div>
                                <div className={`${classes.card_content} grid gap-y-1`}>
                                    <span>Number of Employees</span>
                                    <p className='font-medium'>{getProfileDisplayData(businessProfile?.totalEmployees)}</p>
                                </div>
                                <div className={`${classes.card_content} break-all grid gap-y-1`}>
                                    <span>Website URL</span>
                                    <p className={`font-medium`}>
                                        <a className={`${classes.websiteUrl} font-medium`} href={businessProfile.websiteUrl} target="_blank" rel="noopener noreferrer">
                                            {getProfileDisplayData(businessProfile?.websiteUrl)}
                                        </a>
                                    </p>
                                </div>
                                <div className={`${classes.card_content} grid gap-y-1`}>
                                    <span>Industry Type</span>
                                    <p className='font-medium'>{getIndustryType(businessProfile?.industry)}</p>
                                </div>
                                <div className={`${classes.card_content} grid gap-y-1`}>
                                    <span>Brand</span>
                                    <p className='font-medium'>{getProfileDisplayData(businessProfile?.brand)}</p>
                                </div>
                                <div className={`${classes.card_content} grid gap-y-1`}>
                                    <span>Business Type</span>
                                    <p className='font-medium'>{getProfileDisplayData(businessProfile?.businessType)}</p>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className={`${classes.secondaryContainer} py-8 flex justify-center w-full rounded-3xl`}>
                            <div className='flex flex-col items-center'>
                                <img src={noData} alt="" className='mb-8' />
                                <p className={`${classes.secondaryText} mb-4 text-base font-semibold`}>You haven't provided Other Information for your business.</p>
                                <Button variant={'primaryContained'} onClick={handleEditClick} label={"Add Other Information"} />
                            </div>
                        </div>
                    )}
                </>
            )}
        </>
    );
};

export default OtherInformationTemplate;
