import React, { useEffect } from 'react'
import { IMaterialRequirementFormik } from '../../../pages/DashBoard/MyMaterialProduct.page';
import { FormikProps } from 'formik';
import Button from '../../../atoms/Button/Button';
import { createUseStyles } from 'react-jss';
import TextFieldV2 from '../../../atoms/Input/TextFieldV2';
import plusIcon from "../../../../assets/icons/addIconOutlinedPrimary50.svg"
import { useNavigate } from 'react-router-dom';
import { CUSTOMER_ROUTES } from '../../../../utils/constant';
import ErrorMessage from '../../../atoms/ErrorMessage/ErrorMessage';
import IconButton from '../../../atoms/Button/IconButton';

interface ProductRequirementTemplateProps {
    handleOnBack: () => void;
    handleOnNext: () => void;
    formik: FormikProps<IMaterialRequirementFormik>
}

const useStyles = createUseStyles((theme: any) => ({

    container: {
        borderRadius: "12px",
        border: `1px solid ${theme.palette.border.neutral.neutral100}`,
    },
    heading: {
        color: theme.palette.text.primary.primary800
    },
    button: {
        padding: '14px !important'
    },
    highlightedText: {
        color: theme.palette.text.primary.primary400
    }
}));

const ProductRequirementTemplate: React.FC<ProductRequirementTemplateProps> = ({ formik, handleOnBack, handleOnNext }) => {
    const classes = useStyles();
    const navigate = useNavigate();
    const handleNext = (event: React.FormEvent) => {
        event.preventDefault();
        if (formik.touched.noOfRows && !Boolean(formik.errors.noOfRows))
            handleOnNext();
    }

    const handleOnCancel = () => {
        navigate(CUSTOMER_ROUTES.MY_MATERIAL_LISTING)
    }

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (

        <div className={`grid gap-y-3`}>
            <div className={`text-2xl font-medium ${classes.heading}`}>Product Category selected: <span className={classes.highlightedText}>{formik.values.categoryName}</span></div>
            <div className={`${classes.container} p-4 gap-x-6`}>
                <div className='flex w-1/2 gap-x-3 items-end'>
                    <div>
                        <TextFieldV2
                            {...formik.getFieldProps("noOfRows")}
                            id="outlined-basic"
                            label="Enter No of Products you want (Max. 10 nos.)"
                            placeholder='Enter Quantity'
                            type="number"
                            error={formik.touched.noOfRows && Boolean(formik.errors.noOfRows)}
                            fullWidth
                            helperText=""
                        />
                        <div>
                            {formik.touched.noOfRows && Boolean(formik.errors.noOfRows) && <ErrorMessage message={formik.errors.noOfRows as string} />}
                        </div>
                    </div>
                    <IconButton
                        variant="primaryContained"
                        iconButton={<img src={plusIcon} />}
                        size='large'
                        onClick={handleNext}
                        className={classes.button}
                    />
                </div>
            </div>

            <div className='flex justify-end gap-x-3 mt-1'>
                <Button label='Cancel' variant='secondaryContained' onClick={handleOnCancel} />
                <Button label='Back' variant='tertiaryContained' onClick={handleOnBack} />
            </div>
        </div>
    )
}

export default ProductRequirementTemplate