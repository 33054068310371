import React, { useRef } from 'react';
import { createUseStyles } from 'react-jss';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import CarouselRef from 'react-multi-carousel';
import rightArrow from "../../../../assets/icons/rightArrow.svg";
import leftArrow from "../../../../assets/icons/leftArrow.svg"
import automotiveSvg from '../../../../assets/images/Automotive.jpg';
import constructionSvg from '../../../../assets/images/Construction.jpg';
import transportationSvg from '../../../../assets/images/Transportation.jpg';
import industrialEquipmentSvg from '../../../../assets/images/IndustrialEquipment.jpg';
import energySvg from '../../../../assets/images/Energy.jpg';
import agricultureSvg from '../../../../assets/images/Agriculture.jpg';
import coldStorageSvg from '../../../../assets/images/ColdStorage.jpg';
import foodPackagingSvg from '../../../../assets/images/FoodPackaging.jpg';

const useStyles = createUseStyles((theme: any) => ({
    heading: {
        fontSize: '48px',
        lineHeight: "58px",
        color: theme.palette.text.primary.primary500,
        "& span": {
            color: theme.palette.text.neutral.neutral900,
        },
    },
    slide: {
        width: '246px',
        borderRadius: "12px",
        "&:hover": {
            boxShadow: "0px 5px 16px 0px rgba(8, 15, 52, 0.08)"
        },
    },
    tab: {
        marginRight: "16px"
    },
    image:{
        borderTopLeftRadius: "12px",
        borderTopRightRadius: "12px"
    },
    title:{
        border:`1px solid ${theme.palette.border.neutral.neutral100}`,
        borderTop: "none",
        color: theme.palette.text.primary.primary900,
        lineHeight: "22px",
        borderBottomLeftRadius: "12px",
        borderBottomRightRadius: "12px"
    },
    customButtonContainer: {
        marginTop: "-12%",
    },
    carouselContainer: {
        "& .react-multiple-carousel__arrow--right": {
            right: "0 !important"
        },
        "& .react-multiple-carousel__arrow--left": {
            left: "0 !important"
        },
        "& .react-multi-carousel-item": {
        },
        "& .react-multi-carousel-list": {
            width: "1184px",
            margin: "0 auto",
        },
        "& .react-multi-carousel-list li": {
            width: "fit-content !important",
        },
    },
    customArrow: {
        borderRadius: "50%",
        width: "48px",
        height: "48px",
        cursor: "pointer",
        transition: "box-shadow 0.3s ease-in-out",
        boxShadow: `0px 6px 12px 2px rgba(10, 18, 41, 0.16), 0px 2px 4px 1px rgba(10, 18, 41, 0.04)`,
    },
}));

const IndustriesSlider: React.FC = () => {
    const classes = useStyles();
    const carouselRef = useRef<CarouselRef>(null);
    const responsive = {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 3000 },
            items: 5,
            partialVisibilityGutter: 0
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 5,
            partialVisibilityGutter: -23
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 3,
            partialVisibilityGutter: 10
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 2,
            partialVisibilityGutter: 0
        },
    };

    const data = [
        {
            imgSrc: automotiveSvg,
            title: "Automotive",
        },
        {
            imgSrc: constructionSvg,
            title: "Construction",
        },
        {
            imgSrc: transportationSvg,
            title: "Transportation",
        },
        {
            imgSrc: industrialEquipmentSvg,
            title: "Industrial Equipment",
        },
        {
            imgSrc: energySvg,
            title: "Energy",
        },
        {
            imgSrc: agricultureSvg,
            title: "Agriculture",
        },
        {
            imgSrc: coldStorageSvg,
            title: "Cold Storage",
        },
        {
            imgSrc: foodPackagingSvg,
            title: "Food Packaging",
        },
    ];

    const ButtonGroup = ({ next, previous, carouselState }: any) => {
        const { currentSlide, totalItems, slidesToShow } = carouselState;
        const isFirstSlide = currentSlide === 0;
        const isLastSlide = currentSlide === totalItems - slidesToShow;

        return (
            <div className={`${classes.customButtonContainer} flex justify-between items-center w-full`}>
                {!isFirstSlide && (
                    <div className={`${classes.customArrow} bg-white flex justify-center items-center`} onClick={previous}>
                        <img src={leftArrow} alt="" />
                    </div>
                )}
                {!isLastSlide && (
                    <div className={`${classes.customArrow} bg-white flex justify-center items-center ml-auto`} onClick={next}>
                        <img src={rightArrow} alt="" />
                    </div>
                )}
            </div>
        );
    };

    return (
        <div className={`w-[1300px] mx-auto grid gap-y-8 mb-10`}>
            <div className={`${classes.heading} font-bold text-center`}>Industries <span>we focus upon</span></div>
            <div className={`${classes.carouselContainer} relative`} style={{ height: "262px" }}>
                <Carousel
                    responsive={responsive}
                    showDots={false}
                    infinite={false}
                    partialVisible={true}
                    ref={carouselRef}
                    arrows={false}
                    removeArrowOnDeviceType={["sm-mobile"]}
                    renderButtonGroupOutside={true}
                    customButtonGroup={<ButtonGroup />}
                >
                    {data.map((item: any, index: any) => (
                                <div className={` ${classes.slide} grid ${index !== data.length - 1 ? classes.tab : ''}`} key={item.id} >
                                    <img className={classes.image} src={item.imgSrc} alt="industryImage" />
                                    <div className={`${classes.title} pl-4 pt-4 pb-6 text-lg font-bold`}>{item.title}</div>
                                </div>
                            ))}
                </Carousel>
            </div>
        </div>
    );
};

export default IndustriesSlider;