import React from "react";
import { defaultTheme } from "../../../utils/theme";
import { RESOURCE_STATUS } from "../../../utils/constant";

interface ResourceStatusProps {
    status: string;
    colourMap?: { [key: string]: string }
}

const STATUS_COLOR = {
    [RESOURCE_STATUS.ACTIVE]: defaultTheme.palette.text.success.success600,
    [RESOURCE_STATUS.PENDING]: defaultTheme.palette.text.warning.warning600,
    [RESOURCE_STATUS.INACTIVE]: defaultTheme.palette.text.neutral.neutral600,
    [RESOURCE_STATUS.REJECTED]: defaultTheme.palette.text.secondary.secondary600,
    [RESOURCE_STATUS.ADJUST_OUT]: defaultTheme.palette.text.neutral.neutral600,
    [RESOURCE_STATUS.DRAFT]: defaultTheme.palette.status.draft.draft600,
    [RESOURCE_STATUS.PUBLISHED]: defaultTheme.palette.text.success.success50,
    [RESOURCE_STATUS.UNPUBLISHED]: defaultTheme.palette.text.secondary.secondary50,
};

const STATUS_BACKGROUND_COLOR = {
    [RESOURCE_STATUS.ACTIVE]: defaultTheme.palette.background.success.success50,
    [RESOURCE_STATUS.PENDING]: defaultTheme.palette.background.warning.warning50,
    [RESOURCE_STATUS.INACTIVE]: defaultTheme.palette.background.neutral.neutral50,
    [RESOURCE_STATUS.REJECTED]: defaultTheme.palette.background.secondary.secondary50,
    [RESOURCE_STATUS.ADJUST_OUT]: defaultTheme.palette.background.neutral.neutral50,
    [RESOURCE_STATUS.DRAFT]: defaultTheme.palette.status.draft.draft50,
    [RESOURCE_STATUS.PUBLISHED]: defaultTheme.palette.background.success.success500,
    [RESOURCE_STATUS.UNPUBLISHED]: defaultTheme.palette.background.secondary.secondary500,
};

const STATUS_BORDER_COLOR = {
    [RESOURCE_STATUS.ACTIVE]: defaultTheme.palette.background.success.success100,
    [RESOURCE_STATUS.PENDING]: defaultTheme.palette.background.warning.warning100,
    [RESOURCE_STATUS.INACTIVE]: defaultTheme.palette.background.neutral.neutral100,
    [RESOURCE_STATUS.REJECTED]: defaultTheme.palette.background.secondary.secondary100,
    [RESOURCE_STATUS.DRAFT]: defaultTheme.palette.status.draft.draft100,
    [RESOURCE_STATUS.PUBLISHED]: defaultTheme.palette.background.success.success500,
    [RESOURCE_STATUS.UNPUBLISHED]: defaultTheme.palette.background.secondary.secondary500,
};

const ResourceStatusV2: React.FC<ResourceStatusProps> = ({ status, colourMap }) => {
    const getStatusColor = (status: string) => {
        return colourMap ? colourMap[status] : STATUS_COLOR[status];
    };

    const getBackgroundColor = (status: string) => {
        return colourMap ? colourMap[status] : STATUS_BACKGROUND_COLOR[status];
    };

    const getBorderColor = (status: string) => {
        return colourMap ? colourMap[status] : STATUS_BORDER_COLOR[status];
    };

    return (
        <span
            style={{
                color: `${getStatusColor(status)}`,
                backgroundColor: `${getBackgroundColor(status)}`,
                border: `1px solid ${getBorderColor(status)}`,
                borderRadius: '8px',
                padding: '6px 12px',
                fontWeight:"500",
                fontSize:"12px"
            }}
        >
            {status}
        </span>
    );
}

export default ResourceStatusV2;
