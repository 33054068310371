import { useLocation, useNavigate } from "react-router-dom";
import { useSnackbar } from "../../../hooks/useSnackBar";
import { DOCUMENT_RESOURCE_TYPE, FINISHED_PRODUCT_ROUTES, SCRAP_PRODUCTS, SCRAP_PRODUCT_ROUTES } from "../../../utils/constant";
import { createUseStyles } from "react-jss";
import { useFormik } from "formik";
import * as yup from "yup";
import { HTTP_STATUS, MY_MATERIAL } from "../../../utils/types";
import { useEffect } from "react";
import { UploadedImageData } from './FinishedProductCreation.page';
import { useFileService } from "../../../services/useFileService";
import { useAuthenticatedUser } from "../../../hooks/useAuthenticatedUser";
import { useScrapProductService } from "../../../services/useScrapProductService";
import AddScrapProductTemplate from "../../template/DashBoard/ScrapProducts/AddScrapProduct.template";

export interface ScrapProductsFormik {
    category: string,
    type: string,
    quantity: number | null,
    sales: string,
    images: (UploadedImageData | File)[],
    imageListToDelete?: number[]
}

const useStyles = createUseStyles((theme: any) => ({
    section: {
        color: theme.palette.text.primary.primary900,
    }
}));

const initialValues = {
    category: "",
    type: "",
    quantity: null,
    sales: "",
    images: [],
    imageListToDelete: []
}

const validationSchema = yup.object().shape({
    category: yup.string().required("Category is required"),
    type: yup.string().required("Type is required"),
    quantity: yup.number()
        .typeError("Enter valid Quantity")
        .moreThan(0, "Quantity should be greater than 0")
        .lessThan(100000, "Quantity should be smaller than 100000")
        .required("Quantity is required"),
    sales: yup.string().required("Sales Mode is required"),
})

const ScrapProductCreationPage = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const classes = useStyles();
    const queryParams = new URLSearchParams(location.search);
    const { showSnackbar, SnackBarComponent } = useSnackbar();
    const scrapProductService = useScrapProductService();
    const cardId = queryParams.get("id");
    const fileService = useFileService();
    const { user } = useAuthenticatedUser();

    const handleOnBack = () => {
        navigate(FINISHED_PRODUCT_ROUTES.MY_MATERIAL(MY_MATERIAL.SCRAP));
    }

    const uploadImage = async (id: number) => {
        try {
            const result = [];
            const fileToUpload = formik.values.images.filter(image => image instanceof File);
            for (let image of fileToUpload) {
                if (image instanceof File) {
                    const presignedUrlResponse = await fileService.generatePresignedUrl(image.name, DOCUMENT_RESOURCE_TYPE.BUSINESS_PROFILE_SCRAP((user?.id as string)), id);
                    if (presignedUrlResponse && presignedUrlResponse.data && presignedUrlResponse.data.data && presignedUrlResponse.data.data.presignedUrl) {
                        const presignedUrl = presignedUrlResponse.data.data.presignedUrl;
                        const imageUploadResponse = await fileService.uploadImageUsingPreSignedUrls(image, presignedUrl);
                        if (imageUploadResponse.status === HTTP_STATUS.OK) {
                            let res = await fileService.createFile({
                                resourceType: 'BUSINESS_PROFILE_SCRAP',
                                resourceId: id,
                                path: `business_profile/${user?.id}/scrap/${id}/${image.name.split(" ").join("_")}`,
                                mimeType: image.type
                            })
                            if (res?.status === HTTP_STATUS.OK) {
                                result.push(true)
                            }
                            else {
                                result.push(false)
                                showSnackbar("error", `Failed to upload image: ${image.name}`)
                            }
                        } else {
                            result.push(false)
                            showSnackbar("error", `Failed to upload image: ${image.name}`)
                        }
                    } else {
                        result.push(false)
                        showSnackbar("error", 'Failed to generate presigned URL')
                    }
                }
            }
            if (!result.includes(false)) {
                handleOnBack();
                showSnackbar("success", "Scrap uploaded successfully");
            } else {
                showSnackbar("error", 'File to upload Images:')
            }
        } catch (error) {
            showSnackbar("error", 'File upload failed:')
        }
    }

    const formik = useFormik<ScrapProductsFormik>({
        initialValues,
        validationSchema,
        onSubmit: async (values) => {
            if (values.images.length === 0) {
                showSnackbar('error', 'At least one image should be selected');
                return;
            }
            const body = {
                scrapCategory: values.category,
                type: values.type,
                monthlyProduction: values.quantity,
                saleMode: values.sales,
                imageListToDelete: values.imageListToDelete?.join(",")
            }
            try {
                if (cardId) {
                    let res = await scrapProductService.updateScrapProductItem(body, Number(cardId));
                    if (res.status === HTTP_STATUS.OK) {
                        await uploadImage(res.data.data.id)
                    }
                    else
                        showSnackbar("error", "Scrap not uploaded");
                }
                else {
                    let res = await scrapProductService.create(body);
                    if (res.status === HTTP_STATUS.OK) {
                        await uploadImage(res.data.data.id)
                    }
                    else
                        showSnackbar("error", "Scrap not created");
                }
            } catch (error) {
                showSnackbar("error", "Scrap Product failed");
            }
        }
    })

    const loadScrapProductById = async () => {
        try {
            let res = await scrapProductService.getScrapProductId(parseInt(cardId as string));
            if (res.status === HTTP_STATUS.OK) {
                formik.setValues({
                    category: res.data.data.scrapCategory,
                    type: res.data.data.type,
                    quantity: res?.data?.data?.monthlyProduction,
                    sales: res.data.data.saleMode,
                    images: res?.data?.data?.images ?? [],
                    imageListToDelete: []
                })
            }
        }
        catch (error) {
            showSnackbar("error", "Error Fetching Data");
        }
    }

    useEffect(() => {
        if (cardId) {
            loadScrapProductById();
        }
    }, [cardId])

    return (
        <div className="grid gap-y-6">
            {SnackBarComponent}
            <div className={`${classes.section} text-lg font-semibold`}>{SCRAP_PRODUCTS.MY_MATERIALS}</div>
            <AddScrapProductTemplate handleOnBack={handleOnBack} formik={formik} setSelectedFile={() => { }} cardId={Number(cardId)} />
        </div>
    )
}

export default ScrapProductCreationPage;