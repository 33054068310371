import { Button } from '@mui/joy';
import React from 'react';
import { createUseStyles } from 'react-jss';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import InfoIcon from '@mui/icons-material/Info';
import EmailIcon from '@mui/icons-material/Email';
import visionBanner from '../../../../src/assets/images/visionBanner.svg';

const useStyles = createUseStyles((theme: any) => ({
    container: {
        width: '1297px',
        margin: '0 auto',
        boxShadow: '0px 0px 5px #00000020',
    },
    primaryContainer: {

    },
    heading: {
        color: theme.palette.text.complementary.complementary400,
    },
    header: {
        // background: theme.palette.background.complementary.complementary500,
        width: '100%',
        height: '350px',
        backgroundImage: `url(${visionBanner})`,
        backgroundSize: 'cover',
        padding: "70px 0px",
        "& h2": {
            color: theme.palette.text.primary.primary960,
        },
        "& p": {
            color: theme.palette.text.neutral.neutral100,
        },
    },
    secondaryContainer: {
        borderLeft: `5px solid ${theme.palette.border.complementary.complementary600}`
    },
    footerContainer: {
        background: theme.palette.background.neutral.neutral50,
        borderTop: `1px solid ${theme.palette.border.neutral.neutral600}`
    },

}));


const ContactUsTemplate: React.FC = () => {
    const classes = useStyles();

    return (
        <div className={`${classes.primaryContainer}`}>
            <div className={`${classes.header} text-left p-4 mb-6`}>
                <div className={`${classes.container} pt-12`}>
                <h2 className='text-3xl mb-3 font-bold'>Contact Us at SteelBazaar</h2>
                <p>You support is our priority!</p>
                </div>
            </div>
            <div className={`${classes.container} p-4`}>
                <div className={`${classes.secondaryContainer} mb-4 pl-3 pt-3 pb-3`}>
                    <h3 className={`${classes.heading} text-2xl font-bold mb-2`}>About Us</h3>
                    <p className='mb-3'>Learn more about SteelBazaar, the leading B2B marketplace for steell products. We connect top steel <br /> suppliers with prominent buyers, offering a seamiess platform for all your steel procurment needs.</p>
                    <Button><HelpOutlineIcon className='mr-2' />Learn More</Button>
                </div>
                <div className={`${classes.secondaryContainer} mb-4 pl-3 pt-3 pb-3`}>
                    <h3 className={`${classes.heading} text-2xl font-bold mb-2`}>Careers with Us</h3>
                    <p className='mb-3'>Join our growing tean if you're passionate about the steel industry and want to work with an innovative <br /> leader, explore career opportunities at SteelBazaar.</p>
                    <Button><ChatBubbleIcon className='mr-2' />Explore Open Positions</Button>
                </div>
                <div className={`${classes.secondaryContainer} mb-4 pl-3 pt-3 pb-3`}>
                    <h3 className={`${classes.heading} text-2xl font-bold mb-2`}>News Center</h3>
                    <p className='mb-3'>Stay updated with the latest industry news, product launches, and important announcements from <br /> SteelBazaar.</p>
                    <Button><WhatsAppIcon className='mr-2' />Visit News Center</Button>
                </div>
                <div className={`${classes.secondaryContainer} mb-4 pl-3 pt-3 pb-3`}>
                    <h3 className={`${classes.heading} text-2xl font-bold mb-2`}>Lending Partners</h3>
                    <p className='mb-3'>We work with trusted lending patners to offer financing solutions for your business. Find out how we can <br /> help you manage cash flow and make larger purchases easier.</p>
                    <Button><LocalShippingIcon className='mr-2' />See Our Lending Patners</Button>
                </div>
                <div className={`${classes.secondaryContainer} mb-4 pl-3 pt-3 pb-3`}>
                    <h3 className={`${classes.heading} text-2xl font-bold mb-2`}>Terms and Conditions</h3>
                    <p className='mb-3'>Understand the terms that govem your use of platform and services. We're transparent about our <br /> policies to ensure a smooth and fair experaince for all our users.</p>
                    <Button><KeyboardReturnIcon className='mr-2' />View Terms</Button>
                </div>
                <div className={`${classes.secondaryContainer} mb-4 pl-3 pt-3 pb-3`}>
                    <h3 className={`${classes.heading} text-2xl font-bold mb-2`}>Privacy Policy</h3>
                    <p className='mb-3'>Wetake your privacy seriously. Learn how we protect your data and ensure that your information is secure <br /> when using SteelBazaar</p>
                    <Button><InfoIcon className='mr-2' />Visit the Help Center</Button>
                </div>
                <div className={`${classes.footerContainer} text-center p-10 !mt-4`}>
                    <p className='flex items-center justify-center'>For any further inquiries or support, feel free to <Button className='!mr-2 !ml-2'><EmailIcon className='mr-2' /> Contact Us</Button> We're here to help you </p>
                </div>
            </div>
        </div>
    );
};

export default ContactUsTemplate;
