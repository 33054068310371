import React, { useEffect, useState } from 'react'
import * as yup from "yup";
import { REGEX, SOCIAL_LINKS } from '../../../utils/constant';
import { HTTP_STATUS } from '../../../utils/types';
import { useBusinessProfileService } from '../../../services/useBusinessProfileService';
import { useSnackbar } from '../../../hooks/useSnackBar';
import { useFormik } from 'formik';
import { useAuthenticatedUser } from '../../../hooks/useAuthenticatedUser';
import { AuthenticatedUserType } from '../../../contexts/AuthenticatedUserContext';
import KeyPersonDetailsTemplate from '../../template/DashBoard/KeyPersonDetails/KeyPersonDetails.template';

export interface ISocialMediaLink {
    [key: string]: string
}

export interface IKeyDetail {
    name: string;
    email: string;
    mobile: string;
    department: string;
    designation: string;
    profilePic: string;
    countryCode: string;
    socialLinks: ISocialMediaLink;
    isAuthorized: boolean;
    alternateEmail: string;
    alternateMobile: string;
    prefix: string;
    whatsAppLink?: string;
    modeOfCommunication?: string;
}

export interface IkeyDetailForm {
    noOfRows: number | null;
    pocs: IKeyDetail[];
    newPocs: IKeyDetail[];
}

const initialValues = {
    noOfRows: 1,
    pocs: [],
    newPocs: []
}

interface SocialLinks {
    [key: string]: string;
}

const KeyPersonDetailsPage: React.FC = () => {

    const bussinessProfileService = useBusinessProfileService();
    const [businessProfile, setBusinessProfileTo] = useState();
    const { showSnackbar, SnackBarComponent } = useSnackbar();
    const { user, setAuthenticatedUser } = useAuthenticatedUser();

    const [keyDetails, setKeyDetailsTo] = useState<IkeyDetailForm>(initialValues)

    const socialLinksValidation = yup.object().shape(
        Object.fromEntries(
            SOCIAL_LINKS.map(link => [
                link.label,
                yup.string()
                    .test(
                        'is-valid-url',
                        'Enter a valid URL for selected social media links',
                        function (value) {
                            if (!value) return true;
                            return REGEX.URL.test(value);
                        }
                    )
            ])
        )
    );

    var validationSchema = yup.object().shape({
        pocs: yup.array().of(
            yup.object().shape({
                name: yup.string().required('name is required'),
                mobile: yup.string().required('number is required'),
                alternateEmail: yup.string().matches(REGEX.EMAIL, 'Enter valid alternate email address'),
                alternateMobile: yup.string().matches(REGEX.PHONE_NUMBER, 'Enter valid phone number'),
                socialLinks: socialLinksValidation
            })
        ),
        noOfRows: yup.number()
            .required('Must be a positive number between 1 and 9')
            .transform((value: any) => (!isNaN(value) ? value : undefined))
            .test(
                'isValidNumber',
                'Must be a positive number between 1 and 9',
                value => value === undefined || (value > 0 && value < 10)
            ),
        newPocs: yup.array().of(
            yup.object().shape({
                name: yup.string().required('Enter valid name').matches(REGEX.FULL_NAME, 'enter valid name'),
                email: yup.string().required('Enter valid email address').matches(REGEX.EMAIL, 'Enter valid email address'),
                alternateEmail: yup.string().matches(REGEX.EMAIL, 'Enter valid alternate email address'),
                alternateMobile: yup.string().matches(REGEX.PHONE_NUMBER, 'Enter valid phone number'),
                mobile: yup.string().required('Enter valid phone number').matches(REGEX.PHONE_NUMBER, 'Enter valid phone number'),
                profilePic: yup.string(),
                socialLinks: socialLinksValidation
            })
        )
    });

    const getBusinessProfile = async () => {
        try {
            const businessProfileResponse = await bussinessProfileService.getBusinessProfile();
            if (businessProfileResponse.status === HTTP_STATUS.OK) {
                const profileData = businessProfileResponse?.data?.data;
                setBusinessProfileTo(profileData);
                setKeyDetailsTo({ pocs: profileData.pocs || [], noOfRows: 1, newPocs: [] });
            }
        } catch (error) {
            showSnackbar('error', 'Business Profile fetch failed');
        }
    };

    const formik = useFormik<IkeyDetailForm>({
        initialValues,
        validationSchema,
        onSubmit: async (values, { setSubmitting }) => {
            setSubmitting(true)
            const body = [
                ...formik.values.pocs,
                ...formik.values.newPocs,
            ];
            try {
                const response = await bussinessProfileService.updateBusinessProfile(body, 'POCS');
                if (response?.status === HTTP_STATUS.OK) {
                    const editedUser = { ...user } as AuthenticatedUserType;
                    editedUser.email = formik.values.pocs[0].email;
                    setAuthenticatedUser(editedUser as AuthenticatedUserType)
                    getBusinessProfile();
                    showSnackbar("success", "Business profile updated successfully");
                } else if (response?.status === HTTP_STATUS.BAD_REQUEST) {
                    showSnackbar('error', response?.data?.statusMessage);
                } else {
                    showSnackbar('error', "Error while updating business profile");
                }
            } catch (error) {
                showSnackbar("error", `Business profile update  failed ${error}`);
            }
            finally {
                setSubmitting(false);
            }
        },
    });

    useEffect(() => {
        formik.setValues({
            ...formik.values,
            ...keyDetails
        })
    }, [keyDetails])

    useEffect(() => {
        getBusinessProfile();
    }, []);

    return (
        <form onSubmit={formik.handleSubmit}>
            <KeyPersonDetailsTemplate formik={formik} />
        </form>
    )
}
export default KeyPersonDetailsPage;