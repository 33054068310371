import React, { useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles((theme: any) => ({
    progressContainer: {
        height: 12, 
        background: theme.palette.border.neutral.neutral200, 
        borderRadius: 4, 
        border: `1px solid ${theme.palette.border.neutral.neutral200}`, 
        position: 'relative',
        overflow: 'hidden',
    },
    progressBar: {
        position: 'absolute',
        height: '100%',
        width: '100%',
        top: 0,
        left: 0,
    },
    progressLine: {
        height: '100%', 
        background: theme.palette.border.primary.primary500, 
        transition: 'width 1s ease-in-out',
    },
    loading: {}, 
}));

interface ProgressBarProps {
    isUploading: boolean;
    reset: boolean;
}

const ProgressBarTemplate: React.FC<ProgressBarProps> = ({ isUploading, reset }) => {
    const classes = useStyles();
    const [progress, setProgress] = useState<number>(0);

    useEffect(() => {
        if (isUploading) {
            const interval = setInterval(() => {
                setProgress((prev) => (prev >= 100 ? 100 : prev + 10));
            }, 100);

            return () => clearInterval(interval);
        } else {
            setProgress(0);
        }
    }, [isUploading, reset]);

    return (
        <div className={classes.progressContainer}>
            <div className={classes.progressBar}>
                <div className={classes.progressLine} style={{ width: `${progress}%` }}></div>
            </div>
        </div>
    );
};

export default ProgressBarTemplate;
