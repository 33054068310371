import React, { useState } from 'react'
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles((theme: any) => ({
    errorBig: {
        color: theme.palette.text.secondary.secondary500,
        position: 'absolute',
        marginTop: '95px'
    },
    errorSmall: {
        color: theme.palette.text.secondary.secondary500,
        position: 'absolute',
        marginTop: '70px'
    }
}));

interface ErrorMessageProps {
    message?: string
    size?: string
}

const ErrorMessage: React.FC<ErrorMessageProps> = ({ message, size }) => {
    const classes = useStyles();
    return (
        <div className={`${classes.errorSmall} text-xs`}>{message}</div>
    )
}

export default ErrorMessage