import React, { useEffect, useState } from 'react';
import { Radio } from '@mui/material';
import { createUseStyles } from 'react-jss';
import RadioActive from '../../../assets/images/radioActive.svg';
import RadioInActive from '../../../assets/images/radioInactive.svg'
import Button from '../Button/Button';
import { MODE } from '../../../utils/constant';
import editIcon from "../../../assets/icons/editPenColorOutlinedIcon.svg"

interface CustomRadioProps {
    heading: string;
    options: { value: string; label: string }[];
    formik: any;
    name: string;
    mode?: string;
}

const useStyles = createUseStyles((theme: any) => ({
    border: {
        border: `1px solid ${theme.palette.border.neutral.neutral100}`
    },
    heading: {
        color: theme.palette.text.primary.primary900,
        lineHeight: "20px"
    },
    value: {
        color: theme.palette.text.neutral.neutral700,
    }
}));

const StatusV2: React.FC<CustomRadioProps> = ({ heading, options, formik, name, mode }) => {
    const classes = useStyles();
    const [isEdit, setIsEdit] = useState<boolean>(false)

    const handleEdit = () => {
        setIsEdit(true);
    };

    const handleCancel = () => {
        setIsEdit(false);
    };

    const handleSave = (event: React.FormEvent) => {
        formik.handleSubmit();
        setIsEdit(false);
    };

    return (
        <div className={`${classes.border} grid gap-y-4 p-6 rounded-xl`}>
            <div className='flex justify-between'>
                <div className={`${classes.heading} text-base font-medium my-auto`}>
                    {heading}
                </div>
                { mode === MODE.VIEW && !isEdit &&
                    <Button variant='secondaryContained' label={"Edit Status"} onClick={handleEdit} startIcon={<img src={editIcon} alt= "editIcon"/>} />
                }
                { mode === MODE.VIEW && isEdit &&
                    <div className='flex gap-x-3'>
                        <Button variant='tertiaryContained' label={"Cancel"} onClick={handleCancel} />
                        <Button variant='secondaryContained' label={"Save"} onClick={handleSave} />
                    </div>
                }
            </div>
            <div className='grid grid-cols-5 gap-x-6'>
                {options.map((item: any) => (
                    <div className={`${classes.border} flex gap-x-4 p-3 rounded-lg`} key={item.value}>
                        <div>
                            <Radio
                                icon={<img src={RadioInActive} alt="icon" />}
                                checkedIcon={<img src={RadioActive} alt="icon" />}
                                checked={formik.values.status === item.value}
                                onChange={() => formik.setFieldValue(name, item.value)}
                                value={item.value}
                                disabled={mode === MODE.VIEW && !isEdit}
                            />
                        </div>
                        <div className={`${classes.value} my-auto text-base font-normal`}>{item.label}</div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default StatusV2;