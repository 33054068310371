import { useEffect, useState } from "react";
import ContactUsPopup from "../../molecules/ContactUsPopup/ContactUsPopup";
import { CONNECT_WITH_US } from "../../../utils/constant";
import callIcon from "../../../assets/icons/callIcon.svg";
import IconButton from "../../atoms/Button/IconButton";

const ContactUs: React.FC = () => {
    const [arrowUpDown, setArrowUpDown] = useState<boolean>(false);

    const handleClick = () => {
        setArrowUpDown(!arrowUpDown);
    }

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            const target = event.target as HTMLElement;
            const iconButtonElement = document.querySelector(".contact-us-button");
            const popupElement = document.querySelector(".contact-us-popup");

            if (
                iconButtonElement &&
                popupElement &&
                !iconButtonElement.contains(target) &&
                !popupElement.contains(target)
            ) {
                setArrowUpDown(false);
            }
        };

        if (arrowUpDown) {
            document.addEventListener("click", handleClickOutside);
        } else {
            document.removeEventListener("click", handleClickOutside);
        }

        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, [arrowUpDown]);

    return (
        <>
            <IconButton className="!px-2.5 contact-us-button" variant="secondaryText" label={CONNECT_WITH_US.TALK_TO_US} size="medium" startIcon={<img src={callIcon} alt="call" />} onClick={handleClick} />
            {arrowUpDown && <ContactUsPopup/>}
        </>
    )
}

export default ContactUs;