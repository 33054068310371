import React from 'react';
import { createUseStyles } from 'react-jss';
import { LOCATION_STATES } from '../../../utils/types';
import { LocationSectionProps } from './AddBusinessLocation.template';
import TextFieldV2 from '../../atoms/Input/TextFieldV2';
import Button from '../../atoms/Button/Button';

const useStyles = createUseStyles((theme: any) => ({
    container: {
        border: `1px solid ${theme.palette.border.neutral.neutral100}`
    },
}));

const GetNumberOfBusinessLocationTemplate: React.FC<LocationSectionProps> = ({ formik, setCurrentSectionTo }) => {
    const classes = useStyles();
    const handleNext = (event: React.FormEvent) => {
        event.preventDefault();
        const requiredFields = ["noOfRows"];
        let fieldError = false;
        for (const key of requiredFields) {
            if (key in formik.errors) {
                formik.setFieldTouched(key, true);
                fieldError = true;
            }
        }
        if (fieldError === true) return;
        const numberOfRows = formik.values.noOfRows ?? 0;
        const emptyLocation = { gstin: '', state: '' };
        const newLocationsValues = Array.from({ length: numberOfRows }, () => ({ ...emptyLocation }));
        formik.setFieldValue('newLocations', newLocationsValues)
        setCurrentSectionTo(LOCATION_STATES.ADD_LOCATIONS);
    };
    
    const handleBack = () => {
        setCurrentSectionTo(LOCATION_STATES.VIEW_LOCATIONS);
        formik.setTouched({});
    };

    return (
        <div className='grid gap-y-4'>
            <div className={`${classes.container} p-4 rounded-xl`}>
                <div className='w-1/2'>
                    <TextFieldV2
                        label="Number of states you are registered with GST"
                        placeholder="Enter data"
                        fullWidth
                        {...formik.getFieldProps("noOfRows")}
                        type="number"
                        error={
                            formik.touched.noOfRows &&
                            Boolean(formik.errors.noOfRows)
                        }
                        helperText={
                            Boolean(formik.touched?.noOfRows) &&
                            formik.errors?.noOfRows
                        }
                    />
                </div>
            </div>

            <div className='flex items-end gap-x-3 justify-self-end'>
                {formik.values?.locations?.length == 0 && < Button
                    variant="tertiaryContained"
                    label="Back"
                    onClick={handleBack}
                />}
                <Button
                    variant="primaryContained"
                    label="Next"
                    onClick={handleNext}
                />
            </div>
        </div>
    )
};

export default GetNumberOfBusinessLocationTemplate;