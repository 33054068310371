import React, { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import { ResourceFilter } from "../../molecules/ResourceFilter/ResourceFilter";
import useMetaDataService from "../../../hooks/useMetaDataService";
import { CATALOGUE_FILTER_HEADINGS, CATALOGUE_LIST_QUERY, CLASS_STATES } from "../../../utils/types";
import Tune from '../../../assets/icons/tune.svg'
import { Badge } from "@mui/material";
import { toAllCapsCase } from "../../../utils/helper";
import { ATTRIBUTES_WITH_RANGE } from "../../../utils/constant";

export interface ICatelogueFilter {
    standards: number[];
    brands: number[];
    grades: number[];
    shapes: string[];
    manufacturers: number[];
    classes: string[];
    catalogueTypes: string[];
}

export interface ICatalogueFilterExtended extends ICatelogueFilter {
    [key: string]: string[] | number[];
}

export interface CatalogueFilterProps {
    onFilterChange: (filterName: CATALOGUE_LIST_QUERY | string, filterId: number | string, isAdd: boolean, value?: string) => void;
    catalogueFilter: ICatalogueFilterExtended;
}

const catalogueFilterSchema = [
    {
        label: CATALOGUE_FILTER_HEADINGS.CLASS,
        isSearchable: false,
        key: CATALOGUE_LIST_QUERY.CLASSES,
        isExclusive: true,
        isRangeFilter: false
    },
    {
        label: CATALOGUE_FILTER_HEADINGS.SUB_CLASS,
        isSearchable: false,
        key: CATALOGUE_LIST_QUERY.CATALOGUE_TYPES,
        isExclusive: true,
        isRangeFilter: false
    },
    {
        label: CATALOGUE_FILTER_HEADINGS.MANUFACTURER,
        isSearchable: false,
        key: CATALOGUE_LIST_QUERY.MANUFACTURERS,
        isExclusive: false,
        isRangeFilter: false
    },
    {
        label: CATALOGUE_FILTER_HEADINGS.BRAND,
        isSearchable: false,
        key: CATALOGUE_LIST_QUERY.BRANDS,
        isExclusive: false,
        isRangeFilter: false
    },
    {
        label: CATALOGUE_FILTER_HEADINGS.STANDARD,
        isSearchable: true,
        key: CATALOGUE_LIST_QUERY.STANDARDS,
        isExclusive: false,
        isRangeFilter: false
    },
    {
        label: CATALOGUE_FILTER_HEADINGS.GRADE,
        isSearchable: false,
        key: CATALOGUE_LIST_QUERY.GRADES,
        isExclusive: false,
        isRangeFilter: false
    },
    {
        label: CATALOGUE_FILTER_HEADINGS.SHAPE,
        isSearchable: false,
        key: CATALOGUE_LIST_QUERY.SHAPES,
        isExclusive: false,
        isRangeFilter: false
    },
];

const useStyles = createUseStyles((theme: any) => ({
    containerHeader: {
        color: theme.palette.text.neutral.neutral800,
    },
    container: {
        border: `1px solid ${theme.palette.border.neutral.neutral100}`
    },
    customBadge: {
        "& .MuiBadge-badge": {
            color: "white",
            backgroundColor: theme.palette.background.secondary.secondary600,
            borderRadius: "4px"
        }
    }
}));

const CatalogueFilter: React.FC<CatalogueFilterProps> = ({ catalogueFilter, onFilterChange }) => {
    const classes = useStyles();
    const { metaData: filterData } = useMetaDataService();

    const [catalogueFilterSchemaState, setCatalogueFilterSchemaStateTo] = useState<any>(catalogueFilterSchema);
    const [isSecondaryClassState, setIsSecondaryClassStateTo] = useState(false);
    const [isNonStandardCatalogueClassState, setIsNonStandardCatalogueClassStateTo] = useState(false);
    const [filteredSchemaState, setFilteredSchemaStateTo] = useState([]);

    const handleChange = (filterName: CATALOGUE_LIST_QUERY | string) => (id: number | CATALOGUE_LIST_QUERY, isSelected: boolean, value?: string) => {
        onFilterChange(filterName, id, isSelected, value);
    };

    useEffect(() => {
        const isSecondaryClass = catalogueFilter?.classes[0] === CLASS_STATES.SECONDARY;
        const isNonStandardCatalogueClass = catalogueFilter?.classes[0] === CLASS_STATES.NONSTANDARD;
        const filteredSchema = isSecondaryClass ? catalogueFilterSchemaState.filter((filter: any) => ![CATALOGUE_FILTER_HEADINGS.BRAND, CATALOGUE_FILTER_HEADINGS.MANUFACTURER, CATALOGUE_FILTER_HEADINGS.STANDARD].includes(filter.label)) : catalogueFilterSchemaState;
        setIsSecondaryClassStateTo(isSecondaryClass);
        setIsNonStandardCatalogueClassStateTo(isNonStandardCatalogueClass);
        setFilteredSchemaStateTo(filteredSchema)
    }, [catalogueFilter])

    useEffect(() => {
        updateFilterSchema();
    }, [filterData])

    const updateFilterSchema = () => {
        for (const attributeName in filterData?.attributeFilters) {
            !catalogueFilterSchema.find(schema => schema.label === attributeName.trim()) &&
                catalogueFilterSchema.push({
                    label: attributeName.trim() as any,
                    isSearchable: false,
                    key: attributeName as any,
                    isExclusive: false,
                    isRangeFilter: ATTRIBUTES_WITH_RANGE.includes(toAllCapsCase(attributeName.trim()))
                });
        }
        setCatalogueFilterSchemaStateTo(catalogueFilterSchema);
    }

    const getResourceOptionsForFilters = (filterKey: any) => {
        const selectedKey = filterKey === CATALOGUE_LIST_QUERY.CATALOGUE_TYPES ? (isSecondaryClassState ? CATALOGUE_LIST_QUERY.SECONDARY_CATALOGUE_TYPES : (isNonStandardCatalogueClassState ? CATALOGUE_LIST_QUERY.NON_STANDARD_CATALOGUE_TYPES : filterKey)) : filterKey;
        if (filterData.attributeFilters && filterData.attributeFilters.hasOwnProperty(selectedKey)) {
            return filterData.attributeFilters[selectedKey];
        }
        return (filterData as any)[selectedKey];
    }

    const getResourceOptionsAppliedFilterCount = (catalogueFilter: ICatalogueFilterExtended) => {
        let resourceOptionsFilter = 0;
        try {
            for (const [key, values] of Object.entries(catalogueFilter)) {
                if (ATTRIBUTES_WITH_RANGE.includes(toAllCapsCase(key))) {
                    resourceOptionsFilter += ((typeof values[0] === 'string' && values[0].length > 0) || (typeof values[1] === 'string' && values[1].length > 0)) ? 1 : 0;
                } else {
                    resourceOptionsFilter += values.length > 0 ? 1 : 0;
                }
            }
        } catch (error) {
            console.error(error);
        }
        return resourceOptionsFilter;
    }

    return (
        <div className={`grid gap-y-10 ${classes.container} rounded-2xl bg-white py-6 px-4`}>
            <div className={`${classes.containerHeader} text-lg font-semibold flex items-center gap-x-4`}>
                <span>
                    <Badge badgeContent={getResourceOptionsAppliedFilterCount(catalogueFilter)} className={classes.customBadge}>
                        <img src={Tune} />
                    </Badge>
                </span>
                Filter By
            </div>
            <div className={`bg-white flex flex-col gap-y-5`}>
                {filteredSchemaState && filteredSchemaState.map((filter: any) => (
                    <ResourceFilter
                        key={filter.key}
                        label={filter.label}
                        isSearchable={filter.isSearchable}
                        resourceOptions={filterData && getResourceOptionsForFilters(filter.key)}
                        onChange={handleChange(filter.key)}
                        selectedOptions={catalogueFilter[filter.key as keyof ICatelogueFilter]}
                        isRangeFilter={filter.isRangeFilter}
                    />
                ))}
            </div>
        </div>
    );
};
export default CatalogueFilter;
