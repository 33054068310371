import React from 'react';
import { createUseStyles } from 'react-jss';
import ImagePreviewerv2 from '../../organisms/ImagePreviewer/ImagePreviewerV2';

export interface IImage {
	id: number;
	location: string;
	mimeType: string;
	path: string;
	resourceId: number;
	resourceType: string;
}

interface ICatalogueImageProps {
	catalogueImages?: IImage[];
	height?: any;
	width?: any;
	isSharable?: boolean;
	isRepresentationImage?: boolean;
}

const useStyles = createUseStyles((theme: any) => ({
	representationText: {
		color: "#FD6202",
	},
}));

const CatalogueImageTemplate: React.FC<ICatalogueImageProps> = ({ catalogueImages, height, width, isRepresentationImage }) => {
	const classes = useStyles();
	return <>
		<ImagePreviewerv2 images={ catalogueImages } height={ height } width={ width } defaultActiveIndex={ 0 } />
		{ isRepresentationImage && <div className={`${classes.representationText} text-sm font-medium mb-1`}>{"These images are for representation purpose only*"}</div> }
	</>;
}

export default CatalogueImageTemplate;