import React from 'react';
import { createUseStyles } from 'react-jss';
import Button from '../../atoms/Button/Button';

const useStyles = createUseStyles((theme: any) => ({
  container: {
    width: '1297px',
    margin: '0 auto',
    boxShadow: '0px 0px 5px #00000020',
  }, 
  primaryContainer: {
  
    margin: "20px 0px !important",
  },
  heading: {
    color: theme.palette.text.primary.primary800,
  },
  subHeading: {
    color: theme.palette.text.complementary.complementary300,
  },  
  primarytext: {
    color: theme.palette.text.neutral.neutral900,
  },
  content: {
    background: theme.palette.background.neutral.neutral50,
    "& p": {
        color: theme.palette.text.neutral.neutral900,
    },
    "& a": {
        color: theme.palette.text.primary.primary700,
        fontWeight: '600',
        textDecoration: 'underline',
    },
  },
  secondaryDescription: {

  },
  footerContent: {
    "& p": {
        color: theme.palette.text.neutral.neutral400,
    },
    "& a": {
        color: theme.palette.text.primary.primary700,
        fontWeight: '600',
        textDecoration: 'underline',
    },
  },
}));

const HelpCenter: React.FC = () => {

    const classes = useStyles();
    return (
        <>
       <div className={`${classes.primaryContainer} `}>
        <div className={`${classes.container} p-5`}>
            <h1 className={`${classes.heading} text-center font-bold text-4xl mb-8`}>Welcome to SteelBazaar's Help Center!</h1>
            <p className={`${classes.primarytext} mb-8 text-xl`}>We're to assist you at every step of your journey, from browsing our steel products to completing you purchase and beyond.</p>

            <div className='mb-8'>
                <h2 className={`${classes.subHeading} text-3xl font-semibold mb-4`}>1. Frequently Asked Questions (FAQs)</h2>
                <div className='flex flex-col gap-2'>
                    <div className={`${classes.content} p-2`}><p className='font-semibold text-lg'>How do I create an account?</p></div>
                    <div className={`${classes.content} p-2`}><p className='font-semibold text-lg'>How cna I search for specific steel products?</p></div>
                    <div className={`${classes.content} p-2`}><p className='font-semibold text-lg'>What are the payment options available?</p></div>
                    <div className={`${classes.content} p-2`}><p className='font-semibold text-lg'>What are your shipping and delivery policies?</p></div>
                    <div className={`${classes.content} p-2`}><p className='font-semibold text-lg'>How do I track my order?</p></div>
                    <div className={`${classes.content} p-2`}><p className='font-semibold text-lg'>Can I return or exchange products?</p></div>
                    <div className={`${classes.content} p-2`}><p className='font-semibold text-lg'>How do I contact customer support?</p></div>
                </div>
                <div className='mt-5'>
                <Button variant={'primaryContained'} label='Explore FAQs'></Button>
                </div>
            </div>

            <div className='mb-8'>
                <h2 className={`${classes.subHeading} text-3xl font-semibold mb-4`}>2. Buying Guide</h2>
                <p className={`${classes.secondaryDescription} mb-4 text-xl font-medium`}>Need help choosing the right steel products? Our step-by-step buying guide walks you through.</p>
                <div className='flex flex-col gap-2'>
                    <div className={`${classes.content} p-2`}><p className='font-semibold text-lg'>Understanding product specifications (size, weight, grade)</p></div>
                    <div className={`${classes.content} p-2`}><p className='font-semibold text-lg'>Comparing suppliers and pricing</p></div>
                    <div className={`${classes.content} p-2`}><p className='font-semibold text-lg'>Adding products to your cart</p></div>
                    <div className={`${classes.content} p-2`}><p className='font-semibold text-lg'>Completing you purchase securely</p></div>
                </div>
                <div className='mt-5'>
                <Button variant={'primaryContained'} label='Learn More'></Button>
                </div>
            </div>

            <div className='mb-8'>
                <h2 className={`${classes.subHeading} text-3xl font-semibold mb-4`}>3. Quote Management</h2>
                <p className={`${classes.secondaryDescription} mb-4 text-xl font-medium`}>Easily manage your quotes with these resources:</p>
                <div className='flex flex-col gap-2'>
                    <div className={`${classes.content} p-2`}><p className='font-semibold text-lg'><strong>Request a Quote</strong>: Learn hoe to submit a quote request for your desired products.</p></div>
                    <div className={`${classes.content} p-2`}><p className='font-semibold text-lg'><strong>View Quotes</strong>: Access your existing quotes and their details.</p></div>
                    <div className={`${classes.content} p-2`}><p className='font-semibold text-lg'><strong>Accept or Reject Quotes</strong>: Find out how to respond to quotes from suppliers.</p></div>
                    <div className={`${classes.content} p-2`}><p className='font-semibold text-lg'><strong>Negotiating Quotes</strong>: Tips on discussing pricing and terms with suppliers.</p></div>
                </div>
                <div className='mt-5'>
                <Button variant={'primaryContained'} label='Manage Quotes'></Button>
                </div>
            </div>

            
            <div className='mb-8'>
                <h2 className={`${classes.subHeading} text-3xl font-semibold mb-4`}>4. Buyer Support</h2>
                <p className={`${classes.secondaryDescription} mb-4 text-xl font-medium`}>We are here to assist you with any buyer-related queries:</p>
                <div className='flex flex-col gap-2'>
                    <div className={`${classes.content} p-2`}><p className='font-semibold text-lg'><strong>Account Issues</strong>: Resolve login problem or account access issue.</p></div>
                    <div className={`${classes.content} p-2`}><p className='font-semibold text-lg'><strong>Products Inquiries</strong>: Ask questions about specific products or specifications.</p></div>
                    <div className={`${classes.content} p-2`}><p className='font-semibold text-lg'><strong>Order Assistance</strong>: Get help with placing or managing orders.</p></div>
                    <div className={`${classes.content} p-2`}><p className='font-semibold text-lg'><strong>Feedback & Complaints</strong>: Share your feedback or file a complaint.</p></div>
                </div>
                <div className='mt-5'>
                <Button variant={'primaryContained'} label='Manage Quotes'></Button>
                </div>
            </div>

            <div className='mb-8'>
                <h2 className={`${classes.subHeading} text-3xl font-semibold mb-4`}>5. Buyer Support</h2>
                <p className={`${classes.secondaryDescription} mb-4 text-xl font-medium`}>We are here to assist you with any buyer-related queries:</p>
                <div className='flex flex-col gap-2'>
                    <div className={`${classes.content} p-2`}><p className='font-semibold text-lg'><strong>Account Issues</strong>: Resolve login problem or account access issue.</p></div>
                    <div className={`${classes.content} p-2`}><p className='font-semibold text-lg'><strong>Products Inquiries</strong>: Ask questions about specific products or specifications.</p></div>
                    <div className={`${classes.content} p-2`}><p className='font-semibold text-lg'><strong>Order Assistance</strong>: Get help with placing or managing orders.</p></div>
                    <div className={`${classes.content} p-2`}><p className='font-semibold text-lg'><strong>Feedback & Complaints</strong>: Share your feedback or file a complaint.</p></div>
                </div>
                <div className='mt-5'>
                <Button variant={'primaryContained'} label='Manage Quotes'></Button>
                </div>
            </div>

            <div className='mb-8'>
                <h2 className={`${classes.subHeading} text-3xl font-semibold mb-4`}>6. Supplier Support</h2>
                <p className={`${classes.secondaryDescription} mb-4 text-xl font-medium`}>Are you a steel supplier looking to sell on SteelBazaar? Get started with:</p>
                <div className='flex flex-col gap-2'>
                    <div className={`${classes.content} p-2`}><p className='font-semibold text-lg'>Account setup</p></div>
                    <div className={`${classes.content} p-2`}><p className='font-semibold text-lg'>Listing your products</p></div>
                    <div className={`${classes.content} p-2`}><p className='font-semibold text-lg'>Managing orders and payments</p></div>
                </div>
                <div className='mt-5'>
                <Button variant={'primaryContained'} label='Supplier Support'></Button>
                </div>
            </div>

            <div className='mb-8'>
                <h2 className={`${classes.subHeading} text-3xl font-semibold mb-4`}>6. Contact Us</h2>
                <p className={`${classes.secondaryDescription} mb-4 text-xl font-medium`}>Need personalized help? Our customer support team is here for you.</p>
                <div className='flex flex-col gap-2'>
                    <div className={`${classes.content} p-2`}><p className='font-semibold text-lg'>Email us: <a href="#">support@steelbazaar.com</a></p></div>
                    <div className={`${classes.content} p-2`}><p className='font-semibold text-lg'>Call us: 1800-123-STEEL (Monday to Friday, 9AM - 6PM)</p></div>
                    <div className={`${classes.content} p-2`}><p className='font-semibold text-lg'>Live Chat : Chat with is for instant help.</p></div>
                </div>
                <div className='mt-5'>
                <Button variant={'primaryContained'} label='Contact Support'></Button>
                </div>
            </div>


            <div className={`${classes.footerContent} text-center text-lg`}><p>Need more help? Vcall our <a href="#">Community Forum</a> or check out our <a href="#">Blog</a> for tips, news, and expert advice on steel trading.</p></div>
            </div>
       </div>
        </>
    );
};
export default HelpCenter;
